import React, { useEffect, useState } from 'react';
import { Button, Header } from '../../components';
import { BASE_URL } from '../../config';
import { Autocomplete, Switch } from '@mui/material';
import { useStateContext } from '../../contexts/ContextProvider';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { MdArrowBack, MdSave } from 'react-icons/md';
import CurrencyInput from 'react-currency-input-field';

const InsertProduct = () => {
	const navigate = useNavigate();
	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		activeMenu,
	} = useStateContext();

	const [jenisProduk, setJenisProduk] = useState(true);
	const [paket, setPaket] = useState(true);
	const [statusStok, setStatusStok] = useState(false);
	const [kodeProduk, setKodeProduk] = useState('');
	const [nama, setNama] = useState('');
	const [hargaJual, setHargaJual] = useState(0);
	const [gambar, setGambar] = useState(null);
	const [idCorp, setIdCorp] = useState(0);
	const [idKategori, setIdKategori] = useState(0);
	const [hargaRombongan, setHargaRombongan] = useState(0);
	const [minRombongan, setMinRombongan] = useState(0);
	const [currentStok, setCurrentStok] = useState(0);
	const [idSatuan, setIdSatuan] = useState(0);

	const notify = (message) => {
		toast.success(message, {
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};
	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
			// onClose: () => navigate(-1),
		});
	};

	// get data kategori produk
	const [dataKategori, setDataKategori] = useState([]);
	const getKategoriProduk = () => {
		fetch(BASE_URL + '/kategoriproduk/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					setDataKategori(data.result);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//get satuan produk
	const [dataSatuan, setDataSatuan] = useState([]);
	const getSatuanProduk = () => {
		fetch(BASE_URL + '/satuanproduk/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setDataSatuan(data.result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// get data corporate
	const [dataCorp, setDataCorp] = useState([]);
	const getCorp = () => {
		const dataBody = {
			order: 'ASC',
			// limit: 100,
			draw: 2,
			page: 1,
			hirarkiId: hirarkiUser,
		};
		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.result != null) {
					setDataCorp(data.result);
				} else {
					setDataCorp([]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getKategoriProduk();
		getSatuanProduk();
		getCorp();
	}, []);

	//insert data produk
	const insertProduk = (e) => {
		e.preventDefault();
		const dataInput = {
			kodeProduk: kodeProduk,
			nama: nama,
			hargaJual: parseInt(hargaJual),
			gambar: gambar,
			jenis: jenisProduk,
			idCorporate: parseInt(idCorp),
			idSatuanProduk: parseInt(idSatuan),
			idKategoriProduk: parseInt(idKategori),
			hargaRombongan: parseInt(hargaRombongan),
			minRombongan: parseInt(minRombongan),
			statusStok: statusStok,
			currentStok: parseInt(currentStok),
			statusPaket: paket,
		};
		fetch(BASE_URL + '/produk/add', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					notify(json.message);
				} else {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				alert(err);
			});
	};

	const onChangeKategori = (event) => {
		setIdKategori(event.target.value);
	};
	const onChangeSatuan = (event) => {
		setIdSatuan(event.target.value);
	};
	const onChangeCorp = (event, values) => {
		setIdCorp(values.value);
	};

	const onChangeCurrency = (e) => {
		e.preventDefault();
		const { value = '' } = e.target;
		const parsedValue = value.replace(/[^\d.]/gi, '');
		setHargaJual(parsedValue);
	};

	const onChangeRombongan = (e) => {
		e.preventDefault();
		const { value = '' } = e.target;
		const parsedValue = value.replace(/[^\d.]/gi, '');
		setHargaRombongan(parsedValue);
	};

	const corpData = [];
	{
		dataCorp.map((item) => {
			let dataValue = {
				value: item.id,
				label: item.uraian,
			};
			corpData.push(dataValue);
		});
	}

	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={insertProduk}
		>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10 rounded-3xl bg-white dark:bg-white">
				{/* <button onClick={() => alert(statusStok)}>CLICK</button> */}

				<Header title={'Tambah Produk'} />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>
				<div className=" gap-5 justify-center  flex">
					<div className=" w-3/6">
						<div className=" mb-5 w-full">
							<TextField
								className="w-full"
								required
								id="kode-produk"
								label="Kode Produk"
								variant="outlined"
								onChange={(text) => setKodeProduk(text.target.value)}
								placeholder="TW-001, AK001,..."
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="mb-5 w-full">
							<CurrencyInput
								required
								className="w-full"
								label="Harga Jual"
								InputLabelProps={{ shrink: true }}
								customInput={TextField}
								data-number-to-fixed="3"
								data-number-stepfactor="100"
								onChange={onChangeCurrency}
								// value={servicefee}
								// className="e-input"
								// type="number"
								placeholder="10.000"
							/>
						</div>
						<div className=" ml-2 mb-5 w-full justify-center">
							<p className=" mb-3 text-gray-500 text-sm">
								Jenis Produk{' '}
							</p>
							<div className="">
								<input
									type="radio"
									id="ticketing"
									name="ticket"
									value="ticketing"
									className=" cursor-pointer "
									onChange={() => setJenisProduk(true)}
									checked={jenisProduk === true}
								/>
								<label
									htmlFor="ticketing"
									className="ml-2 text-base cursor-pointer"
								>
									Ticketing
								</label>
							</div>
							<div className="">
								<input
									type="radio"
									id="nonticketing"
									name="ticket  "
									value="nonticketing"
									className=" cursor-pointer"
									onChange={() => setJenisProduk(false)}
									checked={jenisProduk === false}
								/>
								<label
									htmlFor="nonticketing"
									className="ml-2 text-base cursor-pointer"
								>
									Non-Ticketing
								</label>
							</div>
						</div>
					</div>

					<div className=" w-full">
						<div className=" mb-5 w-full">
							<TextField
								className="w-full"
								required
								id="nama-produk"
								label="Nama Produk"
								variant="outlined"
								onChange={(text) => setNama(text.target.value)}
								placeholder="Tiket, Paket A,..."
								InputLabelProps={{ shrink: true }}
							/>
						</div>
						<div className="flex mb-5 gap-5">
							<div className=" w-full">
								<CurrencyInput
									required
									className="w-full"
									label="Harga Rombongan"
									InputLabelProps={{ shrink: true }}
									customInput={TextField}
									data-number-to-fixed="3"
									data-number-stepfactor="100"
									onChange={onChangeRombongan}
									// value={servicefee}
									// className="e-input"
									// type="number"
									placeholder="10.000"
								/>
							</div>
							<div className=" w-full">
								<TextField
									className="w-full"
									required
									id="min-rombongan"
									label="Minimal Rombongan"
									variant="outlined"
									onChange={(text) =>
										setMinRombongan(text.target.value)
									}
									placeholder="1, 2, ..."
									type="number"
									InputLabelProps={{ shrink: true }}
								/>
							</div>
						</div>
						<div className=" ml-2 mb-5 w-full justify-center">
							<p className=" mb-3 text-gray-500 text-sm">
								Status Paket{' '}
							</p>
							<div className="">
								<input
									type="radio"
									id="paket"
									name="paket"
									value="paket"
									className=" cursor-pointer "
									onChange={() => setPaket(true)}
									defaultChecked={paket === true}
								/>
								<label
									htmlFor="paket"
									className="ml-2 text-base cursor-pointer"
								>
									Paket
								</label>
							</div>
							<div className="">
								<input
									type="radio"
									id="nonpaket"
									name="paket"
									value="nonpaket"
									className=" cursor-pointer"
									onChange={() => setPaket(false)}
									defaultChecked={paket === false}
								/>
								<label
									htmlFor="nonpaket"
									className="ml-2 text-base cursor-pointer"
								>
									Non-Paket
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className=" flex w-full gap-5 mt-3">
					<div className=" w-full">
						<FormControl required fullWidth>
							<InputLabel id="select-jenisuser">
								Kategori Produk
							</InputLabel>
							<Select
								required
								labelId="select-jenisuser"
								id="select-jenisuser"
								// value={age}
								label="Kategori Produk"
								onChange={onChangeKategori}
								// defaultValue={0}
							>
								<MenuItem disabled={true} value={0}>
									- Pilih Kategori Produk -
								</MenuItem>
								{dataKategori.map((Data) => (
									<MenuItem key={Data.id} value={Data.id}>
										{Data.uraian}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						{/* corporate insert */}
						<div className=" w-full -mr-0 md:-mr-0 mt-5">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="combo-box-demo"
								options={corpData}
								onChange={onChangeCorp}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Corporate"
										// InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
					</div>
					<div className="  w-full">
						<FormControl required fullWidth>
							<InputLabel id="select-satuan">Satuan</InputLabel>
							<Select
								required
								labelId="select-satuan"
								id="select-satuan"
								// value={age}
								label="Satuan"
								onChange={onChangeSatuan}
								// defaultValue={0}
							>
								<MenuItem disabled={true} value={0}>
									- Pilih Satuan -
								</MenuItem>
								{dataSatuan.map((Data) => (
									<MenuItem key={Data.id} value={Data.id}>
										{Data.uraian}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>

				<div className=" w-full flex gap-5 mb-5 mt-5">
					<div className=" ml-3 w-full">
						<p className=" mb-2 text-gray-500 text-sm">Status Stok</p>
						<div className=" flex">
							<p className="  mt-2 text-gray-500 text-sm">Off</p>
							<Switch
								defaultChecked={statusStok === true}
								onChange={() => setStatusStok(!statusStok)}
							/>
							<p className=" mt-2 text-gray-500 text-sm">On</p>
						</div>
					</div>
					<div className=" w-full">
						<TextField
							// disabled={!statusStok}
							className="w-full"
							required
							id="stok"
							label="Stok Saat Ini"
							variant="outlined"
							placeholder="1, 2, ..."
							InputLabelProps={{ shrink: true }}
							onChange={(e) => setCurrentStok(e.target.value)}
							type="number"
						/>
					</div>
				</div>
				<div className=" mt-3 justify-between  flex gap-5">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						borderRadius={'10px'}
						color="white"
						text="Previous Page"
						icon={<MdArrowBack />}
					/>
					<Button
						// customFunc={() => insertProduk()}
						type="submit"
						bgColor={currentColor}
						borderRadius={'10px'}
						color="white"
						text="Submit"
						icon={<MdSave />}
					/>
				</div>
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
			</div>
		</form>
	);
};

export default InsertProduct;
