import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_AQUIRING_LIST,
	URL_GATEWAT_DEBIT_SEGMEN_DETAIL_ADD,
	URL_ISSUING_LIST,
} from '../../../config';
import TextField from '@mui/material/TextField';
import { Autocomplete, InputAdornment } from '@mui/material';
import { urlDecrypt } from '../../../utils/CryptoUtils';
import _ from 'lodash';

var CryptoJS = require('crypto-js');

const GatewaySegmentasiDetailAdd = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose, setAuth } =
		useStateContext();

	const navigate = useNavigate();
	const { Id } = useParams();

	const [dataMerchant, setDataMerchant] = useState(null);

	const [dataIssuer, setDataIssuer] = useState([]);
	const [dataAcquiring, setDataAcquiring] = useState([]);
	const [kodeBankIssuing, setKodeBankIssuing] = useState('');
	const [kodeBankAcquiring, setKodeBankAcquiring] = useState('');

	const [dataTransType, setDataTransType] = useState([
		{ value: 1, label: 'DEBIT' },
		{ value: 2, label: 'CREDIT' },
	]);

	const [idTipeTransaksi, setIdTipeTransaksi] = useState(null);
	const [mdrPercentage, setMdrPercentage] = useState(0);
	const [transactionFee, setTransactionFee] = useState(0);
	const [paymentPid, setPaymentPid] = useState('');

	const [status, setStatus] = useState(false);

	const dataUpdate = (e) => {
		e.preventDefault();
		modalOpenClose(true);

		const inputData = {
			idroutesegmen: dataMerchant ? dataMerchant.idRouteSegmen : null,
			data: [
				{
					kodeBankAcquiring: kodeBankAcquiring,
					kodeBankIssuing: kodeBankIssuing,
					idTipeTransaksi: idTipeTransaksi,
					mdrPercentage: mdrPercentage
						? parseFloat(mdrPercentage)
						: 0,
					transactionFee: transactionFee
						? parseFloat(transactionFee)
						: 0,
					paymentPid: paymentPid,
				},
			],
		};

		fetch(BASE_URL + URL_GATEWAT_DEBIT_SEGMEN_DETAIL_ADD, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	const getAquiring = () => {
		let mData = {
			order: 'ASC',
			orderBy: 'id',
			limit: 999,
			page: 1,
		};

		fetch(BASE_URL + URL_AQUIRING_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.success === true) {
					if (json.result != null) {
						let shortName = _.sortBy(json.result, 'acquiringName');

						let objData = shortName
							.filter((m) => m.bankCode)
							.map((o) => {
								o.label = o.acquiringName;
								o.value = o.bankCode;

								return o;
							});

						setDataAcquiring(objData);
					} else {
						setDataAcquiring([]);
					}
				} else {
					setDataAcquiring([]);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	const getIssuer = () => {
		modalOpenClose(true);

		let mData = {
			order: 'DESC',
			orderBy: 'id',
			Keyword: '',
		};

		fetch(BASE_URL + URL_ISSUING_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);

				if (json.success === true) {
					if (json.result != null) {
						let shortName = _.sortBy(json.result, 'issuingShortName');

						let objData = shortName.map((o) => {
							o.label = o.issuingShortName;
							o.value = o.bankCode;

							return o;
						});

						setDataIssuer(objData);
					} else {
						setDataIssuer([]);
					}
				} else {
					setDataIssuer([]);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	useEffect(() => {
		getAquiring();
		getIssuer();
	}, []);

	useEffect(() => {
		if (Id) {
			var data = urlDecrypt(Id);

			if (data != null) {
				setDataMerchant(data);
			} else {
				notifyErr('Invalid URL');
			}
		}
	}, [Id]);

	const handleChangeTransType = (event, values) => {
		if (values != null) {
			setIdTipeTransaksi(values.value);
		} else {
			setIdTipeTransaksi(null);
		}
	};

	const handleChangeIssuing = (event, values) => {
		if (values != null) {
			setKodeBankIssuing(values.value);
		} else {
			setKodeBankIssuing(null);
		}
	};

	const handleChangeAquiring = (event, values) => {
		if (values != null) {
			setKodeBankAcquiring(values.value);
		} else {
			setKodeBankAcquiring(null);
		}
	};

	const currentIndexTransType = (kodeTransType) =>
		dataTransType && dataTransType.length > 0
			? dataTransType.findIndex((item) => {
					return item.value === kodeTransType;
			  })
			: 0;

	const currentIndexIssuing = (kodeBankIssuing) =>
		dataIssuer && dataIssuer.length > 0
			? dataIssuer.findIndex((item) => {
					return item.value === kodeBankIssuing;
			  })
			: 0;

	const currentIndexAcquiring = (kodeBankAcquiring) =>
		dataAcquiring && dataAcquiring.length > 0
			? dataAcquiring.findIndex((item) => {
					return item.value === kodeBankAcquiring;
			  })
			: 0;

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="New Segmentation Detail" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Segmentation Name"
						variant="outlined"
						placeholder="Segmentation Name"
						InputLabelProps={{ shrink: true }}
						InputProps={{
							readOnly: true,
						}}
						value={dataMerchant ? dataMerchant.namaRouteSegmen : null}
					/>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndexIssuing}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectIssuer"
								options={dataIssuer}
								onChange={handleChangeIssuing}
								defaultValue={
									dataIssuer ? dataIssuer[currentIndexIssuing] : null
								}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Issuer"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndexTransType}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectTransType"
								options={dataTransType}
								onChange={handleChangeTransType}
								defaultValue={
									dataTransType
										? dataTransType[currentIndexTransType]
										: null
								}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Transaction Type"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndexAcquiring}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectIssuer"
								options={dataAcquiring}
								onChange={handleChangeAquiring}
								defaultValue={
									dataAcquiring
										? dataAcquiring[currentIndexAcquiring]
										: null
								}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Acquiring"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="MDR Percentage"
						variant="outlined"
						onChange={(text) => setMdrPercentage(text.target.value)}
						placeholder="MDR Percentage"
						type={'number'}
						InputLabelProps={{ shrink: true }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">%</InputAdornment>
							),
						}}
						value={mdrPercentage}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Transaction Fee"
						variant="outlined"
						type={'number'}
						onChange={(text) => setTransactionFee(text.target.value)}
						placeholder="Transaction Fee"
						InputLabelProps={{ shrink: true }}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">Rp</InputAdornment>
							),
						}}
						value={transactionFee}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Payment PID"
						variant="outlined"
						placeholder="Payment PID"
						InputLabelProps={{ shrink: true }}
						onChange={(text) => setPaymentPid(text.target.value)}
						value={paymentPid}
					/>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default GatewaySegmentasiDetailAdd;
