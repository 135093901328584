import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL } from '../../../config';

import { TextField } from '@mui/material';

const SettlementKeyAdd = () => {
	const navigate = useNavigate();

	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, setAuth, activeMenu } =
		useStateContext();

	const [value, setValue] = useState('');
	const [idCorp, setIdCorp] = useState(0);
	const [Data, setData] = useState([]);
	const [dataUser, setDataUser] = useState([]);
	const [nama, setNama] = useState('');

	const getCorp = () => {
		const dataBody = {
			order: 'ASC',
			// limit: 100,
			draw: 2,
			page: 1,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					setData(json.result);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const [workerUser, setWorkerUser] = useState();

	useEffect(() => {
		getCorp();
	}, []);

	const dataInput = (e) => {
		e.preventDefault();

		const inputData = {
			nama: nama,
			value: value,
			workerUsername: workerUser,
		};

		fetch(BASE_URL + '/core/add', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				let message = json.message;
				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((err) => {
				notifyErr(err.message);
			});
	};

	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={dataInput}
		>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title={'New Settlement Key'} />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				{/* <button onClick={() => alert(nama)}>CLICK</button> */}
				<div className=" w-full gap-8 flex">
					<div className=" mb-5 w-full">
						<TextField
							className="w-full"
							required
							id="value"
							label="Value"
							variant="outlined"
							placeholder="Value"
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setValue(text.target.value)}
						/>
					</div>
					<div className=" mb-5 w-full">
						<TextField
							className="w-full"
							required
							id="nama"
							label="Nama"
							variant="outlined"
							placeholder="Nama"
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setNama(text.target.value)}
						/>
					</div>
				</div>

				<div className=" flex mb-5 mt-2 gap-5">
					<div className=" mb-5 w-full">
						<TextField
							className="w-full"
							required
							id="username-worker"
							label="Username Worker"
							variant="outlined"
							placeholder="Username Worker"
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setWorkerUser(text.target.value)}
						/>
					</div>
				</div>

				<div className=" flex gap-5 justify-between">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Previous Page"
						color={'white'}
						icon={<MdArrowBack />}
					/>
					<Button
						// customFunc={() => dataInput()}
						type="submit"
						bgColor={currentColor}
						borderRadius={'10px'}
						color="white"
						height="fit"
						width="60"
						text="Submit"
						icon={<MdSave />}
					/>
				</div>
			</div>
		</form>
	);
};

export default SettlementKeyAdd;
