import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '../../../components';
import { BASE_URL } from '../../../config';

import DataTable from 'react-data-table-component';
import { Button } from '../../../components';
import { useStateContext } from '../../../contexts/ContextProvider';
import { MdEdit, MdSearch } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import { ActionCell, Enc } from './DataTableComponent';
import { checkIsAvailableModule } from '../../../Routes';
import _ from 'lodash';
import { FiRefreshCw } from 'react-icons/fi';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '8px',
			paddingRight: '8px',
		},
	},
};

const User = () => {
	const {
		currentAuth,
		setAuth,
		privilege,
		hirarkiUser,
		screenWidth,
		modalOpenClose,
	} = useStateContext();

	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');

	const [totalRows, setTotalRows] = useState();

	const [order, setOrder] = useState('asc');
	const [header, setHeader] = useState('nama');

	const [pending, setPending] = useState(false);
	// -----

	const searchData = () => {
		setSearch(keyword);
	};

	const getDataUser = (page, totalData = limit) => {
		modalOpenClose(true);
		const dataBody = {
			order: order,
			limit: totalData,
			page: parseInt(page),
			keyword: search,
			orderBy: header,
			hirarkiId: corporate ? corporate : hirarkiUser,
		};
		fetch(BASE_URL + '/user/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					console.log(data.result);
					if (data.result != null) {
						console.log(data.result);
						setData(data.result);
						setCount(data.countData);
						setTotalRows(data.countData);
						modalOpenClose(false);
					} else {
						setData([]);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	useEffect(() => {
		getDataUser(1);
	}, [search, order, header]);

	const { activeMenu, currentColor } = useStateContext();

	const handlePageChange = (page) => {
		getDataUser(page);
		setCurrentPage(page);
	};

	const handlePerRowsChange = (totalData, page) => {
		getDataUser(page, totalData);
		setLimit(totalData);
	};

	const customSort = (rows, selector, direction) => {
		console.log(selector);
		setHeader(rows.selector);
		setOrder(selector);
	};

	const [userColumn, setUserColumn] = useState([
		{
			name: 'User Type',
			selector: 'jenisUser',
			sortable: true,
			width: 140,
			cell: (row) => (
				<div style={{ alignSelf: 'center' }}>
					<button
						type="button"
						style={
							row.jenisUser === 'Dashboard'
								? { background: '#E1F5FE' }
								: row.jenisUser === 'MPOS'
								? { background: '#FFF3E0' }
								: row.jenisUser === 'INTENT'
								? { background: '#E8F5E9' }
								: row.jenisUser === 'QPROX'
								? { background: '#E1F5FE' }
								: row.jenisUser === 'EDC'
								? { background: '#E8F5E9' }
								: { background: 'Red' }
						}
						className={`py-2 px-3 text-sm capitalize rounded-2xl`}
					>
						<p
							style={
								row.jenisUser === 'Dashboard'
									? { color: '#339EF4' }
									: row.jenisUser === 'MPOS'
									? { color: '#FFAD33' }
									: row.jenisUser === 'INTENT'
									? { color: '#4CAF50' }
									: row.jenisUser === 'QPROX'
									? { color: '#339EF4' }
									: row.jenisUser === 'EDC'
									? { color: '#4CAF50' }
									: { color: 'Red' }
							}
						>
							{row.jenisUser}
						</p>
					</button>
				</div>
			),
		},
		{
			name: 'Name',
			selector: 'nama',
			sortable: true,
			width: 200,
			cell: (row) => <div className=" ">{row.nama}</div>,
		},
		{
			name: 'Username',
			selector: 'username',
			sortable: true,
			width: 140,
			cell: (row) => <div className=" ">{row.username}</div>,
		},
		{
			name: 'Corporate',
			selector: 'namaCorporate',
			sortable: true,
			width: 200,
			cell: (row) => <div className=" ">{row.namaCorporate}</div>,
		},
		{
			name: 'Email',
			selector: 'email',
			sortable: true,
			width: 250,
			cell: (row) => <div className=" ">{row.email}</div>,
		},
		{
			name: 'Enc Credential',
			selector: 'encCredentials',
			sortable: true,
			width: 200,
		},
		{
			name: '',
			cell: (row) => Enc(row),
		},
		{
			name: 'Callback Data',
			selector: 'callbackData',
			sortable: true,
			width: 200,
			cell: (row) => (
				<div className=" ">{row.callbackData ? 'YES' : 'NO'}</div>
			),
		},
	]);

	useEffect(() => {
		if (
			checkIsAvailableModule('EDIT_CONFIG_USER', privilege) &&
			checkIsAvailableModule('DELETE_CONFIG_USER', privilege)
		) {
			let addColumn = _.cloneDeep(userColumn);
			addColumn.unshift({
				name: 'Action',
				cell: (row) => ActionCell(row, () => handlePageChange(1)),
			});

			setUserColumn(addColumn);
		}
	}, []);

	// filter corporate states and functions
	const [dataCorporate, setDataCorporate] = useState([]);
	const [corporate, setCorporate] = useState('');
	const [corpData, setCorpData] = useState([]);
	const [value, setValue] = useState({
		label: '- All Corporate -',
		value: '',
	});
	const [dropdownSearch, setDropdownSearch] = useState('');
	const [searchTimeoutId, setSearchTimeoutId] = useState(null);
	const [corporateLoading, setCorporateLoading] = useState(false);

	const corpDropdown = () => {
		const data = dataCorporate.map((item) => ({
			value: item.hirarkiId,
			label: item.uraian,
		}));

		setCorpData(data);
	};

  const getCorporate = () => {
    const bodyData = {
      order: "ASC",
      page: 1,
      limit: 200,
      keyword: dropdownSearch,
      hirarkiId: hirarkiUser,
    };
    // setCorporateLoading(true);

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setDataCorporate(data.result);
						// console.log(data.result);
					} else {
						setDataCorporate([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			})
			.finally(() => {
				setCorporateLoading(false);
			});
	};

	const handleClick = () => {
		getDataUser(1);
	};

	const onChangeCorp = (event, values) => {
		console.log('value', values);
    	setCorporateLoading(false);
		setValue(values);
		setCorporate(values.value);
	};

	useEffect(() => {
		getCorporate();
	}, []);

  useEffect(() => {
    if (dataCorporate.length > 0) {
      corpDropdown();
    }
  }, [dataCorporate]);

	// Cleanup function to clear timeout on unmount
	useEffect(() => {
		return () => {
			if (searchTimeoutId) {
				clearTimeout(searchTimeoutId);
			}
		};
	}, [searchTimeoutId]); // Dependency on searchTimeoutId

  return (
    <>
      <div
        className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4  md:p-10  rounded-3xl bg-white 
      ${activeMenu === true ? " max-w-full" : " max-w-full"}`}
      >
        <p className=" text-base font-semibold mb-7 ">Filter Users</p>
        {/* select Transaction Status dan Payment Category */}
        <div className="grid grid-cols-12 gap-3 mb-7">
          {/* corporate insert */}
          <div className="w-full -mr-0 md:-mr-0 col-span-12 sm:col-span-6">
            <Autocomplete
              required
              fullWidth={true}
              style={{
                width: "auto",
              }}
              disablePortal
              id="combo-box-demo"
              options={corpData}
              value={value}
              onChange={onChangeCorp}
              inputValue={dropdownSearch}
              onInputChange={(event, newInputValue) => {
                setCorporateLoading(true);
                setDropdownSearch(newInputValue);
                // Clear any existing timeout before starting a new one
                if (searchTimeoutId) {
                  clearTimeout(searchTimeoutId);
                }
                setSearchTimeoutId(
                  setTimeout(() => {
                    dropdownSearch && getCorporate();
                  }, 2000)
                );
              }}
              disableClearable // This prop disables the clear button
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Corporate"
                  placeholder="- All Corporate -"
                  // InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {corporateLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          value !== null &&
                          dropdownSearch !== "" && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear"
                                onClick={() => {
                                  setDropdownSearch("");
                                  setValue(null);
                                  setCorporate("");
                                  // setDataCorporate([]);
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
        <Button
          customFunc={() => handleClick()}
          bgColor={currentColor}
          color="white"
          text="Filter"
          borderRadius={"10px"}
          icon={<FiRefreshCw />}
        />
      </div>
      <div
        className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4  md:p-10  rounded-3xl bg-white 
      ${activeMenu === true ? " max-w-full" : " max-w-full"}`}
      >
        <div className="flex justify-between">
          <Header title={"Users Data"} />
          {checkIsAvailableModule("ADD_CONFIG_USER", privilege) && (
            <Button
              customFunc={() => navigate("/insert-user")}
              text="New Data +"
              bgColor={currentColor}
              color="white"
              height="fit"
              borderRadius="10px"
            />
          )}
        </div>

				<div className=" mt-5 md:-mt-5 flex md:justify-end">
					<div className=" w-full md:w-3/12 mb-2 mt-2  flex end">
						<FormControl className=" w-full" variant="outlined">
							<OutlinedInput
								size="small"
								notched
								id="outlined-adornment-password"
								type={'text'}
								// value={values.password}
								placeholder="Search"
								onChange={(text) => setKeyword(text.target.value)}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										searchData();
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={() => searchData()}
											edge="end"
										>
											<MdSearch />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
				</div>
				<DataTable
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					columns={userColumn}
					data={data}
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					onSort={customSort}
					sortServer
					theme="solarized"
					customStyles={customStyles}
					style={{ width: '20%' }}
				/>
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
			</div>
		</>
	);
};

export default User;
