import React, { useState, useEffect, useMemo } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Header } from "../../../components";
import { useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { Button } from "../../../components";
import { BASE_URL } from "../../../config";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEdit, MdRemoveRedEye, MdSearch } from "react-icons/md";
import { RiLoader4Line } from "react-icons/ri";
import notfound from "../../../data/notfound.png";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { DeviceAction, DeviceView, MerchKey } from "./DataTableComponent";
import { checkIsAvailableModule } from "../../../Routes";
import _ from "lodash";
import { FiRefreshCw } from "react-icons/fi";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";

const customStyles = {
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "700",
      paddingLeft: "0 8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "0px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};

const Device = () => {
  const {
    currentColor,
    currentAuth,
    setAuth,
    privilege,
    hirarkiUser,
    screenWidth,
    modalOpenClose,
    activeMenu,
  } = useStateContext();

  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState(1);
  const [count, setCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [header, setHeader] = useState("");

  const [pending, setPending] = useState(false);

  // get Data Device
  const [dataDevice, setDataDevice] = useState([]);

  const getDataDevice = (page = pagination, totalData = limit) => {
    modalOpenClose(true);

    const bodyData = {
      order: order,
      limit: totalData,
      page: page,
      keyword: search,
      orderBy: header,
      hirarkiId: corporate ? corporate : hirarkiUser,
    };
    fetch(BASE_URL + "/device/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentAuth}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.statusCode === "401") {
          setAuth(null);
          navigate("/");
          localStorage.removeItem("auth");
          modalOpenClose(false);
        } else {
          if (data.result != null) {
            console.log(data.result);
            setDataDevice(data.result);
            setCount(data.countData);
            modalOpenClose(false);
          } else {
            setDataDevice([]);
            modalOpenClose(false);
          }
        }
      })
      .catch((err) => {
        console.log("err =>", err);
        modalOpenClose(false);
      });
  };

  useEffect(() => {
    getDataDevice();
  }, [search, order, header]);

  const handlePerRowsChange = (totalData, page) => {
    getDataDevice(page, totalData);
    setLimit(totalData);
  };

  const handlePageChange = (page) => {
    getDataDevice(page);
    setCurrentPage(page);
  };

  const searchData = () => {
    setSearch(keyword);
  };

  const customSort = (rows, selector, direction) => {
    console.log(selector);
    setHeader(rows.selector);
    setOrder(selector);
  };

  const [deviceColumn, setDeviceColumn] = useState([
    {
      name: "Device ID",
      width: 160,
      cell: (row) => DeviceView(row),
    },
    {
      name: "Corporate",
      selector: "namaCorporate",
      sortable: true,
      width: 230,
    },
    {
      name: "Merchant Key",
      selector: "merchantKey",
      sortable: true,
      width: 200,
    },
    {
      name: "",
      cell: (row) => MerchKey(row),
    },
    {
      name: "Settlement Key",
      selector: "CSKName",
      sortable: true,
      width: 250,
    },
    {
      name: "DKI TID",
      selector: "dkiTid",
      sortable: true,
      width: 160,
    },
    {
      name: "DKI MID",
      selector: "dkiMid",
      sortable: true,
      width: 160,
    },
    {
      name: "MKP MID",
      selector: "mid",
      sortable: true,
      width: 160,
    },
    {
      name: "Ops Outlet ID",
      selector: "opsOutletCode",
      sortable: true,
      width: 160,
    },
  ]);

  useEffect(() => {
    if (
      checkIsAvailableModule("EDIT_CONFIG_DEVICE", privilege) &&
      checkIsAvailableModule("DELETE_CONFIG_DEVICE", privilege)
    ) {
      let addColumn = _.cloneDeep(deviceColumn);
      addColumn.unshift({
        name: "Action", //useTemplate
        selector: "",
        sortable: true,
        width: 100,
        cell: (row) => DeviceAction(row, () => handlePageChange(1)),
      });

      setDeviceColumn(addColumn);
      // getCorporate();
    }
  }, []);

  //   filter corporate state and functions
  const [dataCorporate, setDataCorporate] = useState([]);
  const [corporate, setCorporate] = useState("");
  const [corpData, setCorpData] = useState([]);
  const [value, setValue] = useState({
    label: "- All Corporate -",
    value: "",
  });
  const [dropdownSearch, setDropdownSearch] = useState("");
  const [searchTimeoutId, setSearchTimeoutId] = useState(null);
  const [corporateLoading, setCorporateLoading] = useState(false);

  const corpDropdown = () => {
    const data = dataCorporate.map((item) => ({
      value: item.hirarkiId,
      label: item.uraian,
    }));

    setCorpData(data);
  };

  //   handle fetch corporate
  const getCorporate = () => {
    const bodyData = {
      order: "ASC",
      page: 1,
      limit: 200,
      keyword: dropdownSearch,
      hirarkiId: hirarkiUser,
    };

    fetch(BASE_URL + "/corporate/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentAuth}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.statusCode === "401") {
          setAuth(null);
          navigate("/");
          localStorage.removeItem("auth");
        } else {
          if (data.result != null) {
            setDataCorporate(data.result);
          } else {
            setDataCorporate([]);
          }
        }
      })
      .catch((err) => {
        console.log("err =>", err);
      })
      .finally(() => {
        setCorporateLoading(false);
      });
  };

  const handleClick = () => {
    getDataDevice();
  };

  const onChangeCorp = (event, values) => {
    setCorporateLoading(false);
    setValue(values);
    setCorporate(values.value);
  };

  useEffect(() => {
    getCorporate();
  }, []);

  useEffect(() => {
    if (dataCorporate.length > 0) {
      corpDropdown();
    }
  }, [dataCorporate]);

  // Cleanup function to clear timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutId) {
        clearTimeout(searchTimeoutId);
      }
    };
  }, [searchTimeoutId]); // Dependency on searchTimeoutId

  return (
    <>
      <div
        className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4  md:p-10  rounded-3xl bg-white 
      ${activeMenu === true ? " max-w-full" : " max-w-full"}`}
      >
        <p className=" text-base font-semibold mb-7 ">Filter Devices</p>
        {/* select Transaction Status dan Payment Category */}
        <div className="grid grid-cols-12 gap-3 mb-7">
          {/* corporate insert */}
          <div className="w-full -mr-0 md:-mr-0 col-span-12 sm:col-span-6">
            <Autocomplete
              required
              fullWidth={true}
              style={{
                width: "auto",
              }}
              disablePortal
              id="combo-box-demo"
              options={corpData}
              value={value}
              onChange={onChangeCorp}
              inputValue={dropdownSearch}
              onInputChange={(event, newInputValue) => {
                setCorporateLoading(true);
                setDropdownSearch(newInputValue);
                // Clear any existing timeout before starting a new one
                if (searchTimeoutId) {
                  clearTimeout(searchTimeoutId);
                }

                setSearchTimeoutId(
                  setTimeout(() => {
                    dropdownSearch && getCorporate();
                  }, 2000)
                );
              }}
              disableClearable // This prop disables the clear button
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Corporate"
                  placeholder="- All Corporate -"
                  // InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {corporateLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          value !== null &&
                          dropdownSearch !== "" && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear"
                                onClick={() => {
                                  setDropdownSearch("");
                                  setValue(null);
                                  setCorporate("");
                                  // setDataCorporate([]);
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
        <Button
          customFunc={() => handleClick()}
          bgColor={currentColor}
          color="white"
          text="Filter"
          borderRadius={"10px"}
          icon={<FiRefreshCw />}
        />
      </div>
      <div
        className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white `}
      >
        <div className="flex justify-between">
          <Header title={"Payment Devices"} />
          {checkIsAvailableModule("ADD_CONFIG_DEVICE", privilege) && (
            <Button
              customFunc={() => navigate("/insert-device")}
              text="New Data +"
              bgColor={currentColor}
              color="white"
              height="fit"
              borderRadius="10px"
            />
          )}
        </div>
        <div className="mt-5 md:-mt-5 flex md:justify-end">
          <div className=" w-full md:w-3/12 mb-2 mt-2  flex end">
            <FormControl className=" w-full" variant="outlined">
              <OutlinedInput
                size="small"
                notched
                id="outlined-adornment-password"
                type={"text"}
                // value={values.password}
                placeholder="Search"
                onChange={(text) => setKeyword(text.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    searchData();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => searchData()} edge="end">
                      <MdSearch />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </div>

        <DataTable
          columns={deviceColumn}
          data={dataDevice}
          noDataComponent={
            <div className=" justify-center">
              <img
                src={notfound}
                style={{
                  width: "auto",
                  height: screenWidth >= 500 ? 400 : 200,
                }}
              />
            </div>
          }
          pagination
          paginationServer
          paginationTotalRows={count}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressPending={pending}
          progressComponent={
            <RiLoader4Line className=" animate-spin text-6xl" />
          }
          onSort={customSort}
          sortServer
          theme="solarized"
          customStyles={customStyles}
        />
        <ToastContainer limit={1} style={{ marginTop: 0 }} autoClose={2500} />
      </div>
    </>
  );
};

export default Device;
