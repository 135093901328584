import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";

import axios from "axios";
import { checkIsAvailableModule } from "../../../Routes";

import { BASE_URL, URL_PAYMENTDATA_LIST } from "../../../config";
import { Header, Button } from "../../../components";
import DataTable, { createTheme } from "react-data-table-component";
import notfound from "../../../data/notfound.png";
import { FiRefreshCw } from "react-icons/fi";
import { QRAction, Copy } from "./DataTableComponent";

import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const StaticQR = () => {
  const navigate = useNavigate();
  const {
    currentColor,
    currentAuth,
    setAuth,
    screenWidth,
    modalOpenClose,
    privilege,
    hirarkiUser,
  } = useStateContext();

  //   table styling
  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "600",
        paddingLeft: "0 8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "0px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  createTheme("solarized", {
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
    background: {
      default: "#ffffff",
    },
  });
  // table header
  const [header, setHeader] = useState([
    {
      name: "Settlement Key",
      selector: (row) => row["settlementKey"],
      width: 130,
    },
    {
      name: "",
      cell: (row) => <Copy label="Settlement Key" value={row.settlementKey} />,
    },
    {
      name: "Settlement Name",
      selector: (row) => row["settlementName"],
      width: 180,
    },
    {
      name: "Merchant Key",
      selector: (row) => row["merchantKey"],
      width: 150,
    },
    {
      name: "",
      cell: (row) => <Copy label="Merchant Key" value={row.merchantKey} />,
    },
    {
      name: "Merchant Username",
      selector: (row) => row["merchantUsername"],
      width: 180,
    },
    {
      name: "Merchant Corporate Name",
      selector: (row) => row["merchantCorporateName"],
      width: 250,
    },
    {
      name: "Static QR",
      selector: (row) => row["staticQR"],
      width: 180,
    },
    {
      name: "Sound Box",
      selector: (row) => row["isSoundbox"],
      width: 180,
    },
    {
      name: "Payment Code",
      selector: (row) => row["paymentCode"],
      width: 180,
    },
    {
      name: "Default Callback URL",
      selector: (row) => row["defaultCallbackURL"],
      width: 170,
    },
    {
      name: "",
      cell: (row) => (
        <Copy label="Default Callback URL" value={row.defaultCallbackURL} />
      ),
    },
  ]);

  // request config
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${currentAuth}`,
    },
  };

  // state initializarion
  const [data, setData] = useState([]);
  const [settlement, setSettlement] = useState([]);
  const [merchant, setMerchant] = useState([]);
  const [payment, setPayment] = useState([]);
  const [filter, setFilter] = useState({
    settlementKey: "",
    merchantKey: "",
    staticQR: "",
    isSoundbox: null,
    paymentCode: "",
    defaultCallbackURL: "",
  });
  const [search, setSearch] = useState({
    valueSettlement: "",
    dropdownSearchSettlement: "",
    valueMerchant: "",
    dropdownSearchMerchant: "",
    valuePayment: "",
    dropdownSearchPayment: "",
    valueSoundBox: "",
    dropdownSearchSoundBox: "",
  });
  const [loading, setLoading] = useState({
    loadingSettlement: false,
    loadingMerchant: false,
  });
  const [searchTimeoutId, setSearchTimeoutId] = useState(null);
  const [settlementKeyOptions, setSettlementKeyOptions] = useState([]);
  const [merchantKeyOptions, setMerchantKeyOptions] = useState([]);
  const [paymentCodeOptions, setPaymentCodeOptions] = useState([]);
  const soundBoxOptions = [
    {
      label: "ALL",
      value: null,
    },
    {
      label: "TRUE",
      value: true,
    },
    {
      label: "FALSE",
      value: false,
    },
  ];

  // fetch static QR data
  const getStaticQR = async () => {
    modalOpenClose(true);
    const body = {
      id: 0,
      settlementKey: filter.settlementKey,
      merchantKey: filter.merchantKey,
      staticQR: filter.staticQR,
      isSoundbox: filter.isSoundbox,
      paymentCode: filter.paymentCode,
      defaultCallbackURL: filter.defaultCallbackURL,
    };

    try {
      const res = await axios.post(
        BASE_URL + "/static-qr-settings/list",
        body,
        config
      );

      if (res.data.result !== null) {
        const mapped = res.data.result.map((data) => ({
          ...data,
          staticQR: !data.staticQR ? "-" : data.staticQR,
          isSoundbox: String(data.isSoundbox),
          defaultCallbackURL: !data.defaultCallbackURL
            ? "-"
            : data.defaultCallbackURL,
        }));
        setData(mapped);
      } else {
        setData([]);
      }
    } catch (e) {
      if (e.response.data.statusCode === "401") {
        setAuth(null);
        navigate("/");
        localStorage.removeItem("auth");
      }
      console.error("Error:", e);
    } finally {
      modalOpenClose(false);
    }
  };

  // fetch settlement key data
  const getSettlementKey = async () => {
    const body = {
      hirarkiId: hirarkiUser,
      limit: 200,
      keyword: search.dropdownSearchSettlement,
      page: 1,
    };

    try {
      const res = await axios.post(BASE_URL + "/core/list", body, config);
      if (res.data.result !== null) {
        setSettlement(res.data.result);
      } else {
        setSettlement([]);
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingSettlement: false,
      }));
    }
  };
  const settlementDropdown = () => {
    const data = settlement.map((item) => ({
      label: item.name,
      value: item.value,
    }));

    setSettlementKeyOptions(data);
  };

  // fetch merchant key data
  const getMerchantKey = async () => {
    const body = {
      hirarkiId: hirarkiUser,
      limit: 200,
      page: 1,
      keyword: search.dropdownSearchMerchant,
    };
    try {
      const res = await axios.post(BASE_URL + "/user/list", body, config);
      if (res.data.result !== null) {
        setMerchant(res.data.result);
      } else {
        setMerchant([]);
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingMerchant: false,
      }));
    }
  };
  const merchantDropdown = () => {
    const data = merchant.map((item) => ({
      label: item.nama,
      value: item.encCredentials,
    }));

    setMerchantKeyOptions(data);
  };

  // fetch payment data
  const getPayment = async () => {
    try {
      const res = await axios.get(BASE_URL + URL_PAYMENTDATA_LIST, config);
      if (res.data.result !== null) {
        setPayment(res.data.result);
      } else {
        setPayment([]);
      }
    } catch (e) {
      console.error("Error :", e);
    }
  };
  const paymentDropdown = () => {
    const data = payment.map((item) => ({
      label: item.uraian,
      value: item.paymentCode,
    }));

    setPaymentCodeOptions(data);
  };

  useEffect(() => {
    getStaticQR();
    getSettlementKey();
    getMerchantKey();
    getPayment();
  }, []);

  useEffect(() => {
    settlementDropdown();
    merchantDropdown();
    paymentDropdown();
  }, [settlement, merchant, payment]);

  // check if action config privilege exist
  useEffect(() => {
    if (checkIsAvailableModule("EDIT_CONFIG_STATIC_QR", privilege)) {
      setHeader((prev) => [
        {
          name: "Action", //useTemplate
          selector: "",
          width: 100,
          cell: (row) => <QRAction row={row.id} />,
        },
        ...prev,
      ]);
    }
  }, []);

  // Cleanup function to clear timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutId) {
        clearTimeout(searchTimeoutId);
      }
    };
  }, [searchTimeoutId]); // Dependency on searchTimeoutId

  return (
    <div className="h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white">
      {/* header and add button */}
      <div className="flex justify-between">
        <Header title={"STATIC QR"} />
        {checkIsAvailableModule("ADD_CONFIG_STATIC_QR", privilege) && (
          <Button
            customFunc={() => navigate("/insert-static-qr")}
            text="New Data +"
            bgColor={currentColor}
            color="white"
            height="fit"
            borderRadius="10px"
          />
        )}
      </div>

      {/* filter */}
      <div className="grid grid-cols-12 gap-5 mb-10">
        <div className="col-span-6">
          <Autocomplete
            required
            fullWidth={true}
            style={{
              width: "auto",
            }}
            disablePortal
            id="combo-box-demo"
            options={settlementKeyOptions}
            value={search.valueSettlement}
            onChange={(event, newInputValue) => {
              setLoading((prev) => ({
                ...prev,
                loadingSettlement: false,
              }));
              setSearch((prev) => ({
                ...prev,
                valueSettlement: newInputValue,
              }));
              setFilter((prev) => ({
                ...prev,
                settlementKey: newInputValue?.value,
              }));
            }}
            inputValue={search.dropdownSearchSettlement}
            onInputChange={(event, newInputValue) => {
              setLoading((prev) => ({
                ...prev,
                loadingSettlement: true,
              }));
              setSearch((prev) => ({
                ...prev,
                dropdownSearchSettlement: newInputValue,
              }));
              // Clear any existing timeout before starting a new one
              if (searchTimeoutId) {
                clearTimeout(searchTimeoutId);
              }

              setSearchTimeoutId(
                setTimeout(() => {
                  search.dropdownSearchSettlement && getSettlementKey();
                }, 2000)
              );
            }}
            disableClearable // This prop disables the clear button
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Settlement Key"
                placeholder="- All Settlement Key -"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading.loadingSettlement ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        search.valueSettlement !== null &&
                        search.dropdownSearchSettlement !== "" && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear"
                              onClick={() => {
                                setSearch((prev) => ({
                                  ...prev,
                                  valueSettlement: "",
                                  dropdownSearchSettlement: "",
                                }));
                                setFilter((prev) => ({
                                  ...prev,
                                  settlementKey: "",
                                }));
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Autocomplete
            required
            fullWidth={true}
            style={{
              width: "auto",
            }}
            disablePortal
            id="combo-box-demo"
            options={merchantKeyOptions}
            value={search.valueMerchant}
            onChange={(event, newInputValue) => {
              setLoading((prev) => ({
                ...prev,
                loadingMerchant: false,
              }));
              setSearch((prev) => ({
                ...prev,
                valueMerchant: newInputValue,
              }));
              setFilter((prev) => ({
                ...prev,
                merchantKey: newInputValue?.value,
              }));
            }}
            inputValue={search.dropdownSearchMerchant}
            onInputChange={(event, newInputValue) => {
              setLoading((prev) => ({
                ...prev,
                loadingMerchant: true,
              }));
              setSearch((prev) => ({
                ...prev,
                dropdownSearchMerchant: newInputValue,
              }));
              // Clear any existing timeout before starting a new one
              if (searchTimeoutId) {
                clearTimeout(searchTimeoutId);
              }

              setSearchTimeoutId(
                setTimeout(() => {
                  search.dropdownSearchMerchant && getMerchantKey();
                }, 2000)
              );
            }}
            disableClearable // This prop disables the clear button
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Merchant Key"
                placeholder="- All Merchant Key -"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading.loadingMerchant ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        search.valueMerchant !== null &&
                        search.dropdownSearchMerchant !== "" && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear"
                              onClick={() => {
                                setSearch((prev) => ({
                                  ...prev,
                                  valueMerchant: "",
                                  dropdownSearchMerchant: "",
                                }));
                                setFilter((prev) => ({
                                  ...prev,
                                  merchantKey: "",
                                }));
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Autocomplete
            required
            fullWidth={true}
            style={{
              width: "auto",
            }}
            disablePortal
            id="combo-box-demo"
            options={paymentCodeOptions}
            value={search.valuePayment}
            onChange={(event, newInputValue) => {
              setSearch((prev) => ({
                ...prev,
                valuePayment: newInputValue,
              }));
              setFilter((prev) => ({
                ...prev,
                paymentCode: newInputValue?.value,
              }));
            }}
            inputValue={search.dropdownSearchPayment}
            onInputChange={(event, newInputValue) => {
              setSearch((prev) => ({
                ...prev,
                dropdownSearchPayment: newInputValue,
              }));
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Payment Code"
                placeholder="- All Payment Code -"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <Autocomplete
            required
            fullWidth={true}
            style={{
              width: "auto",
            }}
            disablePortal
            id="sound-box-filter"
            options={soundBoxOptions}
            value={search.valueSoundBox}
            onChange={(event, newValue) => {
              setSearch((prev) => ({
                ...prev,
                valueSoundBox: newValue,
              }));
              setFilter((prev) => ({
                ...prev,
                isSoundbox: newValue?.value,
              }));
            }}
            inputValue={search.dropdownSearchSoundBox}
            onInputChange={(event, newInputValue) => {
              setSearch((prev) => ({
                ...prev,
                dropdownSearchSoundBox: newInputValue,
              }));
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Sound Box"
                placeholder="- All Sound Box -"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </div>
        <div className="col-span-6">
          <TextField
            className="w-full"
            id="static-qr"
            label="Static QR"
            variant="outlined"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, staticQR: e.target.value }))
            }
            placeholder="Static QR"
            InputLabelProps={{ shrink: true }}
            value={filter.staticQR}
          />
        </div>
        <div className="col-span-6">
          <TextField
            className="w-full"
            id="call-url"
            label="Default Callback URL"
            variant="outlined"
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                defaultCallbackURL: e.target.value,
              }))
            }
            placeholder="Callback URL"
            InputLabelProps={{ shrink: true }}
            value={filter.defaultCallbackURL}
          />
        </div>
        <div className="col-span-12">
          <Button
            customFunc={() => getStaticQR()}
            text="Filter"
            bgColor={currentColor}
            color="white"
            borderRadius="10px"
            icon={<FiRefreshCw />}
          />
        </div>
      </div>

      {/* table */}
      <div className="flex-1">
        <DataTable
          columns={header}
          data={data}
          noDataComponent={
            <div className=" justify-center">
              <img
                src={notfound}
                style={{
                  width: "auto",
                  height: screenWidth >= 500 ? 400 : 200,
                }}
                alt="not-found"
              />
            </div>
          }
          pagination
          theme="solarized"
          customStyles={customStyles}
          width={"70%"}
        />
      </div>
    </div>
  );
};

export default StaticQR;
