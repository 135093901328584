import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import {
	BASE_URL,
	URL_GATEWAT_DEBIT_MERCHANT_DETAIL,
	URL_GATEWAT_DEBIT_MERCHANT_LIST,
	URL_KATEGORI_LIST,
} from '../../../config';
import { CorpAction } from './DataTableComponent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';

import _ from 'lodash';
import { checkIsAvailableModule } from '../../../Routes';
import ToggleText from '../../../components/ToggleText';
import { urlEncrypt } from '../../../utils/CryptoUtils';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const GatewayMerchant = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		privilege,
		screenWidth,
		dataLogin,
	} = useStateContext();

	const navigate = useNavigate();
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [count, setCount] = useState(0);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');

	const [pending, setPending] = useState(false);

	// get Data Corporate

	const [dataCorp, setDataCorp] = useState([]);

	const getDataCorp = (page = pagination, totalData = limit) => {
		if (dataLogin && dataLogin.username != 'admbankina') {
			modalOpenClose(true);

			fetch(BASE_URL + URL_GATEWAT_DEBIT_MERCHANT_LIST, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${currentAuth}`,
				},
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.statusCode === '401') {
						setAuth(null);
						navigate('/');
						localStorage.removeItem('auth');
						modalOpenClose(false);
					} else {
						if (data.result != null) {
							setDataCorp(data.result);
							setCount(data.length);
							modalOpenClose(false);
						} else {
							setDataCorp([]);
							setCount(0);
							modalOpenClose(false);
						}
					}
				})
				.catch((err) => {
					console.log('err =>', err);
					modalOpenClose(false);
				});
		} else {
			getDataCorpDetail();
		}
	};

	const getDataCorpDetail = (
		page = pagination,
		totalData = limit
	) => {
		modalOpenClose(true);

		let mData = {
			merchantKey: 'gI4UU7RucF33ICpW0p6Gzh1fZrjnOv9dt42nc/OHSmQ=',
		};

		fetch(BASE_URL + URL_GATEWAT_DEBIT_MERCHANT_DETAIL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						setDataCorp([data.result]);
						setCount(1);
						modalOpenClose(false);
					} else {
						setDataCorp([]);
						setCount(0);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	// list corporate Column
	const [corpColumn, setCorpColum] = useState([
		...(dataLogin && dataLogin.username != 'admbankina'
			? [
					{
						name: 'Username',
						selector: 'merchantUsername',
						sortable: true,
						width: 150,
					},
			  ]
			: []),
		...[
			{
				name: 'Merchant Key',
				selector: 'merchantKey',
				sortable: true,
				width: 250,
				cell: (row) => (
					<ToggleText minToggle={150} text={row.merchantKey} />
				),
			},
			{
				name: 'MID',
				selector: 'mkpMid',
				sortable: true,
				width: 200,
			},
			{
				name: 'Segment Name',
				selector: 'namaRouteSegmen',
				sortable: true,
				width: 150,
			},
			{
				name: 'Email',
				selector: 'merchantEmail',
				sortable: true,
				width: 150,
				cell: (row) => (
					<ToggleText minToggle={150} text={row.merchantEmail} />
				),
			},
			{
				name: 'No Telp',
				selector: 'noTelp',
				sortable: true,
				width: 200,
			},
			{
				name: 'Address',
				selector: 'address',
				sortable: true,
				width: 200,
				cell: (row) => (
					<ToggleText minToggle={150} text={row.address} />
				),
			},
		],
	]);

	const searchData = () => {
		if (search) {
			let mData = _.cloneDeep(dataCorp);
			return mData.filter(
				(o) =>
					o.merchantUsername
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					o.merchantKey
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					o.mkpMid.toLowerCase().includes(search.toLowerCase()) ||
					o.namaRouteSegmen
						.toLowerCase()
						.includes(search.toLowerCase())
			);
		} else {
			return dataCorp;
		}
	};

	useEffect(() => {
		let addColumn = _.cloneDeep(corpColumn);
		if (
			checkIsAvailableModule('EDIT_GATEWAY_DEBIT_MERCHANT', privilege)
			// &&
			// checkIsAvailableModule(
			// 	'DELETE_GATEWAY_DEBIT_MERCHANT',
			// 	privilege
			// )
		) {
			addColumn.unshift({
				name: 'Action', //useTemplate
				selector: '',
				sortable: true,
				width: 100,
				cell: (row) => CorpAction(row, () => getDataCorp()),
			});
		}

		if (
			checkIsAvailableModule(
				'VIEW_GATEWAY_DEBIT_MERCHANT_ACQUIRING',
				privilege
			)
		) {
			addColumn.push({
				name: 'Acquiring', //useTemplate
				selector: '',
				sortable: true,
				width: 200,
				cell: (row) => (
					<button
						onClick={() =>
							navigate(
								`/gateway-debit-merchant-acquiring/${urlEncrypt(row)}`
							)
						}
						style={{ background: '#e1f5fe' }}
						className={`py-2 px-3 text-sm capitalize rounded-2xl`}
					>
						<p
							className=" font-semibold"
							style={{
								color: '#5757f3',
							}}
						>
							Detail
						</p>
					</button>
				),
			});
		}

		setCorpColum(addColumn);

		getDataCorp();
	}, []);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className="flex  justify-between">
				<Header title={'Debit Gateway Merchant '} />
				{checkIsAvailableModule(
					'ADD_GATEWAY_DEBIT_MERCHANT',
					privilege
				) && (
					<Button
						customFunc={() => navigate('/add-gateway-debit-merchant')}
						text="Add New +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>

			<div className=" mt-5 md:-mt-5 flex md:justify-end">
				<div className=" w-full  md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									setSearch(keyword);
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearch(keyword)}
										edge="end"
									>
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={corpColumn}
					data={searchData()}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					// paginationServer
					// paginationTotalRows={count}
					// paginationDefaultPage={currentPage}
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					// onSort={customSort}
					// sortServer
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default GatewayMerchant;
