import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import { BASE_URL, URL_ROLE_LIST } from '../../../config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdSearch } from 'react-icons/md';
import notfound from '../../../data/notfound.png';

import { RiLoader4Line } from 'react-icons/ri';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';
import { RoleAction } from './DataTableComponent';
import _ from 'lodash';
import { checkIsAvailableModule } from '../../../Routes';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

const Role = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		screenWidth,
		modalOpenClose,
		privilege,
	} = useStateContext();

	const navigate = useNavigate();
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [count, setCount] = useState(0);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState('asc');
	const [header, setHeader] = useState('');

	const [pending, setPending] = useState(false);

	// get Data Device
	const [dataRole, setDataRole] = useState([]);
	const getDataRole = (page = pagination, totalData = limit) => {
		modalOpenClose(true);

		const bodyData = {
			corporateHirarki: hirarkiUser,
		};

		fetch(BASE_URL + URL_ROLE_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						console.log(data.result);
						setDataRole(data.result);
						setCount(data.countData);
						modalOpenClose(false);
					} else {
						setDataRole([]);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	useEffect(() => {
		getDataRole();
	}, [search, order, header]);

	const handlePageChange = (page) => {
		getDataRole(page);
		setCurrentPage(page);
	};

	const searchData = () => {
		if (search) {
			let mData = _.cloneDeep(dataRole);
			return mData.filter(
				(o) =>
					o.roleName.toLowerCase().includes(search.toLowerCase()) ||
					o.corporate.toLowerCase().includes(search.toLowerCase())
			);
		} else {
			return dataRole;
		}
	};

	const [roleColumn, setRoleColumn] = useState([
		{
			name: 'Role',
			selector: 'roleName',
			sortable: true,
			width: 200,
		},
		// {
		// 	name: 'Corporate',
		// 	selector: 'corporate',
		// 	sortable: true,
		// 	width: 230,
		// },
		// {
		// 	name: 'Hirarki',
		// 	selector: 'corporateHirarki',
		// 	sortable: true,
		// 	width: 280,
		// },
	]);

	useEffect(() => {
		if (
			checkIsAvailableModule('EDIT_CONFIG_ROLE', privilege) &&
			checkIsAvailableModule('DELETE_CONFIG_ROLE', privilege)
		) {
			let addColumn = _.cloneDeep(roleColumn);
			addColumn.unshift({
				name: 'Action',
				selector: '',
				sortable: true,
				width: 100,
				cell: (row) => RoleAction(row, () => handlePageChange(1)),
			});

			setRoleColumn(addColumn);
		}
	}, []);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white `}
		>
			<div className="flex justify-between">
				<Header title={'Data Role User'} />
				{checkIsAvailableModule('ADD_CONFIG_ROLE', privilege) && (
					<Button
						customFunc={() => navigate('/insert-role')}
						text="New Data +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>
			<div className="mt-5 md:-mt-5 flex md:justify-end">
				<div className=" w-full md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									setSearch(keyword);
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={() => searchData()} edge="end">
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<DataTable
				columns={roleColumn}
				// data={dataRole}

				data={searchData()}
				noDataComponent={
					<div className=" justify-center">
						<img
							src={notfound}
							style={{
								width: 'auto',
								height: screenWidth >= 500 ? 400 : 200,
							}}
						/>
					</div>
				}
				// pagination
				// paginationServer
				// paginationTotalRows={count}
				// paginationDefaultPage={currentPage}
				// onChangeRowsPerPage={handlePerRowsChange}
				// onChangePage={handlePageChange}
				progressPending={pending}
				progressComponent={
					<RiLoader4Line className=" animate-spin text-6xl" />
				}
				pagination
				theme="solarized"
				customStyles={customStyles}
			/>
			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default Role;
