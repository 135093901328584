import { Drawer, Modal, TextField } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useStateContext } from '../../contexts/ContextProvider';
import { Button } from '../../components';
import {
	BASE_URL,
	URL_REPORTING_SYNC,
	URL_REPORTING_VOID,
} from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encryptHmac512Base64 } from '../../utils/CryptoUtils';

export const TransactionDetail = (props) => {
	const { redColor, modalOpenClose, currentAuth } = useStateContext();

	const [modal, setModal] = useState(false);
	const [modalPassword, setModalPassword] = useState(null);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const getDetailData = (mDetail) => {
		let mData = [];

		mData.push({
			key: 'id',
			value: mDetail.id,
		});
		mData.push({
			key: 'Invoice Number',
			value: mDetail.noHeader,
		});
		mData.push({
			key: 'Merchant Ref',
			value: mDetail.merchantNoRef,
		});
		mData.push({
			key: 'Created At',
			value: mDetail.createdAt,
		});
		mData.push({
			key: 'Settled At',
			value: mDetail.settledAt,
		});
		mData.push({
			key: 'Settlement Status',
			value: mDetail.statusSettlement,
		});
		mData.push({
			key: 'Payment Category ID',
			value: mDetail.paymentCategoryId,
		});
		mData.push({
			key: 'Payment Category Name',
			value: mDetail.paymentCategoryName,
		});
		mData.push({
			key: 'Payment ID',
			value: mDetail.paymentMethodId,
		});
		mData.push({
			key: 'Payment Name',
			value: mDetail.paymentMethodName,
		});
		mData.push({
			key: 'Principle Ref',
			value: mDetail.responseNoRef,
		});
		mData.push({
			key: 'Amount',
			value: mDetail.paymentAmount,
		});
		mData.push({
			key: 'MDR',
			value: mDetail.paymentMDR,
		});
		mData.push({
			key: 'Payment Fee',
			value: mDetail.paymentFee,
		});
		mData.push({
			key: 'Service Fee',
			value: mDetail.serviceFee,
		});
		mData.push({
			key: 'Vendor Fee',
			value: mDetail.vendorFee,
		});
		mData.push({
			key: 'Corporate ID',
			value: mDetail.corporateId,
		});
		mData.push({
			key: 'Corporate CID',
			value: mDetail.corporateCID,
		});
		mData.push({
			key: 'Corporate Level Code',
			value: mDetail.corporateHirarki,
		});
		mData.push({
			key: 'Corporate Name',
			value: mDetail.corporateName,
		});
		mData.push({
			key: 'Device ID',
			value: mDetail.deviceId,
		});
		mData.push({
			key: 'Settlement Destination',
			value: mDetail.settlementDestination,
		});
		mData.push({
			key: 'MID',
			value: mDetail.bankMID,
		});
		mData.push({
			key: 'TID',
			value: mDetail.bankTID,
		});
		mData.push({
			key: 'Card Pan',
			value: mDetail.cardPan,
		});
		mData.push({
			key: 'Card Type',
			value: mDetail.cardType,
		});
		mData.push({
			key: 'Card Last Balance',
			value: mDetail.cardLastBalance,
		});
		mData.push({
			key: 'Card Current Balance',
			value: mDetail.cardCurrentBalance,
		});
		mData.push({
			key: 'Card Log Trx',
			value: mDetail.cardLogTrx,
		});
		mData.push({
			key: 'Discount',
			value: mDetail.paymentDisc,
		});
		mData.push({
			key: 'Status Description',
			value: mDetail.paymentStatusDesc,
		});
		mData.push({
			key: 'Transaction Date',
			value: mDetail.transactionDate,
		});
		mData.push({
			key: 'Settlement Date',
			value: mDetail.settlementDate,
		});
		mData.push({
			key: 'Status Code',
			value: mDetail.statusCode,
		});
		mData.push({
			key: 'Latitude',
			value: mDetail.latitude,
		});
		mData.push({
			key: 'Longitude',
			value: mDetail.longitude,
		});
		mData.push({
			key: 'Ops PID',
			value: mDetail.opsPID,
		});
		mData.push({
			key: 'is Ops Sync',
			value: mDetail.opsSyncFlag,
		});
		mData.push({
			key: 'Ops Sync Date Time',
			value: mDetail.opsSyncDatetime,
		});
		mData.push({
			key: 'Ops Session UUID',
			value: mDetail.opsSessionUUID,
		});
		mData.push({
			key: 'Ops Request UUID',
			value: mDetail.opsRequestUUID,
		});
		mData.push({
			key: 'Description',
			value: mDetail.description,
		});
		mData.push({
			key: 'Merchant Key',
			value: mDetail.merchantKey,
		});
		mData.push({
			key: 'Settlement Key',
			value: mDetail.settlementKey,
		});
		mData.push({
			key: 'Users ID',
			value: mDetail.usersId,
		});
		mData.push({
			key: 'Users Name',
			value: mDetail.usersName,
		});
		mData.push({
			key: 'Users Username',
			value: mDetail.usersUsername,
		});
		mData.push({
			key: 'Acquiring Code',
			value: mDetail.acquiringCode,
		});
		mData.push({
			key: 'Acquiring Name',
			value: mDetail.acquiringName,
		});
		mData.push({
			key: 'Inquiry Process Duration',
			value: mDetail.inquiryProcessDuration
				? mDetail.inquiryProcessDuration + ' ms'
				: '',
		});
		mData.push({
			key: 'Inquiry Worker Duration',
			value: mDetail.inquiryWorkerDuration
				? mDetail.inquiryWorkerDuration + ' ms'
				: '',
		});
		mData.push({
			key: 'Inquiry Worker Request Time',
			value: mDetail.inquiryWorkerRequestTime,
		});
		mData.push({
			key: 'Inquiry Worker Response Time',
			value: mDetail.inquiryWorkerResponseTime,
		});
		mData.push({
			key: 'Confirmation Process Duration',
			value: mDetail.confirmationProcessDuration
				? mDetail.confirmationProcessDuration + ' ms'
				: '',
		});
		mData.push({
			key: 'Confirmation Worker Duration',
			value: mDetail.confirmationWorkerDuration
				? mDetail.confirmationWorkerDuration + ' ms'
				: '',
		});
		mData.push({
			key: 'Confirmation Worker Request Time',
			value: mDetail.confirmationWorkerRequestTime,
		});
		mData.push({
			key: 'Confirmation Worker Response Time',
			value: mDetail.confirmationWorkerResponseTime,
		});
		mData.push({
			key: 'Bank Settle At',
			value: mDetail.bankSettledAt,
		});
		mData.push({
			key: 'Bank Settle Code',
			value: mDetail.settledStatusCode
				? mDetail.settledStatusCode == '01'
					? 'FAILED'
					: 'SUCCESS'
				: '',
		});
		mData.push({
			key: 'Bank Settle Desc',
			value: mDetail.settledStatusDesc,
		});
		mData.push({
			key: 'Callback Time',
			value: mDetail.callbackTime,
		});
		mData.push({
			key: 'Callback Url',
			value: mDetail.callbackUrl,
		});
		mData.push({
			key: 'Callback Response Data',
			value: mDetail.callbackResponseData,
		});
		mData.push({
			key: 'Payment Code',
			value: mDetail.paymentCode,
		});
		mData.push({
			key: 'Issuer Code',
			value: mDetail.issuerCode,
		});
		mData.push({
			key: 'Issuer Name',
			value: mDetail.issuerName,
		});
		mData.push({
			key: 'Locations',
			value: mDetail.locations,
		});

		return mData;
	};

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => props.onSuccess(),
		});
	};

	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const callVoidData = (e) => {
		e.preventDefault();

		setModal(false);
		setModalPassword(null);

		modalOpenClose(true);

		const inputData = {
			noHeader: props.data.noHeader,
			username: username,
			password: encryptHmac512Base64(password),
		};

		fetch(BASE_URL + URL_REPORTING_VOID, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				modalOpenClose(false);
				setUsername('');
				setPassword('');

				if (json.success === true) {
					notify('Data successfully void');
				} else if (json.success === false) {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				notifyErr(err.message);
				setUsername('');
				setPassword('');
			});
	};

	const callSyncCashlezData = (e) => {
		e.preventDefault();

		setModal(false);
		setModalPassword(null);

		modalOpenClose(true);

		const inputData = {
			noHeader: props.data.noHeader,
			username: username,
			password: encryptHmac512Base64(password),
		};

		fetch(BASE_URL + URL_REPORTING_SYNC, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				modalOpenClose(false);
				setUsername('');
				setPassword('');

				if (json.success === true) {
					notify('Data successfully sync');
				} else if (json.success === false) {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				notifyErr(err.message);
				setUsername('');
				setPassword('');
			});
	};

	return (
		<Drawer
			className="md:w-400"
			anchor={props.anchor}
			open={props.open}
			onClose={props.onClose}
			sx={{
				width: props.width,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: {
					width: props.width,
					boxSizing: 'border-box',
				},
			}}
		>
			<div>
				<div className="header flex flex-row shadow-md pl-5 pr-5 pt-5 pb-3 mb-3 justify-between items-center">
					<h3>Detail Data</h3>
					<MdClose
						onClick={() => props.onClose()}
						className="cursor-pointer"
					/>
				</div>
				{props.data &&
					(props.data.paymentCode == '10004' ||
						props.data.paymentCode == '10005') &&
					props.data.statusCode == '101' && (
						<div className="header flex flex-row pl-5 pr-5 pt-5 pb-3 mb-3 justify-between items-center">
							<Button
								customFunc={() => setModal(true)}
								bgColor={redColor}
								color="white"
								text="Void"
								borderRadius={'10px'}
								icon={<FiRefreshCw />}
							/>
						</div>
					)}

				{props.data &&
					((props.data.statusCode == '102' &&
						props.data.paymentCode != '40001') ||
						(props.data.statusCode == '101' &&
							props.data.paymentCode == '40001' &&
							!props.data.statusSettlement)) && (
						<div className="header flex flex-row pl-5 pr-5 pt-5 pb-3 mb-3 justify-between items-center">
							<Button
								customFunc={() => setModalPassword(2)}
								bgColor={redColor}
								color="white"
								text="Syncronize"
								borderRadius={'10px'}
								icon={<FiRefreshCw />}
							/>
						</div>
					)}

				<div className="flex flex-col pl-5 pr-5 pb-5">
					{props.data &&
						getDetailData(props.data).map((row) => {
							return (
								<div className="flex flex-col justify-between text-wrap mb-3 mt-3">
									<p className="font-weight-light font-semibold break-words">
										{row.key}:
									</p>
									<span className="font-weight-bold break-words">
										{row.value}
									</span>
								</div>
							);
						})}
				</div>
			</div>

			<Modal onBackdropClick={() => setModal(false)} open={modal}>
				<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p className=" font-semibold text-base md:text-xl">
						Anda ingin melakukan pengembalian dana ?
					</p>
					<div className=" flex justify-center gap-5 mt-5 ">
						<Button
							customFunc={() => setModal(false)}
							bgColor={'gray'}
							borderRadius={'10px'}
							color="white"
							text="Batalkan"
						/>
						<Button
							customFunc={() => {
								setModal(false);
								setModalPassword(1);
							}}
							bgColor={'red'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Lanjut"
							color={'white'}
						/>
					</div>
				</div>
			</Modal>

			<Modal
				onBackdropClick={() => setModalPassword(null)}
				open={modalPassword != null}
				onClose={() => {
					setUsername('');
					setPassword('');
				}}
			>
				<form
					onSubmit={
						modalPassword == 1 ? callVoidData : callSyncCashlezData
					}
				>
					<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
						<p className=" font-semibold text-base md:text-xl">
							Masukkan data login Anda
						</p>
						<div className=" mt-5 w-full">
							<TextField
								className="w-full"
								required
								id="corp-name"
								label="Username"
								variant="outlined"
								onChange={(text) => setUsername(text.target.value)}
								placeholder="Username"
								InputLabelProps={{ shrink: true }}
								value={username}
								defaultValue={username}
							/>
						</div>

						<div className=" mt-5 w-full">
							<TextField
								className="w-full"
								required
								id="corp-name"
								label="Password"
								variant="outlined"
								type="password"
								onChange={(text) => setPassword(text.target.value)}
								placeholder="Password"
								InputLabelProps={{ shrink: true }}
								value={password}
								defaultValue={password}
							/>
						</div>

						<div className=" flex justify-center gap-5 mt-5 ">
							<Button
								customFunc={() => {
									setModalPassword(null);
									setUsername('');
									setPassword('');
								}}
								bgColor={'gray'}
								borderRadius={'10px'}
								color="white"
								text="Batalkan"
							/>
							<Button
								type="submit"
								bgColor={'red'}
								width="60"
								height="fit"
								borderRadius={'10px'}
								text="Submit"
								color={'white'}
							/>
						</div>
					</div>
				</form>
			</Modal>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</Drawer>
	);
};

TransactionDetail.defaultProps = {
	anchor: 'right',
	open: false,
	onClose: () => {},
	width: 'auto',
	data: null,
};
