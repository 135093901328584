import React, { useEffect, useState } from 'react';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns/src';

import {
	MdKeyboardBackspace,
	MdArrowBack,
	MdSave,
} from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL } from '../../../config';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import CurrencyInput from 'react-currency-input-field';
import { Autocomplete, TextareaAutosize } from '@mui/material';

const CorporateAdd = () => {
	const navigate = useNavigate();

	const notify = (message) => {
		toast.success(message, {
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		activeMenu,
	} = useStateContext();

	const [uraian, setUraian] = useState('');
	const [alamat, setAlamat] = useState('');
	const [telepon, setTelepon] = useState('');
	const [servicefee, setServicefee] = useState('0');
	const [corpCategory, setCorpCategory] = useState(0);
	const [idkota, setIdkota] = useState(0);
	const [ipLocal, setIpLocal] = useState('');
	const [isPercentage, setIsPercentage] = useState(false);
	const [parentCID, setParentCID] = useState('');

	const [corporateLogo, setCorporateLogo] = useState('');
	const [corporateFooter, setCorporateFooter] = useState('');

	const [corporateBankCode, setCorporateBankCode] = useState('');
	const [corporateBankAccountNumber, setCorporateBankAccountNumber] =
		useState('');

	const [status, setStatus] = useState(false);

	const [categoryCorporate, setCategoryCorporate] = useState(false);
	const [dataKota, setDataKota] = useState([]);
	const [category, setCategory] = useState([]);
	const [idCategory, setIdCategory] = useState([]);
	const [Data, setData] = useState([]);

	// insert data to corporate
	const dataInput = (e) => {
		e.preventDefault();

		console.log('servicefee', servicefee);
		const parsedSfee = servicefee
			.replace(/\./g, '')
			.replace(/\,/g, '.');
		// .replace(/./g, '').replace(',', '.');

		console.log('parsedSfee', parsedSfee);

		const inputData = {
			uraian: uraian,
			alamat: alamat,
			telepon: telepon,
			servicefee: parseInt(servicefee),
			corporatecategory: parseInt(corpCategory),
			iplocalserver: ipLocal,
			idkota: parseInt(idkota),
			ispercentage: isPercentage,
			parentCID: parentCID,
			corporateLogo: corporateLogo,
			corporateFooter: corporateFooter,
			corporateBankCode: corporateBankCode,
			corporateBankAccount: corporateBankAccountNumber,
		};
		fetch(BASE_URL + '/corporate/add', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					setStatus(json.success);
					let message = json.message;
					if (json.success === true) {
						notify(message);
					} else if (json.success === false) {
						console.log(json.result);
						notifyErr(message);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getCorpCategory = () => {
		fetch(BASE_URL + '/corpcategory/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setData(data.result);

				let tempArr = [];
				let idArr = [];
				data.result.map((item) => {
					tempArr.push(item.kode);
					idArr.push(item.idcategory);
				});
				setCategory(tempArr);
				setIdCategory(idArr);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getKota = () => {
		fetch(BASE_URL + '/kota/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setDataKota(data.result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [corp, setCorp] = useState([]);

	const getCorp = () => {
		const dataBody = {
			// order: 'ASC',
			// // limit: 100,
			// draw: 2,
			// page: 1,
			// keyword: '',
			// hirarkiId: hirarkiUser,

			order: 'asc',
			limit: 0,
			page: 1,
			keyword: '',
			orderBy: '',
			hirarkiId: hirarkiUser,
		};
		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.result != null) {
					setCorp(data.result);
				} else {
					setCorp([]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getCorpCategory();
		getKota();
		getCorp();
	}, []);

	const data = [];
	{
		corp.map((item) => {
			let datavalue = {
				value: item.cid,
				label: item.uraian + ' - ' + item.cid,
			};
			data.push(datavalue);
		});
	}

	const kotaData = [];
	{
		dataKota.map((item) => {
			let datavalue = {
				value: item.id,
				label: item.uraian + ' - ' + item.namaProvinsi,
			};
			kotaData.push(datavalue);
		});
	}

	const handleChangeCategory = (event) => {
		setCorpCategory(event.target.value);
	};

	const handleChangeLevel = (event) => {
		setParentCID(event.target.value);
	};

	const handleChangeKota = (event, values) => {
		if (values != null) {
			setIdkota(values.value);
		} else {
			setIdkota(0);
		}
	};

	const [error, setError] = useState(false);
	const handleSubmit = (e) => {};

	const submitData = () => {
		if (
			corpCategory === 0 ||
			uraian.length === 0 ||
			telepon.length === 0 ||
			alamat.length === 0 ||
			parentCID === 0 ||
			idkota === 0 ||
			servicefee === 0
		) {
			setError(true);
		} else {
			setError(false);
			dataInput();
			if (status === true) {
				navigate(-1);
			}
		}
	};

	const onChangeCurrency = (e) => {
		e.preventDefault();
		const { value = '' } = e.target;
		console.log(
			'value',
			isPercentage
				? value.replace('Rp ', '').replace(/\./g, '')
				: value.replace('Rp ', '').replace(/\./g, '')
		);
		setServicefee(
			isPercentage
				? value.replace('Rp ', '').replace(/\./g, '')
				: value.replace('Rp ', '').replace(/\./g, '')
		);
	};

	const onChange = (event, values) => {
		console.log(values.value);
		if (values != null) {
			setParentCID(values.value);
		} else {
			setParentCID('');
		}
	};

	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={dataInput}
		>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10 rounded-3xl  bg-white">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="New Corporate" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" w-full  ">
					<FormControl
						required
						error={categoryCorporate}
						className=" w-full"
					>
						<InputLabel id="select-corp">
							Pilih Kategori Corporate
						</InputLabel>
						<Select
							className=" w-full"
							required
							InputLabelProps={{ shrink: true }}
							labelId="select-corp"
							id="select-corp"
							label="Pilih Kategori Corporate"
							onChange={handleChangeCategory}
						>
							<MenuItem disabled value={'base'}>
								- Pilih Kategory Corporate -
							</MenuItem>
							{Data.map((Data) => (
								<MenuItem
									key={Data.idCategory}
									value={Data.idCategory}
								>
									{Data.uraian}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Corporate Name"
						variant="outlined"
						onChange={(text) => setUraian(text.target.value)}
						placeholder="MKP APPS2PAY"
						InputLabelProps={{ shrink: true }}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="telepon"
						label="Telepon"
						variant="outlined"
						placeholder="0871-2345-123"
						InputLabelProps={{ shrink: true }}
						onChange={(text) => setTelepon(text.target.value)}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="alamat"
						label="Alamat"
						variant="outlined"
						placeholder="Jalan Pemuda No. 8"
						InputLabelProps={{ shrink: true }}
						onChange={(text) => setAlamat(text.target.value)}
					/>
				</div>

				<div>
					<div className="flex gap-5 mt-5 mb-3">
						<div className=" w-full -mr-0 md:-mr-0 ">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="combo-box-demo"
								options={data}
								onChange={onChange}
								defaultValue={data[0]}
								sx={{ width: 300 }}
								renderInput={(params) => (
									<TextField required {...params} label="Corporate" />
								)}
							/>
						</div>
						<div className=" w-full">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectKota"
								options={kotaData}
								onChange={handleChangeKota}
								defaultValue={kotaData[0]}
								sx={{ width: 300 }}
								renderInput={(params) => (
									<TextField required {...params} label="Kota" />
								)}
							/>
						</div>
					</div>

					<div className="flex gap-5 mt-5 mb-3">
						<div className=" w-full -mr-0 md:-mr-0 ">
							<TextField
								className="w-full"
								id="corp-logo"
								label="Bank Code"
								variant="outlined"
								onChange={(text) =>
									setCorporateBankCode(text.target.value)
								}
								placeholder="ex: 002"
								InputLabelProps={{ shrink: true }}
								value={corporateBankCode}
							/>
						</div>
						<div className=" w-full -mr-0 md:-mr-0 ">
							<TextField
								className="w-full"
								id="corp-logo"
								label="Bank Account Number"
								variant="outlined"
								onChange={(text) =>
									setCorporateBankAccountNumber(text.target.value)
								}
								placeholder="ex: 1234567890"
								InputLabelProps={{ shrink: true }}
								value={corporateBankAccountNumber}
							/>
						</div>
					</div>

					<div className=" mt-12 w-full">
						<TextField
							className="w-full"
							required
							id="iplokal"
							label="IP Lokal Server"
							variant="outlined"
							placeholder="192.123..."
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setIpLocal(text.target.value)}
						/>
					</div>

					<div className="flex gap-5">
						<div className=" mt-5 w-6/12">
							<CurrencyInput
								required
								className="w-full"
								label="Service Fee"
								InputLabelProps={{ shrink: true }}
								customInput={TextField}
								data-number-to-fixed="3"
								// data-number-stepfactor="100"
								intlConfig={
									isPercentage
										? undefined
										: { locale: 'id-ID', currency: 'IDR' }
								}
								decimalSeparator=","
								decimalLimit={isPercentage}
								decimalScale={2}
								onChange={onChangeCurrency}
								value={servicefee}
								placeholder="10.000.000"
							/>
						</div>
						<div className=" mt-2 w-3/6">
							<p className=" mb-1 text-gray-500 text-sm">
								Satuan Service Fee
							</p>
							<div className="flex">
								<input
									checked={isPercentage === false}
									onChange={() => setIsPercentage(false)}
									id="Rp"
									className="cursor-pointer"
									type="radio"
									placeholder="Rupiah"
								/>
								<label
									htmlFor="Rp"
									className="ml-2 text-base cursor-pointer"
								>
									Rupiah
								</label>
							</div>
							<div className="flex">
								<input
									checked={isPercentage === true}
									onChange={() => setIsPercentage(true)}
									id="percent"
									className="cursor-pointer"
									type="radio"
									placeholder="Rupiah"
								/>
								<label
									htmlFor="percent"
									className="ml-2 text-base cursor-pointer"
								>
									Presentase
								</label>
							</div>
						</div>
					</div>

					<div className=" mt-5 w-full">
						<TextField
							className="w-full"
							id="corp-logo"
							label="Corporate Logo"
							variant="outlined"
							onChange={(text) => setCorporateLogo(text.target.value)}
							placeholder="Base64 logo"
							InputLabelProps={{ shrink: true }}
							value={corporateLogo}
							multiline
							maxRows={4}
						/>
					</div>
					<div className=" mt-5 w-full">
						<TextField
							className="w-full"
							id="corp-logo"
							label="Corporate Footer"
							variant="outlined"
							onChange={(text) =>
								setCorporateFooter(text.target.value)
							}
							placeholder="Footer"
							InputLabelProps={{ shrink: true }}
							value={corporateFooter}
							multiline
							maxRows={4}
						/>
					</div>
				</div>

				<div className=" flex mt-10 justify-between">
					<div className=" ">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							icon={<MdArrowBack />}
						/>
					</div>
					<div className=" ">
						<Button
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default CorporateAdd;
