import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_EDIT,
} from '../../../config';

import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

import _ from 'lodash';
import { urlDecrypt } from '../../../utils/CryptoUtils';
import { maskingAllText } from '../../../utils/StringUitls';

var CryptoJS = require('crypto-js');

const GatewayMerchantAcquiringDeviceUpdate = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose, setAuth } =
		useStateContext();

	const navigate = useNavigate();

	const { Id } = useParams();

	const [bankTID, setBankTID] = useState('');
	const [mkpTID, setMkpTID] = useState('');
	const [deviceId, setDeviceId] = useState('');
	const [mainkey, setMainKey] = useState('');
	const [workingKey, setWorkingKey] = useState('');
	const [eltmk, setEltmk] = useState('');
	const [ltwkid, setLtwkid] = useState('');
	const [makkey, setMakkey] = useState('');
	const [dekkey, setDekkey] = useState('');

	const [dataMerchant, setDataMerchant] = useState(null);

	const [status, setStatus] = useState(false);

	const dataUpdate = (e) => {
		e.preventDefault();
		modalOpenClose(true);

		const inputData = {
			idDeviceMerchantAcquiringBank: dataMerchant
				? dataMerchant.idDeviceMerchantAcquiringBank
				: null,
			idTipeTransaksi: dataMerchant
				? dataMerchant.idTipeTransaksi
				: null,
			mkpTid: mkpTID,
			bankTid: bankTID,
			deviceId: deviceId,
			mainkey: mainkey,
			workingKey: workingKey,
			eltmk: eltmk,
			ltwkid: ltwkid,
			makkey: makkey,
			dekkey: dekkey,
			keyOne:
				dataMerchant && dataMerchant.keyOne
					? dataMerchant.keyOne
					: '-',
			keyTwo:
				dataMerchant && dataMerchant.keyTwo
					? dataMerchant.keyTwo
					: '-',
			keyThree:
				dataMerchant && dataMerchant.keyThree
					? dataMerchant.keyThree
					: '-',
			keyFour:
				dataMerchant && dataMerchant.keyFour
					? dataMerchant.keyFour
					: '-',
			keyFive:
				dataMerchant && dataMerchant.keyFive
					? dataMerchant.keyFive
					: '-',
		};

		fetch(
			BASE_URL + URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_EDIT,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${currentAuth}`,
				},
				body: JSON.stringify(inputData),
			}
		)
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	useEffect(() => {
		if (Id) {
			var data = urlDecrypt(Id);

			if (data != null) {
				setDataMerchant(data);
				setBankTID(data.bankTid);
				setMkpTID(data.mkpTid);
				setDeviceId(data.deviceId);
				setMainKey(data.mainKey);
				setWorkingKey(data.workingKey);
				setEltmk(data.eltmk);
				setLtwkid(data.ltwkid);
				setMakkey(data.makkey);
				setDekkey(data.dekkey);
			} else {
				notifyErr('Invalid URL');
			}
		}
	}, [Id]);

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="Update Acquiring Device" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Acquiring"
						variant="outlined"
						placeholder="Acquiring"
						InputLabelProps={{ shrink: true }}
						InputProps={{
							readOnly: true,
						}}
						value={
							dataMerchant
								? dataMerchant.kodeBankAcquiring +
								  ' - ' +
								  dataMerchant.namaBankAcquiring +
								  ' - ' +
								  (dataMerchant.idTipeTransaksi == 1
										? 'DEBIT'
										: 'CREDIT')
								: null
						}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Device ID"
						variant="outlined"
						onChange={(text) => setDeviceId(text.target.value)}
						placeholder="Device ID"
						InputLabelProps={{ shrink: true }}
						value={deviceId}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Bank TID"
						variant="outlined"
						onChange={(text) => setBankTID(text.target.value)}
						placeholder="TID"
						InputLabelProps={{ shrink: true }}
						value={bankTID}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="MKP TID"
						variant="outlined"
						onChange={(text) => setMkpTID(text.target.value)}
						placeholder="MKP TID"
						InputLabelProps={{ shrink: true }}
						value={mkpTID}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Main Key"
						variant="outlined"
						onChange={(text) => setMainKey(text.target.value)}
						placeholder="Main Key"
						InputLabelProps={{ shrink: true }}
						value={mainkey}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Working Key"
						variant="outlined"
						onChange={(text) => setWorkingKey(text.target.value)}
						placeholder="Working Key"
						InputLabelProps={{ shrink: true }}
						value={workingKey}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="LTWK Id"
						variant="outlined"
						onChange={(text) => setLtwkid(text.target.value)}
						placeholder="LTWK Id"
						InputLabelProps={{ shrink: true }}
						value={ltwkid}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="LTMK Id"
						variant="outlined"
						onChange={(text) => setEltmk(text.target.value)}
						placeholder="LTMK Id"
						InputLabelProps={{ shrink: true }}
						value={eltmk}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="MAK Key"
						variant="outlined"
						onChange={(text) => setMakkey(text.target.value)}
						placeholder="MAK Key"
						InputLabelProps={{ shrink: true }}
						value={makkey}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="DEK Key"
						variant="outlined"
						onChange={(text) => setDekkey(text.target.value)}
						placeholder="DEK Key"
						InputLabelProps={{ shrink: true }}
						value={dekkey}
					/>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default GatewayMerchantAcquiringDeviceUpdate;
