import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}

ReactDOM.render(
	<ContextProvider>
		<App />
	</ContextProvider>,
	document.getElementById('root')
);
