import React from 'react';
import PropTypes from 'prop-types';

class ToggleText extends React.Component {
	state = {
		minToggle: this.props.minToggle,
		text: this.props.text,
		isToggle: this.props.isToggle,
	};

	getSubstringText = (text, numberSubstring) => {
		return text.substring(0, numberSubstring);
	};

	render() {
		return (
			<div className="d-flex flex-row align-items-center">
				<div>
					{this.getSubstringText(
						this.props.text,
						!this.state.isToggle
							? this.state.minToggle
							: this.props.text.length
					)}
				</div>
				{!this.state.isToggle &&
					this.props.text.length > this.state.minToggle && (
						<i
							className="text-info"
							style={{ cursor: 'pointer' }}
							onClick={() =>
								this.setState({ isToggle: !this.state.isToggle })
							}
						>
							..more
						</i>
					)}
			</div>
		);
	}
}

ToggleText.defaultProps = {
	text: '',
	isToggle: false,
	minToggle: 30,
	style: {},
};

ToggleText.protoTypes = {
	text: PropTypes.string,
	isToggle: PropTypes.bool,
	minToggle: PropTypes.number,
	style: PropTypes.object,
};

export default ToggleText;
