import { FiShoppingBag } from "react-icons/fi";
import { MdRadioButtonUnchecked } from "react-icons/md";

export const links = [
  {
    title: "Dashboard",
    icons: <FiShoppingBag />,
    links: [
      {
        name: "Dashboard",
        icon: <MdRadioButtonUnchecked />,
        direct: "dashboard",
      },
    ],
  },
  {
    title: "Pengaturan",
    links: [
      {
        name: "Corporate",
        icon: <MdRadioButtonUnchecked />,
        direct: "corporate",
      },
      {
        name: "Corporate Payment",
        icon: <MdRadioButtonUnchecked />,
        direct: "corporate-payment",
      },
      {
        name: "Core Settlement Keys",
        icon: <MdRadioButtonUnchecked />,
        direct: "core-settlement-keys",
      },
      {
        name: "User",
        icon: <MdRadioButtonUnchecked />,
        direct: "user",
      },
      {
        name: "Device",
        icon: <MdRadioButtonUnchecked />,
        direct: "device",
      },
    ],
  },
  {
    title: "Produk",
    links: [
      {
        name: "Produk",
        icon: <MdRadioButtonUnchecked />,
        direct: "produk",
      },
    ],
  },
  {
    title: "Laporan",
    links: [
      {
        name: "Success Transaction",
        icon: <MdRadioButtonUnchecked />,
        direct: "success-transaction",
      },
      {
        name: "Settled Transaction",
        icon: <MdRadioButtonUnchecked />,
        direct: "settled-transaction",
      },
      {
        name: "List Transaction",
        icon: <MdRadioButtonUnchecked />,
        direct: "list-transaction",
      },
      // {
      //   name: "File Settlement MTI",
      //   icon: <MdRadioButtonUnchecked />,
      //   direct: "file-settlement-mti",
      // },
    ],
  },
  {
    title: "Log Out",
    links: [],
  },
];
