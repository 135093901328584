import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import {
	BASE_URL_SETTLEMENT,
	URL_REPORTING_MALUKU_LIST,
	URL_REPORTING_MALUKU_SYNC_CRON,
	URL_REPORTING_A2P_SYNC_CRON,
} from '../../../config';
import { CorpAction, CorpSyncLoc } from './DataTableComponent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';
import { MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	Autocomplete,
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
} from '@mui/material';
import ToggleText from '../../../components/ToggleText';
import moment from 'moment';
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FiRefreshCw, FiUploadCloud } from 'react-icons/fi';
import _ from 'lodash';
import { checkIsAvailableModule } from '../../../Routes';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const SettlementMaluku = () => {
	const {
		setAuth,
		modalOpenClose,
		privilege,
		screenWidth,
		currentColor,
		yellowColor,
		greenColor,
		redColor,
	} = useStateContext();

	const dateTo = moment().endOf('day').format('YYYY-MM-DD');
	const dateFrom = moment().startOf('day').format('YYYY-MM-DD');
	const maxDate = moment().subtract(1, 'months').format('YYYY-MM-01');

	// data for filter
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);

	const navigate = useNavigate();
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [merchantKey, setMerchantKey] = useState('');

	const [pending, setPending] = useState(false);
	const [dataCorp, setDataCorp] = useState([]);
	const [dataFilter, setDataFilter] = useState([
		{ label: 'ALL STATUS', value: 'ALL' },
		{ label: 'SUCCESS', value: 'SUCCESS' },
		{ label: 'PENDING', value: 'PENDING' },
		{ label: 'FAILED', value: 'FAILED' },
	]);

	const [dataSearch, setDataSearch] = useState([
		{ label: 'No Header', value: 'noHeader' },
		{ label: 'Device Id', value: 'deviceId' },
		{ label: 'Corporate Name', value: 'corporateName' },
	]);

	const [idSearch, setIdSearch] = useState(null);

	const [idFilter, setIdFilter] = useState('ALL');

	const dateToMax = moment(startDate).add(1, 'months').isAfter(dateTo)
		? dateTo
		: moment(startDate).add(1, 'months').format('YYYY-MM-DD');

	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
		});
	};
	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const getDataCorp = () => {
		modalOpenClose(true);
		setDataCorp([]);

		let mData = {
			noHeader: idSearch && idSearch == 'noHeader' ? keyword : '',
			listNoHeader: [],
			mid: '',
			tid: '',
			deviceID: idSearch && idSearch == 'deviceId' ? keyword : '',
			cardPan: '',
			settlementKey: '',
			merchantKey: merchantKey,
			paymentCode: '',
			paymentMethod: '',
			paymentCategory: '',
			responseNoRef: '',
			acquiringCode: '',
			acquiringName: '',
			corporateName:
				idSearch && idSearch == 'corporateName' ? keyword : '',
			bankResponseCode: '',
			bankResponseDesc: '',
			syncStatus: idFilter == 'ALL' ? '' : idFilter,
			syncStatusList: [],
			settledFrom: '',
			settledTo: '',
			createdFrom: startDate,
			createdTo: endDate,
			syncFrom: '',
			syncTo: '',
		};

		fetch(BASE_URL_SETTLEMENT + URL_REPORTING_MALUKU_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic bWtwbW9iaWxlOm1rcG1vYmlsZTEyMw==`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						console.log(data.result);
						setDataCorp(data.result);
						modalOpenClose(false);
					} else {
						setDataCorp([]);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	const reqSyncMaluku = () => {
		modalOpenClose(true);

		fetch(BASE_URL_SETTLEMENT + URL_REPORTING_MALUKU_SYNC_CRON, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic bWtwbW9iaWxlOm1rcG1vYmlsZTEyMw==`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.success) {
						modalOpenClose(false);
						notify(data.result);

						getDataCorp();
					} else {
						modalOpenClose(false);
						notifyErr(data.result);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
				notifyErr(err.message);
			});
	};

	const reqSyncA2p = () => {
		modalOpenClose(true);

		fetch(BASE_URL_SETTLEMENT + URL_REPORTING_A2P_SYNC_CRON, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic bWtwbW9iaWxlOm1rcG1vYmlsZTEyMw==`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.success) {
						modalOpenClose(false);
						notify(data.result);

						getDataCorp();
					} else {
						modalOpenClose(false);
						notifyErr(data.result);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
				notifyErr(err.message);
			});
	};

	const searchData = () => {
		// setSearch(keyword);
		getDataCorp();
	};

	const handleClick = () => {
		getDataCorp();
	};

	// list corporate Column
	const getColum = () => {
		let corpColumn = [
			{
				name: 'Sync Status',
				selector: 'syncStatus',
				sortable: true,
				width: 150,
				cell: (row) => (
					<button
						style={
							row.syncStatus.toLowerCase() == 'success'
								? { background: '#E8F5E9' }
								: row.syncStatus.toLowerCase() == 'pending'
								? { background: '#FFF3E0' }
								: row.syncStatus.toLowerCase() == 'failed'
								? { background: '#FFF3E0' }
								: { background: '#E3F2FD' }
						}
						className={`py-2 px-3 text-sm capitalize rounded-2xl`}
					>
						{row.syncStatus.toLowerCase() == 'success' ? (
							<p
								className=" font-semibold"
								style={{ color: '#5AB55E' }}
							>
								{row.syncStatus}
							</p>
						) : row.syncStatus.toLowerCase() == 'pending' ? (
							<p
								className=" font-semibold"
								style={{ color: '#FFAA2C' }}
							>
								{row.syncStatus}
							</p>
						) : row.syncStatus.toLowerCase() == 'failed' ? (
							<p
								className=" font-semibold"
								style={{ color: '#F5574C' }}
							>
								{row.syncStatus}
							</p>
						) : (
							<p
								className=" font-semibold"
								style={{ color: '#2B9BF4' }}
							>
								{row.syncStatus}
							</p>
						)}
					</button>
				),
			},
			{
				name: 'No Header',
				selector: 'noHeader',
				sortable: true,
				width: 150,
			},
			{
				name: 'Transaction At',
				selector: 'settledAt',
				sortable: true,
				width: 150,
			},
			{
				name: 'Payment',
				selector: 'paymentCategory',
				sortable: true,
				width: 120,
			},
			{
				name: 'Nominal',
				selector: 'amount',
				sortable: true,
				width: 120,
				cell: (row) => (
					<ToggleText
						minToggle={60}
						text={'Rp' + row.amount.format()}
					/>
				),
			},
			{
				name: 'MDR',
				selector: 'mdr',
				sortable: true,
				width: 120,
				cell: (row) => (
					<ToggleText
						minToggle={60}
						text={'Rp' + row.mdr.toString().replace('.', ',')}
					/>
				),
			},
			{
				name: 'Nominal Nett',
				selector: 'amount',
				sortable: true,
				width: 200,
				cell: (row) => (
					<ToggleText
						minToggle={60}
						text={'Rp' + (row.amount - (row.mdr ?? 0)).format()}
					/>
				),
			},
			{
				name: 'Device ID',
				selector: 'deviceID',
				sortable: true,
				width: 120,
			},
			{
				name: 'Corporate',
				selector: 'corporateName',
				sortable: true,
				width: 120,
			},
			{
				name: 'Sync At',
				selector: 'syncAt',
				sortable: true,
				width: 150,
			},
			{
				name: 'Bank Response',
				selector: 'bankResponseCode',
				sortable: true,
				width: 200,
				cell: (row) => (
					<ToggleText
						minToggle={60}
						text={
							row.bankResponseDesc
								? '(' +
								  row.bankResponseCode +
								  ') ' +
								  row.bankResponseDesc
								: ''
						}
					/>
				),
			},
			{
				name: 'Merchant Key',
				selector: 'merchantKey',
				sortable: true,
				width: 150,
				cell: (row) => (
					<ToggleText
						minToggle={20}
						text={startDate + ' - ' + endDate}
					/>
				),
			},
		];

		if (
			checkIsAvailableModule('SYNC_LOC_CONFIG_CORPORATE', privilege)
		) {
			corpColumn.push({
				name: 'Sync', //useTemplate
				selector: '',
				sortable: true,
				width: 80,
				style: {
					flex: 1,
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
				},
				cell: (row) =>
					(row.syncStatus.toLowerCase() == 'pending' ||
						row.syncStatus.toLowerCase() == 'failed') &&
					CorpSyncLoc(row, handleClick),
			});
		}

		return corpColumn;
	};

	useEffect(() => {
		getDataCorp();
	}, []);

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const exportToExcel = async (dataForExcel) => {
		const dataExcel = dataForExcel.map((o) => {
			let mData = {};
			mData.Status = o.syncStatus;
			mData.NoHeader = o.noHeader;
			mData.TransactionAt = o.settledAt;
			mData.Payment = o.paymentCategory;
			mData.Nominal = 'Rp' + o.amount.format();
			mData.MDR = 'Rp' + o.mdr.toString().replace('.', ',');
			mData.NominalNett = 'Rp' + (o.amount - (o.mdr ?? 0)).format();
			mData.DeviceID = o.deviceID;
			mData.CorporateName = o.corporateName;
			mData.SyncAt = o.syncAt;
			mData.BankResponseCode = o.bankResponseCode;
			mData.BankResponseDesc = o.bankResponseDesc;
			mData.MerchantKey = o.merchantKey;

			return mData;
		});

		const ws = XLSX.utils.json_to_sheet(dataExcel);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, {
			bookType: 'xlsx',
			type: 'array',
		});

		const data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(
			data,
			`Data Settlement BPD Maluku (${startDate} s.d ${endDate})` +
				fileExtension
		);
	};

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className=" md:flex gap-5 mb-5">
				{/* start date input */}
				<div className=" w-full mb-5 md:mb-0">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							required
							className=" w-full"
							label="From Time"
							value={startDate}
							onChange={(newValue) => {
								setStartDate(newValue.format('YYYY-MM-DD'));
								setEndDate(newValue.format('YYYY-MM-DD'));
							}}
							inputFormat="DD-MM-YYYY"
							maxDate={dateTo}
							renderInput={(params) => (
								<TextField required {...params} />
							)}
						/>
					</LocalizationProvider>
				</div>
				{/* end date input */}
				<div className=" w-full">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							required
							className=" w-full"
							label="Until Time"
							value={endDate}
							onChange={(newValue) => {
								setEndDate(newValue.format('YYYY-MM-DD'));
							}}
							inputFormat="DD-MM-YYYY"
							minDate={startDate}
							maxDate={dateToMax}
							renderInput={(params) => (
								<TextField required {...params} />
							)}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div className=" md:flex flex-row gap-5 mb-5 mt-5">
				<div className="w-1/2">
					<Autocomplete
						fullWidth={true}
						style={{
							width: 'auto',
						}}
						disablePortal
						id="selectFilter"
						options={dataFilter}
						onChange={(event, values) => {
							if (values != null) {
								setIdFilter(values.value);
							} else {
								setIdFilter(1);
							}
						}}
						value={dataFilter.filter((o) => o.value == idFilter)[0]}
						renderInput={(params) => (
							<TextField {...params} label="Filter Status" />
						)}
					/>
				</div>
				<div className=" w-1/2">
					<TextField
						className="w-full"
						id="corp-name"
						label="Merchant Key"
						variant="outlined"
						onChange={(text) => setMerchantKey(text.target.value)}
						placeholder="Merchant Key"
						InputLabelProps={{ shrink: true }}
						value={merchantKey}
					/>
				</div>
			</div>

			<Button
				customFunc={() => handleClick()}
				bgColor={currentColor}
				color="white"
				text="Filter"
				borderRadius={'10px'}
				icon={<FiRefreshCw />}
			/>

			<div className=" mt-5 md:mt-10 flex md:justify-between">
				<Header
					className="mb-3 flex-1"
					title={'Settlement BPD Maluku'}
				/>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						searchData();
					}}
					className="w-full mb-2 flex-1 flex end"
				>
					<FormControl
						className=" w-full flex"
						variant="outlined"
						style={{
							flexDirection: 'row',
						}}
					>
						<Autocomplete
							fullWidth={true}
							className="flex-1 mr-2"
							disablePortal
							id="selectFilter"
							options={dataSearch}
							onChange={(event, values) => {
								if (values != null) {
									setIdSearch(values.value);
								} else {
									setIdSearch(1);
								}
							}}
							size="small"
							value={dataSearch.filter((o) => o.value == idSearch)[0]}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Filter Search"
								/>
							)}
						/>
						<OutlinedInput
							size="small"
							className="flex-1"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							// required
							// onKeyPress={(e) => {
							// 	if (e.key === 'Enter') {

							// 	}
							// }}
							endAdornment={
								<InputAdornment position="end">
									<IconButton type="submit" edge="end">
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</form>
			</div>

			<div className=" mt-3 md:mt-3 md:mb-5 flex md:justify-between">
				<div className="flex-1 flex flex-row">
					<Button
						className="mr-2"
						customFunc={() => reqSyncMaluku()}
						bgColor={redColor}
						color="white"
						text="Sync to Maluku"
						borderRadius={'10px'}
						icon={<FiRefreshCw />}
					/>

					<Button
						customFunc={() => reqSyncA2p()}
						bgColor={greenColor}
						color="white"
						text="Sync from A2P"
						borderRadius={'10px'}
						icon={<FiRefreshCw />}
					/>
				</div>

				<Button
					customFunc={() => exportToExcel(dataCorp)}
					bgColor={greenColor}
					color="white"
					text="Export Excel"
					borderRadius={'10px'}
					className="align-self-end"
					icon={<FiUploadCloud />}
				/>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={getColum()}
					data={dataCorp}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					// paginationServer
					// paginationTotalRows={count}
					// paginationDefaultPage={currentPage}
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					// onSort={customSort}
					// sortServer
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default SettlementMaluku;
