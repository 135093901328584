import React, { useEffect, useState } from 'react';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns/src';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	BASE_URL_ONLINE,
	URL_GATEWAY_TRANSACTION_ADD,
	URL_GATEWAY_TRANSACTION_EDIT,
	URL_PAYMENTDATA_LIST,
	URL_STATUS_ADD,
} from '../../../config';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';

var CryptoJS = require('crypto-js');

const GatewayTransactionUpdate = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose, setAuth } =
		useStateContext();

	const navigate = useNavigate();
	const { Id } = useParams();

	const [id, setId] = useState(null);
	const [dataPayment, setDataPayment] = useState([]);
	const [idPayment, setIdPayment] = useState([]);
	const [urlWorker, setUrlWorker] = useState('');
	const [idJenisRouter, setIdJenisRouter] = useState(null);
	const [jenisRouter, setJenisRouter] = useState([
		{ id: 1, value: 'CALLBACK', label: 'CALLBACK' },
		{ id: 2, value: 'INQUIRY', label: 'INQUIRY' },
		{ id: 3, value: 'CONFIRMATION', label: 'CONFIRMATION' },
		{ id: 4, value: 'VOID', label: 'VOID' },
	]);

	const [status, setStatus] = useState(false);

	const currentIndexPayment =
		dataPayment && dataPayment.length > 0
			? dataPayment.findIndex((item) => {
					return item.value === idPayment;
			  })
			: 0;

	const currentIndexJenis =
		jenisRouter && jenisRouter.length > 0
			? jenisRouter.findIndex((item) => {
					return item.value === idJenisRouter;
			  })
			: 0;

	const dataUpdate = (e) => {
		e.preventDefault();

		modalOpenClose(true);

		const inputData = {
			urlworker: urlWorker,
			jenis: jenisRouter[currentIndexJenis].label,
			idmetodepembayaran: dataPayment[currentIndexPayment].id,
			namametodepembayaran: dataPayment[currentIndexPayment].uraian,
			idkategoripembayaran:
				dataPayment[currentIndexPayment].idKategoriPembayaran,
			namakategoripembayaran:
				dataPayment[currentIndexPayment].namaKategoriPembayaran,
		};

		fetch(BASE_URL_ONLINE + URL_GATEWAY_TRANSACTION_EDIT + id, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Basic bWtwbW9iaWxlOm1rcG1vYmlsZTEyMw==`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message || 'Successfully updating new record');
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	const getPaymentList = () => {
		fetch(BASE_URL + URL_PAYMENTDATA_LIST, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						let mdata = _.cloneDeep(data.result);

						mdata.map((o) => {
							o.value = o.id;
							o.label = o.uraian + ' - ' + o.acquiringName;

							return o;
						});

						mdata = _.sortBy(mdata, ['acquiringId']);

						setDataPayment(mdata);
					} else {
						setDataPayment([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	useEffect(() => {
		getPaymentList();
	}, []);

	const handleChangePayment = (event, values) => {
		if (values != null) {
			setIdPayment(values.value);
		} else {
			setIdPayment(null);
		}
	};

	const handleChangeJenis = (event, values) => {
		if (values != null) {
			setIdJenisRouter(values.value);
		} else {
			setIdJenisRouter(null);
		}
	};

	useEffect(() => {
		if (Id) {
			try {
				var byted = Id.replace(/p1L2u3S/g, '+')
					.replace(/s1L2a3S4h/g, '/')
					.replace(/e1Q2u3A4l/g, '=');

				var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
				var initialId = bytes.toString(CryptoJS.enc.Utf8);

				var data = JSON.parse(initialId);

				console.log(data);
				console.log(Number(data.acquiringId));

				setUrlWorker(data.urlworker);
				setIdPayment(data.idmetodepembayaran);
				setIdJenisRouter(data.jenis);
				setId(data._id);
			} catch (err) {
				navigate(`/404`);
			}
		}
	}, [Id]);

	const selectedPayment =
		dataPayment && dataPayment.length > 0
			? dataPayment.filter((o) => o.value == idPayment)[0].label
			: '';

	const selectedJenis =
		idJenisRouter && jenisRouter && jenisRouter.length > 0
			? jenisRouter.filter((o) => o.value == idJenisRouter)[0].label
			: '';

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="New Gateway Transaction" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="URL Worker"
						variant="outlined"
						onChange={(text) => setUrlWorker(text.target.value)}
						placeholder="URL Worker"
						InputLabelProps={{ shrink: true }}
						value={urlWorker}
					/>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndexJenis}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectJenis"
								options={jenisRouter}
								onChange={handleChangeJenis}
								value={selectedJenis}
								inputValue={selectedJenis}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Type"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndexPayment}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectPayment"
								options={dataPayment}
								onChange={handleChangePayment}
								value={selectedPayment}
								inputValue={selectedPayment}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Payment"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default GatewayTransactionUpdate;
