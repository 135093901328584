import React from 'react';
import { MdArrowBack, MdOutlineCancel } from 'react-icons/md';

import Button from './Button';
import { useStateContext } from '../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
	const {
		currentColor,
		notification,
		setNotification,
		closeClick,
		currentName,
		handleClick,
		setAuth,
		setHirarkiLogin,
	} = useStateContext();

	const navigate = useNavigate();

	return (
		<div className=" nav-item absolute right-5 md:right-3 top-16 shadow-md bg-white dark:bg-[#42464D] p-4 md:p-8 rounded-lg w-44 md:w-72">
			<div className=" flex justify-between items-center">
				<div className="  gap-3">
					<p className=" mb-5 font-semibold text-sm md:text-lg dark:text-gray-200">
						Welcome, {currentName}
					</p>

					<div className=" flex justify-end">
						<Button
							customFunc={() => {
								handleClick();
								setAuth(null);
								setHirarkiLogin(null);
								navigate('/');
							}}
							bgColor={'red'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Log Out"
							color={'white'}
							icon={<MdArrowBack />}
						/>
					</div>
				</div>
				{/* <Button
          icon={<MdOutlineCancel />}
          customFunc={() => closeClick("notification")}
          initialState={false}
          color="gray"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        /> */}
			</div>
		</div>
	);
};

export default UserProfile;
