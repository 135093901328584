import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	MdOutlineCancel,
	MdRadioButtonUnchecked,
} from 'react-icons/md';

import { useStateContext } from '../contexts/ContextProvider';

import A2Plogo from '../data/A2Plogo.png';

import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import { CustomRoutes, getRouteAvailable } from '../Routes';
import { SidebarData } from '../SidebarData';
import _ from 'lodash';

const drawerWidth = 290;

const styles = (theme) => ({
	drawer: {
		position: 'absolute',
		overflowX: 'hidden',
		zIndex: theme.zIndex.drawer + 2,
		[theme.breakpoints.up('sm')]: {
			position: 'relative',
			width: drawerWidth,
			flexShrink: 0,
			zIndex: theme.zIndex.drawer,
		},
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
		}),
		overflowX: 'hidden',
		width: 0,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9 + 1,
		},
	},
});

const StyledDrawer = styled(Drawer)`
	${({ theme, open }) => {
		const classes = styles(theme);
		return {
			...classes.drawer,
			...(open ? classes.drawerOpen : classes.drawerClose),
		};
	}}

	.MuiDrawer-paper {
		${({ theme, open }) => {
			const classes = styles(theme);
			return open ? classes.drawerOpen : classes.drawerClose;
		}}

		&::-webkit-scrollbar {
			width: 20px;
		}
		&:hover {
			&::-webkit-scrollbar-thumb {
				display: none;
			}
		}
		&::-webkit-scrollbar-thumb {
			display: none;
		}
		&::-webkit-scrollbar-track {
			display: none;
		}
	}
`;

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const Sidebar2 = () => {
	var address = window.location.host;

	const {
		activeMenu,
		setActiveMenu,
		screenSize,
		currentColor,
		setNavbar,
		setAuth,
		setHirarkiLogin,
		privilege,
	} = useStateContext();

	const handleCloseSideBar = () => {
		if (activeMenu && screenSize <= 500) {
			setActiveMenu(false);
		}
	};

	const theme = useTheme();
	const [open, setOpen] = useState(true);

	const handleDrawerClose = () => {
		setActiveMenu(false);
	};

	const sideBarOpen = () => {
		if (screenSize <= 1500) {
			setActiveMenu(false);
		}
	};

	const activeLink =
		'flex items-center ml-3 gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2';

	const normalLink =
		'flex items-center ml-3  gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

	const logOut = () => {
		setAuth(null);
		setHirarkiLogin(null);
		localStorage.removeItem('auth');
	};

	var width = window.innerWidth;
	const sidebarWidth = width >= 500 ? 300 : 0;

	const getSidebarAvailable = (routeData) => {
		let mSidebarData = _.cloneDeep(SidebarData);
		let mRoute = _.cloneDeep(routeData);

		let mFilter = mSidebarData.filter((o) => {
			let routeFilter = mRoute.filter(
				(m) => m.name == o.nameRoute && m.status
			);

			return routeFilter.length > 0;
		});

		return mFilter;
	};

	useEffect(() => {
		sideBarOpen();
	}, []);

	const groupSidebar = (sidebar) => {
		if (sidebar) {
			let group = _.groupBy(sidebar, 'groupName');
			return Object.keys(group);
		} else {
			return [];
		}
	};

	let availableData = getSidebarAvailable(
		getRouteAvailable(CustomRoutes, privilege)
	);

	return (
		<div>
			<StyledDrawer
				sx={{
					width: activeMenu ? sidebarWidth : 0,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="left"
				open={activeMenu}
			>
				<div className=" mt-5 md:-mt-0" />
				<DrawerHeader>
					<div className=" fixed flex justify-between  items-center mb-5 bg-white md:mt-2 px-4 pt-3 z-50 ">
						<Link
							to="/"
							onClick={handleCloseSideBar}
							className="items-center gap-2 mr-3 md:mr-4  mt-4 flex text-xl font-extrabold tracking-tight 
              
          dark:text-white text-slate-900  "
						>
							{address === 'localhost:3000' ? (
								<div className="flex items-center   ">
									<img
										src={A2Plogo}
										style={{ width: 'auto', height: 100 }}
									/>
								</div>
							) : address === '192.168.1.56:3000' ? (
								<div className="  flex items-center ">
									<img
										src={A2Plogo}
										style={{ width: 'auto', height: 100 }}
									/>
								</div>
							) : (
								<div className="  flex items-center gap-2">
									<img
										src={A2Plogo}
										style={{ width: 'auto', height: 100 }}
									/>
								</div>
							)}
						</Link>
						{width <= 500 ? (
							<button
								type="button"
								onClick={() => handleDrawerClose()}
								className="text-xl rounded-full  hover:bg-light-gray mt-6 dark:text-gray-400 ml-3"
							>
								<MdOutlineCancel />
							</button>
						) : (
							<p className=" mr-8 text-2xl"></p>
						)}
					</div>
				</DrawerHeader>
				<List className="">
					<div className=" mt-5" />
					{availableData &&
						groupSidebar(availableData).map((gName) => {
							return (
								<div>
									<button
										onClick={() => setOpen((prevOpen) => !prevOpen)}
										className="text-gray-400 m-3 ml-5 mt-4 uppercase"
									>
										{gName}
									</button>
									{availableData
										.filter((o) => o.groupName == gName)
										.map((data) => {
											return (
												<NavLink
													to={data.link}
													onClick={handleCloseSideBar}
													style={({ isActive }) => ({
														backgroundColor: isActive
															? currentColor
															: '',
													})}
													className={({ isActive }) =>
														isActive ? activeLink : normalLink
													}
												>
													{data.icon}
													<span className=" capitalize">
														{data.nameLink}
													</span>
												</NavLink>
											);
										})}
								</div>
							);
						})}

					<div>
						<button
							onClick={() => setOpen((prevOpen) => !prevOpen)}
							className="text-gray-400 m-3 ml-5 mt-4 uppercase"
						>
							Log Out
						</button>
						<NavLink
							to={'/'}
							onClick={() => logOut()}
							className={normalLink}
						>
							<MdRadioButtonUnchecked />
							<span className=" capitalize">Log Out</span>
						</NavLink>
					</div>

					<div className=" mb-10" />
				</List>
			</StyledDrawer>
		</div>
	);
};

export default Sidebar2;
