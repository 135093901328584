const ReusableSummaryCard = ({
  logo,
  nominal,
  name,
  period,
  color,
  colorBg,
}) => {
  return (
    <div
      // style={{ background: colorBg }}
      className="col-span-12 md:col-span-6 lg:col-span-3 shadow-lg shadow-indigo-100 p-5 rounded-3xl"
    >
      <div className="flex justify-between gap-4 items-center h-full">
        {/* text */}
        <div className="">
          <p
            className="mb-3 text-3xl text-white font-semibold"
            style={{ color: color }}
          >
            {nominal}
          </p>

          <p className="font-semibold">{name}</p>
          <p className="text-white font-light text-sm text-gray-500">
            {period}
          </p>
        </div>
        {/* logo */}
        <div className="">
          <p
            style={{
              background: colorBg,
            }}
            className="text-3xl font-bold rounded-full p-5"
          >
            {logo}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReusableSummaryCard;
