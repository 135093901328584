import { Modal, TextField } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { Button } from '../../components';
import { ToastContainer, toast } from 'react-toastify';
import { useStateContext } from '../../contexts/ContextProvider';
import {
	encryptHmac512,
	encryptHmac512Base64,
} from '../../utils/CryptoUtils';
import { BASE_URL, URL_REPORTING_VOID } from '../../config';

export const VoidTransaction = (props) => {
	const { redColor, modalOpenClose, currentAuth } = useStateContext();

	const [modal, setModal] = useState(false);
	const [modalPassword, setModalPassword] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [data, setData] = useState(null);

	useEffect(() => {
		if (props.data) {
			setData(props.data);
			setModal(true);
		}
	}, [props.data]);

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => props.onSuccess(),
		});
	};

	const notifyErr = (message) => {
		toast.error(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const callVoidData = (e) => {
		e.preventDefault();

		setModal(false);
		setModalPassword(false);

		modalOpenClose(true);

		const inputData = {
			noHeader: props.data.noHeader,
			username: username,
			password: encryptHmac512Base64(password),
		};

		fetch(BASE_URL + URL_REPORTING_VOID, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				modalOpenClose(false);
				setUsername('');
				setPassword('');
				setData(null);
				props.onCancel();

				if (json.success === true) {
					if (json.result.success) {
						notify('Data successfully void');
					} else {
						notifyErr(json.result.message);
					}
				} else if (json.success === false) {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				notifyErr(err.message);
				setUsername('');
				setPassword('');
				setData(null);
				props.onCancel();
			});
	};

	return (
		<>
			<Modal
				onBackdropClick={() => {
					setModal(false);
					setData(null);
					props.onCancel();
				}}
				open={modal}
			>
				<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p className=" font-semibold text-base md:text-xl">
						Anda ingin melakukan pengembalian dana ?
					</p>
					<div className=" flex justify-center gap-5 mt-5 ">
						<Button
							customFunc={() => {
								setModal(false);
								setData(null);
								props.onCancel();
							}}
							bgColor={'gray'}
							borderRadius={'10px'}
							color="white"
							text="Batalkan"
						/>
						<Button
							customFunc={() => {
								setModal(false);
								setModalPassword(true);
							}}
							bgColor={'red'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Lanjut"
							color={'white'}
						/>
					</div>
				</div>
			</Modal>

			<Modal
				onBackdropClick={() => {
					setModalPassword(false);
					setData(null);
					props.onCancel();
				}}
				open={modalPassword}
				onClose={() => {
					setUsername('');
					setPassword('');
					setData(null);
					props.onCancel();
				}}
			>
				<form onSubmit={callVoidData}>
					<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
						<p className=" font-semibold text-base md:text-xl">
							Masukkan data login Anda
						</p>
						<div className=" mt-5 w-full">
							<TextField
								className="w-full"
								required
								id="corp-name"
								label="Username"
								variant="outlined"
								onChange={(text) => setUsername(text.target.value)}
								placeholder="Username"
								InputLabelProps={{ shrink: true }}
								value={username}
								defaultValue={username}
							/>
						</div>

						<div className=" mt-5 w-full">
							<TextField
								className="w-full"
								required
								id="corp-name"
								label="Password"
								variant="outlined"
								type="password"
								onChange={(text) => setPassword(text.target.value)}
								placeholder="Password"
								InputLabelProps={{ shrink: true }}
								value={password}
								defaultValue={password}
							/>
						</div>

						<div className=" flex justify-center gap-5 mt-5 ">
							<Button
								customFunc={() => {
									setModalPassword(false);
									setUsername('');
									setPassword('');
									setModalPassword(false);
									setData(null);
									props.onCancel();
								}}
								bgColor={'gray'}
								borderRadius={'10px'}
								color="white"
								text="Batalkan"
							/>
							<Button
								type="submit"
								bgColor={'red'}
								width="60"
								height="fit"
								borderRadius={'10px'}
								text="Submit"
								color={'white'}
							/>
						</div>
					</div>
				</form>
			</Modal>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</>
	);
};
