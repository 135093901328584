import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL, URL_ROLE_LIST } from '../../../config';

import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';

var CryptoJS = require('crypto-js');

const UserUpdate = () => {
	const { Id } = useParams();
	const navigate = useNavigate();

	var byted = Id.replace(/p1L2u3S/g, '+')
		.replace(/s1L2a3S4h/g, '/')
		.replace(/e1Q2u3A4l/g, '=');

	var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
	var initialId = bytes.toString(CryptoJS.enc.Utf8);

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		activeMenu,
	} = useStateContext();

	// get data corporate
	const [corp, setCorp] = useState([]);

	const getCorp = () => {
		const dataBody = {
			order: 'ASC',
			// limit: 100,
			draw: 2,
			page: 1,
			hirarkiId: hirarkiUser,
		};
		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						console.log(data.result);
						setCorp(data.result);
					} else {
						setCorp([]);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// get data category (jenis user)
	const [category, setCategory] = useState([]);

	const getCorpCategory = () => {
		fetch(BASE_URL + '/user/jenis', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				// console.log(response);
				return response.json();
			})
			.then((data) => {
				setCategory(data.result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// get data blacklist (check box)
	const [blacklist, setBlacklist] = useState([]);
	const getBlackListUser = () => {
		const dataInput = {
			idUser: parseInt(initialId),
		};
		fetch(BASE_URL + '/bl/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.result != null) {
					setBlacklist(json.result.blktgPembayaran);
				} else {
					setBlacklist([]);
				}
			})
			.catch((err) => {
				alert(err);
			});
	};

	const [dataRole, setDataRole] = useState([]);

	const getDataRole = () => {
		const dataInput = {
			corporateHirarki: hirarkiUser,
		};

		fetch(BASE_URL + URL_ROLE_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log(json);
				if (json.result != null) {
					let mdata = _.cloneDeep(json.result);
					mdata.map((o) => {
						o.value = o.id;
						o.label = o.roleName;
					});

					setDataRole(mdata);
				} else {
					setDataRole([]);
				}
			})
			.catch((err) => {
				return console.log(err);
			});
	};

	// handle checkbox for blacklist
	const [checked, setChecked] = useState([]);

	const setArray = () => {
		let tempArray = [...checked];
		{
			blacklist.map((item) => {
				if (item.status === true) {
					tempArray.push(item.idKategoriPembayaran);
				}
			});
		}
		setChecked(tempArray);
	};

	useEffect(() => {
		setArray();
	}, [blacklist]);

	// data used to fill out the form
	const [nama, setNama] = useState('');
	const [username, setUsername] = useState('');
	const [jenis, setJenis] = useState(0);
	const [idCorp, setIdCorp] = useState(0);
	const [email, setEmail] = useState('');
	const [idUser, setIdUser] = useState(0);
	const [role, setRole] = useState(null);

	const [callbackDataList, setCallbackDataList] = useState([
		{ label: 'YES', value: true },
		{ label: 'NO', value: false },
	]);
	const [callbackData, setCallbackData] = useState(false);

	const [userLength, setUserLength] = useState(false);
	const usernameCheck = () => {
		if (username.length > 0 && username.length < 8) {
			setUserLength(true);
		} else if (username.length >= 8 || username.length === 0) {
			setUserLength(false);
		}
	};

	useEffect(() => {
		usernameCheck();
	}, [username]);

	// update data function
	const updateData = (e) => {
		e.preventDefault();
		const dataInput =
			jenis === 1
				? {
						nama: nama,
						username: username,
						jenis: parseInt(jenis),
						idcorporate: parseInt(idCorp),
						email: email,
						id: parseInt(initialId),
						idRole: role,
						callbackData: callbackData,
				  }
				: {
						nama: nama,
						username: username,
						jenis: parseInt(jenis),
						idcorporate: parseInt(idCorp),
						email: email,
						id: parseInt(initialId),
						callbackData: callbackData,
				  };

		fetch(BASE_URL + '/user/edit', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				setIdUser(json.result);
				let message = json.message;
				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((err) => {
				notify(err.essage);
			});
	};

	const blacklistUpdate = (idcorp) => {
		const data = checked.map((data) => ({
			idKategoriPembayaran: data,
		}));

		const dataInput = {
			idUser: parseInt(initialId),
			idKategoriPembayaran: data,
		};

		fetch(BASE_URL + '/bl/edit', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {})
			.catch((err) => {
				notify(err.message);
			});
	};

	// handle dropdown change value

	const onChangeCategory = (event, values) => {
		console.log(values.value);
		setJenis(values.value);
	};

	const [warning, setWarning] = useState(false);
	const [oneData, setOneData] = useState([]);

	const getOneUser = () => {
		const dataInput = {
			id: parseInt(initialId),
		};

		fetch(BASE_URL + '/user/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					setOneData(json.result);
					console.log(json);
				} else {
					setOneData([]);
					console.log(json);
				}
			})
			.catch((err) => {
				console.log('error =>', err);
			});
	};

	useEffect(() => {
		getDataRole();
		getCorp();
		getCorpCategory();
		getBlackListUser();
		getOneUser();
	}, []);

	// set data to form from getOneUser() function
	const setData = () => {
		{
			oneData.map((item) => {
				// data is array, so map function is used
				setNama(item.nama);
				setUsername(item.username);
				setEmail(item.email);
				setIdCorp(item.idCorporate);
				setJenis(item.idJenis);
				setRole(item.idRole);
				setCallbackData(item.callbackData);
			});
		}
	};

	// run function if oneData is filled
	useEffect(() => {
		setData();
	}, [oneData]);

	const onChangeRole = (event, values) => {
		setRole(values.value);
	};

	const onChangeCallbackData = (event, values) => {
		setCallbackData(values.value);
	};

	const dataCorp = [];
	{
		corp.map((item) => {
			let dataValue = {
				value: item.id,
				label: item.uraian,
			};
			dataCorp.push(dataValue);
		});
	}

	const currentIndex = dataCorp.findIndex((item) => {
		return item.value === idCorp;
	});

	const onChangeCorp = (event, values) => {
		setIdCorp(values.value);
	};

	const dataUser = [];
	{
		category.map((item) => {
			let dataValue = {
				value: item.id,
				label: item.jenisUser,
			};
			dataUser.push(dataValue);
		});
	}

	const indexJenisUser = dataUser.findIndex((item) => {
		return item.value === jenis;
	});

	const selectedRole =
		role && dataRole && dataRole.length > 0
			? dataRole.filter((o) => o.value == role)[0].roleName
			: '';

	const currentIndexCallbackData =
		callbackDataList && callbackDataList.length > 0
			? callbackDataList.findIndex((item) => {
					return item.value === callbackData;
			  })
			: 0;
	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={updateData}
		>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10 rounded-3xl bg-white dark:bg-white">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="Ubah User" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" flex gap-5 mb-5">
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="name"
							label="Nama"
							variant="outlined"
							onChange={(text) => setNama(text.target.value)}
							placeholder="Dimas Beck, Putri Ayu, ..."
							InputLabelProps={{ shrink: true }}
							value={nama}
						/>
					</div>
					<div className=" w-full">
						<TextField
							error={userLength}
							className="w-full"
							required
							id="username"
							label="Username"
							variant="outlined"
							onChange={(text) => setUsername(text.target.value)}
							placeholder="dimasbk, ptrayu, ..."
							InputLabelProps={{ shrink: true }}
							value={username}
						/>

						{userLength === true ? (
							<div>
								<p className=" absolute text-red-500 text-xs">
									*Username kurang dari 8 karakter
								</p>
							</div>
						) : (
							''
						)}
					</div>
				</div>

				<div className=" flex gap-5 mb-5">
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="email"
							label="Email"
							variant="outlined"
							onChange={(text) => setEmail(text.target.value)}
							placeholder="email@gmail.com"
							InputLabelProps={{ shrink: true }}
							value={email}
						/>
					</div>

					<div className=" w-full -mr-0 md:-mr-0 ">
						<Autocomplete
							key={currentIndex}
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="combo-box-demo"
							options={dataCorp}
							onChange={onChangeCorp}
							defaultValue={dataCorp[currentIndex]}
							renderInput={(params) => (
								<TextField
									placeholder="Corporate"
									required
									{...params}
									label="Corporate"
								/>
							)}
						/>
					</div>
				</div>

				<div className=" w-full flex gap-5">
					<div className=" w-full">
						<Autocomplete
							key={indexJenisUser}
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="jenis-user"
							options={dataUser}
							onChange={onChangeCategory}
							defaultValue={dataUser[indexJenisUser]}
							renderInput={(params) => (
								<TextField required {...params} label="Jenis User" />
							)}
						/>
					</div>

					{jenis === 1 ? (
						<div className=" w-full">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="jenis-user"
								options={dataRole}
								onChange={onChangeRole}
								defaultValue={selectedRole}
								value={selectedRole}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Jenis Role"
									/>
								)}
							/>
						</div>
					) : (
						''
					)}

					<div className=" w-full">
						<Autocomplete
							required
							key={currentIndexCallbackData}
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							disableClearable
							id="jenis-user"
							options={callbackDataList}
							onChange={onChangeCallbackData}
							defaultValue={
								callbackDataList
									? callbackDataList[currentIndexCallbackData]
									: null
							}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Using Callback Data"
								/>
							)}
						/>
					</div>
				</div>

				<div className=" mt-10 flex gap-5 justify-between">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						borderRadius={'10px'}
						color="white"
						text="Previous Page"
						icon={<MdArrowBack />}
					/>
					<Button
						type="submit"
						bgColor={currentColor}
						borderRadius={'10px'}
						color="white"
						text="Submit"
						icon={<MdSave />}
					/>
				</div>
			</div>
		</form>
	);
};

export default UserUpdate;
