import {
	Dashboard,
	Corporate,
	CorporatePayment,
	Produk,
	ListTransaction,
	SettledTransaction,
	SuccessTransaction,
	Login,
	TransactionDetail,
	InsertProduct,
	UpdateProduct,
	PageNotFound,
} from './page';

import PaymentCategory from './page/Master/PaymentCategory/PaymentCategory';
import PaymentData from './page/Master/PaymentData/PaymentData';
import PaymentCategoryUpdate from './page/Master/PaymentCategory/PaymentCategoryUpdate';
import PaymentCategoryAdd from './page/Master/PaymentCategory/PaymentCategoryAdd';
import AcquiringPayment from './page/Master/AquiringPayment/AcquiringPayment';
import AcquiringPaymentUpdate from './page/Master/AquiringPayment/AcquiringPaymentUpdate';
import AcquiringPaymentAdd from './page/Master/AquiringPayment/AcquiringPaymentAdd';
import PaymentDataAdd from './page/Master/PaymentData/PaymentDataAdd';
import PaymentDataUpdate from './page/Master/PaymentData/PaymentDataUpdate';
import ServiceLog from './page/System/ServiceLog/ServiceLog';
import StatusData from './page/System/StatusData/StatusData';
import StatusDataUpdate from './page/System/StatusData/StatusDataUpdate';
import StatusDataAdd from './page/System/StatusData/StatusDataAdd';
import SettlementMaluku from './page/Settlement/BPDMaluku/SettlementMaluku';
import { Navigate } from 'react-router-dom';
import CorporateAdd from './page/Pengaturan/Corporate/CorporateAdd';
import CorporateUpdate from './page/Pengaturan/Corporate/CorporateUpdate';
import CorporatePaymentUpdate from './page/Pengaturan/CorporatePayment/CorporatePaymentUpdate';
import SettlementKey from './page/Pengaturan/SettlementKey/SettlementKey';
import SettlementKeyAdd from './page/Pengaturan/SettlementKey/SettlementKeyAdd';
import SettlementKeyUpdate from './page/Pengaturan/SettlementKey/SettlementKeyUpdate';
import User from './page/Pengaturan/User/User';
import UserAdd from './page/Pengaturan/User/UserAdd';
import UserUpdate from './page/Pengaturan/User/UserUpdate';
import Device from './page/Pengaturan/Device/Device';
import DeviceAdd from './page/Pengaturan/Device/DeviceAdd';
import DeviceUpdate from './page/Pengaturan/Device/DeviceUpdate';
import DeviceLog from './page/Pengaturan/Device/DeviceLog';
import RoleTask from './page/Master/RoleTask/RoleTask';
import RoleTasktaUpdate from './page/Master/RoleTask/RoleTaskUpdate';
import RoleTaskAdd from './page/Master/RoleTask/RoleTaskAdd';
import Role from './page/Pengaturan/Role/Role';
import RoleAdd from './page/Pengaturan/Role/RoleAdd';
import RoleUpdate from './page/Pengaturan/Role/RoleUpdate';

import _ from 'lodash';
import GatewayTransaction from './page/System/GatewayTransaction/GatewayTransaction';
import GatewayTransactionAdd from './page/System/GatewayTransaction/GatewayTransactionAdd';
import GatewayTransactionUpdate from './page/System/GatewayTransaction/GatewayTransactionUpdate';
import IssuingPaymentUpdate from './page/Master/IssuingPayment/IssuingPaymentUpdate';
import IssuingPaymentAdd from './page/Master/IssuingPayment/IssuingPaymentAdd';
import IssuingPayment from './page/Master/IssuingPayment/IssuingPayment';
import IssuingBINPayment from './page/Master/IssuingBINPayment/IssuingBINPayment';
import IssuingBINPaymentUpdate from './page/Master/IssuingBINPayment/IssuingBINPaymentUpdate';
import IssuingBINPaymentAdd from './page/Master/IssuingBINPayment/IssuingBINPaymentAdd';
import CardType from './page/Master/CardType/CardType';
import CardTypeUpdate from './page/Master/CardType/CardTypeUpdate';
import CardTypeAdd from './page/Master/CardType/CardTypeAdd';
import GatewayMerchant from './page/DebitGateway/GatewayMerchant/GatewayMerchant';
import GatewayMerchantUpdate from './page/DebitGateway/GatewayMerchant/GatewayMerchantUpdate';
import GatewayMerchantAdd from './page/DebitGateway/GatewayMerchant/GatewayMerchantAdd';
import GatewaySegmentasi from './page/DebitGateway/GatewaySegmentasi/GatewaySegmentasi';
import GatewaySegmentasiUpdate from './page/DebitGateway/GatewaySegmentasi/GatewaySegmentasiUpdate';
import GatewaySegmentasiAdd from './page/DebitGateway/GatewaySegmentasi/GatewaySegmentasiAdd';
import GatewayMerchantAcquiring from './page/DebitGateway/GatewayMerchantAcquiring/GatewayMerchantAcquiring';
import GatewayMerchantAcquiringUpdate from './page/DebitGateway/GatewayMerchantAcquiring/GatewayMerchantAcquiringUpdate';
import GatewayMerchantAcquiringAdd from './page/DebitGateway/GatewayMerchantAcquiring/GatewayMerchantAcquiringAdd';
import GatewayMerchantAcquiringDevice from './page/DebitGateway/GatewayMerchantAcquiringDevice/GatewayMerchantAcquiringDevice';
import GatewayMerchantAcquiringDeviceUpdate from './page/DebitGateway/GatewayMerchantAcquiringDevice/GatewayMerchantAcquiringDeviceUpdate';
import GatewayMerchantAcquiringDeviceAdd from './page/DebitGateway/GatewayMerchantAcquiringDevice/GatewayMerchantAcquiringDeviceAdd';
import GatewaySegmentasiDetailUpdate from './page/DebitGateway/GatewaySegmentasiDetail/GatewaySegmentasiDetailUpdate';
import GatewaySegmentasiDetailAdd from './page/DebitGateway/GatewaySegmentasiDetail/GatewaySegmentasiDetailAdd';
import GatewaySegmentasiDetail from './page/DebitGateway/GatewaySegmentasiDetail/GatewaySegmentasiDetail';
import DeviceLogDetail from './page/Pengaturan/Device/DeviceLogDetail';
import ListTransactionBDKI from './page/Laporan/ListTransactionBDKI';
import SuccessTransactionBDKI from './page/Laporan/SuccessTransactionBDKI';
import DashboardBDKI from './page/DashboardBDKI';
import MerchantTransactionBDKI from './page/Laporan/MerchantTransactionBDKI';
import CorporateDetail from './page/Pengaturan/Corporate/CorporateDetail';
import PaymentLinkAdd from './page/PaymentLink/PaymentLinkAdd';
import PaymentLinkList from './page/PaymentLink/PaymentLinkList';
import StaticQR from './page/Pengaturan/StaticQR/StaticQR';
import StaticQRAdd from './page/Pengaturan/StaticQR/StaticQRAdd';
import StaticQREdit from './page/Pengaturan/StaticQR/StaticQREdit';
import SoundboxUser from './page/Soundbox/User/SoundboxUser';
import SoundboxSummary from './page/Soundbox/Summary/SoundboxSummary';
import SoundboxList from './page/Soundbox/List/SoundboxList';
import SoundboxUserUpdate from './page/Soundbox/User/SoundboxUserUpdate';

export const CustomRoutes = [
	{
		name: 'login',
		path: '/login',
		element: <Login />,
		groupRole: 'ALL',
		roleTask: [],
	},
	{
		name: 'home',
		path: '/',
		element: <Dashboard />,
		groupRole: 'DASHBOARD',
		roleTask: [
			'VIEW_REVENUE_DATA',
			'VIEW_CHART_REVENUE',
			'VIEW_CHART_REVENUE_HOURLY',
			'VIEW_CHART_REVENUE_BY_PAYMENT',
		],
	},
	{
		name: 'home',
		path: '/',
		element: <DashboardBDKI />,
		groupRole: 'DASHBOARD',
		roleTask: [
			'VIEW_REVENUE_DATA_V2',
			'VIEW_CHART_REVENUE_V2',
			'VIEW_CHART_REVENUE_HOURLY_V2',
			'VIEW_CHART_REVENUE_BY_PAYMENT_V2',
		],
	},
	{
		name: 'dashboard',
		path: '/dashboard',
		element: <Dashboard />,
		groupRole: 'DASHBOARD',
		roleTask: [
			'VIEW_REVENUE_DATA',
			'VIEW_CHART_REVENUE',
			'VIEW_CHART_REVENUE_HOURLY',
			'VIEW_CHART_REVENUE_BY_PAYMENT',
		],
	},
	{
		name: 'corporate',
		path: '/corporate',
		element: <Corporate />,
		groupRole: 'CONFIG_CORPORATE',
		roleTask: [
			'VIEW_CONFIG_CORPORATE',
			'ADD_CONFIG_CORPORATE',
			'EDIT_CONFIG_CORPORATE',
			'DELETE_CONFIG_CORPORATE',
		],
	},
	{
		name: 'insert-corporate',
		path: '/insert-corporate',
		element: <CorporateAdd />,
		groupRole: 'CONFIG_CORPORATE',
		roleTask: ['ADD_CONFIG_CORPORATE'],
	},
	{
		name: 'update-corp',
		path: '/update-corp/:Id',
		element: <CorporateUpdate />,
		groupRole: 'CONFIG_CORPORATE',
		roleTask: ['VIEW_CONFIG_CORPORATE'],
	},
	{
		name: 'view-corp-detail',
		path: '/view-corp-detail/:Id',
		element: <CorporateDetail />,
		groupRole: 'CONFIG_CORPORATE',
		roleTask: ['VIEW_CONFIG_CORPORATE'],
	},
	{
		name: 'corporate-payment',
		path: '/corporate-payment',
		element: <CorporatePayment />,
		groupRole: 'CONFIG_CORPORATE_PAYMENT',
		roleTask: [
			'VIEW_CONFIG_CORPORATE_PAYMENT',
			'EDIT_CONFIG_CORPORATE_PAYMENT',
		],
	},
	{
		name: 'insert-active-payment',
		path: '/insert-active-payment/:Id',
		element: <CorporatePaymentUpdate />,
		groupRole: 'CONFIG_CORPORATE_PAYMENT',
		roleTask: ['EDIT_CONFIG_CORPORATE_PAYMENT'],
	},
	{
		name: 'core-settlement-keys',
		path: '/core-settlement-keys',
		element: <SettlementKey />,
		groupRole: 'CONFIG_SETTLEMENT_KEY',
		roleTask: [
			'VIEW_CONFIG_SETTLEMENT_KEY',
			'ADD_CONFIG_SETTLEMENT_KEY',
			'EDIT_CONFIG_SETTLEMENT_KEY',
			'DELETE_CONFIG_SETTLEMENT_KEY',
		],
	},
	{
		name: 'insert-core-settlement',
		path: '/insert-core-settlement',
		element: <SettlementKeyAdd />,
		groupRole: 'CONFIG_SETTLEMENT_KEY',
		roleTask: ['ADD_CONFIG_SETTLEMENT_KEY'],
	},
	{
		name: 'update-core-settlement',
		path: '/update-core-settlement/:Id',
		element: <SettlementKeyUpdate />,
		groupRole: 'CONFIG_SETTLEMENT_KEY',
		roleTask: ['EDIT_CONFIG_SETTLEMENT_KEY'],
	},
	{
		name: 'user',
		path: '/user',
		element: <User />,
		groupRole: 'CONFIG_USER',
		roleTask: [
			'VIEW_CONFIG_USER',
			'ADD_CONFIG_USER',
			'EDIT_CONFIG_USER',
			'DELETE_CONFIG_USER',
		],
	},
	{
		name: 'insert-user',
		path: '/insert-user',
		element: <UserAdd />,
		groupRole: 'CONFIG_USER',
		roleTask: ['ADD_CONFIG_USER'],
	},
	{
		name: 'update-user',
		path: '/update-user/:Id',
		element: <UserUpdate />,
		groupRole: 'CONFIG_USER',
		roleTask: ['EDIT_CONFIG_USER'],
	},
	{
		name: 'device',
		path: '/device',
		element: <Device />,
		groupRole: 'CONFIG_DEVICE',
		roleTask: [
			'VIEW_CONFIG_DEVICE',
			'ADD_CONFIG_DEVICE',
			'EDIT_CONFIG_DEVICE',
			'DELETE_CONFIG_DEVICE',
		],
	},
	{
		name: 'insert-device',
		path: '/insert-device',
		element: <DeviceAdd />,
		groupRole: 'CONFIG_DEVICE',
		roleTask: ['ADD_CONFIG_DEVICE'],
	},
	{
		name: 'update-device',
		path: '/update-device/:Id',
		element: <DeviceUpdate />,
		groupRole: 'CONFIG_DEVICE',
		roleTask: ['EDIT_CONFIG_DEVICE'],
	},
	{
		name: 'log-device',
		path: '/log-device',
		element: <DeviceLog />,
		groupRole: 'CONFIG_DEVICE',
		roleTask: ['VIEW_LOG_DEVICE'],
	},
	{
		name: 'log-device',
		path: '/log-device/:Id',
		element: <DeviceLogDetail />,
		groupRole: 'CONFIG_DEVICE',
		roleTask: ['VIEW_LOG_DEVICE'],
	},
	{
		name: 'payment-link-add',
		path: '/payment-link-add',
		element: <PaymentLinkAdd />,
		groupRole: 'PAYMENT_LINK',
		roleTask: ['PAYMENT_LINK_ADD'],
	},
	{
		name: 'payment-link',
		path: '/payment-link',
		element: <PaymentLinkList />,
		groupRole: 'PAYMENT_LINK',
		roleTask: [
			'PAYMENT_LINK_VIEW',
			'PAYMENT_LINK_ADD',
			'PAYMENT_LINK_CANCEL',
			'PAYMENT_LINK_ACTIVATION',
		],
	},
	{
		name: 'merchant-transaction',
		path: '/merchant-transaction',
		element: <MerchantTransactionBDKI />,
		groupRole: 'REPORT_TRANSACTION',
		roleTask: [
			'REPORT_MERCHANT_BDKI_VIEW',
			'REPORT_MERCHANT_BDKI_EXCEL_DATA',
		],
	},
	{
		name: 'static-qr',
		path: '/static-qr',
		element: <StaticQR />,
		groupRole: 'CONFIG_STATIC_QR',
		roleTask: ['VIEW_CONFIG_STATIC_QR'],
	},
	{
		name: 'static-qr',
		path: '/insert-static-qr',
		element: <StaticQRAdd />,
		groupRole: 'CONFIG_STATIC_QR',
		roleTask: ['ADD_CONFIG_STATIC_QR'],
	},
	{
		name: 'static-qr',
		path: '/update-static-qr/:Id',
		element: <StaticQREdit />,
		groupRole: 'CONFIG_STATIC_QR',
		roleTask: ['EDIT_CONFIG_STATIC_QR'],
	},
	{
		name: 'soundbox-summary',
		path: '/soundbox-summary',
		element: <SoundboxSummary />,
		groupRole: 'SOUNDBOX_SUMMARY',
		roleTask: [
			'VIEW_SOUNDBOX_SUMMARY',
			'VIEW_SOUND_CARD',
			'VIEW_QR_CARD',
			'VIEW_SOUND_CHART',
			'VIEW_QR_CHART',
		],
	},
	{
		name: 'soundbox-list',
		path: '/soundbox-list',
		element: <SoundboxList />,
		groupRole: 'SOUNDBOX_LIST',
		roleTask: ['VIEW_SOUNDBOX_LIST', 'VIEW_SOUNDBOX_LIST_DETAIL'],
	},
	{
		name: 'soundbox-user',
		path: '/soundbox-user',
		element: <SoundboxUser />,
		groupRole: 'SOUNDBOX_USER',
		roleTask: ['VIEW_SOUNDBOX_USER', 'EDIT_SOUNDBOX_USER'],
	},
	{
		name: 'soundbox',
		path: '/update-soundbox-user/:Id',
		element: <SoundboxUserUpdate />,
		groupRole: 'SOUNDBOX_USER',
		roleTask: ['EDIT_SOUNDBOX_USER'],
	},
	{
		name: 'list-transaction',
		path: '/list-transaction',
		element: <ListTransaction />,
		groupRole: 'REPORT_TRANSACTION',
		roleTask: [
			'REPORT_LIST_VIEW',
			'REPORT_LIST_PDF_SUMMARY',
			'REPORT_LIST_EXCEL_DATA',
			'REPORT_DETAIL_TRANSACTION',
		],
	},
	{
		name: 'list-transaction',
		path: '/list-transaction',
		element: <ListTransactionBDKI />,
		groupRole: 'REPORT_TRANSACTION',
		roleTask: [
			'REPORT_LIST_BDKI_VIEW',
			'REPORT_LIST_BDKI_PDF_SUMMARY',
			'REPORT_LIST_BDKI_EXCEL_DATA',
		],
	},
	{
		name: 'settled-transaction',
		path: '/settled-transaction',
		element: <SettledTransaction />,
		groupRole: 'REPORT_TRANSACTION',
		roleTask: [
			'REPORT_SETTLE_VIEW',
			'REPORT_SETTLE_PDF_SUMMARY',
			'REPORT_SETTLE_EXCEL_LIST',
			'REPORT_DETAIL_TRANSACTION',
		],
	},
	{
		name: 'success-transaction',
		path: '/success-transaction',
		element: <SuccessTransaction />,
		groupRole: 'REPORT_TRANSACTION',
		roleTask: [
			'REPORT_SUCCESS_VIEW',
			'REPORT_SUCCESS_PDF_SUMMARY',
			'REPORT_SUCCESS_EXCEL_LIST',
			'REPORT_DETAIL_TRANSACTION',
		],
	},
	{
		name: 'success-transaction',
		path: '/success-transaction',
		element: <SuccessTransactionBDKI />,
		groupRole: 'REPORT_TRANSACTION',
		roleTask: [
			'REPORT_SUCCESS_BDKI_VIEW',
			'REPORT_SUCCESS_BDKI_PDF_SUMMARY',
			'REPORT_SUCCESS_BDKI_EXCEL_LIST',
		],
	},
	{
		name: 'transaction-detail',
		path: '/transaction-detail/:Id',
		element: <TransactionDetail />,
		groupRole: 'REPORT_TRANSACTION',
		roleTask: ['REPORT_DETAIL_TRANSACTION'],
	},
	{
		name: 'produk',
		path: '/produk',
		element: <Produk />,
		groupRole: '',
		roleTask: [],
	},
	{
		name: 'insert-produk',
		path: '/insert-produk',
		element: <InsertProduct />,
		groupRole: '',
		roleTask: [],
	},
	{
		name: 'update-produk/:Id',
		path: '/update-produk/:Id',
		element: <UpdateProduct />,
		groupRole: '',
		roleTask: [],
	},
	{
		name: 'payment-category',
		path: '/payment-category',
		element: <PaymentCategory />,
		groupRole: 'MASTER_PAYMENT_CATEGORY',
		roleTask: [
			'VIEW_MASTER_PAYMENT_CATEGORY',
			'ADD_MASTER_PAYMENT_CATEGORY',
			'EDIT_MASTER_PAYMENT_CATEGORY',
			'DELETE_MASTER_PAYMENT_CATEGORY',
		],
	},
	{
		name: 'update-payment-category',
		path: '/update-payment-category/:Id',
		element: <PaymentCategoryUpdate />,
		groupRole: 'MASTER_PAYMENT_CATEGORY',
		roleTask: ['EDIT_MASTER_PAYMENT_CATEGORY'],
	},
	{
		name: 'add-payment-category',
		path: '/add-payment-category',
		element: <PaymentCategoryAdd />,
		groupRole: 'MASTER_PAYMENT_CATEGORY',
		roleTask: ['ADD_MASTER_PAYMENT_CATEGORY'],
	},
	{
		name: 'acquiring-payment',
		path: '/acquiring-payment',
		element: <AcquiringPayment />,
		groupRole: 'MASTER_AQUIRING',
		roleTask: [
			'VIEW_MASTER_AQUIRING',
			'ADD_MASTER_AQUIRING',
			'EDIT_MASTER_AQUIRING',
			'DELETE_MASTER_AQUIRING',
		],
	},
	{
		name: 'update-acquiring-payment',
		path: '/update-acquiring-payment/:Id',
		element: <AcquiringPaymentUpdate />,
		groupRole: 'MASTER_AQUIRING',
		roleTask: ['EDIT_MASTER_AQUIRING'],
	},
	{
		name: 'add-acquiring-payment',
		path: '/add-acquiring-payment',
		element: <AcquiringPaymentAdd />,
		groupRole: 'MASTER_AQUIRING',
		roleTask: ['ADD_MASTER_AQUIRING'],
	},
	{
		name: 'issuing-payment',
		path: '/issuing-payment',
		element: <IssuingPayment />,
		groupRole: 'MASTER_ISSUING',
		roleTask: [
			'VIEW_MASTER_ISSUING',
			'ADD_MASTER_ISSUING',
			'EDIT_MASTER_ISSUING',
			'DELETE_MASTER_ISSUING',
		],
	},
	{
		name: 'update-issuing-payment',
		path: '/update-issuing-payment/:Id',
		element: <IssuingPaymentUpdate />,
		groupRole: 'MASTER_ISSUING',
		roleTask: ['EDIT_MASTER_ISSUING'],
	},
	{
		name: 'add-issuing-payment',
		path: '/add-issuing-payment',
		element: <IssuingPaymentAdd />,
		groupRole: 'MASTER_ISSUING',
		roleTask: ['ADD_MASTER_ISSUING'],
	},
	{
		name: 'card-type',
		path: '/card-type',
		element: <CardType />,
		groupRole: 'CARD_TYPE',
		roleTask: [
			'VIEW_CARD_TYPE',
			'ADD_CARD_TYPE',
			'EDIT_CARD_TYPE',
			'DELETE_CARD_TYPE',
		],
	},
	{
		name: 'update-card-type',
		path: '/update-card-type/:Id',
		element: <CardTypeUpdate />,
		groupRole: 'CARD_TYPE',
		roleTask: ['EDIT_CARD_TYPE'],
	},
	{
		name: 'add-card-type',
		path: '/add-card-type',
		element: <CardTypeAdd />,
		groupRole: 'CARD_TYPE',
		roleTask: ['ADD_CARD_TYPE'],
	},
	{
		name: 'issuing-bin-payment',
		path: '/issuing-bin-payment',
		element: <IssuingBINPayment />,
		groupRole: 'MASTER_BIN_ISSUING',
		roleTask: [
			'VIEW_MASTER_BIN_ISSUING',
			'ADD_MASTER_BIN_ISSUING',
			'EDIT_MASTER_BIN_ISSUING',
			'DELETE_MASTER_BIN_ISSUING',
		],
	},
	{
		name: 'update-issuing-bin-payment',
		path: '/update-issuing-bin-payment/:Id',
		element: <IssuingBINPaymentUpdate />,
		groupRole: 'MASTER_BIN_ISSUING',
		roleTask: ['EDIT_MASTER_BIN_ISSUING'],
	},
	{
		name: 'add-issuing-bin-payment',
		path: '/add-issuing-bin-payment',
		element: <IssuingBINPaymentAdd />,
		groupRole: 'MASTER_BIN_ISSUING',
		roleTask: ['ADD_MASTER_BIN_ISSUING'],
	},
	{
		name: 'payment-data',
		path: '/payment-data',
		element: <PaymentData />,
		groupRole: 'MASTER_PAYMENT_DATA',
		roleTask: [
			'VIEW_MASTER_PAYMENT_DATA',
			'ADD_MASTER_PAYMENT_DATA',
			'EDIT_MASTER_PAYMENT_DATA',
			'DELETE_MASTER_PAYMENT_DATA',
		],
	},
	{
		name: 'update-payment-data',
		path: '/update-payment-data/:Id',
		element: <PaymentDataUpdate />,
		groupRole: 'MASTER_PAYMENT_DATA',
		roleTask: ['EDIT_MASTER_PAYMENT_DATA'],
	},
	{
		name: 'add-payment-data',
		path: '/add-payment-data',
		element: <PaymentDataAdd />,
		groupRole: 'MASTER_PAYMENT_DATA',
		roleTask: ['ADD_MASTER_PAYMENT_DATA'],
	},
	{
		name: 'service-log',
		path: '/service-log',
		element: <ServiceLog />,
		groupRole: 'SYSTEM_SERVICE_LOG',
		roleTask: ['VIEW_SYSTEM_SERVICE_LOG'],
	},
	{
		name: 'status-data',
		path: '/status-data',
		element: <StatusData />,
		groupRole: 'SYSTEM_STATUS_DATA',
		roleTask: [
			'VIEW_SYSTEM_STATUS_DATA',
			'ADD_SYSTEM_STATUS_DATA',
			'EDIT_SYSTEM_STATUS_DATA',
			'DELETE_SYSTEM_STATUS_DATA',
		],
	},
	{
		name: 'update-status-data/:Id',
		path: '/update-status-data/:Id',
		element: <StatusDataUpdate />,
		groupRole: 'SYSTEM_STATUS_DATA',
		roleTask: ['EDIT_SYSTEM_STATUS_DATA'],
	},
	{
		name: 'add-status-data',
		path: '/add-status-data',
		element: <StatusDataAdd />,
		groupRole: 'SYSTEM_STATUS_DATA',
		roleTask: ['ADD_SYSTEM_STATUS_DATA'],
	},
	{
		name: 'settlement-maluku',
		path: '/settlement-maluku',
		element: <SettlementMaluku />,
		groupRole: 'SETTLEMENT_DATA',
		roleTask: ['SETTLEMENT_MALUKU_VIEW'],
	},
	{
		name: 'role-task',
		path: '/role-task',
		element: <RoleTask />,
		groupRole: 'MASTER_ROLE_TASK',
		roleTask: [
			'VIEW_MASTER_ROLE_TASK',
			'ADD_MASTER_ROLE_TASK',
			'EDIT_MASTER_ROLE_TASK',
			'DELETE_MASTER_ROLE_TASK',
		],
	},
	{
		name: 'update-role-task',
		path: '/update-role-task/:Id',
		element: <RoleTasktaUpdate />,
		groupRole: 'MASTER_ROLE_TASK',
		roleTask: ['EDIT_MASTER_ROLE_TASK'],
	},
	{
		name: 'add-role-task',
		path: '/add-role-task',
		element: <RoleTaskAdd />,
		groupRole: 'MASTER_ROLE_TASK',
		roleTask: ['ADD_MASTER_ROLE_TASK'],
	},
	{
		name: 'role',
		path: '/role',
		element: <Role />,
		groupRole: 'CONFIG_ROLE',
		roleTask: [
			'VIEW_CONFIG_ROLE',
			'ADD_CONFIG_ROLE',
			'EDIT_CONFIG_ROLE',
			'DELETE_CONFIG_ROLE',
		],
	},
	{
		name: 'insert-role',
		path: '/insert-role',
		element: <RoleAdd />,
		groupRole: 'MASTER_ROLE_TASK',
		roleTask: ['ADD_CONFIG_ROLE'],
	},
	{
		name: 'update-role/:Id',
		path: '/update-role/:Id',
		element: <RoleUpdate />,
		groupRole: 'MASTER_ROLE_TASK',
		roleTask: ['EDIT_CONFIG_ROLE'],
	},
	{
		name: 'gateway-transaction',
		path: '/gateway-transaction',
		element: <GatewayTransaction />,
		groupRole: 'SYSTEM_GATEWAY_TRANSACTION',
		roleTask: [
			'SYSTEM_GATEWAY_TRANSACTION_VIEW',
			'SYSTEM_GATEWAY_TRANSACTION_ADD',
			'SYSTEM_GATEWAY_TRANSACTION_EDIT',
			'SYSTEM_GATEWAY_TRANSACTION_DELETE',
		],
	},
	{
		name: 'insert-gateway-transaction',
		path: '/insert-gateway-transaction',
		element: <GatewayTransactionAdd />,
		groupRole: 'SYSTEM_GATEWAY_TRANSACTION',
		roleTask: ['SYSTEM_GATEWAY_TRANSACTION_ADD'],
	},
	{
		name: 'update-gateway-transaction/:Id',
		path: '/update-gateway-transaction/:Id',
		element: <GatewayTransactionUpdate />,
		groupRole: 'SYSTEM_GATEWAY_TRANSACTION',
		roleTask: ['SYSTEM_GATEWAY_TRANSACTION_EDIT'],
	},
	{
		name: 'gateway-debit-merchant',
		path: '/gateway-debit-merchant',
		element: <GatewayMerchant />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT',
		roleTask: [
			'VIEW_GATEWAY_DEBIT_MERCHANT',
			'ADD_GATEWAY_DEBIT_MERCHANT',
			'EDIT_GATEWAY_DEBIT_MERCHANT',
			'DELETE_GATEWAY_DEBIT_MERCHANT',
		],
	},
	{
		name: 'update-gateway-debit-merchant/:Id',
		path: '/update-gateway-debit-merchant/:Id',
		element: <GatewayMerchantUpdate />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT',
		roleTask: ['EDIT_GATEWAY_DEBIT_MERCHANT'],
	},
	{
		name: 'add-gateway-debit-merchant',
		path: '/add-gateway-debit-merchant',
		element: <GatewayMerchantAdd />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT',
		roleTask: ['ADD_GATEWAY_DEBIT_MERCHANT'],
	},
	{
		name: 'gateway-debit-merchant-acquiring/:Id',
		path: '/gateway-debit-merchant-acquiring/:Id',
		element: <GatewayMerchantAcquiring />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT_ACQUIRING',
		roleTask: [
			'VIEW_GATEWAY_DEBIT_MERCHANT_ACQUIRING',
			'ADD_GATEWAY_DEBIT_MERCHANT_ACQUIRING',
			'EDIT_GATEWAY_DEBIT_MERCHANT_ACQUIRING',
			'DELETE_GATEWAY_DEBIT_MERCHANT_ACQUIRING',
		],
	},
	{
		name: 'update-gateway-debit-merchant-acquiring/:Id',
		path: '/update-gateway-debit-merchant-acquiring/:Id',
		element: <GatewayMerchantAcquiringUpdate />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT_ACQUIRING',
		roleTask: ['EDIT_GATEWAY_DEBIT_MERCHANT_ACQUIRING'],
	},
	{
		name: 'add-gateway-debit-merchant-acquiring/:Id',
		path: '/add-gateway-debit-merchant-acquiring/:Id',
		element: <GatewayMerchantAcquiringAdd />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT_ACQUIRING',
		roleTask: ['ADD_GATEWAY_DEBIT_MERCHANT_ACQUIRING'],
	},
	{
		name: 'gateway-debit-merchant-acquiring-device/:Id',
		path: '/gateway-debit-merchant-acquiring-device/:Id',
		element: <GatewayMerchantAcquiringDevice />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
		roleTask: [
			'VIEW_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
			'ADD_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
			'EDIT_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
			'DELETE_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
		],
	},
	{
		name: 'update-gateway-debit-merchant-acquiring-device/:Id',
		path: '/update-gateway-debit-merchant-acquiring-device/:Id',
		element: <GatewayMerchantAcquiringDeviceUpdate />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
		roleTask: ['EDIT_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE'],
	},
	{
		name: 'add-gateway-debit-merchant-acquiring-device/:Id',
		path: '/add-gateway-debit-merchant-acquiring-device/:Id',
		element: <GatewayMerchantAcquiringDeviceAdd />,
		groupRole: 'GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
		roleTask: ['ADD_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE'],
	},
	{
		name: 'gateway-debit-segmentasi',
		path: '/gateway-debit-segmentasi',
		element: <GatewaySegmentasi />,
		groupRole: 'GATEWAY_DEBIT_SEGMENTASI',
		roleTask: [
			'VIEW_GATEWAY_DEBIT_SEGMENTASI',
			'ADD_GATEWAY_DEBIT_SEGMENTASI',
			'EDIT_GATEWAY_DEBIT_SEGMENTASI',
			'DELETE_GATEWAY_DEBIT_SEGMENTASI',
		],
	},
	{
		name: 'update-gateway-debit-segmentasi/:Id',
		path: '/update-gateway-debit-segmentasi/:Id',
		element: <GatewaySegmentasiUpdate />,
		groupRole: 'GATEWAY_DEBIT_SEGMENTASI',
		roleTask: ['EDIT_GATEWAY_DEBIT_SEGMENTASI'],
	},
	{
		name: 'add-gateway-debit-segmentasi',
		path: '/add-gateway-debit-segmentasi',
		element: <GatewaySegmentasiAdd />,
		groupRole: 'GATEWAY_DEBIT_SEGMENTASI',
		roleTask: ['ADD_GATEWAY_DEBIT_SEGMENTASI'],
	},
	{
		name: 'gateway-debit-segmentasi-detail/:Id',
		path: '/gateway-debit-segmentasi-detail/:Id',
		element: <GatewaySegmentasiDetail />,
		groupRole: 'GATEWAY_DEBIT_SEGMENTASI_DETAIL',
		roleTask: [
			'VIEW_GATEWAY_DEBIT_SEGMENTASI_DETAIL',
			'ADD_GATEWAY_DEBIT_SEGMENTASI_DETAIL',
			'EDIT_GATEWAY_DEBIT_SEGMENTASI_DETAIL',
			'DELETE_GATEWAY_DEBIT_SEGMENTASI_DETAIL',
		],
	},
	{
		name: 'update-gateway-debit-segmentasi-detail/:Id',
		path: '/update-gateway-debit-segmentasi-detail/:Id',
		element: <GatewaySegmentasiDetailUpdate />,
		groupRole: 'GATEWAY_DEBIT_SEGMENTASI_DETAIL',
		roleTask: ['EDIT_GATEWAY_DEBIT_SEGMENTASI_DETAIL'],
	},
	{
		name: 'add-gateway-debit-segmentasi-detail/:Id',
		path: '/add-gateway-debit-segmentasi-detail/:Id',
		element: <GatewaySegmentasiDetailAdd />,
		groupRole: 'GATEWAY_DEBIT_SEGMENTASI_DETAIL',
		roleTask: ['ADD_GATEWAY_DEBIT_SEGMENTASI_DETAIL'],
	},
	// {
	// 	name: 'detail-gateway-debit-segmentasi/:Id',
	// 	path: '/detail-gateway-debit-segmentasi/:Id',
	// 	element: <GatewaySegmentasiDetail />,
	// 	groupRole: 'GATEWAY_DEBIT_SEGMENTASI',
	// 	roleTask: ['DETAIL_GATEWAY_DEBIT_SEGMENTASI'],
	// },
	{
		name: 'all',
		path: '*',
		element: <Navigate to="/404" />,
		groupRole: 'ALL',
		roleTask: [],
	},
	{
		name: '404',
		path: '/404',
		element: <PageNotFound />,
		groupRole: 'ALL',
		roleTask: [],
	},
];

export const getRouteAvailable = (route, previlage) => {
	let mPrev = _.cloneDeep(previlage);
	let mRoute = _.cloneDeep(route);

	let mFilter = mRoute.map((o) => {
		let prevFilter = mPrev.filter(
			(m) => o.roleTask.includes(m.roleTask) && m.status
		);

		if (prevFilter.length > 0) {
			o.status = true;
		} else {
			o.status = false;
		}

		return o;
	});

	return mFilter.length > 0
		? mFilter.filter((o) => o.status)
		: mFilter;
};

export const checkIsAvailableModule = (moduleTask, previlageData) => {
	let filter = previlageData
		? previlageData.filter(
				(o) => o.roleTask == moduleTask && o.status
		  )
		: [];
	return filter ? filter.length > 0 : false;
};
