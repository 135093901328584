import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Modal,
	TextField,
} from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { Button } from '../../components';
import { ToastContainer, toast } from 'react-toastify';
import { useStateContext } from '../../contexts/ContextProvider';

export const ModalCreateConfirm = (props) => {
	const { redColor, modalOpenClose, currentAuth } = useStateContext();

	const [modal, setModal] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => props.onSuccess(),
		});
	};

	const notifyErr = (message) => {
		toast.error(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const callVoidData = () => {
		props.onSubmit();
	};

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={props.isModal}
				onClose={() => {
					setData(null);
					props.onCancel();
				}}
			>
				<DialogTitle>Data Confirmation</DialogTitle>
				<DialogContent>
					<DialogContentText
						style={{ borderBottomWidth: 1, paddingBottom: 10 }}
					>
						Please check the data below before sending
					</DialogContentText>
					<Box
						noValidate
						component="form"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							m: 'auto',
							width: '100%',
						}}
					>
						<div className="w-1/2 d-flex self-end flex-row justify-end align-items-center">
							<p className="font-weight-bold text-xl mt-2 mb-2">
								Bill To
							</p>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">Corporate Name :</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.customerData?.customerName ?? ''}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">Corporate Email :</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.customerData?.customerInitialEmail ??
										''}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Corporate Phone Number :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.customerData?.customerPhoneNumber ??
										''}
								</p>
							</div>
						</div>

						<div className="w-full d-flex flex-row justify-content-end align-items-center">
							<p className="font-weight-bold text-xl mt-2 mb-2">
								Invoice Data
							</p>

							<div
								style={{ display: 'flex' }}
								className="mt-2 mb-2 flex-row w-full flex-1 items-center"
							>
								<p className="text-sm">Invoice Number :</p>
								<p className="text-lg ml-2">
									{props.data?.merchantNoRef ?? ''}
								</p>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								background: '#F0F6F8',
								borderRadius: 10,
								padding: 10,
							}}
							className="flex-row w-full justify-content-center align-items-center"
						>
							<div className="d-flex flex-1 flex-col">
								<p className="mt-1 mb-1 text-sm">Remarks :</p>
								<p className="mt-1 mb-1 text-lg">
									{props.data?.remarks ?? ''}
								</p>
							</div>
							<div className="d-flex flex-1 flex-col">
								<p className="mt-1 mb-1 text-sm text-right">
									Amount :
								</p>
								<p className="mt-1 mb-1 text-xl text-right">
									{props.data?.amount
										? 'Rp' + props.data?.amount?.format()
										: '-'}
								</p>
								<p
									style={{ fontStyle: 'italic' }}
									className="mt-1 mb-1 text-xs font-weight-bold text-right"
								>
									{props.data?.isExclude
										? 'Note: Amount does not include MDR'
										: 'Note: Amount includes MDR'}
								</p>
							</div>
						</div>

						<div className="w-1/2 d-flex flex-row justify-end align-items-center mt-3">
							<p className="font-weight-bold text-xl mt-2 mb-2">
								Invoice Setup
							</p>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Link Maximum Attemp :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.maxAttempt &&
									props.data?.maxAttempt != 0
										? props.data?.maxAttempt
										: '-'}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Link Expired Date Time :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.expiredDatetime
										? props.data?.expiredDatetime
										: '-'}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Partner Callback URL :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.callbackUrl
										? props.data?.callbackUrl
										: '-'}
								</p>
							</div>
						</div>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						customFunc={() => {
							setModal(false);
							setData(null);
							props.onCancel();
						}}
						text={'Cancel'}
					/>
					<Button
						bgColor={'red'}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Submit"
						type="button"
						customFunc={() => callVoidData()}
						color={'white'}
					/>
				</DialogActions>
			</Dialog>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</>
	);
};
