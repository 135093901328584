import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {
	Alert,
	Autocomplete,
	Drawer,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
} from '@mui/material';

import { Button } from '../../components';
import { paymentLinkListColumn } from './columnData';
import DataTable from 'react-data-table-component';
import {
	BASE_URL,
	BASE_URL_ONLINE,
	URL_ERROR_LIST,
	URL_PAYMENT_LINK_LIST,
	URL_REPORTING_LIST,
	URL_REPORTING_LIST_V2,
} from '../../config';
import { FiRefreshCw } from 'react-icons/fi';

import { BiSpreadsheet } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import { MdSearch } from 'react-icons/md';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';

import notfound from '../../data/notfound.png';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import { RiLoader4Line } from 'react-icons/ri';
import { checkIsAvailableModule } from '../../Routes';
import { MatrixUtils } from '../../utils/MatrixUtils';

import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';
import { ModalDetailLink } from './ModalDetailLink';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px',
			paddingRight: '8px',
		},
	},
};

const drawerWidth = 500;

const PaymentLinkList = () => {
	const navigate = useNavigate();

	const {
		activeMenu,
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		hirarkiUser,
		screenWidth,
		privilege,
		dataLogin,
	} = useStateContext();

	const dateTo = moment().format('YYYY-MM-DD');
	const dateFrom = moment().subtract(6, 'd').format('YYYY-MM-DD');
	const maxDate = moment().subtract(1, 'months').format('YYYY-MM-01');

	// data for filter
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);
	const [statusTrx, setStatusTrx] = useState(0);
	const [pembayaran, setPembayaran] = useState('');
	//   const [corporate, setCorporate] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [pending, setPending] = useState(false);
	//   const [dataCorporate, setDataCorporate] = useState([]);
	const [kategoriPembayaran, setKategoriPembayaran] = useState([]);
	const [dataCoreSettle, setDataCoreSettle] = useState([]);
	const [order, setOrder] = useState('desc');
	const [header, setHeader] = useState('');
	const [listTrx, setListTrx] = useState([]);
	const [count, setCount] = useState(100);
	const [dataExcel, setDataExcel] = useState([]);
	const [selected, setSelected] = useState([]);
	const [dataStatusCode, setDataStatusCode] = useState([]);

	const dateStarted = moment(startDate).format('YYYY-MM-DD');
	const dateEnded = moment(endDate).format('YYYY-MM-DD');

	const [selectedDetail, setSelectedDetail] = useState(null);
	const [selectedVoid, setSelectedVoid] = useState(null);

	// fixed corporate filter bug
	const [value, setValue] = useState(null);
	const [dropdownSearch, setDropdownSearch] = useState('');
	const [corporate, setCorporate] = useState('');
	const [dataCorporate, setDataCorporate] = useState([]);
	const [corpData, setCorpData] = useState([]);
	const [searchTimeoutId, setSearchTimeoutId] = useState(null);
	const [corporateLoading, setCorporateLoading] = useState(false);
	const [modalDetailLink, setModalDetailLink] = useState(null);

	const [idCorpAccount, setIdCorpAccount] = useState('');
	const [corpAccount, setCorpAccount] = useState([]);
	const [corpAccountData, setCorpAccountData] = useState([]);
	const [searchCorporateAccount, setSearchCorporateAccount] =
		useState('');
	const [
		searchCorporateAccountLoading,
		setSearchCorporateAccountLoading,
	] = useState(false);
	const [
		searchCorporateAccountTimeout,
		setSearchCorporateAccountTimeout,
	] = useState(null);
	const [
		searchCorporateAccountValue,
		setSearchCorporateAccountValue,
	] = useState(null);

	const [dataFilter, setDataFilter] = useState([
		{ value: 'merchantNoRef', label: 'Invoice Number' },
		{ value: 'linkRequestNo', label: 'Link Ref' },
		{ value: 'transactionNumber', label: 'Transaction Ref' },
		{ value: 'apps2PayNoRef', label: 'Payment Ref' },
		{
			value: 'requestCustomerInitialEmail',
			label: 'Corporate Email',
		},
		{
			value: 'requestCustomerPhoneNumber',
			label: 'Corporate Phone Number',
		},
		{ value: 'requestCustomerName', label: 'Corporate Name' },
	]);

	const [selectedFilter, setSelectedFilter] = useState(dataFilter[0]);

	const dateToMax = moment(startDate).add(1, 'months').isAfter(dateTo)
		? dateTo
		: moment(startDate).add(1, 'months').format('YYYY-MM-DD');

	const getCorpAccount = () => {
		const dataBody = {
			id: 0,
			corporateCID: value ? value.value : '',
			corporateName: '',
			merchantKey: '',
			filter: {
				order: 'ASC',
				limit: 100,
				page: 1,
				orderBy: 'id',
			},
		};

		fetch(BASE_URL + '/payment-link/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setCorpAccount(data.result);
					} else {
						resetAccountData();
					}
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setSearchCorporateAccountLoading(false);
			});
	};

	useEffect(() => {
		if (corpAccount.length > 0) {
			corpAccountDropdown();
		}
	}, [corpAccount]);

	useEffect(() => {
		if (value) {
			getCorpAccount();
		} else {
			resetAccountData();
		}
	}, [value]);

	const onCorpAccountChange = (event, values) => {
		console.log('onCorpAccountChange', values);
		setSearchCorporateAccountValue(values);
		setIdCorpAccount(values.value);
	};

	const resetAccountData = () => {
		setIdCorpAccount('');
		setCorpAccount([]);
		setCorpAccountData([]);
		setSearchCorporateAccount('');
		setSearchCorporateAccountLoading(false);
		setSearchCorporateAccountTimeout(null);
		setSearchCorporateAccountValue(null);
	};

	useEffect(() => {
		return () => {
			if (searchCorporateAccountTimeout) {
				clearTimeout(searchCorporateAccountTimeout);
			}
		};
	}, [searchCorporateAccountTimeout]);

	const corpAccountDropdown = () => {
		const data = corpAccount.map((item) => ({
			value: item.merchantKey,
			label: item.userName,
			userEmail: item.userEmail,
			phoneNumber: item.phoneNumber,
			merchantKey: item.merchantKey,
			secretKey: item.secretKey,
		}));

		setCorpAccountData(data);
	};

	const getPaymentLinkList = (
		page = pagination,
		totalData = limit
	) => {
		modalOpenClose(true);

		const bodyData = {
			startRequestDate: dateStarted,
			endRequestDate: dateEnded,
			trxCreatedStartDate: '',
			trxCreatedEndDate: '',
			startPaymentDate: '',
			endPaymentDate: '',
			limit: totalData,
			page: parseInt(page),
			order: 'DESC',
			orderBy: 'id',
			data: {
				merchantKey: searchCorporateAccountValue
					? searchCorporateAccountValue.merchantKey
					: '',
				merchantNoRef:
					search && selectedFilter.value == 'merchantNoRef'
						? search
						: '',
				linkRequestNo:
					search && selectedFilter.value == 'linkRequestNo'
						? search
						: '',
				transactionNumber:
					search && selectedFilter.value == 'transactionNumber'
						? search
						: '',
				trxMerchantNoRef: '',
				apps2PayNoRef:
					search && selectedFilter.value == 'apps2PayNoRef'
						? search
						: '',
				paymentCategory: '',
				paymentMethodID: 0,
				corporateCID: corporate ? corporate : '',
				paymentStatusCode: '',
				requestCustomerName:
					search && selectedFilter.value == 'requestCustomerName'
						? search
						: '',
				requestCustomerInitialEmail:
					search &&
					selectedFilter.value == 'requestCustomerInitialEmail'
						? search
						: '',
				requestCustomerPhoneNumber:
					search &&
					selectedFilter.value == 'requestCustomerPhoneNumber'
						? search
						: '',
				paymentCustomerName: '',
				paymentCustomerInitialEmail: '',
				paymentCustomerPhoneNumber: '',
				customerPaymentEmail: '',
			},
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_PAYMENT_LINK_LIST +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + URL_PAYMENT_LINK_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				modalOpenClose(false);

				if (data.success) {
					if (data.result != null) {
						setCount(data.countData);
						setListTrx(data.result);
					} else {
						setCount(0);
						setListTrx([]);
					}
				} else {
					setListTrx([]);
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				setListTrx([]);
				console.log('err =>', err);
			});
	};

	const getDataExcel = (page = pagination, totalData = limit) => {
		modalOpenClose(true);

		const bodyData = {
			startDate: dateStarted,
			endDate: dateEnded,
			kategoriPembayaran: pembayaran,
			paymentStatus: parseInt(statusTrx),
			corporate: corporate,
			sattlementDestination: selected.map((o) => {
				return { sattlementDestination: o.label };
			}),
			parameter: search ? selectedFilter.value : '',
			cid: '',
			pagination: {
				order: 'ASC',
				limit: parseInt(count),
				page: parseInt(page),
				keyword: search,
			},
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_REPORTING_LIST_V2 +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + URL_REPORTING_LIST_V2, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.success) {
					if (data.result != null) {
						setDataExcel(data.result);
						exportToExcel(data.result);
						modalOpenClose(false);
					} else {
						setDataExcel([]);
						modalOpenClose(false);
					}
				} else {
					setDataExcel([]);
					modalOpenClose(false);
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	const handleChange = (event, values) => {
		setSelected(values);
	};

	const onChangeSelectedFilter = (event, values) => {
		setKeyword('');
		setSelectedFilter(values);
	};

	const handlePageChange = (page) => {
		getPaymentLinkList(page);
		setCurrentPage(page);
	};

	const handlePerRowsChange = (totalData, page) => {
		getPaymentLinkList(page, totalData);
		setLimit(totalData);
	};

	const handleClick = () => {
		getPaymentLinkList();
	};

	const searchData = () => {
		setSearch(keyword);
	};

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const exportToExcel = async (dataForExcel) => {
		const dataExcel = dataForExcel.map((o) => {
			let mFilter = dataStatusCode.filter(
				(m) => m.errorsCode == o.statusCode
			);
			let mData = {};
			mData.Status = o.paymentStatusDesc;
			mData.StatusDescription =
				mFilter.length > 0 ? mFilter[0].errorsReadable : 'Unknown';
			mData.InvoiceNo = o.noHeader;
			mData.MerchantRef = o.merchantNoRef;
			mData.CreatedDate = o.createdAt
				? o.createdAt.split(' ')[0]
				: '';
			mData.CreatedTime = o.createdAt
				? o.createdAt.split(' ')[1]
				: '';
			mData.Amount = o.paymentAmount;
			mData.PaymentCategory = o.paymentCategoryName;
			mData.PaymentName = o.paymentMethodName;
			mData.CoporateID = o.corporateCID;
			mData.CorporateName = o.corporateName;
			mData.SettleDest = o.settlementDestination;
			mData.SettleStatus = o.statusSettlement ? 'YES' : 'NO';
			mData.PrincipleRef = o.responseNoRef;
			mData.DeviceID = o.deviceId;
			mData.MID = o.bankMID;
			mData.TID = o.bankTID;
			mData.Cardpan = o.cardPan;
			mData.cardType = o.cardType;
			mData.STAN = o.stan ?? '';
			mData.RRN = o.rrn ?? '';
			mData.ApprovalCode = o.bankApprovalCode ?? '';
			mData.OnusOffus = o.cardTypeTrx ?? '';
			mData.MDR = o.paymentMDR;
			mData.ServiceFee = o.serviceFee;
			mData.PaymentFee = o.paymentFee;
			mData.VendorFee = o.vendorFee;

			return mData;
		});

		const ws = XLSX.utils.json_to_sheet(dataExcel);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, {
			bookType: 'xlsx',
			type: 'array',
		});
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(
			data,
			`Data Transaksi (${dateStarted} - ${dateEnded})` + fileExtension
		);
	};

	const customSort = (rows, selector, direction) => {
		setHeader(rows.selector);
		setOrder(selector);
	};

	useEffect(() => {
		getPaymentLinkList();
	}, [search, order, header]);

	useEffect(() => {
		if (selectedDetail) {
		}
	}, [selectedDetail]);

	const corpDropdown = () => {
		const data = dataCorporate.map((item) => ({
			value: item.cid,
			label: item.uraian,
		}));

		setCorpData(data);
	};

	const getCorporate = () => {
		const bodyData = {
			order: 'ASC',
			limit: 25,
			page: 1,
			keyword: dropdownSearch,
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setDataCorporate(data.result);
					} else {
						setDataCorporate([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			})
			.finally(() => {
				setCorporateLoading(false);
			});
	};

	const onChangeCorp = (event, values) => {
		setValue(values);
		setCorporate(values.value);
	};

	useEffect(() => {
		getCorporate();
	}, []);

	useEffect(() => {
		if (dataCorporate.length > 0) {
			corpDropdown();
		}
	}, [dataCorporate]);

	// Cleanup function to clear timeout on unmount
	useEffect(() => {
		return () => {
			if (searchTimeoutId) {
				clearTimeout(searchTimeoutId);
			}
		};
	}, [searchTimeoutId]); // Dependency on searchTimeoutId

	return (
		<div className={` ${activeMenu ? '-' : ''}`}>
			<div
				className={` h-fit m-2 mt-16 md:m-10 p-6 md:p-10  rounded-3xl bg-white max-w-screen md:max-w-full`}
			>
				{/* <Header category={"Filter Data"} /> */}
				<p className=" text-base font-semibold mb-7 ">Filter Data</p>

				<div className=" md:flex gap-5 mb-5">
					{/* start date input */}
					<div className=" w-full mb-5 md:mb-0">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								className=" w-full"
								label="Start Date"
								value={startDate}
								onChange={(newValue) => {
									setStartDate(newValue);
									setEndDate(newValue);
								}}
								inputFormat="DD-MM-YYYY"
								maxDate={dateTo}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
					{/* end date input */}
					<div className=" w-full">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								className=" w-full"
								label="Until Date"
								value={endDate}
								onChange={(newValue) => {
									setEndDate(newValue);
								}}
								inputFormat="DD-MM-YYYY"
								minDate={startDate}
								maxDate={dateToMax}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
				</div>

				{/* select Corporate n settlement destination */}
				<div className=" md:flex gap-5 mt-7 mb-7">
					{/* corporate insert */}
					<div className="w-full -mr-0 md:-mr-0 mb-5 md:mb-0 ">
						<Autocomplete
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="combo-box-demo"
							options={corpData}
							value={value}
							onChange={onChangeCorp}
							inputValue={dropdownSearch}
							onInputChange={(event, newInputValue) => {
								setCorporateLoading(true);
								setDropdownSearch(newInputValue);
								// Clear any existing timeout before starting a new one
								if (searchTimeoutId) {
									clearTimeout(searchTimeoutId);
								}

								setSearchTimeoutId(
									setTimeout(() => {
										getCorporate();
									}, 2000)
								);
							}}
							disableClearable
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Corporate"
									placeholder=""
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{corporateLoading ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : (
													value !== null &&
													dropdownSearch !== '' && (
														<InputAdornment position="end">
															<IconButton
																aria-label="clear"
																onClick={() => {
																	setDropdownSearch('');
																	setValue(null);
																	setCorporate('');
																	// setDataCorporate([]);
																}}
																edge="end"
															>
																<ClearIcon />
															</IconButton>
														</InputAdornment>
													)
												)}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</div>

					<div className="w-full -mr-0 md:-mr-0 mb-5 md:mb-0 ">
						<Autocomplete
							required
							style={{
								width: 'auto',
							}}
							disablePortal
							disableClearable
							id="combo-box-demo"
							options={corpAccountData}
							onChange={onCorpAccountChange}
							value={searchCorporateAccountValue}
							inputValue={searchCorporateAccount}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									placeholder="Corporate"
									label="Corporate Account"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{searchCorporateAccountLoading ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : (
													searchCorporateAccountValue !== null &&
													searchCorporateAccount !== '' && (
														<InputAdornment position="end">
															<IconButton
																aria-label="clear"
																onClick={() => {
																	setSearchCorporateAccount('');
																	setSearchCorporateAccountValue(
																		null
																	);
																	setIdCorpAccount('');
																	// setDataCorporate([]);
																}}
																edge="end"
															>
																<ClearIcon />
															</IconButton>
														</InputAdornment>
													)
												)}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
							onInputChange={(event, newInputValue) => {
								setSearchCorporateAccountLoading(true);
								setSearchCorporateAccount(newInputValue);
								// Clear any existing timeout before starting a new one
								if (searchCorporateAccountTimeout) {
									clearTimeout(searchCorporateAccountTimeout);
								}

								setSearchCorporateAccountTimeout(
									setTimeout(() => {
										getCorpAccount();
									}, 2000)
								);
							}}
						/>
					</div>
				</div>
				<Button
					customFunc={() => handleClick()}
					bgColor={currentColor}
					color="white"
					text="Filter"
					borderRadius={'10px'}
					icon={<FiRefreshCw />}
				/>
			</div>

			{/* bottom container */}
			<div
				className={` h-fit px-5  md:m-10 p-4 md:p-10  rounded-3xl bg-white 
      ${activeMenu === true ? 'max-w-full' : ' max-w-full'}`}
			>
				<div className=" flex justify-between mb-5">
					<div className=" flex items-center gap-4 mt-5 mb-4">
						<p className=" text-2xl">
							<BiSpreadsheet />
						</p>
						<p className=" font-semibold">Invoice Data</p>
					</div>
					{checkIsAvailableModule(
						'REPORT_LIST_EXCEL_DATA',
						privilege
					) && (
						<Button
							customFunc={() => getDataExcel()}
							text="Excel Export"
							bgColor={currentColor}
							color="white"
							height="fit"
							borderRadius="10px"
							icon={<BsFileEarmarkExcelFill />}
						/>
					)}
				</div>
				<div className=" mt-5 flex justify-end">
					<div className=" w-1/2 mb-2 flex end">
						<div className=" w-full mr-2">
							<Autocomplete
								required
								size="small"
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								disableClearable
								id="status-trx"
								options={dataFilter}
								defaultValue={dataFilter[0]}
								onChange={onChangeSelectedFilter}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Filter Field"
									/>
								)}
							/>
						</div>
						<FormControl className=" w-full" variant="outlined">
							<OutlinedInput
								size="small"
								notched
								id="outlined-adornment-password"
								type={'text'}
								placeholder="Search"
								onChange={(text) => setKeyword(text.target.value)}
								value={keyword}
								defaultValue={keyword}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										searchData();
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={() => searchData()}
											edge="end"
										>
											<MdSearch />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
				</div>
				<DataTable
					className=" "
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					columns={paymentLinkListColumn(
						dataStatusCode,
						(id, isVoid = false) => {
							if (!isVoid) setModalDetailLink(id);
							else setSelectedVoid(id);
						},
						privilege,
						true
					)}
					data={listTrx}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					pagination
					paginationServer
					paginationTotalRows={count}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					onSort={customSort}
					sortServer
					theme="solarized"
					customStyles={customStyles}
				/>

				<ModalDetailLink
					onCancel={() => {
						setModalDetailLink(null);
					}}
					isModal={modalDetailLink != null}
					data={modalDetailLink}
				/>
			</div>
		</div>
	);
};

export default PaymentLinkList;
