import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL, URL_ROLE_LIST } from '../../../config';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
	MdArrowBack,
	MdSave,
	MdVisibility,
	MdVisibilityOff,
} from 'react-icons/md';

import {
	Autocomplete,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
} from '@mui/material';

import _ from 'lodash';

const UserAdd = () => {
	const navigate = useNavigate();

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		activeMenu,
	} = useStateContext();

	const [nama, setNama] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('-');
	const [jenis, setJenis] = useState(0);
	const [role, setRole] = useState(null);
	const [idCorp, setIdCorp] = useState(0);
	const [email, setEmail] = useState('');

	const [callbackDataList, setCallbackDataList] = useState([
		{ label: 'YES', value: true },
		{ label: 'NO', value: false },
	]);
	const [callbackData, setCallbackData] = useState(false);

	const [corp, setCorp] = useState([]);
	const getCorp = () => {
		const dataBody = {
			order: 'ASC',
			// limit: 100,
			draw: 2,
			page: 1,
			hirarkiId: hirarkiUser,
		};
		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					setCorp(data.result);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [category, setCategory] = useState([]);
	const getCorpCategory = () => {
		fetch(BASE_URL + '/user/jenis', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				// console.log(response);
				return response.json();
			})
			.then((data) => {
				// setData(data.result);
				setCategory(data.result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [blacklist, setBlacklist] = useState([]);
	const getBlackList = () => {
		fetch(BASE_URL + '/kategoripembayaran/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				setBlacklist(json.result);
			})
			.catch((err) => {
				alert(err);
			});
	};

	const [warning, setWarning] = useState(false);
	const [userLength, setUserLength] = useState(false);
	const [passwordLength, setPasswordLength] = useState(false);
	const usernameCheck = () => {
		if (username.length > 0 && username.length < 8) {
			setUserLength(true);
		} else if (username.length >= 8 || username.length === 0) {
			setUserLength(false);
		}
	};

	const passwordCheck = () => {
		if (password.length > 0 && password.length < 8) {
			setPasswordLength(true);
		} else if (password.length >= 8 || password.length === 0) {
			setPasswordLength(false);
		}
	};

	const retypeCheck = () => {
		if (password2 === password || password.length === 0) {
			setWarning(false);
		} else {
			setWarning(true);
		}
	};

	const [dataRole, setDataRole] = useState([]);
	const getDataRole = () => {
		const dataInput = {
			corporateHirarki: hirarkiUser,
		};

		fetch(BASE_URL + URL_ROLE_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log(json);
				if (json.result != null) {
					let mdata = _.cloneDeep(json.result);
					mdata.map((o) => {
						o.value = o.id;
						o.label = o.roleName;
					});

					setDataRole(mdata);
				} else {
					setDataRole([]);
				}
			})
			.catch((err) => {
				return console.log(err);
			});
	};

	useEffect(() => {
		getCorp();
		getCorpCategory();
		getBlackList();
		getDataRole();
	}, []);

	useEffect(() => {
		usernameCheck();
	}, [username]);

	useEffect(() => {
		passwordCheck();
	}, [password]);

	useEffect(() => {
		retypeCheck();
	}, [password2, password]);

	const [visible, setVisible] = useState(false);
	const [visible2, setVisible2] = useState(false);

	const [checked, setChecked] = useState([]);

	const handleCheckBox = (e) => {
		if (e.target.checked === true) {
			setChecked([...checked, Number(e.target.value)]);
		}
	};

	const [idUser, setIdUser] = useState(0);

	const inputData = (e) => {
		e.preventDefault();
		const dataInput =
			jenis === 1
				? {
						nama: nama,
						username: username,
						password: password,
						jenis: parseInt(jenis),
						idcorporate: parseInt(idCorp),
						email: email,
						idRole: role,
						callbackData: callbackData,
				  }
				: {
						nama: nama,
						username: username,
						password: password,
						jenis: parseInt(jenis),
						idcorporate: parseInt(idCorp),
						email: email,
						callbackData: callbackData,
				  };

		fetch(BASE_URL + '/user/add', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				setIdUser(json.result);
				let message = json.message;
				if (json.success === true) {
					notify(message);
					// blackListInput(json.result);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((err) => {
				alert(err);
			});
	};

	const blackListInput = (idcorp) => {
		const data = checked.map((data) => ({
			idKategoriPembayaran: data,
		}));

		const dataInput = {
			idUser: parseInt(idcorp),
			idKategoriPembayaran: data,
		};

		fetch(BASE_URL + '/bl/edit', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log(json);
			})
			.catch((err) => {
				alert(err);
			});
	};

	const data = [];
	{
		corp.map((item) => {
			let dataValue = {
				value: item.id,
				label: item.uraian,
			};
			data.push(dataValue);
		});
	}

	const onCorpChange = (event, values) => {
		setIdCorp(values.value);
	};

	const onChangeCategory = (event, values) => {
		setJenis(values.value);
	};

	const onChangeRole = (event, values) => {
		setRole(values.value);
	};

	const onChangeCallbackData = (event, values) => {
		setCallbackData(values.value);
	};

	const dataUser = [];
	{
		category.map((item) => {
			let dataValue = {
				value: item.id,
				label: item.jenisUser,
			};
			dataUser.push(dataValue);
		});
	}

	const selectedRole =
		role && dataRole && dataRole.length > 0
			? dataRole.filter((o) => o.value == role)[0].roleName
			: '';

	const currentIndexCallbackData =
		callbackDataList && callbackDataList.length > 0
			? callbackDataList.findIndex((item) => {
					return item.value === callbackData;
			  })
			: 0;

	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={inputData}
		>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10 rounded-3xl bg-white dark:bg-white">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="Insert User" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" flex gap-5 mb-7">
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="name"
							label="Nama"
							variant="outlined"
							onChange={(text) => setNama(text.target.value)}
							placeholder="Dimas Beck, Putri Ayu, ..."
							InputLabelProps={{ shrink: true }}
						/>
					</div>
					<div className=" w-full">
						<TextField
							error={userLength}
							className="w-full"
							required
							id="username"
							label="Username"
							variant="outlined"
							onChange={(text) => setUsername(text.target.value)}
							placeholder="dimasbk, ptrayu, ..."
							InputLabelProps={{ shrink: true }}
						/>
						{userLength === true ? (
							<div>
								<p className=" absolute text-red-500 text-xs">
									*Username kurang dari 8 karakter
								</p>
							</div>
						) : (
							''
						)}
					</div>
				</div>

				<div className=" flex gap-5 mb-7">
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="email"
							label="Email"
							variant="outlined"
							onChange={(text) => setEmail(text.target.value)}
							placeholder="email@gmail.com"
							InputLabelProps={{ shrink: true }}
						/>
					</div>
					<div className=" w-full">
						<Autocomplete
							required
							style={{
								width: 'auto',
							}}
							disablePortal
							id="combo-box-demo"
							options={data}
							onChange={onCorpChange}
							defaultValue={data[0]}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									placeholder="Corporate"
									label="Corporate"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
					</div>
				</div>

				<div className=" flex gap-5 mb-7 ">
					<div className=" w-full">
						<FormControl
							error={passwordLength}
							required
							className=" w-full"
							variant="outlined"
						>
							<InputLabel
								shrink
								htmlFor="outlined-adornment-password"
							>
								Password
							</InputLabel>
							<OutlinedInput
								notched
								id="outlined-adornment-password"
								type={visible ? 'text' : 'password'}
								// value={values.password}
								placeholder="Password"
								onChange={(text) => setPassword(text.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											// aria-label="toggle password visibility"
											onClick={() =>
												setVisible((current) => !current)
											}
											// onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{visible ? (
												<MdVisibilityOff />
											) : (
												<MdVisibility />
											)}
										</IconButton>
									</InputAdornment>
								}
								label="Password"
							/>
						</FormControl>
						{passwordLength === true ? (
							<div>
								<p className=" absolute text-red-500 text-xs">
									*Password kurang dari 8 karakter
								</p>
							</div>
						) : (
							''
						)}
					</div>
					<div className=" w-full">
						<FormControl
							error={warning}
							required
							className=" w-full"
							variant="outlined"
						>
							<InputLabel
								shrink
								htmlFor="outlined-adornment-password"
							>
								Re-Type Password
							</InputLabel>
							<OutlinedInput
								notched
								id="outlined-adornment-password"
								type={visible2 ? 'text' : 'password'}
								// value={values.password}
								placeholder="Re-Type Password"
								onChange={(text) => setPassword2(text.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() =>
												setVisible2((current) => !current)
											}
											// onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{visible2 ? (
												<MdVisibilityOff />
											) : (
												<MdVisibility />
											)}
										</IconButton>
									</InputAdornment>
								}
								label="Re-Type Password"
							/>
						</FormControl>
						{warning === true ? (
							<p className=" text-xs absolute text-red-500 ">
								*Password tidak sesuai
							</p>
						) : (
							''
						)}
					</div>
				</div>

				<div className=" w-full flex gap-5">
					<div className=" w-full">
						<Autocomplete
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="jenis-user"
							options={dataUser}
							onChange={onChangeCategory}
							// defaultValue={dataUser[0]}
							renderInput={(params) => (
								<TextField required {...params} label="Jenis User" />
							)}
						/>
					</div>

					{jenis === 1 ? (
						<div className=" w-full">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="jenis-user"
								options={dataRole}
								onChange={onChangeRole}
								value={selectedRole}
								inputValue={selectedRole}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Jenis Role"
									/>
								)}
							/>
						</div>
					) : (
						''
					)}

					<div className=" w-full">
						<Autocomplete
							required
							key={currentIndexCallbackData}
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							disableClearable
							id="jenis-user"
							options={callbackDataList}
							onChange={onChangeCallbackData}
							defaultValue={
								callbackDataList
									? callbackDataList[currentIndexCallbackData]
									: null
							}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Using Callback Data"
								/>
							)}
						/>
					</div>
				</div>

				<div className=" mt-5 flex gap-5 justify-between">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						borderRadius={'10px'}
						color="white"
						text="Previous Page"
						icon={<MdArrowBack />}
					/>
					<Button
						// customFunc={() => handleSubmit()}
						type="submit"
						bgColor={currentColor}
						borderRadius={'10px'}
						color="white"
						text="Submit"
						icon={<MdSave />}
					/>
				</div>
			</div>
		</form>
	);
};

export default UserAdd;
