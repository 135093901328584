import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";

import axios from "axios";
import { checkIsAvailableModule } from "../../../Routes";

import { BASE_URL } from "../../../config";
import { Header, Button } from "../../../components";
import DataTable, { createTheme } from "react-data-table-component";
import notfound from "../../../data/notfound.png";
import { FiRefreshCw } from "react-icons/fi";
import {
  SoundboxUserAction,
  Copy,
} from "../DataTableComponent/DataTableComponent";

import {
  Autocomplete,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { MdSearch } from "react-icons/md";

const SoundboxUser = () => {
  const navigate = useNavigate();
  const {
    currentColor,
    currentAuth,
    setAuth,
    screenWidth,
    modalOpenClose,
    privilege,
    hirarkiUser,
  } = useStateContext();

  //   table styling
  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "600",
        paddingLeft: "0 8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "0px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  createTheme("solarized", {
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
    background: {
      default: "#ffffff",
    },
  });
  // table header
  const [header, setHeader] = useState([
    {
      name: "Action", //useTemplate
      selector: "",
      width: 100,
      cell: (row) => <SoundboxUserAction row={row.id} />,
    },
    {
      name: "Nama",
      selector: (row) => row["nama"],
      sortable: true,
      width: 250,
    },
    {
      name: "Username",
      selector: (row) => row["username"],
      sortable: true,
      width: 250,
    },
    {
      name: "Corporate Name",
      selector: (row) => row["corporateName"],
      sortable: true,
      width: 250,
    },
    {
      name: "Merchant Key",
      selector: (row) => row["soundboxMerhcantKey"],
      sortable: true,
      width: 150,
    },
    {
      name: "",
      cell: (row) => (
        <Copy label="Merchant Key" value={row.soundboxMerhcantKey} />
      ),
    },
  ]);

  // request config
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${currentAuth}`,
    },
  };

  // state initialization
  const [data, setData] = useState([]);
  const [corporate, setCorporate] = useState([]);
  const [corporateOption, setCorporateOption] = useState([]);

  const [search, setSearch] = useState({
    valueCorporate: "",
    dropdownSearchCorporate: "",
  });
  const [filter, setFilter] = useState({
    hirarkiId: "",
    keyword: "",
  });
  const [loading, setLoading] = useState({
    loadingCorporate: "",
  });
  const [searchTimeoutId, setSearchTimeoutId] = useState(null);

  // fetch user sound box data
  const getUserSoundBoxList = async () => {
    modalOpenClose(true);
    const body = {
      order: "ASC",
      orderBy: "id",
      hirarkiId: filter.hirarkiId ? filter.hirarkiId : hirarkiUser,
      keyword: filter.keyword,
    };

    try {
      const res = await axios.post(
        BASE_URL + "/user/soundbox-access/list",
        body,
        config
      );

      if (res.data.result !== null) {
        const mappedData = res.data.result.map((item) => ({
          ...item,
          soundboxMerhcantKey: !item.soundboxMerhcantKey
            ? "-"
            : item.soundboxMerhcantKey,
        }));
        setData(mappedData);
      } else {
        setData([]);
      }
    } catch (e) {
      if (e.response.data.statusCode === "401") {
        setAuth(null);
        navigate("/");
        localStorage.removeItem("auth");
      }
      console.error("Error:", e);
    } finally {
      modalOpenClose(false);
    }
  };

  // fetch Corporate data
  const getCorporateData = async () => {
    const body = {
      order: "ASC",
      limit: 200,
      page: 1,
      keyword: search.dropdownSearchCorporate,
      hirarkiId: hirarkiUser,
    };

    try {
      const res = await axios.post(BASE_URL + "/corporate/list", body, config);
      if (res.data.result !== null) {
        setCorporate(res.data.result);
      } else {
        setCorporate([]);
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingCorporate: false,
      }));
    }
  };
  const corporateDropdown = () => {
    const data = corporate.map((item) => ({
      label: item.uraian,
      value: item.hirarkiId,
    }));

    setCorporateOption(data);
  };

  useEffect(() => {
    getUserSoundBoxList();
    getCorporateData();
  }, []);

  useEffect(() => {
    corporateDropdown();
  }, [corporate]);

  // check if action config privilege exist
  useEffect(() => {
    if (!checkIsAvailableModule("EDIT_SOUNDBOX_USER", privilege)) {
      setHeader((prev) => prev.filter((item) => item.name !== "Action"));
    }
  }, []);

  // Cleanup function to clear timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutId) {
        clearTimeout(searchTimeoutId);
      }
    };
  }, [searchTimeoutId]); // Dependency on searchTimeoutId

  return (
    <div className="h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white">
      {/* header and add button */}
      <div className="flex justify-between">
        <Header title={"Soundbox User"} />
      </div>

      {/* filter */}
      <div className="grid grid-cols-12 gap-5 mb-10">
        <div className="col-span-6">
          <Autocomplete
            required
            fullWidth={true}
            style={{
              width: "auto",
            }}
            disablePortal
            id="corporate-option"
            options={corporateOption}
            value={search.valueCorporate}
            onChange={(event, newInputValue) => {
              setLoading((prev) => ({
                ...prev,
                loadingCorporate: false,
              }));
              setSearch((prev) => ({
                ...prev,
                valueCorporate: newInputValue,
              }));
              setFilter((prev) => ({
                ...prev,
                hirarkiId: newInputValue?.value,
              }));
            }}
            inputValue={search.dropdownSearchCorporate}
            onInputChange={(event, newInputValue) => {
              setLoading((prev) => ({
                ...prev,
                loadingCorporate: true,
              }));
              setSearch((prev) => ({
                ...prev,
                dropdownSearchCorporate: newInputValue,
              }));
              // Clear any existing timeout before starting a new one
              if (searchTimeoutId) {
                clearTimeout(searchTimeoutId);
              }

              setSearchTimeoutId(
                setTimeout(() => {
                  search.dropdownSearchCorporate && getCorporateData();
                }, 2000)
              );
            }}
            disableClearable // This prop disables the clear button
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Corporate Name"
                placeholder="- All Corporate Name -"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading.loadingCorporate ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        search.valueCorporate !== null &&
                        search.dropdownSearchCorporate !== "" && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear"
                              onClick={() => {
                                setSearch((prev) => ({
                                  ...prev,
                                  valueCorporate: "",
                                  dropdownSearchCorporate: "",
                                }));
                                setFilter((prev) => ({
                                  ...prev,
                                  hirarkiId: "",
                                }));
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="col-span-12">
          <Button
            customFunc={() => getUserSoundBoxList()}
            text="Filter"
            bgColor={currentColor}
            color="white"
            borderRadius="10px"
            icon={<FiRefreshCw />}
          />
        </div>
      </div>

      <div className=" w-full mb-2 mt-2 flex justify-end">
        <div className="md:w-3/12">
          <FormControl className=" w-full" variant="outlined">
            <OutlinedInput
              size="small"
              notched
              id="keyword-user-soundbox"
              type={"text"}
              value={filter.keyword}
              placeholder="Search"
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, keyword: e.target.value }))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  getUserSoundBoxList();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => getUserSoundBoxList()} edge="end">
                    <MdSearch />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>

      {/* table */}
      <div className="flex-1">
        <DataTable
          columns={header}
          data={data}
          noDataComponent={
            <div className=" justify-center">
              <img
                src={notfound}
                style={{
                  width: "auto",
                  height: screenWidth >= 500 ? 400 : 200,
                }}
                alt="not-found"
              />
            </div>
          }
          pagination
          theme="solarized"
          customStyles={customStyles}
          width={"70%"}
        />
      </div>
    </div>
  );
};

export default SoundboxUser;
