import React, { useState } from 'react';
import './Login.css';
import { Button, Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { IoMdEye, IoMdEyeOff, IoMdLogIn } from 'react-icons/io';
import { BASE_URL, URL_LOGIN } from '../../config';
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	MdLockOutline,
	MdPersonOutline,
	MdVisibility,
	MdVisibilityOff,
} from 'react-icons/md';
import TextField from '@mui/material/TextField';
import {
	FormControl,
	IconButton,
	InputLabel,
	OutlinedInput,
} from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';

import A2PlogoWhite from '../../data/A2PlogoWhite.png';
import left from '../../data/left.svg';
import right from '../../data/right.svg';
import {
	encryptHmac512,
	encryptHmac512Base64,
} from '../../utils/CryptoUtils';

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: 'white',
		},
		'& label.Mui-': {
			color: 'white',
		},
		'& .MuiInput-underline': {
			borderBottomColor: 'green',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'white',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'white',
			},
			'&:hover fieldset': {
				borderColor: '#ffffff',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'white',
			},
		},
	},
})(TextField);

const Login = () => {
	const {
		setAuth,
		setPrivilegeRole,
		setHirarkiLogin,
		setEncDataLogin,
	} = useStateContext();

	const notify = (text) => {
		toast.success(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const notifyError = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const [visible, setVisible] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const onLogin = (e) => {
		e.preventDefault();

		const inputData = {
			username: username,
			password: encryptHmac512Base64(password),
		};

		fetch(BASE_URL + URL_LOGIN, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					notify(json.message);
					setAuth(
						json.result.token,
						json.result.nama,
						json.result.role
					);

					if (json.result.privilege != null) {
						setPrivilegeRole(json.result.privilege);
					} else {
						setPrivilegeRole([]);
					}

					setHirarkiLogin(json.result.hirarkiId);
					setEncDataLogin(inputData);
				} else if (json.success === false) {
					notifyError(json.message);
				}
			})
			.catch((err) => {
				notifyError('Gagal Login');
			});
	};

	const height = window.innerHeight;
	const width = window.innerWidth;

	return (
		<form onSubmit={onLogin}>
			<div className="appnew">
				<div
					className={` justify-center h-fit m-2  md:m-10 p-10 md:p-10 shadow-xl rounded-2xl bg-[#6769F0] w-3/4 md:w-full lg:w-2/6 z-50 `}
				>
					{/* <Header title={"Login"} /> */}
					<div className=" justify-center flex -mt-10">
						<img
							className=" justify-center"
							src={A2PlogoWhite}
							style={{ width: 'auto', height: 110 }}
						/>
					</div>

					<div className=" flex ">
						<MdPersonOutline className="absolute ml-5 text-white text-2xl mt-4 z-50 " />
						<div className=" w-full mb-5">
							<CssTextField
								// className={classes.margin}
								className="w-full"
								required
								// label="Username"
								autoFocus={true}
								type="text"
								placeholder="Username"
								onChange={(text) => setUsername(text.target.value)}
								inputProps={{
									autoCapitalize: 'none',
									style: {
										background: '#5859EF',
										opacity: 0.5,
										color: 'white',
										// marginLeft: 50,
										paddingLeft: 60,
										borderRadius: 5,
										fontWeight: 'initial',
										// textTransform: "lowercase",
									},
								}}
								InputLabelProps={{
									shrink: true,
									style: {
										color: 'white',
									},
								}}
							/>
						</div>
					</div>

					<div className=" flex ">
						<MdLockOutline className=" absolute ml-5 text-white text-2xl mt-4 z-50" />

						<div className=" w-full">
							<div className="flex">
								<FormControl
									required
									className=" w-full"
									variant="outlined"
								>
									<CssTextField
										required
										notched
										id="outlined-adornment-password"
										type={visible ? 'text' : 'password'}
										placeholder="Password"
										onChange={(text) =>
											setPassword(text.target.value)
										}
										classes={{ root: { backgroundColor: 'green' } }}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<button
														type="button"
														aria-label="toggle password visibility"
														onClick={() =>
															setVisible((current) => !current)
														}
														className=" absolute -ml-6 text-2xl -mr-0 text-white"
														style={{ zIndex: 50 }}
													>
														{visible ? (
															<MdVisibility />
														) : (
															<MdVisibilityOff />
														)}
													</button>
												</InputAdornment>
											),
										}}
										inputProps={{
											autoCapitalize: 'none',
											style: {
												background: '#5859EF',
												opacity: 0.5,
												color: 'white',
												paddingLeft: 60,
												paddingRight: 45,
												marginRight: -22,
												borderRadius: 5,
												fontWeight: 'initial',
											},
										}}
										InputLabelProps={{
											shrink: true,
											style: {
												color: 'white',
											},
										}}
									/>
								</FormControl>
							</div>
						</div>
					</div>
					<div className=" flex justify-center mt-8 ">
						<Button
							type="submit"
							icon={<IoMdLogIn />}
							bgColor={'#3536AE'}
							color="white"
							width="full"
							width2="full"
							text="Login"
							borderRadius={'10px'}
						/>
					</div>
					<p className=" text-white opacity-50 justify-center flex text-xs mt-2">
						Update v3.5.8
					</p>
				</div>
				<div className=" flex justify-between md:w-full gap-7 md:gap-0 md:px-20 absolute mt-96 -mb-80 md:-mb-14 z-0">
					<img
						src={left}
						style={{
							height: 'auto',
							width: width >= 500 ? width / 4 : width / 2.7,
						}}
					/>
					<img
						src={right}
						style={{
							height: 'auto',
							width: width >= 500 ? width / 3.3 : width / 2.3,
						}}
					/>
				</div>
			</div>

			{/* <div className=" mb-10" /> */}
			<ToastContainer autoClose={1000} limit={1} />
		</form>
	);
};

export default Login;
