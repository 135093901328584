import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Header } from "../../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useStateContext } from "../../../contexts/ContextProvider";
import { BASE_URL, URL_PAYMENTDATA_LIST } from "../../../config";
import axios from "axios";
import { MdArrowBack, MdSave } from "react-icons/md";

const StaticQRAdd = () => {
  const navigate = useNavigate();

  const { currentColor, currentAuth, setAuth, hirarkiUser, activeMenu } =
    useStateContext();

  const notify = (message) => {
    toast.success(message, {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
      onClose: () => navigate(-1),
    });
  };

  const notifyErr = (text) => {
    toast.error(text, {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [settlement, setSettlement] = useState([]);
  const [merchant, setMerchant] = useState([]);
  const [payment, setPayment] = useState([]);
  const [addForm, setAddForm] = useState({
    settlementKey: "",
    merchantKey: "",
    staticQR: "",
    isSoundbox: null,
    paymentCode: "",
    defaultCallbackURL: "",
  });
  const [search, setSearch] = useState({
    valueSettlement: "",
    dropdownSearchSettlement: "",
    valueMerchant: "",
    dropdownSearchMerchant: "",
    valuePayment: "",
    dropdownSearchPayment: "",
    valueSoundBox: "",
    dropdownSearchSoundBox: "",
  });
  const [loading, setLoading] = useState({
    loadingSettlement: false,
    loadingMerchant: false,
  });
  const [searchTimeoutId, setSearchTimeoutId] = useState(null);
  const [settlementKeyOptions, setSettlementKeyOptions] = useState([]);
  const [merchantKeyOptions, setMerchantKeyOptions] = useState([]);
  const [paymentCodeOptions, setPaymentCodeOptions] = useState([]);
  const soundBoxOptions = [
    {
      label: "TRUE",
      value: true,
    },
    {
      label: "FALSE",
      value: false,
    },
  ];

  // request config
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${currentAuth}`,
    },
  };

  // fetch settlement key data
  const getSettlementKey = async () => {
    const body = {
      hirarkiId: hirarkiUser,
      limit: 200,
      keyword: search.dropdownSearchSettlement,
      page: 1,
    };

    try {
      const res = await axios.post(BASE_URL + "/core/list", body, config);
      if (res.data.result !== null) {
        setSettlement(res.data.result);
      } else {
        setSettlement([]);
      }
    } catch (e) {
      if (e.response.data.statusCode === "401") {
        setAuth(null);
        navigate("/");
        localStorage.removeItem("auth");
      }
      console.error("Error:", e);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingSettlement: false,
      }));
    }
  };
  const settlementDropdown = () => {
    const data = settlement.map((item) => ({
      label: item.name,
      value: item.value,
    }));

    setSettlementKeyOptions(data);
  };

  // fetch merchant key data
  const getMerchantKey = async () => {
    const body = {
      hirarkiId: hirarkiUser,
      limit: 200,
      page: 1,
      keyword: search.dropdownSearchMerchant,
    };
    try {
      const res = await axios.post(BASE_URL + "/user/list", body, config);
      if (res.data.result !== null) {
        setMerchant(res.data.result);
      } else {
        setMerchant([]);
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingMerchant: false,
      }));
    }
  };
  const merchantDropdown = () => {
    const data = merchant.map((item) => ({
      label: item.nama,
      value: item.encCredentials,
    }));

    setMerchantKeyOptions(data);
  };

  // fetch payment data
  const getPayment = async () => {
    try {
      const res = await axios.get(BASE_URL + URL_PAYMENTDATA_LIST, config);
      if (res.data.result !== null) {
        setPayment(res.data.result);
      } else {
        setPayment([]);
      }
    } catch (e) {
      console.error("Error :", e);
    }
  };
  const paymentDropdown = () => {
    const data = payment.map((item) => ({
      label: item.uraian,
      value: item.paymentCode,
    }));

    setPaymentCodeOptions(data);
  };

  //   handle add static qr
  const handleAddStaticQR = async (e) => {
    e.preventDefault();
    const body = {
      ...addForm,
    };

    try {
      const res = await axios.post(
        BASE_URL + "/static-qr-settings/add",
        body,
        config
      );
      if (res.data.success) {
        notify(res.data.message);
      } else {
        notifyErr(res.data.message);
      }
    } catch (e) {
      notifyErr(e.message);
      console.error("Error", e);
    }
  };

  useEffect(() => {
    getSettlementKey();
    getMerchantKey();
    getPayment();
  }, []);

  useEffect(() => {
    settlementDropdown();
    merchantDropdown();
    paymentDropdown();
  }, [settlement, merchant, payment]);

  // Cleanup function to clear timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutId) {
        clearTimeout(searchTimeoutId);
      }
    };
  }, [searchTimeoutId]); // Dependency on searchTimeoutId

  return (
    <form
      className={` ${activeMenu ? "-ml-0" : ""}`}
      onSubmit={handleAddStaticQR}
    >
      <div className="h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white">
        <ToastContainer limit={1} style={{ marginTop: 0 }} autoClose={2500} />
        <div>
          <Header title="Insert Static QR" />
          <p className=" -mt-10 mb-10 text-sm text-gray-500">* required</p>
        </div>

        <div className="grid grid-cols-12 gap-5 mb-10">
          <div className="col-span-6">
            <Autocomplete
              required
              fullWidth={true}
              style={{
                width: "auto",
              }}
              disablePortal
              id="combo-box-demo"
              options={settlementKeyOptions}
              value={search.valueSettlement}
              onChange={(event, newInputValue) => {
                setLoading((prev) => ({
                  ...prev,
                  loadingSettlement: false,
                }));
                setSearch((prev) => ({
                  ...prev,
                  valueSettlement: newInputValue,
                }));
                setAddForm((prev) => ({
                  ...prev,
                  settlementKey: newInputValue?.value,
                }));
              }}
              inputValue={search.dropdownSearchSettlement}
              onInputChange={(event, newInputValue) => {
                setLoading((prev) => ({
                  ...prev,
                  loadingSettlement: true,
                }));
                setSearch((prev) => ({
                  ...prev,
                  dropdownSearchSettlement: newInputValue,
                }));
                // Clear any existing timeout before starting a new one
                if (searchTimeoutId) {
                  clearTimeout(searchTimeoutId);
                }

                setSearchTimeoutId(
                  setTimeout(() => {
                    search.dropdownSearchSettlement && getSettlementKey();
                  }, 2000)
                );
              }}
              disableClearable // This prop disables the clear button
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Settlement Key"
                  placeholder="- All Settlement Key -"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading.loadingSettlement ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          search.valueSettlement !== null &&
                          search.dropdownSearchSettlement !== "" && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear"
                                onClick={() => {
                                  setSearch((prev) => ({
                                    ...prev,
                                    valueSettlement: "",
                                    dropdownSearchSettlement: "",
                                  }));
                                  setAddForm((prev) => ({
                                    ...prev,
                                    settlementKey: "",
                                  }));
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-6">
            <Autocomplete
              required
              fullWidth={true}
              style={{
                width: "auto",
              }}
              disablePortal
              id="combo-box-demo"
              options={merchantKeyOptions}
              value={search.valueMerchant}
              onChange={(event, newInputValue) => {
                setLoading((prev) => ({
                  ...prev,
                  loadingMerchant: false,
                }));
                setSearch((prev) => ({
                  ...prev,
                  valueMerchant: newInputValue,
                }));
                setAddForm((prev) => ({
                  ...prev,
                  merchantKey: newInputValue?.value,
                }));
              }}
              inputValue={search.dropdownSearchMerchant}
              onInputChange={(event, newInputValue) => {
                setLoading((prev) => ({
                  ...prev,
                  loadingMerchant: true,
                }));
                setSearch((prev) => ({
                  ...prev,
                  dropdownSearchMerchant: newInputValue,
                }));
                // Clear any existing timeout before starting a new one
                if (searchTimeoutId) {
                  clearTimeout(searchTimeoutId);
                }

                setSearchTimeoutId(
                  setTimeout(() => {
                    search.dropdownSearchMerchant && getMerchantKey();
                  }, 2000)
                );
              }}
              disableClearable // This prop disables the clear button
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Merchant Key"
                  placeholder="- All Merchant Key -"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading.loadingMerchant ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          search.valueMerchant !== null &&
                          search.dropdownSearchMerchant !== "" && (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear"
                                onClick={() => {
                                  setSearch((prev) => ({
                                    ...prev,
                                    valueMerchant: "",
                                    dropdownSearchMerchant: "",
                                  }));
                                  setAddForm((prev) => ({
                                    ...prev,
                                    merchantKey: "",
                                  }));
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="col-span-6">
            <Autocomplete
              required
              fullWidth={true}
              style={{
                width: "auto",
              }}
              disablePortal
              id="combo-box-demo"
              options={paymentCodeOptions}
              value={search.valuePayment}
              onChange={(event, newInputValue) => {
                setSearch((prev) => ({
                  ...prev,
                  valuePayment: newInputValue,
                }));
                setAddForm((prev) => ({
                  ...prev,
                  paymentCode: newInputValue?.value,
                }));
              }}
              inputValue={search.dropdownSearchPayment}
              onInputChange={(event, newInputValue) => {
                setSearch((prev) => ({
                  ...prev,
                  dropdownSearchPayment: newInputValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Payment Code"
                  placeholder="- All Payment Code -"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <div className="col-span-6">
            <Autocomplete
              required
              fullWidth={true}
              style={{
                width: "auto",
              }}
              disablePortal
              id="sound-box-filter"
              options={soundBoxOptions}
              value={search.valueSoundBox}
              onChange={(event, newValue) => {
                setSearch((prev) => ({
                  ...prev,
                  valueSoundBox: newValue,
                }));
                setAddForm((prev) => ({
                  ...prev,
                  isSoundbox: newValue?.value,
                }));
              }}
              inputValue={search.dropdownSearchSoundBox}
              onInputChange={(event, newInputValue) => {
                setSearch((prev) => ({
                  ...prev,
                  dropdownSearchSoundBox: newInputValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Sound Box"
                  placeholder="- All Sound Box -"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <div className="col-span-6">
            <TextField
              className="w-full"
              id="static-qr"
              label="Static QR"
              variant="outlined"
              onChange={(e) =>
                setAddForm((prev) => ({ ...prev, staticQR: e.target.value }))
              }
              placeholder="Static QR"
              InputLabelProps={{ shrink: true }}
              value={addForm.staticQR}
            />
          </div>
          <div className="col-span-6">
            <TextField
              className="w-full"
              id="call-url"
              label="Default Callback URL"
              variant="outlined"
              onChange={(e) =>
                setAddForm((prev) => ({
                  ...prev,
                  defaultCallbackURL: e.target.value,
                }))
              }
              placeholder="Callback URL"
              InputLabelProps={{ shrink: true }}
              value={addForm.defaultCallbackURL}
            />
          </div>
        </div>

        <div className=" mt-5 flex gap-5 justify-between">
          <Button
            customFunc={() => navigate(-1)}
            bgColor={"gray"}
            borderRadius={"10px"}
            color="white"
            text="Previous Page"
            icon={<MdArrowBack />}
          />
          <Button
            type="submit"
            bgColor={currentColor}
            borderRadius={"10px"}
            color="white"
            text="Submit"
            icon={<MdSave />}
          />
        </div>
      </div>
    </form>
  );
};

export default StaticQRAdd;
