import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { HiTrash } from 'react-icons/hi';
import { MdEdit, MdSync } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL, URL_ISSUING_REMOVE } from '../../../config';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Button } from '../../../components';
import { checkIsAvailableModule } from '../../../Routes';

var CryptoJS = require('crypto-js');

export const CorpAction = (row, onSuccess) => {
	const { currentAuth, privilege, modalOpenClose } =
		useStateContext();

	// Encrypt
	const ciphertext = CryptoJS.AES.encrypt(
		JSON.stringify(row),
		'nasirames'
	).toString();

	var enc = ciphertext
		.replace(/\+/g, 'p1L2u3S')
		.replace(/\//g, 's1L2a3S4h')
		.replace(/=/g, 'e1Q2u3A4l');

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
		});
	};
	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};
	const navigate = useNavigate();

	const deleteData = (id) => {
		modalOpenClose(true);

		const inputData = {
			id: parseInt(id),
		};

		fetch(BASE_URL + URL_ISSUING_REMOVE, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				modalOpenClose(false);

				if (json.success === true) {
					notify('Data deleted successfully');
					setModal(false);
					onSuccess();
				} else if (json.success === false) {
					notifyErr(json.message);
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				notifyErr(err.message);
			});
	};

	const [modal, setModal] = useState(false);

	return (
		<div className="flex gap-2">
			{checkIsAvailableModule('EDIT_CARD_TYPE', privilege) && (
				<button
					type="button"
					onClick={() => navigate(`/update-card-type/${enc}`)}
					className=" text-2xl text-green-500 hover:drop-shadow-xl"
				>
					<MdEdit />
				</button>
			)}

			{/* {checkIsAvailableModule('DELETE_MASTER_ISSUING', privilege) && (
				<button
					disabled={row.id === 1 ? true : false}
					onClick={() => setModal(true)}
					className=" text-2xl text-red-500 hover:drop-shadow-xl"
				>
					<HiTrash />
				</button>
			)} */}

			<Modal onBackdropClick={() => setModal(false)} open={modal}>
				<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p className=" font-semibold text-base md:text-xl">
						Anda ingin menghapus data?
					</p>
					<div className=" flex justify-center gap-5 mt-5 ">
						<Button
							customFunc={() => setModal(false)}
							bgColor={'gray'}
							borderRadius={'10px'}
							color="white"
							text="Previous Page"
							// icon={<MdArrowBack />}
						/>
						<Button
							customFunc={() => deleteData(row.id)}
							bgColor={'red'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Continue"
							color={'white'}
							// icon={<MdArrowBack />}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
