import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { BASE_URL } from '../../config';
import { TextField } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { RiLoader4Line } from 'react-icons/ri';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';

const LineChart = ({ Corp }) => {
	const {
		currentColor,
		currentAuth,
		activeMenu,
		screenWidth,
		dataLogin,
	} = useStateContext();

	const start = moment().format('YYYY-MM-DD');

	const [loading, setLoading] = useState(true);

	const [theDate, setTheDate] = useState(start);
	const pickedDate = moment(theDate).format('YYYY-MM-DD');
	const dateTo = moment().format('YYYY-MM-DD');

	//get data hourly
	const [dataHourly, setDataHourly] = useState([]);

	const getDataHourly = () => {
		setLoading(false);
		const inputData = {
			Date: pickedDate,
			corporate: Corp,
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(inputData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			`/dashboard/hourly/v2` +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + '/dashboard/hourly/v2', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.result != null) {
					setDataHourly(json.result);
					setLoading(true);
				} else {
					setDataHourly([]);
					setLoading(true);
				}
			})
			.catch((err) => {
				setLoading(true);
			});
	};

	useEffect(() => {
		if (Corp && theDate) {
			getDataHourly();
		}
	}, [theDate, Corp]);

	const [filtered, setFiltered] = useState([]);
	const data2 = [
		{
			date: 0,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 1,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 2,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 3,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 4,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 5,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 6,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 7,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 8,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 9,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 10,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 11,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 12,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 13,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 14,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 15,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 16,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 17,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 18,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 19,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 20,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 21,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 22,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 23,
			omset: 0,
			trxCount: 0,
		},
		{
			date: 24,
			omset: 0,
			trxCount: 0,
		},
	];

	const filterData = () => {
		// var ids = new Set(initialData.map((d) => d.ID));
		// var merged = [...initialData, ...newData.filter((d) => !ids.has(d.ID))];
		var date = new Set(dataHourly.map((item) => item.date));

		var merged = [
			...dataHourly,
			...data2.filter((item) => !date.has(item.date)),
		];

		setFiltered(merged);
	};
	useEffect(() => {
		filterData();
	}, [dataHourly]);

	const sortData = () => {
		filtered.sort((a, b) => {
			return a.date - b.date;
		});
		ApexCharts.exec(state.options.chart.id, 'updateSeries', [
			{
				name: 'Transaction Count',
				data: filtered.map((item) => {
					return item.trxCount;
				}),
			},
		]);
		const opsi = {
			xaxis: {
				// type: "datetime",
				categories: filtered.map((item) => {
					return item.date;
				}),
				title: {
					text: 'Time (Hourly)',
				},
			},
		};
		ApexCharts.exec(state.options.chart.id, 'updateOptions', opsi);
		// ApexCharts.exec("mychart");
	};
	useEffect(() => {
		sortData();
	}, [filtered]);

	const series = [
		{
			name: 'Transaction Count',
			data: filtered.map((item) => {
				return item.trxCount;
			}),
		},
	];

	const state = {
		options: {
			chart: {
				id: 'mychart',
				height: 350,
				type: 'area',
				toolbar: {
					autoSelected: 'zoom',
					export: {
						csv: {
							filename:
								'Traffic Gross Transaction ' +
								moment(theDate).format('DD MMM YYYY'),
						},
						svg: {
							filename:
								'Traffic Gross Transaction ' +
								moment(theDate).format('DD MMM YYYY'),
						},
						png: {
							filename:
								'Traffic Gross Transaction ' +
								moment(theDate).format('DD MMM YYYY'),
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
			},
			xaxis: {
				// type: "datetime",
				categories: filtered.map((item) => {
					return item.date;
				}),
				title: {
					text: 'Time (Hourly)',
				},
			},
			yaxis: {
				title: {
					text: 'Amount of transaction',
				},
				labels: {
					formatter: function (val) {
						return val.format();
					},
				},
			},
			colors: [currentColor],
			tooltip: {
				shared: false,
				y: {
					formatter: function (val) {
						return val.format();
					},
				},
				x: {
					show: true,
					formatter: function (val) {
						return val - 1 + ':00';
					},
				},
			},
		},
	};
	const width = window.innerWidth;

	return (
		<div className=" w-full mt-5 md:mt-0 mr-5 md:mr-0">
			{/* <p className=" text-xl">REVENUE</p> */}
			<div className="   md:flex justify-between">
				<p className=" font-bold text-base md:text-xl">
					Traffic Gross Transaction
				</p>
				<div className=" w-full mt-5 md:mt-0 md:w-3/12 mb-2 flex end">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							className=" w-full"
							label="Choose Date"
							value={theDate}
							onChange={(newValue) => {
								setTheDate(newValue);
							}}
							inputFormat="DD-MM-YYYY"
							// minDate={maxDate}
							maxDate={dateTo}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div hidden={loading}>
				<p style={{ color: currentColor }}>
					<RiLoader4Line className=" animate-spin left-1/2 ml-0 md:ml-20 mt-24 z-50 text-5xl md:text-7xl absolute " />
				</p>
			</div>
			<ReactApexChart
				options={state.options}
				series={series}
				type="area"
				height={350}
				width={activeMenu ? screenWidth - 470 : '100%'}
			/>
		</div>
	);
};

export default LineChart;
