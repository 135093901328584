import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../components';
import { useNavigate } from 'react-router-dom';

import { useStateContext } from '../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_PAYMENT_LINK_CHECK_REF,
	URL_PAYMENT_LINK_LAST_RECORD,
	URL_ROLE_LIST,
} from '../../config';

import {
	MdArrowBack,
	MdCheckCircle,
	MdOutlineClose,
	MdSave,
} from 'react-icons/md';
import ClearIcon from '@mui/icons-material/Clear';
import {
	Autocomplete,
	CircularProgress,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Switch,
	TextField,
	Typography,
	styled,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import _ from 'lodash';
import { Help, HelpOutline } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encrypt,
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';
import { ModalCreateConfirm } from './ModalCreateConfirm';
import { ModalCreateCallback } from './ModalCreateCallback';
import { IoMdSearch } from 'react-icons/io';

const HtmlTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}));

const PaymentLinkAdd = () => {
	const navigate = useNavigate();

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		activeMenu,
		modalOpenClose,
	} = useStateContext();

	const [idCorp, setIdCorp] = useState('');
	const [corp, setCorp] = useState([]);
	const [corpData, setCorpData] = useState([]);
	const [searchCorporate, setSearchCorporate] = useState('');
	const [searchCorporateLoading, setSearchCorporateLoading] =
		useState(false);
	const [searchCorporateTimeout, setSearchCorporateTimeout] =
		useState(null);
	const [searchCorporateValue, setSearchCorporateValue] =
		useState(null);

	const [idCorpAccount, setIdCorpAccount] = useState('');
	const [corpAccount, setCorpAccount] = useState([]);
	const [corpAccountData, setCorpAccountData] = useState([]);
	const [searchCorporateAccount, setSearchCorporateAccount] =
		useState('');
	const [
		searchCorporateAccountLoading,
		setSearchCorporateAccountLoading,
	] = useState(false);
	const [
		searchCorporateAccountTimeout,
		setSearchCorporateAccountTimeout,
	] = useState(null);
	const [
		searchCorporateAccountValue,
		setSearchCorporateAccountValue,
	] = useState(null);

	const [merchantNoRef, setMerchantNoRef] = useState('');
	const [linkType, setLinkType] = useState('');
	const [openAmount, setOpenAmount] = useState('');
	const [amount, setAmount] = useState('');
	const [maxAttempt, setMaxAttempt] = useState('');
	const [maxAmount, setMaxAmount] = useState('');
	const [maxPayment, setMaxPayment] = useState('');
	const [expiredDatetime, setExpiredDatetime] = useState('');
	const [expPaymentDuration, setExpPaymentDuration] = useState('');
	const [remarks, setRemarks] = useState('');
	const [isExclude, setIsExclude] = useState(false);
	const [customerData, setCustomerData] = useState('');
	const [customerName, setCustomerName] = useState('');
	const [customerInitialEmail, setCustomerInitialEmail] =
		useState('');
	const [customerPaymentEmail, setCustomerPaymentEmail] =
		useState('');
	const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
	const [customerremarks, setCustomerRemarks] = useState('');
	const [callbackUrl, setCallbackUrl] = useState('');
	const [shortUrl, setShortUrl] = useState('');

	const [modalConfirm, setModalConfirm] = useState(null);
	const [modalCallback, setModalCallback] = useState(null);

	const [lastPaymentRecord, setLastPaymentRecord] = useState(null);
	const [validateMerchantRef, setValidateMerchantRef] =
		useState(null);

	const [validateMerchantRefLoading, setValidateMerchantRefLoading] =
		useState(false);

	const getCorp = () => {
		const dataBody = {
			order: 'ASC',
			limit: 25,
			page: 1,
			keyword: searchCorporate,
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setCorp(data.result);
					} else {
						setCorp([]);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setSearchCorporateLoading(false);
			});
	};

	const getCorpAccount = () => {
		const dataBody = {
			id: 0,
			corporateCID: searchCorporateValue
				? searchCorporateValue.value
				: '',
			corporateName: '',
			merchantKey: '',
			filter: {
				order: 'ASC',
				limit: 100,
				page: 1,
				orderBy: 'id',
			},
		};

		fetch(BASE_URL + '/payment-link/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setCorpAccount(data.result);
					} else {
						resetAccountData();
					}
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setSearchCorporateAccountLoading(false);
			});
	};

	const getLastAccountRef = () => {
		setLastPaymentRecord(null);

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(null).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'GET' +
			'||' +
			URL_PAYMENT_LINK_LAST_RECORD +
			'||' +
			(searchCorporateAccountValue &&
			searchCorporateAccountValue.value
				? searchCorporateAccountValue?.merchantKey ?? ''
				: '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		console.log('dataPLain', dataPLain);

		let signature = encryptHmac512Base64(
			dataPLain,
			searchCorporateAccountValue && searchCorporateAccountValue.value
				? searchCorporateAccountValue?.secretKey ?? ''
				: ''
		);

		console.log('signature', signature);

		fetch(BASE_URL + URL_PAYMENT_LINK_LAST_RECORD, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
				'X-MKP-Key':
					searchCorporateAccountValue &&
					searchCorporateAccountValue.value
						? searchCorporateAccountValue?.merchantKey ?? ''
						: '',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setLastPaymentRecord(data.result);
					} else {
						setLastPaymentRecord(false);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getValidateInvoice = () => {
		setValidateMerchantRefLoading(true);

		setValidateMerchantRef(null);

		let mData = {
			merchantNoRef: merchantNoRef,
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(mData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_PAYMENT_LINK_CHECK_REF +
			'||' +
			(searchCorporateAccountValue &&
			searchCorporateAccountValue.value
				? searchCorporateAccountValue?.merchantKey ?? ''
				: '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		console.log('dataPLain', dataPLain);

		let signature = encryptHmac512Base64(
			dataPLain,
			searchCorporateAccountValue && searchCorporateAccountValue.value
				? searchCorporateAccountValue?.secretKey ?? ''
				: ''
		);

		console.log('signature', signature);

		fetch(BASE_URL + URL_PAYMENT_LINK_CHECK_REF, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
				'X-MKP-Key':
					searchCorporateAccountValue &&
					searchCorporateAccountValue.value
						? searchCorporateAccountValue?.merchantKey ?? ''
						: '',
			},

			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setValidateMerchantRefLoading(false);

				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.success) {
						setValidateMerchantRef(data.success);
					} else {
						setValidateMerchantRef(false);
					}
				}
			})
			.catch((err) => {
				setValidateMerchantRefLoading(false);
				console.log(err);
			});
	};

	const corpDropdown = () => {
		const data = corp.map((item) => ({
			value: item.cid,
			label: item.uraian,
		}));

		setCorpData(data);
	};

	const corpAccountDropdown = () => {
		const data = corpAccount.map((item) => ({
			value: item.merchantKey,
			label: item.userName,
			userEmail: item.userEmail,
			phoneNumber: item.phoneNumber,
			merchantKey: item.merchantKey,
			secretKey: item.secretKey,
		}));

		setCorpAccountData(data);
	};

	useEffect(() => {
		let mExpired = moment().add('day', 1);
		setExpiredDatetime(mExpired);

		getCorp();
	}, []);

	useEffect(() => {
		if (corp.length > 0) {
			corpDropdown();
		}
	}, [corp]);

	useEffect(() => {
		if (corpAccount.length > 0) {
			corpAccountDropdown();
		}
	}, [corpAccount]);

	useEffect(() => {
		if (searchCorporateValue) {
			getCorpAccount();
		} else {
			resetAccountData();
		}
	}, [searchCorporateValue]);

	useEffect(() => {
		if (searchCorporateAccountValue) {
			getLastAccountRef();
		} else {
			// resetAccountData();
		}
	}, [searchCorporateAccountValue]);

	const resetAccountData = () => {
		setIdCorpAccount('');
		setCorpAccount([]);
		setCorpAccountData([]);
		setSearchCorporateAccount('');
		setSearchCorporateAccountLoading(false);
		setSearchCorporateAccountTimeout(null);
		setSearchCorporateAccountValue(null);
	};

	const resetCorporateData = () => {
		setIdCorp('');
		setSearchCorporate('');
		setSearchCorporateLoading(false);
		setSearchCorporateTimeout('');
		setSearchCorporateValue('');
	};

	const inputData = (e) => {
		e.preventDefault();

		if (validateMerchantRef == true) {
			let mData = {
				merchantNoRef: merchantNoRef,
				linkType: 'PRIVATE',
				openAmount: false,
				amount: amount
					? Number(
							amount
								.replace('Rp ', '')
								.replace('Rp', '')
								.replace(/\./g, '')
					  )
					: 0,
				maxAttempt: maxAttempt ? Number(maxAttempt) : 0,
				maxAmount: null,
				maxPayment: null,
				expiredDatetime: expiredDatetime
					? moment(expiredDatetime).format('YYYY-MM-DD HH:mm:ss')
					: '', //yyyy-mm-dd hh:mm:ss
				expPaymentDuration: null, //in minutes
				remarks: remarks,
				isExclude: isExclude,
				customerData: {
					customerName:
						searchCorporateValue && searchCorporateValue.value
							? searchCorporateValue.label
							: '',
					customerInitialEmail:
						searchCorporateAccountValue &&
						searchCorporateAccountValue.value
							? searchCorporateAccountValue?.userEmail ?? ''
							: '',
					customerPaymentEmail:
						searchCorporateAccountValue &&
						searchCorporateAccountValue.value
							? searchCorporateAccountValue?.userEmail ?? ''
							: '',
					customerPhoneNumber:
						searchCorporateAccountValue &&
						searchCorporateAccountValue.value
							? searchCorporateAccountValue?.phoneNumber ?? ''
							: '',
					remarks:
						searchCorporateAccountValue &&
						searchCorporateAccountValue.value
							? searchCorporateAccountValue?.userName ?? ''
							: '',
				},
				callbackUrl: callbackUrl, //optional
				shortUrl: shortUrl, //optional
			};

			sessionStorage.setItem(
				'iP-Link',
				encrypt(JSON.stringify(mData))
			);

			setModalConfirm(mData);
		} else {
			notifyErr('Please enter the correct invoice number');
		}
	};

	const submitData = () => {
		modalOpenClose(true);

		let mData = {
			merchantNoRef: merchantNoRef,
			linkType: 'PRIVATE',
			openAmount: false,
			amount: amount
				? Number(
						amount
							.replace('Rp ', '')
							.replace('Rp', '')
							.replace(/\./g, '')
				  )
				: null,
			maxAttempt: maxAttempt ? Number(maxAttempt) : null,
			maxAmount: null,
			maxPayment: null,
			expiredDatetime: expiredDatetime
				? moment(expiredDatetime).format('YYYY-MM-DD HH:mm:ss')
				: '', //yyyy-mm-dd hh:mm:ss
			expPaymentDuration: null, //in minutes
			remarks: remarks,
			isExclude: isExclude,
			customerData: {
				customerName:
					searchCorporateValue && searchCorporateValue.value
						? searchCorporateValue.label
						: '',
				customerInitialEmail:
					searchCorporateAccountValue &&
					searchCorporateAccountValue.value
						? searchCorporateAccountValue?.userEmail ?? ''
						: '',
				customerPaymentEmail:
					searchCorporateAccountValue &&
					searchCorporateAccountValue.value
						? searchCorporateAccountValue?.userEmail ?? ''
						: '',
				customerPhoneNumber:
					searchCorporateAccountValue &&
					searchCorporateAccountValue.value
						? searchCorporateAccountValue?.phoneNumber ?? ''
						: '',
				remarks:
					searchCorporateAccountValue &&
					searchCorporateAccountValue.value
						? searchCorporateAccountValue?.userName ?? ''
						: '',
			},
			callbackUrl: callbackUrl, //optional
			shortUrl: shortUrl, //optional
		};

		sessionStorage.setItem('pLink_d', encrypt(JSON.stringify(mData)));

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(mData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			'/payment-link/transaction/generate-link' +
			'||' +
			(searchCorporateAccountValue &&
			searchCorporateAccountValue.value
				? searchCorporateAccountValue?.merchantKey ?? ''
				: '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(
			dataPLain,
			searchCorporateAccountValue && searchCorporateAccountValue.value
				? searchCorporateAccountValue?.secretKey ?? ''
				: ''
		);

		fetch(BASE_URL + '/payment-link/transaction/generate-link', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
				'X-MKP-Key':
					searchCorporateAccountValue &&
					searchCorporateAccountValue.value
						? searchCorporateAccountValue?.merchantKey ?? ''
						: '',
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				modalOpenClose(false);
				console.log('data', data);

				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					let message = data.message;
					if (data.success === true) {
						sessionStorage.removeItem('iP-Link');
						notify(message);

						setModalCallback(data.result);
					} else if (data.success === false) {
						console.log(data.result);
						notifyErr(message);
					}
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				console.log(err);
			});
	};

	const onCorpChange = (event, values) => {
		setSearchCorporateValue(values);
		setIdCorp(values.value);
	};

	const onCorpAccountChange = (event, values) => {
		console.log('onCorpAccountChange', values);
		setSearchCorporateAccountValue(values);
		setIdCorpAccount(values.value);
	};

	useEffect(() => {
		return () => {
			if (searchCorporateTimeout) {
				clearTimeout(searchCorporateTimeout);
			}
		};
	}, [searchCorporateTimeout]);

	useEffect(() => {
		return () => {
			if (searchCorporateAccountTimeout) {
				clearTimeout(searchCorporateAccountTimeout);
			}
		};
	}, [searchCorporateAccountTimeout]);

	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={inputData}
		>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Header
					title="Create Invoice"
					className="md:pl-10 md:pr-10 mb-0 ml-2"
				/>

				<div className="flex flex-row align-items-start justify-content-between">
					<div className="flex-auto md:ml-10 md:mr-10">
						<div className="max-w-full shadow-lg h-fit m-2 p-2 md:p-10 rounded-3xl bg-white dark:bg-white">
							<p className="text-xl text-black">Bill To</p>
							<p className="mt-1 mb-5 text-sm text-gray-500">
								* required
							</p>
							<div className=" flex gap-10">
								<div className=" w-full flex-1">
									<div className=" w-full">
										<label className=" -mt-10 mb-10 text-sm text-gray-500">
											Corporate *
										</label>
										<Autocomplete
											required
											style={{
												width: 'auto',
											}}
											disablePortal
											disableClearable
											id="combo-box-demo"
											options={corpData}
											onChange={onCorpChange}
											value={searchCorporateValue}
											inputValue={searchCorporate}
											renderInput={(params) => (
												<TextField
													required
													{...params}
													placeholder="Corporate"
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<>
																{searchCorporateLoading ? (
																	<CircularProgress
																		color="inherit"
																		size={20}
																	/>
																) : (
																	searchCorporateValue !== null &&
																	searchCorporate !== '' && (
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="clear"
																				onClick={() => {
																					setSearchCorporate('');
																					setSearchCorporateValue(
																						null
																					);
																					setIdCorp('');
																					// setDataCorporate([]);
																				}}
																				edge="end"
																			>
																				<ClearIcon />
																			</IconButton>
																		</InputAdornment>
																	)
																)}
																{params.InputProps.endAdornment}
															</>
														),
													}}
												/>
											)}
											onInputChange={(event, newInputValue) => {
												setSearchCorporateLoading(true);
												setSearchCorporate(newInputValue);
												// Clear any existing timeout before starting a new one
												if (searchCorporateTimeout) {
													clearTimeout(searchCorporateTimeout);
												}

												setSearchCorporateTimeout(
													setTimeout(() => {
														getCorp();
													}, 2000)
												);
											}}
										/>
									</div>
									<div className=" w-full mt-5">
										<label className=" -mt-10 mb-10 text-sm text-gray-500">
											Corporate Account *
										</label>
										<Autocomplete
											required
											style={{
												width: 'auto',
											}}
											disablePortal
											disableClearable
											id="combo-box-demo"
											options={corpAccountData}
											onChange={onCorpAccountChange}
											value={searchCorporateAccountValue}
											inputValue={searchCorporateAccount}
											renderInput={(params) => (
												<TextField
													required
													{...params}
													placeholder="Corporate"
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<>
																{searchCorporateAccountLoading ? (
																	<CircularProgress
																		color="inherit"
																		size={20}
																	/>
																) : (
																	searchCorporateAccountValue !==
																		null &&
																	searchCorporateAccount !== '' && (
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="clear"
																				onClick={() => {
																					setSearchCorporateAccount(
																						''
																					);
																					setSearchCorporateAccountValue(
																						null
																					);
																					setIdCorpAccount('');
																					// setDataCorporate([]);
																				}}
																				edge="end"
																			>
																				<ClearIcon />
																			</IconButton>
																		</InputAdornment>
																	)
																)}
																{params.InputProps.endAdornment}
															</>
														),
													}}
												/>
											)}
											onInputChange={(event, newInputValue) => {
												setSearchCorporateAccountLoading(true);
												setSearchCorporateAccount(newInputValue);
												// Clear any existing timeout before starting a new one
												if (searchCorporateAccountTimeout) {
													clearTimeout(searchCorporateAccountTimeout);
												}

												setSearchCorporateAccountTimeout(
													setTimeout(() => {
														getCorpAccount();
													}, 2000)
												);
											}}
										/>
									</div>
								</div>
								<div className=" w-full flex-1">
									<div
										className="flex gap-5 mt-5"
										style={{ flexDirection: 'column' }}
									>
										<div className=" w-full">
											<label className=" mb-10 text-sm text-gray-500">
												Corporate Name
											</label>
											<p className="text-sm text-black">
												{searchCorporateValue &&
												searchCorporateValue.value
													? searchCorporateValue.label
													: '-'}
											</p>
										</div>
										<div className=" w-full">
											<label className=" mb-10 text-sm text-gray-500">
												Corporate Email
											</label>
											<p className="text-sm text-black">
												{searchCorporateAccountValue &&
												searchCorporateAccountValue.value
													? searchCorporateAccountValue?.userEmail ??
													  '-'
													: '-'}
											</p>
										</div>
										<div className=" w-full">
											<label className=" mb-10 text-sm text-gray-500">
												Corporate Phone Number
											</label>
											<p className="text-sm text-black">
												{searchCorporateAccountValue &&
												searchCorporateAccountValue.value
													? searchCorporateAccountValue?.phoneNumber ??
													  '-'
													: '-'}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="max-w-full shadow-lg h-fit m-2 mt-3 p-2 md:p-10 rounded-3xl bg-white dark:bg-white">
							<ToastContainer
								limit={1}
								style={{ marginTop: 0 }}
								autoClose={2500}
							/>

							{/* <Header title="Invoice" /> */}
							<p className=" mt-2 mb-10 text-xl text-black">
								Invoice Data
							</p>

							<p className=" -mt-10 mb-10 text-sm text-gray-500">
								* required
							</p>

							<div className=" flex gap-5 mb-7">
								<div className=" w-1/2">
									<label className=" -mt-10 mb-10 text-sm text-gray-500">
										Invoice Number*
									</label>
									<TextField
										className="w-full"
										required
										id="name"
										variant="outlined"
										disabled={searchCorporateAccountValue == null}
										value={merchantNoRef}
										onChange={(text) => {
											setValidateMerchantRef(null);

											if (searchCorporateAccountValue != null)
												setMerchantNoRef(text.target.value);
										}}
										placeholder="(e.g. 01/PRJ/202407/0001)"
										onBlur={() => {
											if (
												merchantNoRef &&
												validateMerchantRef == null &&
												validateMerchantRefLoading == false
											) {
												getValidateInvoice();
											}
										}}
										error={validateMerchantRef == false}
										helperText={
											validateMerchantRef == false ? (
												'This invoice number has been registered'
											) : (
												<p className="font-weight-bold">
													{lastPaymentRecord?.merchantNoRef
														? 'Last Invoice:  ' +
														  lastPaymentRecord?.merchantNoRef
														: ''}
												</p>
											)
										}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => {
															if (
																merchantNoRef &&
																validateMerchantRefLoading == null
															)
																getValidateInvoice();
														}}
														edge="end"
													>
														{validateMerchantRefLoading == false ? (
															validateMerchantRef != null ? (
																validateMerchantRef == false ? (
																	<MdOutlineClose color="red" />
																) : (
																	<MdCheckCircle color="green" />
																)
															) : (
																<IoMdSearch />
															)
														) : (
															<CircularProgress />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>
							</div>

							<div className=" flex gap-5 mb-7">
								<div className=" w-full">
									<label className=" -mt-10 mb-10 text-sm text-gray-500">
										Remarks*
									</label>
									<TextField
										className="w-full"
										required
										id="name"
										variant="outlined"
										disabled={searchCorporateAccountValue == null}
										value={remarks}
										onChange={(text) => {
											if (searchCorporateAccountValue != null)
												setRemarks(text.target.value);
										}}
										placeholder="(e.g. Pembayaran tagihan A)"
									/>
								</div>
								<div className=" w-full">
									<label className=" -mt-10 mb-10 text-sm text-gray-500">
										Amount*
									</label>
									<CurrencyInput
										className="w-full"
										required
										id="name"
										variant="outlined"
										customInput={TextField}
										disabled={searchCorporateAccountValue == null}
										value={
											amount &&
											Number(
												amount
													.replace('Rp ', '')
													.replace('Rp', '')
													.replace(/\./g, '')
											) > 0
												? Number(
														amount
															.replace('Rp ', '')
															.replace('Rp', '')
															.replace(/\./g, '')
												  )
												: null
										}
										data-number-to-fixed="3"
										// data-number-stepfactor="100"
										intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
										defaultValue={amount}
										onChange={(text) => {
											if (
												searchCorporateAccountValue != null &&
												!isNaN(
													+text.target.value
														.replace('Rp ', '')
														.replace('Rp', '')
														.replace(/\./g, '')
												)
											)
												setAmount(text.target.value);
										}}
										placeholder="(e.g. 10.000)"
									/>
								</div>
							</div>
							<div className=" flex gap-5 mb-7 justify-center items-center">
								<div className=" w-full">
									<p className="text-sm text-right text-gray-500">
										Amount Exclude MDR ?*
									</p>
								</div>
								<div className=" w-full">
									<FormControlLabel
										value="end"
										control={<Switch color="primary" />}
										label="Exclude"
										labelPlacement="end"
										checked={isExclude}
										onChange={(e, checked) => setIsExclude(checked)}
									/>
								</div>
							</div>

							<p className=" mt-2 mb-3 text-xl text-black">
								Link Detail
							</p>

							<div className=" flex gap-5 mb-7">
								<div className=" w-full">
									<label className="mb-2 text-sm text-gray-500">
										<span className="mr-1">Link Maximum Attemp</span>

										<HtmlTooltip
											title={
												<React.Fragment>
													<Typography color="inherit">
														Link Maximum Attemp
													</Typography>
													{
														'Maximum number of users can access the invoice link, link will be unavailable if it has been accessed beyond the limit.'
													}
												</React.Fragment>
											}
										>
											<HelpOutline />
										</HtmlTooltip>
									</label>
									<TextField
										className="w-full"
										id="name"
										variant="outlined"
										disabled={searchCorporateAccountValue == null}
										value={maxAttempt}
										type="number"
										onChange={(text) => {
											if (searchCorporateAccountValue != null) {
												if (text.target.value >= 0)
													setMaxAttempt(text.target.value);
											}
										}}
										placeholder='(empty or "0" if unlimited)'
									/>
								</div>
								<div className=" w-full h-auto">
									<label className="mb-2 text-sm text-gray-500">
										Link Expired Date Time
									</label>

									<DateTimePicker
										className="w-full"
										disabled={searchCorporateAccountValue == null}
										onChange={(text) => {
											setExpiredDatetime(text);
										}}
										value={expiredDatetime}
										DialogProps={{
											sx: {
												zIndex: 999,
											},
										}}
										renderInput={(params) => (
											<TextField
												required
												{...params}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										)}
									/>
								</div>
							</div>

							<div className=" flex gap-5 mb-7">
								<div className=" w-full">
									<label className="mb-2 text-sm text-gray-500">
										<span className="mr-1">Partner Callback URL</span>

										<HtmlTooltip
											title={
												<React.Fragment>
													<Typography color="inherit">
														Partner Callback URL
													</Typography>
													{
														'API partner URL if server-to-server notification is required.'
													}
												</React.Fragment>
											}
										>
											<HelpOutline />
										</HtmlTooltip>
									</label>
									<TextField
										className="w-full"
										id="name"
										variant="outlined"
										disabled={searchCorporateAccountValue == null}
										value={callbackUrl}
										onChange={(text) =>
											setCallbackUrl(text.target.value)
										}
										placeholder="(e.g. https://partner-api.com/callback/xxx)"
									/>
								</div>
								<div className=" w-full">
									<label className="mb-2 text-sm text-gray-500">
										<span className="mr-1">Customize Link URL</span>

										<HtmlTooltip
											title={
												<React.Fragment>
													<Typography color="inherit">
														Customize Link URL
													</Typography>
													{
														'Mention a short URL if you need a special name for the generated link. E.g. PARTNER-A-123'
													}
													<br />
													<em>{'Url generated: '}</em>{' '}
													<b>
														{
															'https://paylink.mkpmobile.com/?short-url=PARTNER-A-123'
														}
													</b>
												</React.Fragment>
											}
										>
											<HelpOutline />
										</HtmlTooltip>
									</label>
									<TextField
										className="w-full"
										id="name"
										variant="outlined"
										disabled={searchCorporateAccountValue == null}
										value={shortUrl}
										onChange={(text) =>
											setShortUrl(text.target.value)
										}
										placeholder="(e.g. PARTNER-A-123)"
									/>
								</div>
							</div>

							<div className=" mt-5 flex gap-5 justify-between">
								<Button
									customFunc={() => navigate(-1)}
									bgColor={'gray'}
									borderRadius={'10px'}
									color="white"
									text="Previous Page"
									icon={<MdArrowBack />}
								/>
								<Button
									type="submit"
									bgColor={currentColor}
									borderRadius={'10px'}
									color="white"
									text="Submit"
									icon={<MdSave />}
								/>
							</div>
						</div>
					</div>
				</div>

				<ModalCreateConfirm
					onCancel={() => setModalConfirm(null)}
					isModal={modalConfirm != null}
					data={modalConfirm}
					onSubmit={() => {
						setModalConfirm(null);
						submitData();
					}}
				/>

				<ModalCreateCallback
					onCancel={() => {
						setModalCallback(null);
						setMerchantNoRef('');
						setLinkType('');
						setOpenAmount('');
						setAmount(null);
						setMaxAttempt('');
						setMaxAmount('');
						setMaxPayment('');
						setExpiredDatetime(null);
						setExpPaymentDuration('');
						setRemarks('');
						setIsExclude(false);
						setCustomerData('');
						setCustomerName('');
						setCustomerInitialEmail('');
						setCustomerPaymentEmail('');
						setCustomerPhoneNumber('');
						setCustomerRemarks('');
						setCallbackUrl('');
						setShortUrl('');
						setValidateMerchantRefLoading(false);
						setValidateMerchantRef(null);

						resetCorporateData();
					}}
					isModal={modalCallback != null}
					data={modalCallback}
				/>
			</LocalizationProvider>
		</form>
	);
};

export default PaymentLinkAdd;
