import React, { useEffect, useMemo, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
// import { Stacked } from "../components";
import {
	BASE_URL,
	URL_DASHBOARD_HEADER,
	URL_DASHBOARD_REVENUE,
} from '../config';
import { MdAccountBalanceWallet, MdSearch } from 'react-icons/md';
import { AiOutlineAreaChart } from 'react-icons/ai';
import { Autocomplete, TextField } from '@mui/material';

import {
	Stacked,
	Pie,
	Button,
	LineChart,
	SparkLine,
	LineJumlahTrx,
	TrafficChart,
	SplineChart,
} from '../components';
import { FiRefreshCcw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import moment from 'moment/moment';
import { checkIsAvailableModule } from '../Routes';

const Dashboard = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		privilege,
		hirarkiUser,
	} = useStateContext();

	const navigate = useNavigate();

	const newDate = new Date();
	const date = newDate.getDate() - 1;

	const dateTo = moment().format('YYYY-MM-DD');
	const dateFrom = moment().subtract(date, 'd').format('YYYY-MM-DD');
	const maxDate = moment().subtract(1, 'months').format('YYYY-MM-01');

	//data for filter
	const [started, setStarted] = useState(dateFrom);
	const [ended, setEnded] = useState(dateTo);
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);
	const [hirarki, setHirarki] = useState(null);
	const [currentHirarki, setCurrentHirarki] = useState('');

	const dateToMax = moment(started).add(1, 'months').isAfter(dateTo)
		? dateTo
		: moment(started).add(1, 'months').format('YYYY-MM-DD');

	// get data for dashboard header
	const [dataHeader, setDataHeader] = useState({
		omset: 0,
		serviceFee: 0,
		trxCount: 0,
	});

	const [dataFilter, setDataFilter] = useState(null);

	//get dataCorporate
	const [dataCorp, setDataCorp] = useState([]);
	const [revenueData, setRevenueData] = useState([]);

	const getHeader = () => {
		const dataBody = {
			startDate: dataFilter.startDate,
			endDate: dataFilter.endDate,
			corporate: dataFilter.hirarki,
		};

		fetch(BASE_URL + URL_DASHBOARD_HEADER, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// Accept: "application/jso n",
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '400') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.success === true) {
						setDataHeader(data.result);
					} else {
						setDataHeader({ omset: 0, serviceFee: 0, trxCount: 0 });
					}
				}
			})
			.catch((err) => {
				console.log('ERROR BY ID', err);
			});
	};

	const getDataCorp = () => {
		const bodyData = {
			order: 'ASC',
			page: 1,
			keyword: '',
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
				} else {
					if (json.result != null) {
						setDataCorp(json.result);
					} else {
						setDataCorp([]);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getRevenueData = () => {
		modalOpenClose(true);

		const bodyData = {
			startDate: dataFilter.startDate,
			endDate: dataFilter.endDate,
			corporate: dataFilter.hirarki,
		};

		fetch(BASE_URL + URL_DASHBOARD_REVENUE, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
					modalOpenClose(false);
				} else {
					if (json.result === null) {
						setRevenueData([]);
						modalOpenClose(false);
					} else {
						setRevenueData(json.result);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				modalOpenClose(false);
			});
	};

	useEffect(() => {
		setDataHeader({
			omset: 0,
			serviceFee: 0,
			trxCount: 0,
		});
	}, []);

	// useEffect(() => {
	// 	if (hirarki && startDate && endDate) {
	// 		getHeader();
	// 		getRevenueData();
	// 		getDataCorp();
	// 	}
	// }, [hirarki, startDate, endDate]);

	useEffect(() => {
		console.log('hirarkiUser', hirarkiUser ?? '-');
		if (hirarkiUser) {
			// setHirarki(hirarkiUser);
			getDataCorp();

			setDataFilter({
				hirarki: hirarkiUser,
				startDate: dateFrom,
				endDate: dateTo,
			});
		}
	}, [hirarkiUser]);

	useEffect(() => {
		console.log('dataFilter', dataFilter);
		if (dataFilter) {
			getHeader();
			getRevenueData();
		}
	}, [dataFilter]);

	const onClickFilter = () => {
		// setStartDate(started);
		// setEndDate(ended);
		// setHirarki(currentHirarki);
		setDataFilter({
			hirarki: currentHirarki,
			startDate: moment(started).format('YYYY-MM-DD'),
			endDate: moment(ended).format('YYYY-MM-DD'),
		});
	};

	var host = window.location.host;

	const width = window.innerWidth;

	const data = [
		{
			value: hirarkiUser,
			label: 'All Corporate',
		},
	];
	{
		dataCorp.map((item) => {
			let dataValue = {
				value: item.hirarkiId,
				label: item.uraian,
			};

			data.push(dataValue);
		});
	}

	const onCorpChange = (event, values) => {
		if (values != null) {
			setCurrentHirarki(values.value);
		} else {
			setCurrentHirarki(hirarkiUser);
		}
	};

	const dateStart = new Date(startDate);
	const formattedStart = dateStart.toLocaleDateString('en-GB', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});

	const dateEnd = new Date(endDate);
	const formattedEnd = dateEnd.toLocaleDateString('en-GB', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});

	return (
		<div className=" w-auto pl-1 pr-2">
			<div className=" w-screen  md:w-full md:flex h-fit gap-5 p-2 md:p-11 mt-20 md:mt-0   px-5 md:px-11">
				<div className=" w-full  ">
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={data}
						onChange={onCorpChange}
						defaultValue={data[0]}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Corporate"
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</div>
				<div className=" mt-3 md:mt-0 w-full">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							className=" w-full"
							label="Filter From"
							value={started}
							onChange={(newValue) => {
								setStarted(newValue);
								setEnded(newValue);
							}}
							inputFormat="DD-MM-YYYY"
							// minDate={maxDate}
							maxDate={dateTo}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
				<div className="mt-3 md:mt-0 mb-3 md:mb-0 w-full">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							className=" w-full"
							label="Filter To"
							value={ended}
							onChange={(newValue) => {
								setEnded(newValue);
							}}
							inputFormat="DD-MM-YYYY"
							minDate={started}
							maxDate={dateToMax}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
				<div className="justify-end flex ">
					<Button
						customFunc={() => onClickFilter()}
						bgColor={currentColor}
						color="white"
						text="Filter"
						borderRadius={'10px'}
						icon={<FiRefreshCcw />}
					/>
				</div>
			</div>

			{/* Dashboard Header */}
			<div className="justify-center w-full ml-1 md:ml-0 flex flex-wrap lg:flex-nowrap px-4 md:px-11 mt-3 md:-mt-3 gap-3  ">
				<div
					style={{ background: currentColor }}
					className="w-full md:w-1/3 px-10 md:px-10  shadow-lg shadow-indigo-300 h-auto mb-3 md:mb-0 md:h-auto md:m-0 p-4 md:p-10  rounded-3xl bg-white "
				>
					<div className=" justify-between  flex ">
						<div className=" -ml-5 mt-5 md:mt-3 ">
							<p
								style={{ background: 'white', color: currentColor }}
								className={` text-2xl font-bold rounded-full text-white p-5`}
							>
								Rp
							</p>
						</div>
						<div className=" text-end md:-mr-5      ">
							<p className=" mb-3 text-2xl text-white font-semibold   ">
								Rp. {dataHeader.omset.format()}
							</p>
							<div className="  ">
								<p className="text-white font-semibold">Revenue</p>
								<p className="text-white font-base">
									{formattedStart} - {formattedEnd}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div
					style={{ background: currentColor }}
					className="w-full md:w-1/3 px-10 md:px-10 shadow-lg shadow-indigo-300 h-auto mb-3 md:mb-0 md:h-auto md:m-0 p-4 md:p-10 rounded-3xl bg-white "
				>
					<div className=" justify-between flex">
						<div className=" -ml-5 mt-5 md:mt-3 ">
							<p
								style={{ background: 'white', color: currentColor }}
								className={` text-3xl font-bold rounded-full text-white p-5`}
							>
								<MdAccountBalanceWallet />
							</p>
						</div>
						<div className="text-end md:-mr-5">
							<p className=" mb-3 text-2xl text-white font-semibold">
								Rp. {dataHeader.serviceFee.format()}
							</p>
							<div className="">
								<p className="text-white font-semibold">
									Service Fee
								</p>
								<p className="text-white font-base w-full">
									{formattedStart} - {formattedEnd}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div
					style={{ background: currentColor }}
					className="w-full md:w-1/3 px-10 md:px-10 shadow-lg shadow-indigo-3 00 h-auto mb-3 md:mb-0 md:h-auto md:m-0 p-4 md:p-10 rounded-3xl bg-white "
				>
					<div className=" justify-between flex">
						<div className=" -ml-5 mt-5 md:mt-3 ">
							<p
								style={{ background: 'white', color: currentColor }}
								className={` text-3xl font-bold rounded-full text-white p-5`}
							>
								<AiOutlineAreaChart />
							</p>
						</div>
						<div className="  text-end md:-mr-5">
							<p className=" text-white mb-3 text-2xl font-semibold">
								{dataHeader.trxCount.format()}
							</p>
							<p className=" text-white font-semibold">
								Transaction Number
							</p>
							<p className="text-white font-base  ">
								{formattedStart} - {formattedEnd}
							</p>
						</div>
					</div>
				</div>
			</div>

			{checkIsAvailableModule('VIEW_CHART_REVENUE', privilege) && (
				<div className="justify-center px-5 md:px-11 mt-8 flex flex-wrap lg:flex-nowrap md:-ml-0 ">
					<div className=" w-full flex flex-wrap h-fit shadow-md p-4 md:p-10 rounded-3xl bg-white dark:bg-white">
						{/* Revenue on chart */}
						<div style={{ flex: 2 }}>
							<Stacked Corp={dataFilter ? dataFilter.hirarki : ''} />
						</div>

						<div className="flex-1">
							{/* Chart Samping */}
							<LineChart
								Corp={dataFilter ? dataFilter.hirarki : ''}
								Start={dataFilter ? dataFilter.startDate : ''}
								End={dataFilter ? dataFilter.endDate : ''}
								Data={revenueData}
							/>
							<div className=" " />
							{/* Chart Samping */}
							<LineJumlahTrx
								Corp={dataFilter ? dataFilter.hirarki : ''}
								Data={revenueData}
								Start={dataFilter ? dataFilter.startDate : ''}
								End={dataFilter ? dataFilter.endDate : ''}
							/>
						</div>
					</div>
				</div>
			)}

			{checkIsAvailableModule(
				'VIEW_CHART_REVENUE_HOURLY',
				privilege
			) && (
				<div className="justify-center px-5 md:px-11 mt-8 flex flex-wrap lg:flex-nowrap md:-ml-0 ">
					<div className=" w-full flex h-fit shadow-md p-4 md:p-10 rounded-3xl bg-white dark:bg-white">
						{/* Traffic Gross Trx Chart */}
						<TrafficChart
							Corp={dataFilter ? dataFilter.hirarki : ''}
							Data={revenueData}
						/>
					</div>
				</div>
			)}

			{checkIsAvailableModule(
				'VIEW_CHART_REVENUE_BY_PAYMENT',
				privilege
			) && (
				<div className="justify-center px-5 md:px-11 mt-8 flex flex-wrap lg:flex-nowrap md:-ml-0 ">
					<div className=" w-full flex h-fit shadow-md p-4 md:p-10 rounded-3xl bg-white dark:bg-white">
						{/* Last Week TRX chart */}
						<SplineChart
							Corp={dataFilter ? dataFilter.hirarki : ''}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Dashboard;
