import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';

const Header = (props) => {
	const { currentMode } = useStateContext();

	return (
		<div
			className={`mb-10 ${props.className ? props.className : ''}`}
		>
			<p
				className={`${
					currentMode === 'Dark'
						? ' text-[#33373E]'
						: 'text-[#33373E]'
				}`}
			>
				{props.category}
			</p>
			<p
				className={`${
					currentMode === 'Dark'
						? ' text-3xl font-semibold tracking-tight text-[#33373E] '
						: 'text-xl md:text-3xl w-full md:w-auto font-bold tracking-tight text-[#33373E]'
				}`}
			>
				{props.title}
			</p>
		</div>
	);
};

export default Header;
