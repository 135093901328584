import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import {
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL } from '../../../config';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import CurrencyInput from 'react-currency-input-field';
import { Autocomplete } from '@mui/material';
import { formatNumber } from '../../../utils/StringUitls';

var CryptoJS = require('crypto-js');

const CorporateUpdate = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, setAuth, hirarkiUser } =
		useStateContext();
	const navigate = useNavigate();

	const { Id } = useParams();

	// Decrypt
	var byted = Id.replace(/p1L2u3S/g, '+')
		.replace(/s1L2a3S4h/g, '/')
		.replace(/e1Q2u3A4l/g, '=');

	var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
	var initialId = bytes.toString(CryptoJS.enc.Utf8);

	const [oneData, setOneData] = useState([]);
	const getOneCorp = () => {
		const bodyData = {
			id: parseInt(initialId),
		};
		fetch(BASE_URL + '/corporate/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (json.result != null) {
						console.log(json.result);
						setOneData(json.result);
						console.log('cid: ', json.result);
					} else {
						setOneData([]);
					}
				}
			})
			.catch((err) => {
				alert(err);
			});
	};

	const [uraian, setUraian] = useState('');
	const [alamat, setAlamat] = useState('');
	const [telepon, setTelepon] = useState('');
	const [servicefee, setServicefee] = useState('');
	const [corpCategory, setCorpCategory] = useState(0);
	const [idkota, setIdkota] = useState(0);
	const [ipLocal, setIpLocal] = useState('');
	const [isPercentage, setIsPercentage] = useState(true);
	const [parentCID, setParentCID] = useState('');

	const [corporateLogo, setCorporateLogo] = useState('');
	const [corporateFooter, setCorporateFooter] = useState('');

	const [corporateBankCode, setCorporateBankCode] = useState('');
	const [corporateBankAccountNumber, setCorporateBankAccountNumber] =
		useState('');

	const [status, setStatus] = useState(false);

	const dataUpdate = () => {
		console.log('servicefee', servicefee);
		const parsedSfee = servicefee
			.replace(/\./g, '')
			.replace(/\,/g, '.');
		// .replace(/./g, '').replace(',', '.');

		console.log('parsedSfee', parsedSfee);

		const inputData = {
			uraian: uraian,
			alamat: alamat,
			telepon: telepon,
			servicefee: +parsedSfee,
			corporatecategory: parseInt(corpCategory),
			iplocalserver: ipLocal,
			idkota: idkota ? parseInt(idkota) : '',
			ispercentage: isPercentage,
			id: parseInt(initialId),
			corporateLogo: corporateLogo,
			corporateFooter: corporateFooter,
			corporateBankCode: corporateBankCode,
			corporateBankAccount: corporateBankAccountNumber,
		};

		console.log('inputData', inputData);

		fetch(BASE_URL + '/corporate/edit', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				// 'Authorization' : `Bearer ${token}`
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				console.log(json.result);
				setStatus(json.success);
				let message = json.message;
				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const [category, setCategory] = useState([]);
	const [idCategory, setIdCategory] = useState([]);
	const [Data, setData] = useState([]);

	const getCorpCategory = () => {
		fetch(BASE_URL + '/corpcategory/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				// console.log(response);
				return response.json();
			})
			.then((data) => {
				console.log(data.result);
				setData(data.result);
				let tempArr = [];
				let idArr = [];
				data.result.map((item) => {
					tempArr.push(item.kode);
					idArr.push(item.idcategory);
					console.log(item);
				});
				setCategory(tempArr);
				setIdCategory(idArr);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [dataKota, setDataKota] = useState([]);
	const getKota = () => {
		fetch(BASE_URL + '/kota/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log(data.result);
				if (data.result != null) {
					setDataKota(data.result);
				} else {
					setDataKota([]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [corp, setCorp] = useState([]);
	const getCorp = () => {
		const dataBody = {
			order: 'ASC',
			// limit: 100,
			page: 1,
			keyword: '',
			hirarkiId: hirarkiUser,
		};
		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log(data.result);
				if (data.result != null) {
					setCorp(data.result);
				} else {
					setCorp([]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getCorpCategory();
		getKota();
		getCorp();
		getOneCorp();
	}, []);

	useEffect(() => {
		get();
	}, [oneData]);

	const kotaData = [];
	{
		dataKota.map((item) => {
			let datavalue = {
				value: item.id,
				label: item.uraian + ' - ' + item.namaProvinsi,
			};
			kotaData.push(datavalue);
		});
	}

	const handleChangeCategory = (event) => {
		console.log(event.target.value);
		setCorpCategory(event.target.value);
	};

	const handleChangeLevel = (event) => {
		console.log(event.target.value);
		setParentCID(event.target.value);
	};

	const handleChangeKota = (event, values) => {
		if (values != null) {
			setIdkota(values.value);
		} else {
			setIdkota(0);
		}
	};

	const [error, setError] = useState(false);

	const get = () => {
		{
			oneData.map(
				(item) => (
					setUraian(item.uraian),
					setCorpCategory(item.corporateCategory),
					setTelepon(item.telepon),
					setAlamat(item.alamat),
					setParentCID(item.parentCID),
					setIdkota(item.idKota),
					setIpLocal(item.ipLocalServer),
					setServicefee(
						item.serviceFee?.toString().replace(/\./g, ',') ?? ''
					),
					setIsPercentage(item.isPercentage),
					setCorporateLogo(item.corporateLogo),
					setCorporateFooter(item.corporateFooter),
					setCorporateBankCode(item.corporateBankCode ?? ''),
					setCorporateBankAccountNumber(
						item.corporateBankAccount ?? ''
					)
				)
			);
		}
	};

	const onChangeCurrency = (e) => {
		const { value = '' } = e.target;

		formatNumber((value ? value : 0).toString());
		setServicefee(value);
		// setServicefee(
		// 	isPercentage
		// 		? value.replace('Rp ', '').replace(/\./g, '')
		// 		: value.replace('Rp ', '').replace(/\./g, '')
		// );
	};

	const currentIndex = kotaData.findIndex((item) => {
		return item.value === idkota;
	});

	return (
		<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
			<Header title="Update Corporate" />
			<p className=" -mt-10 mb-10 text-sm text-gray-500">
				* required
			</p>

			<div className="  ">
				<FormControl className=" w-full">
					<InputLabel id="select-corp">Corporate Category</InputLabel>
					<Select
						labelId="select-corp"
						id="select-corp"
						label="Corporate Category"
						onChange={handleChangeCategory}
						value={corpCategory}
					>
						<MenuItem disabled value={'base'}>
							- Pilih Kategory Corporate -
						</MenuItem>
						{Data.map((Data) => (
							<MenuItem key={Data.idCategory} value={Data.idCategory}>
								{Data.uraian}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{error === true && corpCategory === 0 ? (
					<p className="mt-1 text-xs text-red-500">
						Corporate Category is Required
					</p>
				) : (
					''
				)}
			</div>

			<div className=" mt-5 w-full">
				<TextField
					className="w-full"
					required
					id="corp-name"
					label="Corporate Name"
					variant="outlined"
					onChange={(text) => setUraian(text.target.value)}
					placeholder="MKP APPS2PAY"
					InputLabelProps={{ shrink: true }}
					value={uraian}
				/>
			</div>

			<div className=" mt-5 w-full">
				<TextField
					className="w-full"
					required
					id="telepon"
					label="Telepon"
					variant="outlined"
					placeholder="0871-2345-123"
					InputLabelProps={{ shrink: true }}
					onChange={(text) => setTelepon(text.target.value)}
					value={telepon}
				/>
			</div>

			<div className=" mt-5 w-full">
				<TextField
					className="w-full"
					required
					id="alamat"
					label="Alamat"
					variant="outlined"
					placeholder="Jalan Pemuda No. 8"
					InputLabelProps={{ shrink: true }}
					onChange={(text) => setAlamat(text.target.value)}
					value={alamat}
				/>
				{error === true && alamat.length === 0 ? (
					<p className="mt-1 text-xs text-red-500">
						Alamat is Required
					</p>
				) : (
					''
				)}
			</div>

			<div>
				<div className="flex gap-6 mt-5">
					<div className=" w-full">
						<Autocomplete
							key={currentIndex}
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="selectKota"
							options={kotaData}
							onChange={handleChangeKota}
							defaultValue={kotaData[currentIndex]}
							renderInput={(params) => (
								<TextField required {...params} label="Kota" />
							)}
						/>
						{/* <FormControl className=" w-full">
              <InputLabel id="select-kota">Kota</InputLabel>
              <Select
                labelId="select-kota"
                id="select-kota"
                label="Kota"
                onChange={handleChangeKota}
                value={idkota}
              >
                <MenuItem value={"base"}>- Pilih Kota -</MenuItem>
                {dataKota.map((Data) => (
                  <MenuItem key={Data.id} value={Data.id}>
                    {Data.uraian} - {Data.namaProvinsi}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
					</div>
				</div>

				<div className="flex gap-5 mt-5 mb-3">
					<div className=" w-full -mr-0 md:-mr-0 ">
						<TextField
							className="w-full"
							id="corp-logo"
							label="Bank Code"
							variant="outlined"
							onChange={(text) =>
								setCorporateBankCode(text.target.value)
							}
							placeholder="ex: 002"
							InputLabelProps={{ shrink: true }}
							value={corporateBankCode}
						/>
					</div>
					<div className=" w-full -mr-0 md:-mr-0 ">
						<TextField
							className="w-full"
							id="corp-logo"
							label="Bank Account Number"
							variant="outlined"
							onChange={(text) =>
								setCorporateBankAccountNumber(text.target.value)
							}
							placeholder="ex: 1234567890"
							InputLabelProps={{ shrink: true }}
							value={corporateBankAccountNumber}
						/>
					</div>
				</div>

				<div className="flex gap-5">
					<div className=" mt-12 w-full">
						<TextField
							className="w-full"
							required
							id="iplokal"
							label="IP Lokal Server"
							variant="outlined"
							placeholder="192.123..."
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setIpLocal(text.target.value)}
							value={ipLocal}
						/>
					</div>
				</div>

				<div className="flex gap-5 w-full">
					<div className=" mt-5 w-6/12">
						<TextField
							required
							className="w-full"
							label="Service Fee"
							InputLabelProps={{ shrink: true }}
							type="text"
							onChange={(event) => {
								var charCode = event.target.value
									.charAt(event.target.value.length - 1)
									.charCodeAt(0);

								console.log('charCode', charCode);

								if (charCode == 45) {
									setServicefee(event.target.value);
								}
								if (
									(charCode < 48 || charCode > 57) &&
									charCode != 44
								) {
								} else {
									setServicefee(event.target.value);
								}
							}}
							value={
								servicefee
									? servicefee
											.replace(/\./g, '')
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
									: ''
							}
							// suffix={isPercentage ? '%' : undefined}
							// prefix={isPercentage ? undefined : 'Rp'}
							// className="e-input"
							// type="number"
							placeholder="number input here"
						/>
					</div>
					<div className=" mt-2 w-2/6">
						<p className=" mb-1 text-gray-500 text-sm">
							Satuan Service Fee
						</p>
						<div className="flex">
							<input
								checked={isPercentage === false}
								onChange={() => setIsPercentage(false)}
								id="Rp"
								className="cursor-pointer"
								type="radio"
								placeholder="Rupiah"
							/>
							<label
								htmlFor="Rp"
								className="ml-2 text-base cursor-pointer"
							>
								Rupiah
							</label>
						</div>
						<div className="flex">
							<input
								checked={isPercentage === true}
								onChange={() => setIsPercentage(true)}
								id="percent"
								className="cursor-pointer"
								type="radio"
								placeholder="Rupiah"
							/>
							<label
								htmlFor="percent"
								className="ml-2 text-base cursor-pointer"
							>
								Presentase
							</label>
						</div>
					</div>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-logo"
						label="Corporate Logo"
						variant="outlined"
						onChange={(text) => setCorporateLogo(text.target.value)}
						placeholder="Base64 logo"
						InputLabelProps={{ shrink: true }}
						value={corporateLogo}
						multiline
						maxRows={4}
					/>
				</div>
				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-logo"
						label="Corporate Footer"
						variant="outlined"
						onChange={(text) => setCorporateFooter(text.target.value)}
						placeholder="Footer"
						InputLabelProps={{ shrink: true }}
						value={corporateFooter}
						multiline
						maxRows={4}
					/>
				</div>
			</div>

			<div className=" flex mt-5 justify-between">
				<div className=" mr-5">
					<Button
						customFunc={() => navigate(-1)}
						// customFunc={() => notifyErr("test")}
						bgColor={'gray'}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Previous Page"
						color={'white'}
						icon={<MdArrowBack />}
					/>
				</div>
				<div>
					<Button
						customFunc={() => dataUpdate()}
						type="submit"
						bgColor={currentColor}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Submit"
						color={'white'}
						icon={<MdSave />}
					/>
				</div>
			</div>

			{/* <div>
            <DropDownListComponent placeholder='DATA' />
        </div>
            <div className="">
                <TextBoxComponent  placeholder="Data 1" floatLabelType="Auto"/>
                <TextBoxComponent placholder="Data 2" floatLabelType="Auto" 
                onChange={e => setText(e.target.value)} 
                // value={text}
                />
            </div>
            <Button 
            bgColor={'red'}
            customFunc={() => alert(text)}
            borderRadius='10px'
            width='20'
            height='8'
            /> */}
		</div>
		// </form>
	);
};

export default CorporateUpdate;
