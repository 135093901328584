import React from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { RiLoader4Line } from 'react-icons/ri';
import ReactApexChart from 'react-apexcharts';

const AreaChart = ({ date, loading, data, title }) => {
	// context states
	const { currentColor } = useStateContext();
	const xaxisCategories = Array.from(
		{ length: 24 },
		(_, i) => `${i}.00`
	);

	// chart configuration
	const state = {
		series: [
			// { name: "Count Request", data: data.map((item) => item.countRequest) },
			{
				name: 'Sent Count',
				data: data.map((item) => item.sentCount),
			},
			{
				name: 'Success Count',
				data: data.map((item) => item.successCount),
			},
			{
				name: 'Failed Count',
				data: data.map((item) => item.failedCount),
			},
		],
		options: {
			chart: {
				stacked: false,
				id: title,
				type: 'area',
				height: 350,
				toolbar: {
					export: {
						csv: {
							filename: `${title}_hourly - ${date}`,
						},
						svg: {
							filename: `${title}_hourly - ${date}`,
						},
						png: {
							filename: `${title}_hourly - ${date}`,
						},
					},
				},
			},
			dataLabels: { enabled: false },
			colors: [currentColor, '#329932', '#FF0000'],
			// colors: [currentColor, "#FF0000", "#329932"],
			xaxis: { categories: xaxisCategories },
			yaxis: {
				title: { text: title },
				labels: {
					formatter: function (value) {
						return value >= 1000000000
							? (value / 1000000000).toFixed(0) + ' Miliar'
							: value.toLocaleString();
					},
				},
			},
			fill: {
				opacity: [0.85, 0.25, 0.6],
				gradient: {
					inverseColors: false,
					shade: 'light',
					type: 'vertical',
					opacityFrom: 0.85,
					opacityTo: 0.55,
					stops: [0, 100, 100, 100],
				},
			},
			stroke: {
				curve: 'smooth',
			},
		},
	};

	return (
		<div className="w-full">
			<div hidden={!loading}>
				<p style={{ color: currentColor }}>
					<RiLoader4Line className="animate-spin ml-32 md:ml-80 z-50 mt-48 text-5xl md:text-7xl absolute" />
				</p>
			</div>

			<ReactApexChart
				options={state.options}
				series={state.series}
				type="area"
				height={500}
			/>
		</div>
	);
};

export default AreaChart;
