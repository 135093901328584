export const formatNumber = (strNumber) => {
	if (strNumber) {
		if (strNumber.includes('.')) {
			let mSplit = strNumber.split('.');
			if (mSplit.length > 1) {
				return (
					Number(mSplit[0]).toLocaleString('id-ID') + ',' + mSplit[1]
				);
			} else {
				return Number(mSplit[0]).toLocaleString('id-ID');
			}
		} else if (strNumber.includes(',')) {
			let mSplit = strNumber.split(',');
			if (mSplit.length > 1) {
				return (
					Number(mSplit[0]).toLocaleString('id-ID') + ',' + mSplit[1]
				);
			} else {
				return Number(mSplit[0]).toLocaleString('id-ID');
			}
		}
	}

	return strNumber;
};

export const maskingAllText = (str, karakter) => {
	let mString = '';
	for (var i = 0; i < str.length; i++) {
		mString += String(karakter);
	}

	return mString;
};
