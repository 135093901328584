import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Header } from "../../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@mui/material";
import { useStateContext } from "../../../contexts/ContextProvider";
import { BASE_URL } from "../../../config";
import axios from "axios";
import { MdArrowBack, MdSave } from "react-icons/md";

var CryptoJS = require("crypto-js");

const SoundboxUserUpdate = () => {
  const navigate = useNavigate();
  const { Id } = useParams();
  // Decrypt
  const byted = Id.replace(/p1L2u3S/g, "+")
    .replace(/s1L2a3S4h/g, "/")
    .replace(/e1Q2u3A4l/g, "=");

  const bytes = CryptoJS.AES.decrypt(byted, "nasirames");
  const initialId = bytes.toString(CryptoJS.enc.Utf8);

  const { currentColor, currentAuth, setAuth, activeMenu } = useStateContext();

  const notify = (message) => {
    toast.success(message, {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
      onClose: () => navigate(-1),
    });
  };
  const notifyErr = (text) => {
    toast.error(text, {
      autoClose: 500,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // Data States
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [editForm, setEditForm] = useState({
    soundboxMerchantKey: "",
    soundboxSecret: "",
    publicKey: "",
    privateKey: "",
  });

  // request config
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${currentAuth}`,
    },
  };

  // fetch current page data
  const getUserSoundBox = async () => {
    const body = {
      id: parseInt(initialId),
    };

    try {
      const res = await axios.post(
        BASE_URL + "/user/soundbox-access/detail",
        body,
        config
      );

      if (res.data.statusCode === "401") {
        setAuth(null);
        navigate("/");
        localStorage.removeItem("auth");
      } else if (res.data.result !== null) {
        setName(res.data.result.nama);
        setUsername(res.data.result.username);
        setEditForm((prev) => ({
          ...prev,
          soundboxMerchantKey: res.data.result.soundboxMerhcantKey,
          soundboxSecret: res.data.result.soundboxSecret,
          publicKey: res.data.result.publicKey,
          privateKey: res.data.result.privateKey,
        }));
      }
    } catch (e) {
      console.error("Error:", e);
    }
  };

  //   handle edit soundbox user
  const handleEditSoundboxUser = async (e) => {
    e.preventDefault();
    const body = {
      id: parseInt(initialId),
      ...editForm,
    };

    try {
      const res = await axios.post(
        BASE_URL + "/user/soundbox-access/update",
        body,
        config
      );
      if (res.data.success) {
        notify(res.data.message);
      } else {
        notifyErr(res.data.message);
      }
    } catch (e) {
      notifyErr(e.message);
      console.error("Error", e);
    }
  };

  useEffect(() => {
    getUserSoundBox(); //fetch current page data on load
  }, []);

  return (
    <form
      className={` ${activeMenu ? "-ml-0" : ""}`}
      onSubmit={handleEditSoundboxUser}
    >
      <div className="h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white">
        <ToastContainer limit={1} style={{ marginTop: 0 }} autoClose={2500} />
        <div>
          <Header title="Update Soundbox User" />
          <p className=" -mt-10 mb-10 text-sm text-gray-500">* required</p>
        </div>

        <div className="grid grid-cols-12 gap-5 mb-10">
          <div className="col-span-6">
            <TextField
              className="w-full"
              id="user-id"
              label="Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={name}
            />
          </div>
          <div className="col-span-6">
            <TextField
              className="w-full"
              id="user-id"
              label="Username"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={username}
            />
          </div>
          <div className="col-span-12">
            <TextField
              className="w-full"
              id="soundbox-secret"
              label="Soundbox Secret"
              variant="outlined"
              onChange={(e) =>
                setEditForm((prev) => ({
                  ...prev,
                  soundboxSecret: e.target.value,
                }))
              }
              placeholder="Soundbox Secret"
              InputLabelProps={{ shrink: true }}
              value={editForm.soundboxSecret}
            />
          </div>
          <div className="col-span-12">
            <TextField
              className="w-full"
              id="soundbox-merchantKey"
              label="Soundbox Merchant Key"
              variant="outlined"
              onChange={(e) =>
                setEditForm((prev) => ({
                  ...prev,
                  soundboxMerchantKey: e.target.value,
                }))
              }
              placeholder="Soundbox Merchant Key"
              InputLabelProps={{ shrink: true }}
              value={editForm.soundboxMerchantKey}
            />
          </div>
          <div className="col-span-12">
            <TextField
              className="w-full"
              id="public-key"
              label="Public Key"
              variant="outlined"
              onChange={(e) =>
                setEditForm((prev) => ({ ...prev, publicKey: e.target.value }))
              }
              placeholder="Public Key"
              InputLabelProps={{ shrink: true }}
              value={editForm.publicKey}
            />
          </div>
          <div className="col-span-12">
            <TextField
              className="w-full"
              id="private-key"
              label="Private Key"
              variant="outlined"
              onChange={(e) =>
                setEditForm((prev) => ({
                  ...prev,
                  privateKey: e.target.value,
                }))
              }
              placeholder="Private Key"
              InputLabelProps={{ shrink: true }}
              value={editForm.privateKey}
            />
          </div>
        </div>

        <div className=" mt-5 flex gap-5 justify-between">
          <Button
            customFunc={() => navigate(-1)}
            bgColor={"gray"}
            borderRadius={"10px"}
            color="white"
            text="Previous Page"
            icon={<MdArrowBack />}
          />
          <Button
            type="submit"
            bgColor={currentColor}
            borderRadius={"10px"}
            color="white"
            text="Submit"
            icon={<MdSave />}
          />
        </div>
      </div>
    </form>
  );
};

export default SoundboxUserUpdate;
