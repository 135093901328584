import React, {
	createContext,
	useContext,
	useState,
	useEffect,
} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encrypt, urlEncrypt } from '../utils/CryptoUtils';

var CryptoJS = require('crypto-js');

const StateContext = createContext();

const initialState = {
	chat: false,
	cart: false,
	userProfile: false,
	notification: false,
};

export const ContextProvider = ({ children }) => {
	const notify = (text) => {
		toast.success(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const [activeMenu, setActiveMenu] = useState(true);
	const [isClicked, setIsClicked] = useState(false);
	const [screenSize, setScreenSize] = useState(undefined);

	const [currentColor, setCurrentColor] = useState('#5757F3');
	const [redColor, setRedColor] = useState('#F5574C');
	const [yellowColor, setYellowColor] = useState('#FFAA2C');
	const [greenColor, setGreenColor] = useState('#5AB55E');
	const [currentMode, setCurrentMode] = useState('Light');
	const [themeSettings, setThemeSettings] = useState(false);
	const [notification, setNotification] = useState(true);

	const [currentAuth, setCurrentAuth] = useState(
		localStorage.getItem('auth')
	);
	const [currentName, setCurrentName] = useState('');
	const [currentRole, setCurrentRole] = useState('');

	const [openModal, setOpenModal] = useState(false);

	const [navbar, setNavbar] = useState(true);
	const [privilege, setPrivilege] = useState([]);
	const [hirarkiUser, setHirarkiUser] = useState('');
	const [dataLogin, setDataLogin] = useState(null);

	const setMode = (e) => {
		setCurrentMode(e.target.value);

		localStorage.setItem('themeMode', e.target.value);

		setThemeSettings(false);
	};

	const setAuth = (auth, nama, role) => {
		setCurrentAuth(auth);
		setCurrentName(nama);
		setCurrentRole(role);

		if (auth && nama && role) {
			let chiperAuth = encrypt(auth);
			let chiperNama = encrypt(nama);
			let chiperRole = encrypt(role);

			localStorage.setItem('auth', chiperAuth);
			localStorage.setItem('nama', chiperNama);
			localStorage.setItem('_r', chiperRole);
		} else {
			if (localStorage.getItem('auth'))
				localStorage.removeItem('auth');
			if (localStorage.getItem('nama'))
				localStorage.removeItem('nama');
			if (localStorage.getItem('_r')) localStorage.removeItem('_r');
		}
	};

	const getMode = () => {
		localStorage.getItem('themeMode');
	};

	const setColor = (color) => {
		setCurrentColor(color);

		localStorage.setItem('colorMode', color);

		setThemeSettings(false);
	};

	const handleClick = (clicked) => {
		setIsClicked(!isClicked);
	};

	const handleClickProfile = (clicked) => {
		setIsClicked(clicked);
	};

	const closeClick = (clicked) => {
		setIsClicked({ ...initialState, [clicked]: false });
	};

	const modalOpenClose = (isopen) => {
		setOpenModal(isopen); //modal function changed
	};

	const setPrivilegeRole = (privi) => {
		setPrivilege(privi);

		let chiperPrivi = encrypt(JSON.stringify(privi));

		if (privi) {
			localStorage.setItem('privilege', chiperPrivi);
		} else {
			if (localStorage.getItem('privilege'))
				localStorage.removeItem('privilege');
		}
	};

	const setHirarkiLogin = (hirarki) => {
		if (hirarki) {
			setHirarkiUser(hirarki);
			localStorage.setItem('hirarki', hirarki);
		} else {
			setHirarkiUser('');
			if (localStorage.getItem('hirarki'))
				localStorage.removeItem('hirarki');

			if (localStorage.getItem('_dl')) localStorage.removeItem('_dl');
		}
	};

	const setEncDataLogin = (dataLogin) => {
		if (dataLogin) {
			setDataLogin(dataLogin);
			localStorage.setItem('_dl', encrypt(JSON.stringify(dataLogin)));
		} else {
			setDataLogin(null);
			if (localStorage.getItem('_dl')) localStorage.removeItem('_dl');
		}
	};

	const screenWidth = window.innerWidth;

	return (
		<StateContext.Provider
			value={{
				activeMenu,
				setActiveMenu,
				isClicked,
				setIsClicked,
				handleClick,
				closeClick,
				screenSize,
				setScreenSize,
				currentColor,
				setCurrentColor,
				redColor,
				yellowColor,
				greenColor,
				currentMode,
				setCurrentMode,
				themeSettings,
				setThemeSettings,
				setMode,
				setColor,
				getMode,
				notification,
				setNotification,
				navbar,
				setNavbar,
				notify,
				currentAuth,
				setAuth,
				currentName,
				currentRole,
				openModal,
				modalOpenClose,
				setPrivilegeRole,
				privilege,
				setPrivilege,
				setHirarkiLogin,
				setEncDataLogin,
				dataLogin,
				hirarkiUser,
				screenWidth,
				handleClickProfile,
			}}
		>
			{children}
		</StateContext.Provider>
	);
};

export const useStateContext = () => useContext(StateContext);
