import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { checkIsAvailableModule } from '../../../Routes';

var CryptoJS = require('crypto-js');

export const MetodePembayaran = (row) => {
	const { privilege } = useStateContext();

	const navigate = useNavigate();
	const Id = row.idCorporate;

	// Encrypt
	const ciphertext = CryptoJS.AES.encrypt(
		JSON.stringify(Id),
		'nasirames'
	).toString();

	var enc = ciphertext
		.replace(/\+/g, 'p1L2u3S')
		.replace(/\//g, 's1L2a3S4h')
		.replace(/=/g, 'e1Q2u3A4l');

	return (
		<button
			onClick={() => navigate(`/insert-active-payment/${enc}`)}
			disabled={
				!checkIsAvailableModule(
					'EDIT_CONFIG_CORPORATE_PAYMENT',
					privilege
				)
			}
			className={` rounded-2xl py-2 text-white font-normal`}
			style={
				row.jmlMetodePemabayaran === 0
					? { background: 'linear-gradient(#FF3657, #DF009C)' }
					: { background: 'linear-gradient(#0040FF, #0198D3)' }
			}
		>
			{row.jmlMetodePemabayaran === 0 ? (
				<p className="px-4">No Result</p>
			) : (
				<p className="px-3">{row.jmlMetodePemabayaran} Payment</p>
			)}
		</button>
	);
};
