import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_CARDTYPE_LIST,
	URL_ISSUING_BIN_EDIT,
	URL_ISSUING_LIST,
} from '../../../config';

import TextField from '@mui/material/TextField';

import _ from 'lodash';
import { Autocomplete } from '@mui/material';

var CryptoJS = require('crypto-js');

const IssuingBINPaymentUpdate = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose, setAuth } =
		useStateContext();

	const navigate = useNavigate();

	const { Id } = useParams();

	const [binCard, setBinCard] = useState('');
	const [switchingCode, setSwitchingCode] = useState('');
	const [cardGroup, setCardGroup] = useState('');
	const [idJenisKartu, setIdJenisKartu] = useState(null);
	const [issuingCode, setIssuingCode] = useState('');
	const [issuingList, setIssuingList] = useState([]);
	const [cardTypeList, setCardTypeList] = useState([]);
	const [id, setId] = useState(null);

	const [status, setStatus] = useState(false);

	const selectedIssuing =
		issuingList && issuingList.length > 0
			? issuingList.filter((o) => o.value == issuingCode)[0].label
			: '';

	const selectedCardtype =
		cardTypeList && cardTypeList.length > 0 && idJenisKartu
			? cardTypeList.filter((o) => o.value == idJenisKartu)[0].label
			: '';

	const dataUpdate = (e) => {
		e.preventDefault();

		const inputData = {
			binCard: binCard,
			switchingCode: switchingCode,
			cardGroup: cardGroup,
			issuingCode: issuingCode,
			idJenisKartu: idJenisKartu,
			namaJenisKartu: selectedCardtype,
			id: id,
		};

		modalOpenClose(true);

		fetch(BASE_URL + URL_ISSUING_BIN_EDIT, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	const getIssuingList = () => {
		let mData = {
			order: 'DESC',
			orderBy: 'id',
			Keyword: '',
		};

		setIssuingList([]);

		fetch(BASE_URL + URL_ISSUING_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						let mData = _.cloneDeep(data.result);
						mData.map((o) => {
							o.value = o.issuingCode;
							o.label =
								o.issuingCode +
								' - ' +
								o.issuingName +
								' (' +
								o.bankCode +
								')';
						});

						setIssuingList(mData);
					} else {
						setIssuingList([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const getCardTypeList = () => {
		setIssuingList([]);

		fetch(BASE_URL + URL_CARDTYPE_LIST, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						let mData = _.cloneDeep(data.result);
						mData.map((o) => {
							o.value = o.idJenisKartu;
							o.label = o.namaJenisKartu;
						});

						setCardTypeList(mData);
					} else {
						setCardTypeList([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const currentIndex =
		issuingList && issuingList.length > 0
			? issuingList.findIndex((item) => {
					return item.value === issuingCode;
			  })
			: 0;

	const handleChangeIssuing = (event, values) => {
		if (values != null) {
			setIssuingCode(values.value);
		} else {
			setIssuingCode(null);
		}
	};

	const currentIndexCardType =
		cardTypeList && cardTypeList.length > 0
			? cardTypeList.findIndex((item) => {
					return item.value === idJenisKartu;
			  })
			: 0;

	const handleChangeCardType = (event, values) => {
		if (values != null) {
			setIdJenisKartu(values.value);
		} else {
			setIdJenisKartu(null);
		}
	};

	useEffect(() => {
		getIssuingList();
		getCardTypeList();
	}, []);

	useEffect(() => {
		if (Id) {
			try {
				var byted = Id.replace(/p1L2u3S/g, '+')
					.replace(/s1L2a3S4h/g, '/')
					.replace(/e1Q2u3A4l/g, '=');

				var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
				var initialId = bytes.toString(CryptoJS.enc.Utf8);

				var data = JSON.parse(initialId);

				console.log('data', data);
				setIssuingCode(data.issuingCode);
				setBinCard(data.binCard);
				setCardGroup(data.cardGroup);
				setSwitchingCode(data.switchingCode);
				setIdJenisKartu(data.idJenisKartu);
				setId(data.id);
			} catch (err) {
				navigate(`/404`);
			}
		}
	}, [Id]);

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="Update Issuing" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndex}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectIssuing"
								options={issuingList}
								onChange={handleChangeIssuing}
								inputValue={selectedIssuing}
								value={selectedIssuing}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Issuer"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="BIN Card"
						variant="outlined"
						onChange={(text) => setBinCard(text.target.value)}
						placeholder="BIN Card"
						InputLabelProps={{ shrink: true }}
						value={binCard}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Switching Code"
						variant="outlined"
						onChange={(text) => setSwitchingCode(text.target.value)}
						placeholder="Switching Code"
						InputLabelProps={{ shrink: true }}
						value={switchingCode}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Card Group"
						variant="outlined"
						onChange={(text) => setCardGroup(text.target.value)}
						placeholder="Card Group"
						InputLabelProps={{ shrink: true }}
						value={cardGroup}
					/>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndexCardType}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectCardType"
								options={cardTypeList}
								inputValue={selectedCardtype}
								value={selectedCardtype}
								onChange={handleChangeCardType}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Card Type"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default IssuingBINPaymentUpdate;
