import {
	Box,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Modal,
	Popover,
	TextField,
	Typography,
} from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { Button } from '../../components';
import { ToastContainer, toast } from 'react-toastify';
import { useStateContext } from '../../contexts/ContextProvider';
import { MdCheckCircle, MdContentCopy } from 'react-icons/md';
import CloseIcon from '@mui/icons-material/Close';
import CopyToClipboard from 'react-copy-to-clipboard';

export const ModalCreateCallback = (props) => {
	const { redColor, modalOpenClose, currentAuth } = useStateContext();

	const [modal, setModal] = useState(false);
	const [data, setData] = useState(null);
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	const notify = (message) => {
		toast.info(message, {
			autoClose: 500,
			position: toast.POSITION.BOTTOM_CENTER,
		});
	};

	const notifyErr = (message) => {
		toast.error(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
		});
	};

	return (
		<>
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={props.isModal}
				onClose={() => {
					setData(null);
					props.onCancel();
				}}
			>
				<DialogTitle color={'green'}>
					<div
						style={{ display: 'flex' }}
						className="justify-start items-center flex-row"
					>
						<p>INVOICE CREATED</p>
						<MdCheckCircle style={{ marginLeft: 10 }} />{' '}
					</div>
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => {
						setData(null);
						props.onCancel();
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<DialogContentText
						style={{ borderBottomWidth: 1, paddingBottom: 10 }}
					>
						Your invoice has been successfully generated
					</DialogContentText>
					<Box
						noValidate
						component="form"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							m: 'auto',
							width: '100%',
						}}
					>
						<div className="w-1/2 d-flex self-end flex-row justify-end align-items-center">
							<p className="font-weight-bold text-xl mt-2 mb-2">
								Bill To
							</p>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">Corporate Name :</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.customerInformation?.customerName ??
										''}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">Corporate Email :</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.customerInformation
										?.customerInitialEmail ?? ''}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Corporate Phone Number :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.customerInformation
										?.customerPhoneNumber ?? ''}
								</p>
							</div>
						</div>

						<div className="w-full d-flex flex-row justify-content-end align-items-center">
							<p className="font-weight-bold text-xl mt-2 mb-2">
								Invoice Data
							</p>

							<div
								style={{ display: 'flex' }}
								className="mt-2 mb-2 flex-row w-full flex-1 items-center"
							>
								<div
									style={{ display: 'flex' }}
									className="flex-1 flex-row w-full items-center"
								>
									<p className="text-sm">Invoice Number :</p>
									<p className="text-lg ml-2">
										{props.data?.merchantNoRef ?? ''}
									</p>
								</div>

								<div
									style={{ display: 'flex' }}
									className="flex-1 flex-row w-full items-center"
								>
									<p className="text-sm">Link Ref :</p>
									<p className="text-lg ml-2">
										{props.data?.requestNo ?? ''}
									</p>
									<CopyToClipboard
										text={
											props.data?.paymentUrl
												? props.data?.paymentUrl
												: ''
										}
										onCopy={(text, result) => {
											setIsCopied(true);
										}}
									>
										<Chip
											style={{ marginLeft: 10, cursor: 'pointer' }}
											icon={<MdContentCopy />}
											label="Copy URL"
											color="primary"
										/>
									</CopyToClipboard>
								</div>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								background: '#F0F6F8',
								borderRadius: 10,
								padding: 10,
							}}
							className="flex-row w-full justify-content-center align-items-center"
						>
							<div className="d-flex flex-1 flex-col">
								<p className="mt-1 mb-1 text-sm">Remarks :</p>
								<p className="mt-1 mb-1 text-lg">
									{props.data?.remarks ?? ''}
								</p>
							</div>
							<div className="d-flex flex-1 flex-col">
								<p className="mt-1 mb-1 text-sm text-right">
									Amount :
								</p>
								<p className="mt-1 mb-1 text-xl text-right">
									{props.data?.amount
										? 'Rp' + props.data?.amount?.format()
										: '-'}
								</p>
								<p
									style={{ fontStyle: 'italic' }}
									className="mt-1 mb-1 text-xs font-weight-bold text-right"
								>
									{props.data?.isExclude
										? 'Note: Amount does not include MDR'
										: 'Note: Amount includes MDR'}
								</p>
							</div>
						</div>

						<div className="w-1/2 d-flex flex-row justify-end align-items-center mt-3">
							<p className="font-weight-bold text-xl mt-2 mb-2">
								Invoice Setup
							</p>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Link Maximum Attemp :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.maxAttempt &&
									props.data?.maxAttempt != 0
										? props.data?.maxAttempt
										: '-'}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Link Expired Date Time :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.expiredPaymentLink
										? props.data?.expiredPaymentLink
										: '-'}
								</p>
							</div>
							<div
								style={{ display: 'flex' }}
								className="d-flex flex-row justify-between"
							>
								<p className="mt-1 mb-1 text-sm">
									Partner Callback URL :
								</p>
								<p className="mt-1 mb-1 text-sm">
									{props.data?.callbackUrl
										? props.data?.callbackUrl
										: '-'}
								</p>
							</div>
						</div>
					</Box>
				</DialogContent>
				{/* <DialogActions>
					<Button
						bgColor={'#ccc'}
						customFunc={() => {
							setModal(false);
							setData(null);
							props.onCancel();
						}}
						color={'white'}
						text={'Close'}
					/>
				</DialogActions> */}
			</Dialog>

			<Popover
				open={isCopied}
				onClose={() => setIsCopied(false)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Typography sx={{ p: 2 }}>
					Payment Link copied to clipboard!
				</Typography>
			</Popover>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</>
	);
};
