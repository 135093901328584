import React, { useEffect, useState } from 'react';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns/src';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL, URL_CARDTYPE_ADD } from '../../../config';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

const CardTypeAdd = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose } =
		useStateContext();

	const navigate = useNavigate();

	const [cardAid, setCardAid] = useState('');
	const [namaJenisKartu, setNamaJenisKartu] = useState('');
	const [idTipeTransaksi, setIdTipeTransaksi] = useState(null);
	const [status, setStatus] = useState(false);

	const [dataTipeTransaksi, setDataTipeTransaksi] = useState([
		{ value: 1, label: 'DEBIT' },
		{ value: 2, label: 'CREDIT' },
	]);

	const dataUpdate = (e) => {
		e.preventDefault();

		modalOpenClose(true);

		const inputData = {
			cardAid: cardAid,
			namaJenisKartu: namaJenisKartu,
			idTipeTransaksi: idTipeTransaksi,
		};

		fetch(BASE_URL + URL_CARDTYPE_ADD, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				notifyErr(error.message);
				modalOpenClose(false);
			});
	};

	const currentIndex =
		dataTipeTransaksi && dataTipeTransaksi.length > 0
			? dataTipeTransaksi.findIndex((item) => {
					return item.value === idTipeTransaksi;
			  })
			: 0;

	const handleChangeTransactionType = (event, values) => {
		if (values != null) {
			setIdTipeTransaksi(values.value);
		} else {
			setIdTipeTransaksi(null);
		}
	};

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="New Card Type" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Card AID"
						variant="outlined"
						onChange={(text) => setCardAid(text.target.value)}
						placeholder="Card AID"
						InputLabelProps={{ shrink: true }}
						value={cardAid}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Card Type Name"
						variant="outlined"
						onChange={(text) => setNamaJenisKartu(text.target.value)}
						placeholder="Card Type Name"
						InputLabelProps={{ shrink: true }}
						value={namaJenisKartu}
					/>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndex}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectIssuing"
								options={dataTipeTransaksi}
								onChange={handleChangeTransactionType}
								defaultValue={
									dataTipeTransaksi
										? dataTipeTransaksi[currentIndex]
										: null
								}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Transaction Type"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default CardTypeAdd;
