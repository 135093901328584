import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import {
	BASE_URL,
	URL_GATEWAT_DEBIT_SEGMEN_DETAIL_LIST,
} from '../../../config';
import { CorpAction } from './DataTableComponent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdArrowBack, MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';

import _ from 'lodash';
import { checkIsAvailableModule } from '../../../Routes';
import ToggleText from '../../../components/ToggleText';
import { urlDecrypt } from '../../../utils/CryptoUtils';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const GatewaySegmentasiDetail = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		privilege,
		screenWidth,
	} = useStateContext();

	const { Id } = useParams();

	const navigate = useNavigate();
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');

	const [pending, setPending] = useState(false);

	// get Data Corporate

	const [dataMerchant, setDataMerchant] = useState(null);
	const [dataCorp, setDataCorp] = useState([]);

	const getDataCorp = (data) => {
		modalOpenClose(true);

		let mData = {
			idRouteSegmen: data ? data.idRouteSegmen : null,
		};

		fetch(BASE_URL + URL_GATEWAT_DEBIT_SEGMEN_DETAIL_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				modalOpenClose(false);
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.success && data.result != null) {
						let shortName = _.orderBy(
							data.result,
							['kodeBankIssuing'],
							['desc']
						);
						setDataCorp(shortName);
					} else {
						setDataCorp([]);
					}
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				console.log('err =>', err.message);
				setDataCorp([]);
			});
	};
	// list corporate Column
	const [corpColumn, setCorpColum] = useState([
		{
			name: 'Issuing',
			selector: '',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText
					minToggle={200}
					text={row.kodeBankIssuing + ' - ' + row.namaBankIssuing}
				/>
			),
		},
		{
			name: 'Type',
			selector: 'idTipeTransaksi',
			sortable: true,
			width: 100,
			cell: (row) => (
				<ToggleText
					minToggle={200}
					text={row.idTipeTransaksi == 1 ? 'DEBIT' : 'CREDIT'}
				/>
			),
		},
		{
			name: 'Acquiring',
			selector: '',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText
					minToggle={200}
					text={row.kodeBankAcquiring + ' - ' + row.namaBankAcquiring}
				/>
			),
		},
		{
			name: 'Trans Type',
			selector: 'namaJenisTransaksi',
			sortable: true,
			width: 200,
		},
		{
			name: 'MDR',
			selector: 'mdrPercentage',
			sortable: true,
			width: 100,
			cell: (row) => <div>{row.mdrPercentage + ' %'}</div>,
		},
		{
			name: 'Fee',
			selector: 'transactionFee',
			sortable: true,
			width: 100,
			cell: (row) => <div>{row.transactionFee}</div>,
		},
		{
			name: 'PID',
			selector: 'paymentPid',
			sortable: true,
			width: 100,
			cell: (row) => <div>{row.paymentPid}</div>,
		},
	]);

	const searchData = () => {
		if (search) {
			let mData = _.cloneDeep(dataCorp);
			return mData.filter(
				(o) =>
					(o.kodeBankIssuing + ' - ' + o.namaBankIssuing)
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					(o.idTipeTransaksi == 1 ? 'DEBIT' : 'CREDIT')
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					(o.kodeBankAcquiring + ' - ' + o.namaBankAcquiring)
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					o.namaJenisTransaksi
						.toLowerCase()
						.includes(search.toLowerCase())
			);
		} else {
			return dataCorp;
		}
	};

	useEffect(() => {
		if (dataMerchant) {
			if (
				checkIsAvailableModule(
					'EDIT_GATEWAY_DEBIT_SEGMENTASI_DETAIL',
					privilege
				) &&
				checkIsAvailableModule(
					'DELETE_GATEWAY_DEBIT_SEGMENTASI_DETAIL',
					privilege
				)
			) {
				let addColumn = _.cloneDeep(corpColumn);
				addColumn.unshift({
					name: 'Action', //useTemplate
					selector: '',
					sortable: true,
					width: 100,
					cell: (row) => {
						row.idRouteSegmen = dataMerchant
							? dataMerchant.idRouteSegmen
							: '';
						row.namaRouteSegmen = dataMerchant
							? dataMerchant.namaRouteSegmen
							: '';
						return CorpAction(row, () => getDataCorp(dataMerchant));
					},
				});

				setCorpColum(addColumn);
			}
		}
	}, [dataMerchant]);

	useEffect(() => {
		if (Id) {
			var data = urlDecrypt(Id);

			if (data != null) {
				console.log('DATA', data);
				setDataMerchant(data);
				getDataCorp(data);
			}
		}
	}, [Id]);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className="flex justify-between">
				<Header title={'Debit Segmentation Detail'} />
				{checkIsAvailableModule(
					'ADD_GATEWAY_DEBIT_SEGMENTASI_DETAIL',
					privilege
				) && (
					<Button
						customFunc={() =>
							navigate('/add-gateway-debit-segmentasi-detail/' + Id)
						}
						text="Add New +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>

			<div className="">
				<div className=" flex">
					<div className="">
						<p className="mb-3">Segmentation ID</p>
						<p className="mb-3">Segmentation Name</p>
					</div>

					<div className=" ml-10 md:ml-10">
						<p className="mb-3">
							: {dataMerchant && dataMerchant.idRouteSegmen}
						</p>
						<p className="mb-3">
							: {dataMerchant && dataMerchant.namaRouteSegmen}
						</p>
					</div>
				</div>
			</div>

			<div className=" mt-5 md:-mt-5 flex md:justify-end">
				<div className=" w-full  md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									setSearch(keyword);
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearch(keyword)}
										edge="end"
									>
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={corpColumn}
					data={searchData()}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					// paginationServer
					// paginationTotalRows={count}
					// paginationDefaultPage={currentPage}
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					// onSort={customSort}
					// sortServer
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<div className=" flex mt-5 justify-between">
				<div className=" mr-5">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Previous Page"
						color={'white'}
						type="button"
						icon={<MdArrowBack />}
					/>
				</div>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default GatewaySegmentasiDetail;
