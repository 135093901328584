import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import {
	BASE_URL,
	URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_LIST,
	URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_LIST,
} from '../../../config';
import { CorpAction } from './DataTableComponent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdArrowBack, MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';

import _ from 'lodash';
import { checkIsAvailableModule } from '../../../Routes';
import ToggleText from '../../../components/ToggleText';
import { urlDecrypt } from '../../../utils/CryptoUtils';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const GatewayMerchantAcquiringDevice = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		privilege,
		screenWidth,
		dataLogin,
	} = useStateContext();

	const { Id } = useParams();

	const navigate = useNavigate();
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');

	const [pending, setPending] = useState(false);

	// get Data Corporate

	const [dataMerchant, setDataMerchant] = useState(null);
	const [dataCorp, setDataCorp] = useState([]);

	const getDataCorp = (data) => {
		modalOpenClose(true);

		let mData = {
			merchantKey: data.merchantKey,
			kodeBankAcquiring: data.kodeBankAcquiring,
			idTipeTransaksi: data.idTipeTransaksi,
		};

		fetch(
			BASE_URL + URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_DEVICE_LIST,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${currentAuth}`,
				},
				body: JSON.stringify(mData),
			}
		)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				modalOpenClose(false);
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.success && data.result != null) {
						setDataCorp(data.result);
					} else {
						setDataCorp([]);
					}
				}
			})
			.catch((err) => {
				modalOpenClose(false);
				console.log('err =>', err.message);
				setDataCorp([]);
			});
	};
	// list corporate Column
	const [corpColumn, setCorpColum] = useState([
		{
			name: 'Acquiring',
			selector: '',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText
					minToggle={200}
					text={row.kodeBankAcquiring + ' - ' + row.namaBankAcquiring}
				/>
			),
		},
		{
			name: 'Type',
			selector: 'idTipeTransaksi',
			sortable: true,
			width: 100,
			cell: (row) => (
				<ToggleText
					minToggle={200}
					text={row.idTipeTransaksi == 1 ? 'DEBIT' : 'CREDIT'}
				/>
			),
		},
		{
			name: 'Device ID',
			selector: 'deviceId',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText minToggle={200} text={row.deviceId} />
			),
		},
		{
			name: 'Bank TID',
			selector: 'bankTid',
			sortable: true,
			width: 100,
			cell: (row) => (
				<ToggleText minToggle={200} text={row.bankTid} />
			),
		},
		{
			name: 'MKP TID',
			selector: 'mkpTid',
			sortable: true,
			width: 100,
			cell: (row) => <ToggleText minToggle={200} text={row.mkpTid} />,
		},
		{
			name: 'Main Key',
			selector: 'mainKey',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText minToggle={150} text={row.mainKey} />
			),
		},
		{
			name: 'Working Key',
			selector: 'workingKey',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText minToggle={150} text={row.workingKey} />
			),
		},
		{
			name: 'MAK Key',
			selector: 'makkey',
			sortable: true,
			width: 200,
			cell: (row) => <ToggleText minToggle={150} text={row.makkey} />,
		},
		{
			name: 'DEK KEY',
			selector: 'dekkey',
			sortable: true,
			width: 200,
			cell: (row) => <ToggleText minToggle={150} text={row.dekkey} />,
		},
		{
			name: 'ELTMK',
			selector: 'eltmk',
			sortable: true,
			width: 200,
			cell: (row) => <ToggleText minToggle={150} text={row.eltmk} />,
		},
		{
			name: 'LTWK ID',
			selector: 'ltwkid',
			sortable: true,
			width: 200,
			cell: (row) => <ToggleText minToggle={150} text={row.ltwkid} />,
		},
	]);

	const searchData = () => {
		if (search) {
			let mData = _.cloneDeep(dataCorp);
			return mData.filter(
				(o) =>
					(o.kodeBankAcquiring + ' - ' + o.namaBankAcquiring)
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					(o.idTipeTransaksi == 1 ? 'DEBIT' : 'CREDIT')
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					o.deviceId.toLowerCase().includes(search.toLowerCase()) ||
					o.bankTid.toLowerCase().includes(search.toLowerCase()) ||
					o.mkpTid.toLowerCase().includes(search.toLowerCase()) ||
					o.mainKey.toLowerCase().includes(search.toLowerCase()) ||
					o.makkey.toLowerCase().includes(search.toLowerCase()) ||
					o.dekkey.toLowerCase().includes(search.toLowerCase()) ||
					o.eltmk.toLowerCase().includes(search.toLowerCase()) ||
					o.ltwkid.toLowerCase().includes(search.toLowerCase())
			);
		} else {
			return dataCorp;
		}
	};

	useEffect(() => {
		if (
			checkIsAvailableModule(
				'EDIT_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
				privilege
			)
			// &&
			// checkIsAvailableModule(
			// 	'DELETE_GATEWAY_DEBIT_MERCHANT',
			// 	privilege
			// )
		) {
			let addColumn = _.cloneDeep(corpColumn);
			addColumn.unshift({
				name: 'Action', //useTemplate
				selector: '',
				sortable: true,
				width: 100,
				cell: (row) => CorpAction(row, () => getDataCorp()),
			});

			setCorpColum(addColumn);
		}
	}, []);

	useEffect(() => {
		if (Id) {
			var data = urlDecrypt(Id);

			if (data != null) {
				console.log('DATA', data);
				setDataMerchant(data);
				getDataCorp(data);
			}
		}
	}, [Id]);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className="flex justify-between">
				<Header title={'Acquiring Gateway Device '} />
				{checkIsAvailableModule(
					'ADD_GATEWAY_DEBIT_MERCHANT_ACQUIRING_DEVICE',
					privilege
				) && (
					<Button
						customFunc={() =>
							navigate(
								'/add-gateway-debit-merchant-acquiring-device/' + Id
							)
						}
						text="Add New +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>

			<div className="">
				<div className=" flex">
					<div className="">
						<p className="mb-3">Merchant Key</p>
						<p className="mb-3">Acquiring</p>
					</div>

					<div className=" ml-10 md:ml-10">
						<p className="mb-3">
							: {dataMerchant && dataMerchant.merchantKey}
						</p>
						<p className="mb-3">
							:{' '}
							{dataMerchant &&
								dataMerchant.kodeBankAcquiring +
									' - ' +
									dataMerchant.namaBankAcquiring +
									' - ' +
									(dataMerchant.idTipeTransaksi == 1
										? 'DEBIT'
										: 'CREDIT')}
						</p>
					</div>
				</div>
			</div>

			<div className=" mt-5 md:-mt-5 flex md:justify-end">
				<div className=" w-full  md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									setSearch(keyword);
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearch(keyword)}
										edge="end"
									>
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={corpColumn}
					data={searchData()}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					// paginationServer
					// paginationTotalRows={count}
					// paginationDefaultPage={currentPage}
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					// onSort={customSort}
					// sortServer
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<div className=" flex mt-5 justify-between">
				<div className=" mr-5">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Previous Page"
						color={'white'}
						type="button"
						icon={<MdArrowBack />}
					/>
				</div>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default GatewayMerchantAcquiringDevice;
