import React, { useState, useEffect } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL } from '../../../config';
import { Button } from '../../../components';
import { FiRefreshCw } from 'react-icons/fi';
import { MdSearch } from 'react-icons/md';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Tab,
	Select,
	MenuItem,
	InputLabel,
	Box,
	Tooltip,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import {
	Copy,
	SoundBoxListTrxAction,
} from '../DataTableComponent/DataTableComponent';
import { checkIsAvailableModule } from '../../../Routes';
import DataTable from 'react-data-table-component';
import notfound from '../../../data/notfound.png';
import TextField from '@mui/material/TextField';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import axios from 'axios';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '8px',
			paddingRight: '8px',
		},
	},
};

const SoundboxList = () => {
	// context states
	const {
		currentAuth,
		setAuth,
		activeMenu,
		screenWidth,
		modalOpenClose,
		currentColor,
		privilege,
	} = useStateContext();

	const navigate = useNavigate();

	// request config
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: `Bearer ${currentAuth}`,
		},
	};

	// tab state
	const [tabIndex, setTabIndex] = useState('1');
	const handleChangeTab = (event, newValue) => {
		setTabIndex(newValue);
	};

	// table state data
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	// states initialization
	const [corporateData, setCorporateData] = useState([]);
	const [deviceData, setDeviceData] = useState([]);
	const [trxData, setTrxData] = useState([]);

	// corporate filter states
	const [corporateFilter, setCorporateFilter] = useState({
		coprorateID: '',
		corporateName: '',
		corporateCID: '',
		corporateHirarki: '',
		merchantKey: '',
	});
	// device filter states
	const [deviceFilter, setDeviceFilter] = useState({
		deviceSN: '',
		deviceCorporateID: '',
		qrType: '',
		corporateHierarchy: '',
		isActive: null,
	});

	const [trxFilterInput, setTrxFilterInput] = useState({
		label: '',
		value: '',
	});

	// trx filter states
	const [trxFilter, setTrxFilter] = useState({
		startDate: moment().subtract(6, 'd').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		startNotificationDate: moment()
			.subtract(6, 'd')
			.format('YYYY-MM-DD'),
		endNotificationDate: moment().format('YYYY-MM-DD'),
	});
	// keyword filter
	const [keyword, setKeyword] = useState('');

	// headers
	const corporateHeaders = [
		{
			name: 'Corporate CID',
			selector: (row) => row['corporateCID'],
			sortable: true,
			width: 190,
			cell: (row) => <div className=" ">{row.corporateCID}</div>,
		},
		{
			name: 'Hirarki',
			selector: (row) => row['corporateHirarki'],
			sortable: true,
			width: 350,
			cell: (row) => <div className=" ">{row.corporateHirarki}</div>,
		},
		{
			name: 'Corporate Name',
			selector: (row) => row['corporateName'],
			sortable: true,
			width: 250,
			cell: (row) => <div className=" ">{row.corporateName}</div>,
		},
		{
			name: 'Callback URL',
			selector: (row) => row['defaultCallbackURL'],
			sortable: true,
			width: 250,
			cell: (row) => (
				<div className=" ">{row.defaultCallbackURL}</div>
			),
		},
		{
			name: 'Merchant Key',
			selector: (row) => row['merchantKey'],
			sortable: true,
			width: 200,
		},
		{
			name: '',
			cell: (row) => (
				<Copy label="Merchant Key" value={row.merchantKey} />
			),
		},
	];
	const deviceHeaders = [
		{
			name: 'Serial Number',
			selector: (row) => row['deviceSN'],
			sortable: true,
			width: 200,
			cell: (row) => <div className=" ">{row.deviceSN}</div>,
		},
		{
			name: 'Active',
			selector: (row) => row['isActive'],
			sortable: true,
			width: 150,
			cell: (row) => (
				<div
					className={`font-medium ${
						row.isActive ? 'text-green-600' : 'text-red-600'
					}`}
				>
					{row.isActive ? 'Active' : 'Inactive'}
				</div>
			),
		},
		{
			name: 'QR Type',
			selector: (row) => row['qrType'],
			sortable: true,
			width: 200,
			cell: (row) => <div className=" ">{row.qrType}</div>,
		},
		{
			name: 'Device Type',
			selector: (row) => row['deviceType'],
			sortable: true,
			width: 150,
			cell: (row) => (
				<div className=" ">
					{row.deviceType === 'D'
						? 'Dynamic'
						: row.deviceType === 'S'
						? 'Static'
						: '-'}
				</div>
			),
		},
	];
	const [trxHeaders, setTrxHeaders] = useState([
		{
			name: 'Action', //useTemplate
			selector: '',
			width: 100,
			cell: (row) => <SoundBoxListTrxAction row={row} />,
		},
		{
			name: 'Transaction Number',
			selector: (row) => row['transactionNumber'],
			sortable: true,
			width: 200,
			cell: (row) => <div className=" ">{row.transactionNumber}</div>,
		},
		{
			name: 'Request Type',
			selector: (row) => row['requestType'],
			sortable: true,
			width: 150,
			cell: (row) => <div className=" ">{row.requestType}</div>,
		},
		// corporate, created at, desc, device status, merchantnoref, notif status, workerduration, request_type, transaction_number, amount, callback time, callback duration
		{
			name: 'Corporate ID',
			selector: (row) => row['corporateCID'],
			sortable: true,
			width: 150,
			cell: (row) => <div className=" ">{row.corporateCID}</div>,
		},
		{
			name: 'Corporate Name',
			selector: (row) => row['corporateName'],
			sortable: true,
			width: 150,
			cell: (row) => <div className=" ">{row.corporateName}</div>,
		},
		{
			name: 'Created At',
			selector: (row) => row['createdAt'],
			sortable: true,
			width: 200,
			cell: (row) => (
				<div className=" ">
					{row.createdAt !== '-'
						? moment(row.createdAt).format('D MMMM YYYY HH:mm:ss')
						: row.createdAt}
				</div>
			),
		},
		{
			name: 'Desc',
			selector: (row) => row['description'],
			sortable: true,
			width: 150,
			cell: (row) => <div className=" ">{row.description}</div>,
		},
		{
			name: 'Merchant Ref',
			selector: (row) => row['merchantNoRef'],
			sortable: true,
			width: 200,
			cell: (row) => <div className=" ">{row.merchantNoRef}</div>,
		},
		{
			name: 'Notif Status',
			selector: (row) => row['notificationStatus'],
			sortable: true,
			width: 150,
			cell: (row) => {
				let mStatusColor = 'text-gray-600';
				let title = '';

				switch (row.notificationStatus) {
					case 'SENT':
						mStatusColor = 'text-green-600';
						title =
							'QR/Sound request has been sent to soundbox server but not received by device';
						break;

					case 'SUCCESS':
						mStatusColor = 'text-blue-600';
						title =
							'QR/Sound successfully delivered and shown/sounds on device';
						break;

					case 'FAILED':
						mStatusColor = 'text-red-600';
						title = 'Failed sending qr/sound to soundbox server';
						break;

					case 'PENDING':
						mStatusColor = 'text-yellow-600';
						title = 'QR/Sound request is being processed by system';
						break;

					default:
						mStatusColor = 'text-gray-600';
						title = 'Notification duration';
						break;
				}

				return (
					<div className={`font-medium ${mStatusColor}`}>
						<Tooltip title={title}>{row.notificationStatus}</Tooltip>
					</div>
				);
			},
		},
		{
			name: 'Device Status',
			selector: (row) => row['deviceStatus'],
			sortable: true,
			width: 150,
			cell: (row) => {
				return (
					<div
						className={`font-medium ${
							row.deviceStatus ? 'text-green-600' : 'text-red-600'
						}`}
					>
						{row.deviceStatus ? 'Active' : 'Inactive'}
					</div>
				);
			},
		},
		{
			name: 'Process Duration',
			selector: (row) => row['merchantNoRef'],
			sortable: true,
			width: 200,
			cell: (row) => (
				<div className=" ">
					<Tooltip title="QR/Sound processing duration">
						{row.requestType == 'SOUND'
							? row.notificationWorkerDuration + ' ms'
							: row.qrWorkerDuration + ' ms'}
					</Tooltip>
				</div>
			),
		},
		{
			name: 'Fulfillment Time',
			selector: (row) => row['soundCallbackTime'],
			sortable: true,
			width: 200,
			cell: (row) => {
				return (
					<div className=" ">
						{row.soundCallbackTime && row.soundCallbackTime !== '-'
							? moment(row.soundCallbackTime).format(
									'D MMMM YYYY HH:mm:ss'
							  )
							: row.soundCallbackTime}
					</div>
				);
			},
		},
		{
			name: 'Fulfillment Duration',
			selector: (row) => row['callbackSoundDuration'],
			sortable: true,
			width: 200,
			cell: (row) => (
				<div className=" ">
					<Tooltip
						title={`Notification fulfillment time takes ${
							row.callbackSoundDuration +
							' ms to get data back from the device'
						}`}
					>
						{row.callbackSoundDuration + ' ms'}
					</Tooltip>
				</div>
			),
		},
	]);

	// fetch table data function
	const getTableData = async (page, totalData = limit) => {
		modalOpenClose(true);

		const corporateBody = {
			id: parseInt(corporateFilter.coprorateID),
			corporateName: corporateFilter.corporateName,
			corporateCID: corporateFilter.corporateCID,
			corporateHirarki: corporateFilter.corporateHirarki,
			merchantKey: corporateFilter.merchantKey,
			keyword,
		};
		const deviceBody = {
			deviceSN: deviceFilter.deviceSN,
			corporateID: parseInt(deviceFilter.deviceCorporateID),
			qrType: deviceFilter.qrType.toUpperCase(),
			corporateHierarchy: deviceFilter.corporateHierarchy,
			isActive: deviceFilter.isActive,
			keyword,
		};
		const trxBody = {
			startDate: moment(trxFilter.startDate).format('YYYY-MM-DD'),
			endDate: moment(trxFilter.endDate).format('YYYY-MM-DD'),
			// startNotificationDate: moment(
			// 	trxFilter.startNotificationDate
			// ).format('YYYY-MM-DD'),
			// endNotificationDate: moment(
			// 	trxFilter.endNotificationDate
			// ).format('YYYY-MM-DD'),
			startNotificationDate: '',
			endNotificationDate: '',
			// limit: totalData,
			// page: parseInt(page),
			limit: 0,
			page: 0,
			data: {
				corporateCID: '',
				corporateHierarchy: '',
				transactionNumber:
					trxFilterInput &&
					trxFilterInput.label == 'transactionNumber'
						? trxFilterInput.value
						: '',
				qrType: '',
				deviceSN:
					trxFilterInput && trxFilterInput.label == 'deviceSN'
						? trxFilterInput.value
						: '',
				notificationStatusCode: '',
				merchantKey: '',
				requestType: '',
			},
		};

		try {
			const res = await axios.post(
				BASE_URL +
					`/soundbox-dashboard/${
						tabIndex === '1'
							? 'corporate'
							: tabIndex === '2'
							? 'device'
							: 'trx'
					}/list`,
				tabIndex === '1'
					? corporateBody
					: tabIndex === '2'
					? deviceBody
					: trxBody,
				config
			);

			if (res.data.result !== null) {
				if (tabIndex === '1') {
					setCorporateData(res.data.result);
				} else if (tabIndex === '2') {
					setDeviceData(res.data.result);
				} else {
					const mappedData = res.data.result.map((data) => ({
						...data,
						transactionNumber: !data.transactionNumber
							? '-'
							: data.transactionNumber,
						requestType: !data.requestType ? '-' : data.requestType,
						createdAt: !data.createdAt ? '-' : data.createdAt,
					}));
					setTrxData(mappedData);
				}
				setCount(res.data.result.length);
			} else {
				setCorporateData([]);
				setDeviceData([]);
				setTrxData([]);
			}
		} catch (e) {
			if (e.response.data.statusCode === '401') {
				setAuth(null);
				navigate('/');
				localStorage.removeItem('auth');
			}
			console.error('Error : ', e);
		} finally {
			modalOpenClose(false);
		}
	};

	// table handler
	const handlePerRowsChange = (totalData, page) => {
		// tabIndex === '3' && getTableData(page, totalData);
		setLimit(totalData);
	};
	const handlePageChange = (page) => {
		// tabIndex === '3' && getTableData(page);
	};

	// fetch new data everytime tab change
	useEffect(() => {
		getTableData(1);
	}, [tabIndex]);

	// check if action config privilege exist
	useEffect(() => {
		if (
			!checkIsAvailableModule('VIEW_SOUNDBOX_LIST_DETAIL', privilege)
		) {
			setTrxHeaders((prev) =>
				prev.filter((item) => item.name !== 'Action')
			);
		}
	}, []);

	return (
		<div className={`${activeMenu ? '-' : ''}`}>
			{/* Filter Card */}
			<div className="h-fit m-2 mt-16 md:m-10 p-6 md:p-10 mb-0 md:rounded-3xl bg-white max-w-screen md:max-w-full">
				{/* title */}
				<p className="font-semibold mb-7">Filter Data</p>
				{/* filter fields*/}
				<div className="grid grid-cols-12 gap-5">
					{/* filter field changes every different tab */}
					{tabIndex === '1' && (
						<>
							<div className="col-span-6">
								<TextField
									className="w-full"
									id="corporateCID"
									label="Corporate CID"
									variant="outlined"
									onChange={(e) =>
										setCorporateFilter((prev) => ({
											...prev,
											corporateCID: e.target.value,
										}))
									}
									placeholder="Corporate CID"
									InputLabelProps={{ shrink: true }}
									value={corporateFilter.corporateCID}
								/>
							</div>
							<div className="col-span-6">
								<TextField
									className="w-full"
									id="corporateName"
									label="Corporate Name"
									variant="outlined"
									onChange={(e) =>
										setCorporateFilter((prev) => ({
											...prev,
											corporateName: e.target.value,
										}))
									}
									placeholder="Corporate Name"
									InputLabelProps={{ shrink: true }}
									value={corporateFilter.corporateName}
								/>
							</div>

							<div className="col-span-6">
								<TextField
									className="w-full"
									id="merchantKey"
									label="Merchant Key"
									variant="outlined"
									onChange={(e) =>
										setCorporateFilter((prev) => ({
											...prev,
											merchantKey: e.target.value,
										}))
									}
									placeholder="Merchant Key"
									InputLabelProps={{ shrink: true }}
									value={corporateFilter.merchantKey}
								/>
							</div>
						</>
					)}
					{tabIndex === '2' && (
						<>
							<div className="col-span-6">
								<TextField
									className="w-full"
									id="deviceSN"
									label="Device Serial Number"
									variant="outlined"
									onChange={(e) =>
										setDeviceFilter((prev) => ({
											...prev,
											deviceSN: e.target.value,
										}))
									}
									placeholder="Device Serial Number"
									InputLabelProps={{ shrink: true }}
									value={deviceFilter.deviceSN}
								/>
							</div>
							<div className="col-span-6">
								<TextField
									className="w-full"
									id="corporateID"
									label="Corporate ID"
									variant="outlined"
									onChange={(e) =>
										setDeviceFilter((prev) => ({
											...prev,
											deviceCorporateID: e.target.value,
										}))
									}
									placeholder="Corporate ID"
									InputLabelProps={{ shrink: true }}
									value={deviceFilter.deviceCorporateID}
								/>
							</div>
							<div className="col-span-6">
								<TextField
									className="w-full"
									id="qrType"
									label="QR Type"
									variant="outlined"
									onChange={(e) =>
										setDeviceFilter((prev) => ({
											...prev,
											qrType: e.target.value,
										}))
									}
									placeholder="QR Type"
									InputLabelProps={{ shrink: true }}
									value={deviceFilter.qrType}
								/>
							</div>
							{/* <div className="col-span-6">
								<TextField
									className="w-full"
									id="corporateHierarchy"
									label="Corporate Hirarki"
									variant="outlined"
									onChange={(e) =>
										setDeviceFilter((prev) => ({
											...prev,
											corporateHierarchy: e.target.value,
										}))
									}
									placeholder="Corporate Hirarki"
									InputLabelProps={{ shrink: true }}
									value={deviceFilter.corporateHierarchy}
								/>
							</div> */}
							<div className="col-span-6">
								<FormControl fullWidth>
									<InputLabel id="isActive">Is Active</InputLabel>
									<Select
										labelId="isActive"
										id="isActive"
										value={deviceFilter.isActive}
										label="Is Active"
										onChange={(e) => {
											setDeviceFilter((prev) => ({
												...prev,
												isActive: e.target.value,
											}));
										}}
									>
										<MenuItem value={null}>All</MenuItem>
										<MenuItem value={true}>Active</MenuItem>
										<MenuItem value={false}>Inactive</MenuItem>
									</Select>
								</FormControl>
							</div>
						</>
					)}
					{tabIndex === '3' && (
						<>
							<div className="col-span-6">
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<DatePicker
										className=" w-full"
										label="Start Date"
										value={trxFilter.startDate}
										onChange={(newValue) => {
											setTrxFilter((prev) => ({
												...prev,
												startDate: newValue,
											}));
										}}
										inputFormat="DD-MM-YYYY"
										maxDate={moment().format('YYYY-MM-DD')}
										renderInput={(params) => (
											<TextField {...params} />
										)}
									/>
								</LocalizationProvider>
							</div>
							<div className="col-span-6">
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<DatePicker
										className=" w-full"
										label="End Date"
										value={trxFilter.endDate}
										onChange={(newValue) => {
											setTrxFilter((prev) => ({
												...prev,
												endDate: newValue,
											}));
										}}
										inputFormat="DD-MM-YYYY"
										minDate={trxFilter.startDate}
										maxDate={
											moment(trxFilter.startDate)
												.add(1, 'months')
												.isAfter(trxFilter.endDate)
												? moment().format('YYYY-MM-DD')
												: moment(trxFilter.startDate).add(1, 'months')
										}
										renderInput={(params) => (
											<TextField {...params} />
										)}
									/>
								</LocalizationProvider>
							</div>
							{/* <div className="col-span-6">
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<DatePicker
										className=" w-full"
										label="Start Notification Date"
										value={trxFilter.startNotificationDate}
										onChange={(newValue) => {
											setTrxFilter((prev) => ({
												...prev,
												startNotificationDate: newValue,
											}));
										}}
										inputFormat="DD-MM-YYYY"
										maxDate={trxFilter.endNotificationDate}
										renderInput={(params) => (
											<TextField {...params} />
										)}
									/>
								</LocalizationProvider>
							</div>
							<div className="col-span-6">
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<DatePicker
										className=" w-full"
										label="End Notification Date"
										value={trxFilter.endNotificationDate}
										onChange={(newValue) => {
											setTrxFilter((prev) => ({
												...prev,
												endNotificationDate: newValue,
											}));
										}}
										inputFormat="DD-MM-YYYY"
										minDate={trxFilter.startNotificationDate}
										maxDate={
											moment(trxFilter.startNotificationDate)
												.add(1, 'months')
												.isAfter(trxFilter.endNotificationDate)
												? trxFilter.endNotificationDate
												: moment(trxFilter.startNotificationDate).add(
														1,
														'months'
												  )
										}
										renderInput={(params) => (
											<TextField {...params} />
										)}
									/>
								</LocalizationProvider>
							</div> */}
						</>
					)}
					{/* filter button */}
					<div className="col-span-12">
						<Button
							customFunc={() => {
								getTableData(1);
							}}
							bgColor={currentColor}
							color="white"
							text="Filter"
							borderRadius={'10px'}
							icon={<FiRefreshCw />}
						/>
					</div>
				</div>
			</div>
			{/* Table Card */}
			<div className="h-fit m-2 mt-16 md:m-10 p-6 md:p-10 mb-0 md:rounded-3xl bg-white max-w-screen md:max-w-full">
				{/* search bar */}
				{tabIndex !== '3' && (
					<div className="mt-5 md:-mt-5 flex md:justify-end">
						<div className="w-full md:w-3/12 mb-2 mt-2">
							<FormControl className=" w-full" variant="outlined">
								<OutlinedInput
									size="small"
									notched
									id="outlined-adornment-password"
									type={'text'}
									placeholder="Search"
									onChange={(e) => setKeyword(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											getTableData();
										}
									}}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												onClick={() => getTableData()}
												edge="end"
											>
												<MdSearch />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</div>
					</div>
				)}

				{tabIndex == '3' && (
					<div className="mt-5 md:-mt-5 flex md:justify-end">
						<div className="w-1/2 md:w-3/12 mb-2 mt-2">
							<FormControl fullWidth size="small">
								<InputLabel id="isActive">Filter Label</InputLabel>
								<Select
									labelId="isActive"
									id="isActive"
									size="small"
									value={trxFilterInput.label}
									label="Is Active"
									onChange={(e) => {
										setTrxFilterInput((prev) => ({
											...prev,
											label: e.target.value,
										}));
									}}
								>
									<MenuItem value={'transactionNumber'}>
										Transaction Number
									</MenuItem>
									<MenuItem value={'deviceSN'}>Device SN</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className="w-1/2 md:w-3/12 mb-2 mt-2">
							<FormControl className=" w-full" variant="outlined">
								<OutlinedInput
									size="small"
									notched
									id="outlined-adornment-password"
									type={'text'}
									placeholder="Search"
									onChange={(e) =>
										setTrxFilterInput((prev) => ({
											...prev,
											value: e.target.value,
										}))
									}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											getTableData();
										}
									}}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												onClick={() => getTableData()}
												edge="end"
											>
												<MdSearch />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</div>
					</div>
				)}
				{/* tabs and table */}
				<Box sx={{ width: '100%', typography: 'body1' }}>
					<TabContext value={tabIndex}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChangeTab}>
								<Tab label="Corporates" value={'1'} />
								<Tab label="Devices" value={'2'} />
								<Tab label="Transaction" value={'3'} />
							</TabList>
						</Box>
						{[
							{ data: corporateData, header: corporateHeaders },
							{ data: deviceData, header: deviceHeaders },
							{ data: trxData, header: trxHeaders },
						].map((item, index) => {
							return (
								<TabPanel value={`${index + 1}`} key={index}>
									<DataTable
										noDataComponent={
											<div className=" justify-center">
												<img
													src={notfound}
													style={{
														width: 'auto',
														height: screenWidth >= 500 ? 400 : 200,
													}}
													alt="not found"
												/>
											</div>
										}
										columns={item.header}
										data={item.data}
										pagination
										// paginationServer={tabIndex === '3' ? true : false}
										paginationServer={false}
										paginationTotalRows={count}
										onChangeRowsPerPage={handlePerRowsChange}
										onChangePage={handlePageChange}
										theme="solarized"
										customStyles={customStyles}
										style={{ width: '20%' }}
									/>
								</TabPanel>
							);
						})}
					</TabContext>
				</Box>
			</div>
		</div>
	);
};

export default SoundboxList;
