import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import {
	BASE_URL,
	URL_CARDTYPE_LIST,
	URL_ISSUING_LIST,
} from '../../../config';
import { CorpAction, CorpSyncLoc } from './DataTableComponent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';

import _ from 'lodash';
import { checkIsAvailableModule } from '../../../Routes';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const CardType = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		privilege,
		screenWidth,
	} = useStateContext();

	const navigate = useNavigate();
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [count, setCount] = useState(0);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [pending, setPending] = useState(false);

	// get Data Corporate

	const [dataCorp, setDataCorp] = useState([]);

	const getDataCorp = () => {
		modalOpenClose(true);

		setDataCorp([]);

		fetch(BASE_URL + URL_CARDTYPE_LIST, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						setDataCorp(data.result);
						setCount(data.length);
						modalOpenClose(false);
					} else {
						setDataCorp([]);
						setCount(0);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	// list corporate Column
	const [corpColumn, setCorpColum] = useState([
		{
			name: 'Card AID',
			selector: 'cardAid',
			sortable: true,
			width: 150,
		},
		{
			name: 'Card Type Name',
			selector: 'namaJenisKartu',
			sortable: true,
			width: 300,
		},
		{
			name: 'ID Transaction Type',
			selector: 'idTipeTransaksi',
			sortable: true,
			width: 300,
		},
	]);

	const searchData = () => {
		if (search) {
			let mData = _.cloneDeep(dataCorp);
			return mData.filter(
				(o) =>
					o.cardAid.toLowerCase().includes(search.toLowerCase()) ||
					o.namaJenisKartu
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					o.idTipeTransaksi
						.toLowerCase()
						.includes(search.toLowerCase())
			);
		} else {
			return dataCorp;
		}
	};

	useEffect(() => {
		let addColumn = _.cloneDeep(corpColumn);

		if (checkIsAvailableModule('EDIT_CARD_TYPE', privilege)) {
			addColumn.unshift({
				name: 'Action', //useTemplate
				selector: '',
				sortable: true,
				width: 100,
				cell: (row) => CorpAction(row, () => getDataCorp()),
			});

			setCorpColum(addColumn);
		}

		getDataCorp();
	}, []);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className="flex  justify-between">
				<Header title={'Card Type'} />
				{checkIsAvailableModule('ADD_CARD_TYPE', privilege) && (
					<Button
						customFunc={() => navigate('/add-card-type')}
						text="Add New +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>

			<div className=" mt-5 md:-mt-5 flex md:justify-end">
				<div className=" w-full  md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									setSearch(keyword);
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearch(keyword)}
										edge="end"
									>
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={corpColumn}
					data={searchData()}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					// paginationServer
					// paginationTotalRows={count}
					// paginationDefaultPage={currentPage}
					// onChangeRowsPerPage={handlePerRowsChange}
					// onChangePage={handlePageChange}
					// onSort={customSort}
					// sortServer
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default CardType;
