import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { useEffect } from "react";
import { useState } from "react";
import { BASE_URL } from "../../config";
import { useStateContext } from "../../contexts/ContextProvider";
import { BiDollar } from "react-icons/bi";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  section: {
    backgroundColor: "//#endregionF8F8F8",
    width: "100%",
    padding: 20,
    paddingTop: 20,
    paddingBottom: 20,
    flex: 1,
  },
  section2: {
    backgroundColor: "#ffffff",
    width: "100%",
    padding: 20,
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: "row",
  },
});

const PDFFile = (data) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={{ flexDirection: "row" }}>
          <Text className=" text-2xl ">
            <BiDollar />
          </Text>
          <Text>Summary</Text>
        </View>
        <View style={styles.section}>
          <Text>Periode:</Text>
          <Text>startDate - endDate</Text>
        </View>
        <View style={styles.section2}>
          <Text>Periode:</Text>
          <Text>startDate - endDate</Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default PDFFile;
