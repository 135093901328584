import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { BsChatLeft, BsPersonCircle } from "react-icons/bs";
import { MdKeyboardArrowDown, MdPerson } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <button
    type="button"
    onClick={() => customFunc()}
    style={{ color }}
    className="relative text-xl rounded-full p-3 hover:bg-gray-100"
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
    />
    {icon}
  </button>
);

const Navbar = () => {
  const {
    activeMenu,
    setActiveMenu,
    isClicked,
    setIsClicked,
    handleClick,
    screenSize,
    setScreenSize,
    currentColor,
    currentMode,
    setNavbar,
    currentName,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const clicked = () => {
    setActiveMenu((prevActiveMenu) => !prevActiveMenu);
    setNavbar((prevNavbar) => !prevNavbar);
  };
  const width = window.innerWidth;
  const sidebarWidth = width >= 500 ? 300 : 0;

  return (
    <div
      style={activeMenu ? { width: width - sidebarWidth } : { width: width }}
      className="flex justify-between p-2 md:mx-2 relative bg-inherit  mr-3 mt-3 px-3 md:px-7"
    >
      <div className={` ml-1 md:mr-0`}>
        <NavButton
          title="Menu"
          customFunc={() => clicked()}
          style={{ background: currentColor }}
          color={currentColor}
          icon={<AiOutlineMenu />}
        />
      </div>

      <div className="flex ">
        <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className={`mt-1 flex items-center gap-2 cursor-pointer p-1  hover:bg-gray-100 rounded-lg ${
              activeMenu ? "mr-0" : "md:mr-2 "
            }`}
            onClick={() => handleClick()}
          >
            <BsPersonCircle
              className={`text-2xl`}
              style={{ color: currentColor }}
            />
            <p>
              <span
                className={`text-14 ${
                  currentMode === "Dark" ? " text-gray-200" : "text-black-400"
                } `}
              >
                Hi,
              </span>{" "}
              <span
                className={`font-bold text-14 ml-1 ${
                  currentMode === "Dark" ? " text-gray-200" : "text-black-400"
                } `}
              >
                {currentName}
              </span>
            </p>
            <MdKeyboardArrowDown className=" text-gray-400 text-14 " />
          </div>
        </TooltipComponent>
        {isClicked && <UserProfile />}
      </div>
    </div>
  );
};

export default Navbar;
