import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import {
	Autocomplete,
	CircularProgress,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import { BASE_URL } from '../../../config';
import { checkIsAvailableModule } from '../../../Routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FiRefreshCcw } from 'react-icons/fi';
import { Button } from '../../../components';
import { FaCheck } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';
import 'moment/locale/en-gb'; // Import the English locale
import moment from 'moment';
import axios from 'axios';
import ReusableSummaryCard from '../../../components/ReusableSummaryCard';
import BarChart from '../../../components/Charts/BarChart';
import SendIcon from '@mui/icons-material/Send';
import FunctionsIcon from '@mui/icons-material/Functions';
import ClearIcon from '@mui/icons-material/Clear';
import AreaChart from '../../../components/Charts/AreaChart';

const SoundboxSummary = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		privilege,
		hirarkiUser,
	} = useStateContext();
	const navigate = useNavigate();
	// request config
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: `Bearer ${currentAuth}`,
		},
	};

	// summary card
	const [summaryCardData, setSummaryCardData] = useState([]);
	const [soundSummary, setSoundSummary] = useState([]);
	const [QRSummary, setQRSummary] = useState([]);
	// bar chart states
	const [chartData, setChartData] = useState([]);
	const [QRChartData, setQRChartData] = useState([]);
	const [soundChartData, setSoundChartData] = useState([]);
	const [timePeriod, setTimePeriod] = useState('monthly');
	// area chart state
	const [hourlyChartData, setHourlyChartData] = useState([]);
	const [hourlyQRData, setHourlyQRData] = useState([]);
	const [hourlySoundData, setHourlySoundData] = useState([]);
	const [hourlyDate, setHourlyDate] = useState(
		moment().format('YYYY-MM-DD')
	);
	// corporate filter
	const [corporateData, setCorporateData] = useState([]);
	const [corporateOption, setCorporateOption] = useState([]);
	const [searchTimeoutId, setSearchTimeoutId] = useState(null);
	const [search, setSearch] = useState({
		valueCorporate: '',
		dropdownSearchCorporate: '',
	});

	// loading states
	const [loading, setLoading] = useState({
		loadingCorporate: false,
		loadingChart: false,
		loadingHourly: false,
	});

	// filter state
	const [filter, setFilter] = useState({
		corporate: '',
		startDate: moment().startOf('month').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
	});
	// confirmed date state after filter button is clicked
	const [fixedDate, setFixedDate] = useState({
		fixedStart: moment()
			.startOf('month')
			.format('D MMM YYYY')
			.replace('.', ''),
		fixedEnd: moment().format('D MMM YYYY').replace('.', ''),
	});
	// minimum and maximum of date allowed in the filter
	const minDate = moment(filter.startDate).startOf('month');
	const maxDate = moment(filter.startDate).endOf('month');

	// default body configuration
	const defaultBody = {
		dateStart: moment(filter.startDate).format('YYYY-MM-DD'),
		dateEnd: moment(filter.endDate).format('YYYY-MM-DD'),
		corporateHierarchy: filter.corporate ? filter.corporate : '',
		// corporateHierarchy: '',
		corporateCID: '',
		qrType: '',
		deviceSN: '',
	};

	// fetch summary card data
	const getSummaryCardData = async () => {
		modalOpenClose(true);
		try {
			const res = await axios.post(
				BASE_URL + '/soundbox-dashboard/analytics/summary',
				defaultBody,
				config
			);

			if (res.data.result !== null) {
				setSummaryCardData(res.data.result);
			} else {
				setSummaryCardData([]);
			}
		} catch (e) {
			if (e.response.data.statusCode === '401') {
				setAuth(null);
				navigate('/');
				localStorage.removeItem('auth');
			}
			console.error('Error :', e);
		} finally {
			modalOpenClose(false);
		}
	};
	// fetch chart data
	const getChartData = async () => {
		setLoading((prev) => ({
			...prev,
			loadingChart: true,
		}));
		try {
			const res = await axios.post(
				BASE_URL + `/soundbox-dashboard/analytics/${timePeriod}`,
				defaultBody,
				config
			);

			if (res.data.result !== null) {
				setChartData(res.data.result);
			} else {
				setChartData([]);
			}
		} catch (e) {
			console.error('Error : ', e);
		} finally {
			setLoading((prev) => ({
				...prev,
				loadingChart: false,
			}));
		}
	};
	// fetch hourly chart data
	const getHourlyChartData = async () => {
		const body = {
			...defaultBody,
			dateStart: moment(hourlyDate).format('YYYY-MM-DD'),
			dateEnd: moment(hourlyDate).format('YYYY-MM-DD'),
		};
		setLoading((prev) => ({
			...prev,
			loadingHourly: true,
		}));
		try {
			const res = await axios.post(
				BASE_URL + `/soundbox-dashboard/analytics/hourly`,
				body,
				config
			);

			if (res.data.result !== null) {
				setHourlyChartData(res.data.result);
			} else {
				setHourlyChartData([]);
			}
		} catch (e) {
			console.error('Error : ', e);
		} finally {
			setLoading((prev) => ({
				...prev,
				loadingHourly: false,
			}));
		}
	};

	// fetch corporate data function
	const getCorporateData = async () => {
		const body = {
			// order: 'ASC',
			// limit: 200,
			// page: 1,
			// keyword: search.dropdownSearchCorporate,
			// hirarkiId: hirarkiUser,
			id: 0,
			corporateName: '',
			corporateCID: '',
			corporateHirarki: '',
			merchantKey: '',
			keyword: search.dropdownSearchCorporate,
		};

		try {
			const res = await axios.post(
				BASE_URL + '/soundbox-dashboard/corporate/list',
				body,
				config
			);
			if (res.data.result !== null) {
				setCorporateData(res.data.result);
			} else {
				setCorporateData([]);
			}
		} catch (e) {
			console.error('Error :', e);
		} finally {
			setLoading((prev) => ({
				...prev,
				loadingCorporate: false,
			}));
		}
	};
	const corporateDropdown = () => {
		const data = corporateData.map((item) => ({
			label: item.corporateName,
			value: item.corporateHirarki,
		}));

		setCorporateOption(data);
	};

	// function to split data from api response
	const processSummaryData = () => {
		// reset container state every run
		setSoundSummary([]);
		setQRSummary([]);
		for (const data of summaryCardData) {
			if (data?.requestType === 'SOUND') {
				setSoundSummary([
					{
						logo: (
							<SendIcon
								sx={{
									fontSize: 30,
									color: 'rgb(3 105 161)',
								}}
							/>
						),
						label: <span className="text-sky-700">Sound Sent</span>,
						value: data.sentCount,
						colorBg: 'rgb(224 242 254)',
					},
					{
						logo: <FaCheck color="rgb(4 120 87)" />,
						label: (
							<span className="text-emerald-700">
								Sound Success Sent
							</span>
						),
						value: data.successCount,
						colorBg: 'rgb(209 250 229)',
					},
					{
						logo: <MdCancel color="rgb(190 18 60)" />,
						label: (
							<span className="text-rose-700">Sound Failed Sent</span>
						),
						value: data.failedCount,
						colorBg: 'rgb(255 228 230)',
					},
					{
						logo: (
							<FunctionsIcon
								sx={{
									fontSize: 30,
									color: 'rgb(161 98 7)',
								}}
							/>
						),
						label: (
							<span className="text-yellow-700">
								Sound Total Request
							</span>
						),
						value: data.countRequest,
						colorBg: 'rgb(254 249 195)',
					},
				]);
			} else if (data?.requestType === 'QR') {
				setQRSummary([
					{
						logo: (
							<SendIcon
								sx={{
									fontSize: 30,
									color: 'rgb(3 105 161)',
								}}
							/>
						),
						label: <span className="text-sky-700">QR Sent</span>,
						value: data.sentCount,
						colorBg: 'rgb(224 242 254)',
					},
					{
						logo: <FaCheck color="rgb(4 120 87)" />,
						label: (
							<span className="text-emerald-700">
								QR Success Sent
							</span>
						),
						value: data.successCount,
						colorBg: 'rgb(209 250 229)',
					},
					{
						logo: <MdCancel color="rgb(190 18 60)" />,
						label: (
							<span className="text-rose-700">QR Failed Sent</span>
						),
						value: data.failedCount,
						colorBg: 'rgb(255 228 230)',
					},
					{
						logo: (
							<FunctionsIcon
								sx={{
									fontSize: 30,
									color: 'rgb(161 98 7)',
								}}
							/>
						),
						label: (
							<span className="text-yellow-700">
								QR Total Request
							</span>
						),
						value: data.countRequest,
						colorBg: 'rgb(254 249 195)',
					},
				]);
			}
		}
	};
	const processChartData = () => {
		setSoundChartData([]);
		setQRChartData([]);
		const filterSoundChart = chartData.filter(
			(item) => item.requestType === 'SOUND'
		);
		const filterQRChart = chartData.filter(
			(item) => item.requestType === 'QR'
		);
		console.log('filterSoundChart', filterSoundChart);
		setSoundChartData(filterSoundChart);
		setQRChartData(filterQRChart);
	};
	const processHourlyData = () => {
		setHourlySoundData([]);
		setHourlyQRData([]);
		const filterSoundHourly = hourlyChartData.filter(
			(item) => item.requestType === 'SOUND'
		);
		const filterQRHourly = hourlyChartData.filter(
			(item) => item.requestType === 'QR'
		);
		setHourlySoundData(filterSoundHourly);
		setHourlyQRData(filterQRHourly);
	};

	// fetch corporate and summary card data on mount
	useEffect(() => {
		getCorporateData();
		getSummaryCardData();
	}, []);
	// fetch chart data every time period change and intial mount
	useEffect(() => {
		getChartData();
	}, [timePeriod]);
	// fetch hourly chart data every hourly date change and initial mount
	useEffect(() => {
		getHourlyChartData();
	}, [hourlyDate]);
	// process data from api
	useEffect(() => {
		processSummaryData();
		processChartData();
		processHourlyData();
	}, [summaryCardData, chartData, hourlyChartData]);
	// insert corporate data to dropdown
	useEffect(() => {
		corporateDropdown();
	}, [corporateData]);
	// Cleanup function to clear timeout on unmount
	useEffect(() => {
		return () => {
			if (searchTimeoutId) {
				clearTimeout(searchTimeoutId);
			}
		};
	}, [searchTimeoutId]); // Dependency on searchTimeoutId

	return (
		<div className="p-11 grid grid-cols-12 gap-11">
			{/* filter summary card */}
			<div className="col-span-12 grid grid-cols-10 gap-5">
				{/* filter field */}
				<div className="col-span-9 grid grid-cols-12 gap-5">
					{/* filter by corporate */}
					<div className="col-span-12 md:col-span-4">
						<Autocomplete
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="corporate-option"
							options={corporateOption}
							value={search.valueCorporate}
							freeSolo
							forcePopupIcon={true}
							onChange={(event, newInputValue) => {
								setLoading((prev) => ({
									...prev,
									loadingCorporate: false,
								}));
								setSearch((prev) => ({
									...prev,
									valueCorporate: newInputValue,
								}));
								setFilter((prev) => ({
									...prev,
									corporate: newInputValue?.value,
								}));
							}}
							inputValue={search.dropdownSearchCorporate}
							onInputChange={(event, newInputValue) => {
								setLoading((prev) => ({
									...prev,
									loadingCorporate: true,
								}));
								setSearch((prev) => ({
									...prev,
									dropdownSearchCorporate: newInputValue,
								}));
								// Clear any existing timeout before starting a new one
								if (searchTimeoutId) {
									clearTimeout(searchTimeoutId);
								}

								setSearchTimeoutId(
									setTimeout(() => {
										search.dropdownSearchCorporate &&
											getCorporateData();
									}, 2000)
								);
							}}
							disableClearable // This prop disables the clear button
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Corporate Name"
									placeholder="- All Corporate Name -"
									InputLabelProps={{ shrink: true }}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loading.loadingCorporate ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : (
													search.valueCorporate !== null &&
													search.dropdownSearchCorporate !== '' && (
														<InputAdornment position="end">
															<IconButton
																aria-label="clear"
																onClick={() => {
																	setSearch((prev) => ({
																		...prev,
																		valueCorporate: '',
																		dropdownSearchCorporate: '',
																	}));
																	setFilter((prev) => ({
																		...prev,
																		corporate: '',
																	}));
																}}
																edge="end"
															>
																<ClearIcon />
															</IconButton>
														</InputAdornment>
													)
												)}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</div>
					{/* filter start date */}
					<div className="col-span-12 md:col-span-4">
						<LocalizationProvider
							dateAdapter={AdapterMoment}
							adapterLocale="en-gb"
						>
							<DatePicker
								className=" w-full"
								label="Filter From"
								value={filter.startDate}
								onChange={(newValue) => {
									setFilter((prev) => ({
										...prev,
										startDate: newValue,
									}));
								}}
								inputFormat="DD-MM-YYYY"
								maxDate={moment()}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
					{/* filter end date */}
					<div className="col-span-12 md:col-span-4">
						<LocalizationProvider
							dateAdapter={AdapterMoment}
							adapterLocale="en-gb"
						>
							<DatePicker
								className=" w-full"
								label="Filter To"
								value={filter.endDate}
								onChange={(newValue) => {
									setFilter((prev) => ({
										...prev,
										endDate: newValue,
									}));
								}}
								inputFormat="DD-MM-YYYY"
								minDate={filter.startDate}
								maxDate={
									moment(filter.startDate)
										.endOf('month')
										.isAfter(moment())
										? moment()
										: moment(filter.startDate).endOf('month')
								}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
				</div>
				{/* filter button */}
				<div className="col-span-10 lg:col-span-1 justify-self-end">
					<Button
						disabled={
							moment(filter.endDate).isAfter(maxDate) ||
							moment(filter.endDate).isBefore(minDate)
								? true
								: false
						}
						customFunc={() => {
							getSummaryCardData();
							getChartData();
							setFixedDate({
								fixedStart: moment(filter.startDate)
									.format('D MMM YYYY')
									.replace('.', ''),
								fixedEnd: moment(filter.endDate)
									.format('D MMM YYYY')
									.replace('.', ''),
							});
						}}
						bgColor={currentColor}
						color="white"
						text="Filter"
						borderRadius={'10px'}
						icon={<FiRefreshCcw />}
					/>
				</div>
			</div>

			{/* summary card */}
			<div className="col-span-12 grid grid-cols-12 gap-5">
				{checkIsAvailableModule('VIEW_SOUND_CARD', privilege) && (
					<>
						{soundSummary.length !== 0 && (
							<div className="col-span-12 text-xl font-semibold">
								Sound Summary
							</div>
						)}
						{soundSummary.map((item, index) => (
							<ReusableSummaryCard
								key={index}
								period={`${moment(
									fixedDate.fixedStart,
									'D MMM YYYY'
								).format('DD MMM')} - ${fixedDate.fixedEnd}`}
								nominal={item.value}
								name={item.label}
								logo={item.logo}
								color={currentColor}
								colorBg={item.colorBg}
							/>
						))}
					</>
				)}
				{checkIsAvailableModule('VIEW_QR_CARD', privilege) && (
					<>
						{QRSummary.length !== 0 && (
							<div className="col-span-12 text-xl font-semibold">
								QR Summary
							</div>
						)}
						{QRSummary.map((item, index) => (
							<ReusableSummaryCard
								key={index}
								period={`${moment(
									fixedDate.fixedStart,
									'D MMM YYYY'
								).format('DD MMM')} - ${fixedDate.fixedEnd}`}
								nominal={item.value}
								name={item.label}
								logo={item.logo}
								color={'#4338ca'}
								colorBg={item.colorBg}
							/>
						))}
					</>
				)}
			</div>

			{/* Chart daily, weekly, monthly */}
			<div className="col-span-12 grid grid-cols-12 gap-3 p-5 shadow-md shadow-indigo-100 rounded-3xl">
				{/* card header */}
				<div className="col-span-12 flex justify-between">
					{/* title card */}
					<div>
						<p className="text-xl font-bold">
							{timePeriod.toUpperCase()} CHART
						</p>
						<p>
							Chart of{' '}
							{moment(fixedDate.fixedStart, 'D MMM YYYY').format(
								'DD MMM'
							)}{' '}
							- {fixedDate.fixedEnd}
						</p>
					</div>
					{/* radio button group */}
					<div className="mt-5 md:flex gap-5">
						{/* radio buttons */}
						<div>
							<input
								type="radio"
								id="daily"
								name={'radioGroup'}
								value="daily"
								className="cursor-pointer"
								onChange={(e) => setTimePeriod(e.target.value)}
							/>
							<label
								htmlFor="daily"
								className="ml-2 md:text-base cursor-pointer"
							>
								Daily
							</label>
						</div>
						<div>
							<input
								type="radio"
								id="weekly"
								name={'radioGroup'}
								value="weekly"
								className="cursor-pointer"
								onChange={(e) => setTimePeriod(e.target.value)}
							/>
							<label
								htmlFor="weekly"
								className="ml-2 md:text-base cursor-pointer"
							>
								Weekly
							</label>
						</div>
						<div>
							<input
								type="radio"
								id="monthly"
								name={'radioGroup'}
								value="monthly"
								className="cursor-pointer"
								onChange={(e) => setTimePeriod(e.target.value)}
								defaultChecked={true}
							/>
							<label
								htmlFor="monthly"
								className="ml-2 md:text-base cursor-pointer"
							>
								Monthly
							</label>
						</div>
					</div>
				</div>

				<div className={`col-span-12`}>
					{/* QR Chart Bar */}
					{checkIsAvailableModule('VIEW_QR_CHART', privilege) && (
						<div
							className={
								checkIsAvailableModule('VIEW_SOUND_CHART', privilege)
									? 'col-span-12'
									: 'col-span-12'
							}
						>
							<BarChart
								dateStart={fixedDate.fixedStart}
								dateEnd={fixedDate.fixedEnd}
								loading={loading.loadingChart}
								timePeriod={timePeriod}
								data={QRChartData}
								title="QR"
							/>
						</div>
					)}

					{/* Sound Chart Bar */}
					{checkIsAvailableModule('VIEW_SOUND_CHART', privilege) && (
						<div
							className={
								checkIsAvailableModule('VIEW_QR_CHART', privilege)
									? 'col-span-12'
									: 'col-span-12'
							}
						>
							<BarChart
								dateStart={filter.startDate}
								dateEnd={filter.endDate}
								loading={loading.loadingChart}
								timePeriod={timePeriod}
								data={soundChartData}
								title="SOUND"
							/>
						</div>
					)}
				</div>
			</div>

			<div className="col-span-12">
				{/* Chart hourly Sound */}
				{checkIsAvailableModule('VIEW_SOUND_CHART', privilege) && (
					<div className="col-span-12 p-5 shadow-md shadow-indigo-100 rounded-3xl">
						<div className="col-span-12 flex justify-between mb-5">
							{/* title card */}
							<div>
								<p className="text-xl font-bold">
									SOUND HOURLY CHART
								</p>
								<p>
									Chart of {moment(hourlyDate).format('D MMM YYYY')}
								</p>
							</div>
							{/* date filter*/}
							<div className="">
								<LocalizationProvider
									dateAdapter={AdapterMoment}
									adapterLocale="en-gb"
								>
									<DatePicker
										className=" w-full"
										label="Filter Date"
										value={hourlyDate}
										onChange={(newValue) => {
											setHourlyDate(newValue);
										}}
										inputFormat="DD-MM-YYYY"
										minDate={filter.startDate}
										maxDate={
											moment(filter.startDate)
												.endOf('month')
												.isAfter(moment())
												? moment()
												: moment(filter.startDate).endOf('month')
										}
										renderInput={(params) => (
											<TextField {...params} />
										)}
									/>
								</LocalizationProvider>
							</div>
						</div>
						<div className="col-span-12">
							<AreaChart
								date={hourlyDate}
								loading={loading.loadingHourly}
								data={hourlySoundData}
								title="SOUND"
							/>
						</div>
					</div>
				)}

				{/* Chart hourly QR */}
				{checkIsAvailableModule('VIEW_QR_CHART', privilege) && (
					<div className="col-span-12 p-5 shadow-md shadow-indigo-100 rounded-3xl">
						<div className="col-span-12 flex justify-between mb-5">
							{/* title card */}
							<div>
								<p className="text-xl font-bold">QR HOURLY CHART</p>
								<p>
									Chart of {moment(hourlyDate).format('D MMM YYYY')}
								</p>
							</div>
							{/* date filter */}
							<div className="">
								<LocalizationProvider
									dateAdapter={AdapterMoment}
									adapterLocale="en-gb"
								>
									<DatePicker
										className=" w-full"
										label="Filter Date"
										value={hourlyDate}
										onChange={(newValue) => {
											setHourlyDate(newValue);
										}}
										inputFormat="DD-MM-YYYY"
										minDate={filter.startDate}
										maxDate={
											moment(filter.startDate)
												.endOf('month')
												.isAfter(moment())
												? moment()
												: moment(filter.startDate).endOf('month')
										}
										renderInput={(params) => (
											<TextField {...params} />
										)}
									/>
								</LocalizationProvider>
							</div>
						</div>
						<div className="col-span-12">
							<AreaChart
								date={hourlyDate}
								loading={loading.loadingHourly}
								data={hourlyQRData}
								title="QR"
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SoundboxSummary;
