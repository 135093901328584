import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {
	Autocomplete,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
} from '@mui/material';

import { Button } from '../../components';
import { listTransactionColumn } from '../../data/columnDataBDKI';
import DataTable from 'react-data-table-component';

import {
	BASE_URL,
	BASE_URL_ONLINE,
	URL_ERROR_LIST,
	URL_REPORTING_LIST,
	URL_REPORTING_LIST_V2,
} from '../../config';
import { FiRefreshCw } from 'react-icons/fi';
import { BiDollar, BiSpreadsheet } from 'react-icons/bi';

import { TiDocument } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';

import document from './pdfExport';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { MdSearch } from 'react-icons/md';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';

import notfound from '../../data/notfound.png';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import { checkIsAvailableModule } from '../../Routes';
import { TransactionDetail } from './TransactionDetail';
import { MatrixUtils } from '../../utils/MatrixUtils';
import _ from 'lodash';
import { VoidTransaction } from './VoidTransaction';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	decrypt,
	encryptHmac512Base64,
	encryptSHA512,
	urlDecrypt,
} from '../../utils/CryptoUtils';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

const SuccessTransactionBDKI = () => {
	const navigate = useNavigate();

	const {
		activeMenu,
		currentColor,
		currentAuth,
		currentRole,
		setAuth,
		modalOpenClose,
		hirarkiUser,
		screenWidth,
		privilege,
		dataLogin,
	} = useStateContext();

	const dateTo = moment().format('YYYY-MM-DD');
	const dateFrom = moment().subtract(6, 'd').format('YYYY-MM-DD');
	const maxDate = moment().subtract(1, 'months').format('YYYY-MM-01');

	// data for filter
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);
	const [statusTrx, setStatusTrx] = useState(1);
	const [pembayaran, setPembayaran] = useState('');
	const [corporate, setCorporate] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const dateStarted = moment(startDate).format('YYYY-MM-DD');
	const dateEnded = moment(endDate).format('YYYY-MM-DD');

	const dateToMax = moment(startDate).add(1, 'months').isAfter(dateTo)
		? dateTo
		: moment(startDate).add(1, 'months').format('YYYY-MM-DD');

	const [dataCoreSettle, setDataCoreSettle] = useState([]);
	const [kategoriPembayaran, setKategoriPembayaran] = useState([]);
	const [dataCorporate, setDataCorporate] = useState([]);
	const [dataExcel, setDataExcel] = useState([]);
	const [dataStatusCode, setDataStatusCode] = useState([]);

	const [order, setOrder] = useState('desc');
	const [header, setHeader] = useState('');
	const [listTrx, setListTrx] = useState([]);

	const [count, setCount] = useState();
	const [successData, setSuccessData] = useState({
		startDate: '',
		endDate: '',
		corporate: '',
		pembayaran: '',
		status: '',
		jumlahTrx: 0,
		totalTunai: 0,
		totalLain: 0,
		totalCC: 0,
		totalQRIS: 0,
		totalDebit: 0,
		totalBrizzi: 0,
		totalEMoney: 0,
		totalTapCash: 0,
		totalFlazz: 0,
		totalJakcard: 0,
		totalVA: 0,
		totalBiller: 0,
		totalBruto: 0,
		totalMDR: 0,
		totalPotongan: 0,
		totalServiceFee: 0,
		totalPaymentFee: 0,
		totalVendorFee: 0,
		totalNett: 0,
	});

	const [selectedDetail, setSelectedDetail] = useState(null);
	const [selectedVoid, setSelectedVoid] = useState(null);

	const [dataFilter, setDataFilter] = useState([
		{ value: 'noHeader', label: 'Invoice Number' },
		{ value: 'deviceId', label: 'Device ID' },
		{ value: 'merchantNoRef', label: 'Merchant Reference' },
		{ value: 'cardPan', label: 'Card PAN' },
		{ value: 'issuerCode', label: 'Issuer Code' },
	]);

	const [selectedFilter, setSelectedFilter] = useState(dataFilter[0]);

	const getCorporate = () => {
		const bodyData = {
			order: 'ASC',
			page: 1,
			keyword: '',
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setDataCorporate(data.result);
					} else {
						setDataCorporate([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const getKategoriPembayaran = () => {
		fetch(BASE_URL + '/kategoripembayaran/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log('Payment Category: ', json.result);
				setKategoriPembayaran(json.result);
			})
			.catch((err) => {
				console.log(('KategoriPembayaran Error: ', err));
			});
	};

	const getCoreSettle = () => {
		const bodyData = {
			order: 'ASC',
			limit: 0,
			page: 0,
		};

		fetch(BASE_URL + '/core/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log('DataCoreSettle: ', data.result);
				if (data.success === true) {
					setDataCoreSettle(data.result);
					console.log('This is CoreSet', data.result);
				} else {
					console.log('TIDAK MUNCUL');
					setDataCoreSettle([]);
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const getCid = (mCorporate) => {
		if (mCorporate && mCorporate != null) {
			let mDataCorporate = _.cloneDeep(dataCorporate);
			let mFilter = mDataCorporate.filter(
				(o) => o.hirarkiId == mCorporate
			);

			if (mFilter.length > 0) {
				return mFilter[0].cid;
			}

			return '';
		}

		return '';
	};

	const getSuccessTransaction = (
		page = pagination,
		totalData = limit
	) => {
		modalOpenClose(true);

		const bodyData = {
			startDate: dateStarted,
			endDate: dateEnded,
			kategoriPembayaran: pembayaran,
			paymentStatus: parseInt(statusTrx),
			corporate: corporate ? corporate : hirarkiUser,
			hirarkiId: hirarkiUser,
			parameter: search ? selectedFilter.value : '',
			// cid: getCid(corporate ? corporate : null),
			cid: '',
			pagination: {
				order: order,
				limit: totalData,
				page: parseInt(page),
				keyword: search,
				orderBy: header,
			},
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_REPORTING_LIST_V2 +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + URL_REPORTING_LIST_V2, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.result != null) {
					setListTrx(data.result);
					modalOpenClose(false);
				} else {
					setListTrx([]);
					modalOpenClose(false);
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				setListTrx([]);
				modalOpenClose(false);
			});
	};

	const getSuccessSummary = () => {
		const inputData = {
			startDate: dateStarted, // date will change
			endDate: dateEnded,
			corporate: corporate ? corporate : hirarkiUser,
			kategoriPembayaran: pembayaran,
		};

		fetch(BASE_URL + '/summary/success', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					console.log('Success Summary' + json);
					setSuccessData(json.result);
					setCount(json.result.jumlahTrx);
				} else {
					console.log(json);
					setCount(0);
					setSuccessData({
						startDate: '',
						endDate: '',
						corporate: '',
						pembayaran: '',
						status: '',
						jumlahTrx: 0,
						totalTunai: 0,
						totalLain: 0,
						totalCC: 0,
						totalQRIS: 0,
						totalDebit: 0,
						totalBrizzi: 0,
						totalEMoney: 0,
						totalTapCash: 0,
						totalFlazz: 0,
						totalJakcard: 0,
						totalVA: 0,
						totalBiller: 0,
						totalBruto: 0,
						totalMDR: 0,
						totalPotongan: 0,
						totalServiceFee: 0,
						totalPaymentFee: 0,
						totalVendorFee: 0,
						totalNett: 0,
					});
				}
			})
			.catch((err) => {
				console.log('This is Error', err);
				setCount(0);
				setSuccessData({
					startDate: '',
					endDate: '',
					corporate: '',
					pembayaran: '',
					status: '',
					jumlahTrx: 0,
					totalTunai: 0,
					totalLain: 0,
					totalCC: 0,
					totalQRIS: 0,
					totalDebit: 0,
					totalBrizzi: 0,
					totalEMoney: 0,
					totalTapCash: 0,
					totalFlazz: 0,
					totalJakcard: 0,
					totalVA: 0,
					totalBiller: 0,
					totalBruto: 0,
					totalMDR: 0,
					totalPotongan: 0,
					totalServiceFee: 0,
					totalPaymentFee: 0,
					totalVendorFee: 0,
					totalNett: 0,
				});
			});
	};

	const getDataExcel = (page = pagination, totalData = limit) => {
		modalOpenClose(true);
		const bodyData = {
			startDate: dateStarted,
			endDate: dateEnded,
			kategoriPembayaran: pembayaran,
			paymentStatus: parseInt(statusTrx),
			corporate: corporate ? corporate : hirarkiUser,
			parameter: search ? selectedFilter.value : '',
			// cid: getCid(corporate ? corporate : null),
			cid: '',
			pagination: {
				order: 'ASC',
				limit: parseInt(count),
				page: parseInt(page),
				keyword: search,
			},
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(bodyData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			URL_REPORTING_LIST_V2 +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + URL_REPORTING_LIST_V2, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.result != null) {
					setDataExcel(data.result);
					exportToExcel(data.result);
					modalOpenClose(false);
				} else {
					setDataExcel([]);
					modalOpenClose(false);
				}
			})
			.catch((err) => {
				console.log('err Excel =>', err);
				modalOpenClose(false);
			});
	};

	const getStatusCode = () => {
		let mData = {
			errorsCode: '',
			errorsCategory: '',
			errorsDescription: '',
			errorsReadable: '',
		};

		fetch(BASE_URL_ONLINE + URL_ERROR_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic bWtwbW9iaWxlOm1rcG1vYmlsZTEyMw==`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						setDataStatusCode(data.result);
					} else {
						setDataStatusCode([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const data = [];
	{
		dataCoreSettle.map((item) => {
			let dataValue = {
				value: item.name,
				label: item.name,
			};
			data.push(dataValue);
		});
	}

	const onChangePembayaran = (event, values) => {
		setPembayaran(values.value);
	};

	const onChangeCorp = (event, values) => {
		setCorporate(values.value);
	};

	const onChangeSelectedFilter = (event, values) => {
		setKeyword('');
		setSelectedFilter(values);
	};

	const handlePageChange = (page) => {
		getSuccessTransaction(page);
		setCurrentPage(page);
	};

	const handlePerRowsChange = (totalData, page) => {
		getSuccessTransaction(page, totalData);
		setLimit(totalData);
	};

	const handleClick = () => {
		getSuccessTransaction();
		getSuccessSummary();
	};

	const printRef = React.useRef();

	const searchData = () => {
		setSearch(keyword);
	};

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const exportToExcel = async (dataForExcel) => {
		const dataExcel = dataForExcel.map((o) => {
			let mFilter = dataStatusCode.filter(
				(m) => m.errorsCode == o.statusCode
			);

			let mCorporate = dataCorporate.filter((m) => {
				return m.cid == o.corporateCID;
			});

			let mData = {};
			mData.Status = o.paymentStatusDesc;
			mData.StatusDescription =
				mFilter.length > 0 ? mFilter[0].errorsReadable : '';
			mData.InvoiceNo = o.noHeader;
			mData.MerchantRef = o.merchantNoRef;
			mData.CreatedDate = o.createdAt
				? o.createdAt.split(' ')[0]
				: '';
			mData.CreatedTime = o.createdAt
				? o.createdAt.split(' ')[1]
				: '';
			mData.Amount = o.paymentAmount;
			// 'Rp' + o.paymentAmount ? o.paymentAmount.format() : '0';
			mData.PaymentCategory = o.paymentCategoryName;
			mData.PaymentName = o.paymentMethodName;
			if (
				checkIsAvailableModule('REPORT_DETAIL_ACQUIRING', privilege)
			) {
				mData.AcquiringCode = o.acquiringCode;
				mData.AcquiringName = o.acquiringName;
			}
			mData.CoporateID = o.corporateCID;
			mData.CorporateName = o.corporateName;
			mData.AccountNumber =
				mCorporate.length > 0
					? mCorporate[0].corporateBankAccount
					: '';
			mData.SettleDest = o.settlementDestination;
			mData.SettleStatus = o.statusSettlement ? 'YES' : 'NO';
			mData.BankResponseCode = o.bankResponseCode;
			mData.BankResponseMessage = o.bankResponseDesc;
			mData.PrincipleRef = o.responseNoRef;
			mData.DeviceID = o.deviceId;
			mData.MID = o.bankMID;
			mData.TID = o.bankTID;
			mData.Cardpan = o.cardPan;
			mData.CardType = o.cardType;
			mData.STAN = o.stan ?? '';
			mData.RRN = o.rrn ?? '';
			mData.ApprovalCode = o.bankApprovalCode ?? '';
			mData.OnusOffus = o.cardTypeTrx ?? '';
			mData.MDR = o.paymentMDR;
			if (
				checkIsAvailableModule(
					'REPORT_DETAIL_MDR_DKI_MKP_1',
					privilege
				) ||
				checkIsAvailableModule(
					'REPORT_DETAIL_MDR_DKI_MKP_2',
					privilege
				)
			) {
				if (
					checkIsAvailableModule(
						'REPORT_DETAIL_MDR_DKI_MKP_1',
						privilege
					) ||
					(checkIsAvailableModule(
						'REPORT_DETAIL_MDR_DKI_MKP_2',
						privilege
					) &&
						o.acquiringCode != '10004')
				) {
					let mresult = '0';

					if (
						o.paymentCategoryName == 'DEBIT' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'on us'
					) {
						mresult = (0.06 / 100) * (o.paymentAmount ?? 0);
					} else if (
						o.paymentCategoryName == 'DEBIT' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'off us'
					) {
						mresult = (0.16 / 100) * (o.paymentAmount ?? 0);
					} else if (o.paymentCategoryName == 'QRIS') {
						mresult = (0.11 / 100) * (o.paymentAmount ?? 0);
					} else if (
						o.paymentCategoryName == 'CC' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'on us'
					) {
						mresult = (0.04 / 100) * (o.paymentAmount ?? 0);
					} else if (
						o.paymentCategoryName == 'CC' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'off us'
					) {
						mresult = (0.06 / 100) * (o.paymentAmount ?? 0);
					}
					mData.MDR_DKI = mresult;
				} else if (
					checkIsAvailableModule(
						'REPORT_DETAIL_MDR_DKI_MKP_2',
						privilege
					) &&
					o.acquiringCode == '10004'
				) {
					let mresult = '0';

					if (
						o.paymentCategoryName == 'DEBIT' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'on us'
					) {
						mresult = (100 / 100) * (o.paymentMDR ?? 0);
					} else if (
						o.paymentCategoryName == 'DEBIT' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'off us'
					) {
						mresult = (0.39 / 100) * (o.paymentAmount ?? 0);
					} else if (o.paymentCategoryName == 'QRIS') {
						mresult = (100 / 100) * (o.paymentMDR ?? 0);
					}

					mData.MDR_DKI = mresult;
				}

				if (
					checkIsAvailableModule(
						'REPORT_DETAIL_MDR_DKI_MKP_1',
						privilege
					) ||
					(checkIsAvailableModule(
						'REPORT_DETAIL_MDR_DKI_MKP_2',
						privilege
					) &&
						o.acquiringCode != '10004')
				) {
					let mresult = '0';

					if (
						o.paymentCategoryName == 'DEBIT' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'on us'
					) {
						mresult = (0.09 / 100) * (o.paymentAmount ?? 0);
					} else if (
						o.paymentCategoryName == 'DEBIT' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'off us'
					) {
						mresult = (0.23 / 100) * (o.paymentAmount ?? 0);
					} else if (o.paymentCategoryName == 'QRIS') {
						mresult = (0.16 / 100) * (o.paymentAmount ?? 0);
					} else if (
						o.paymentCategoryName == 'CC' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'on us'
					) {
						mresult = (0.06 / 100) * (o.paymentAmount ?? 0);
					} else if (
						o.paymentCategoryName == 'CC' &&
						o.cardTypeTrx &&
						o.cardTypeTrx.toLowerCase() == 'off us'
					) {
						mresult = (0.09 / 100) * (o.paymentAmount ?? 0);
					}
					mData.MDR_MKP = mresult;
				} else if (
					checkIsAvailableModule(
						'REPORT_DETAIL_MDR_DKI_MKP_2',
						privilege
					)
				) {
					mData.MDR_MKP = 0;
				}
			}
			// 'Rp' + o.paymentMDR ? o.paymentMDR.format() : '0';
			mData.ServiceFee = o.serviceFee;
			// 'Rp' + o.serviceFee ? o.serviceFee.format() : '0';
			mData.PaymentFee = o.paymentFee;
			// 'Rp' + o.paymentFee ? o.paymentFee.format() : '0';
			mData.VendorFee = o.vendorFee;
			// 'Rp' + o.vendorFee ? o.vendorFee.format() : '0';

			return mData;
		});

		const ws = XLSX.utils.json_to_sheet(dataExcel);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, {
			bookType: 'xlsx',
			type: 'array',
		});

		const data = new Blob([excelBuffer], { type: fileType });

		FileSaver.saveAs(
			data,
			`Data Transaksi (${dateStarted} - ${dateEnded})` + fileExtension
		);
	};

	const customSort = (rows, selector, direction) => {
		setHeader(rows.selector);
		setOrder(selector);
	};

	const corpData = [
		{
			value: '',
			label: '- All Corporate -',
		},
	];
	{
		dataCorporate.map((item) => {
			let dataValue = {
				value: item.hirarkiId,
				label: item.uraian,
			};
			corpData.push(dataValue);
		});
	}

	const dataCategory = [
		{
			value: '',
			label: '- Semua Payment Category -',
		},
	];
	{
		kategoriPembayaran.map((item) => {
			let dataValue = {
				value: item.uraian,
				label: item.uraian,
			};
			dataCategory.push(dataValue);
		});
	}

	useEffect(() => {
		getCorporate();
		getKategoriPembayaran();
		// getCoreSettle();
		getSuccessSummary();
		// getStatusCode();
	}, []);

	useEffect(() => {
		getSuccessTransaction();
	}, [search, order, header]);

	const getDetailData = (id) => {
		let mFilter = id ? listTrx.filter((o) => o.id == id) : [];

		if (mFilter.length > 0) {
			return mFilter[0];
		} else {
			return null;
		}
	};

	return (
		<div className={` `}>
			<div
				className={` h-fit m-2 mt-16 md:m-10 p-6 md:p-10 mb-0 md:rounded-3xl bg-white max-w-screen md:max-w-full`}
			>
				{/* <Header category={"Filter Data"} /> */}
				<div className=" ">
					<p className=" text-base font-semibold mb-7 ">
						Filter Data
					</p>
					<div className=" md:flex gap-5 mb-5  ">
						{/* start date input */}
						<div className=" mb-5 md:mb-0 w-full">
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									className=" w-full"
									label="Start Date"
									value={startDate}
									onChange={(newValue) => {
										setStartDate(newValue);
										setEndDate(newValue);
									}}
									inputFormat="DD-MM-YYYY"
									maxDate={dateTo}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
						{/* end date input */}
						<div className=" w-full">
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									className=" w-full"
									label="Until Date"
									value={endDate}
									onChange={(newValue) => {
										setEndDate(newValue);
									}}
									inputFormat="DD-MM-YYYY"
									minDate={startDate}
									maxDate={dateToMax}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</div>
					</div>

					{/* select Transaction Status dan Payment Category */}
					<div className="  flex gap-3 mb-7">
						<div className=" w-full">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								disableClearable
								id="jenis-user"
								options={dataCategory}
								defaultValue={dataCategory[0]}
								onChange={onChangePembayaran}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Payment Category"
									/>
								)}
							/>
						</div>

						{/* corporate insert */}
						<div className="w-full -mr-0 md:-mr-0  ">
							<Autocomplete
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="combo-box-demo"
								options={corpData}
								defaultValue={corpData[0]}
								onChange={onChangeCorp}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Corporate"
										// InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</div>
					</div>
					<Button
						customFunc={() => handleClick()}
						bgColor={currentColor}
						color="white"
						text="Filter"
						borderRadius={'10px'}
						icon={<FiRefreshCw />}
					/>
				</div>
			</div>

			{/* Success Transaction Summary */}
			<div
				ref={printRef}
				className={` h-fit   md:m-10 p-6 md:p-10  md:rounded-3xl bg-white 
      ${activeMenu === true ? 'max-w-full' : ' max-w-full'}`}
			>
				<div className=" flex justify-between">
					<p> </p>
					<PDFDownloadLink
						document={document(successData)}
						fileName={`Data Summary (${dateStarted} - ${dateEnded})`}
					>
						{checkIsAvailableModule(
							'REPORT_SUCCESS_BDKI_PDF_SUMMARY',
							privilege
						) && (
							<Button
								bgColor={currentColor}
								color="white"
								text="Export to PDF"
								borderRadius={'10px'}
								icon={<TiDocument />}
							/>
						)}
					</PDFDownloadLink>
				</div>

				<div>
					<div className=" flex items-center gap-4 mb-4">
						<p className=" text-2xl">
							<BiDollar />
						</p>
						<p className=" font-semibold  ">Summary</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute ">
							Periode:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal  ml-36 md:ml-72 ">
							{successData.startDate} - {successData.endDate}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute ">
							Corporate:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							{successData.corporate ? successData.corporate : '-'}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Pembayaran:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							{successData.pembayaran === ''
								? 'All'
								: successData.pembayaran}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Status:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							{successData.status ? successData.status : '-'}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base  font-normal absolute">
							Amount of transaction:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							{successData.jumlahTrx
								? successData.jumlahTrx.format02()
								: '0'}{' '}
							Transaksi
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Tunai:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalTunai.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base  font-normal absolute">
							Total Lainnya:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalLain.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base  font-normal absolute">
							Total CC:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalCC.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Debit:
						</p>
						<p className=" text-gray-600  text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalDebit.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base  font-normal absolute">
							Total QRIS:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalQRIS.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600  text-sm md:text-base font-normal absolute">
							Total Brizzi:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalBrizzi.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total E-Money:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalEMoney.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Tapcash:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalTapCash.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Flazz:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalFlazz.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total JakCard:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalJakcard.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total VA:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalVA.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Bruto:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalBruto.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total MDR:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalMDR.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Potongan:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalPotongan.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Service Fee:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalServiceFee.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Payment Fee:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalPaymentFee.format()}
						</p>
					</div>
					<div className=" bg-[#ffffff] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Vendor Fee:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalVendorFee.format()}
						</p>
					</div>
					<div className=" bg-[#F8F8F8] p-4 flex w-full ">
						<p className=" text-gray-600 text-sm md:text-base font-normal absolute">
							Total Nett:
						</p>
						<p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72 ">
							Rp. {successData.totalNett.format()}
						</p>
					</div>
				</div>
			</div>

			{/* bottom container */}
			<div
				className={` h-fit   md:m-10 p-6 md:p-10  md:rounded-3xl bg-white `}
			>
				<div className=" flex justify-between mb-5">
					<div className=" flex items-center gap-4 mt-5 mb-4">
						<p className=" text-2xl">
							<BiSpreadsheet />
						</p>
						<p className=" font-semibold">Transaction Data</p>
					</div>
					{checkIsAvailableModule(
						'REPORT_SUCCESS_BDKI_EXCEL_LIST',
						privilege
					) && (
						<Button
							customFunc={() => getDataExcel()}
							text="Excel Export"
							bgColor={currentColor}
							color="white"
							height="fit"
							borderRadius="10px"
							icon={<BsFileEarmarkExcelFill />}
						/>
					)}
				</div>

				<div className=" mt-5 flex justify-end">
					<div className=" w-1/2 mb-2 flex end">
						<div className=" w-full mr-2">
							<Autocomplete
								required
								size="small"
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="status-trx"
								options={dataFilter}
								defaultValue={dataFilter[0]}
								onChange={onChangeSelectedFilter}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Filter Field"
									/>
								)}
							/>
						</div>
						<FormControl className=" w-full" variant="outlined">
							<OutlinedInput
								size="small"
								notched
								id="outlined-adornment-password"
								type={'text'}
								placeholder="Search"
								onChange={(text) => setKeyword(text.target.value)}
								value={keyword}
								defaultValue={keyword}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										searchData();
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											onClick={() => searchData()}
											edge="end"
										>
											<MdSearch />
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
				</div>

				<DataTable
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					columns={listTransactionColumn(
						dataCorporate,
						dataStatusCode,
						(id, isVoid = false) => {
							if (!isVoid) setSelectedDetail(id);
							else setSelectedVoid(id);
						},
						privilege
					)}
					data={listTrx}
					pagination
					paginationServer
					paginationTotalRows={count}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					paginationRowsPerPageOptions={[20, 30, 50, 100]}
					sortServer
					onSort={customSort}
					theme="solarized"
					customStyles={customStyles}
				/>
			</div>

			<TransactionDetail
				open={selectedDetail}
				onClose={() => setSelectedDetail(null)}
				width={MatrixUtils.isMobile() ? MatrixUtils.width() : 400}
				data={getDetailData(selectedDetail)}
				onSuccess={() => {
					setSelectedDetail(null);
					getSuccessSummary();
					getSuccessTransaction();
				}}
			/>

			<VoidTransaction
				data={getDetailData(selectedVoid)}
				onSuccess={() => {
					setSelectedVoid(null);
					getSuccessSummary();
					getSuccessTransaction();
				}}
				onCancel={() => {
					setSelectedVoid(null);
				}}
			/>
		</div>
	);
};

export default SuccessTransactionBDKI;
