import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import { checkIsAvailableModule } from "../../../Routes";

import { Popover, Typography } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";

const CryptoJS = require("crypto-js");

export const QRAction = ({row}) => {
  const { privilege } = useStateContext();

  // Encrypt
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(row),
    "nasirames"
  ).toString();

  var enc = ciphertext
    .replace(/\+/g, "p1L2u3S")
    .replace(/\//g, "s1L2a3S4h")
    .replace(/=/g, "e1Q2u3A4l");

  const navigate = useNavigate();

  return (
    <div className="flex gap-2">
      {checkIsAvailableModule("EDIT_CONFIG_STATIC_QR", privilege) && (
        <button
          type="button"
          onClick={() => navigate(`/update-static-qr/${enc}`)}
          className=" text-2xl text-green-500 hover:drop-shadow-xl"
        >
          <MdEdit />
        </button>
      )}
    </div>
  );
};

export const Copy = ({label, value}) => {
  const [popOver, setPopOver] = useState(false);
  return (
    <div className="flex w-full">
      <CopyToClipboard text={value}>
        <button
          onClick={() => {
            setPopOver(true);
          }}
          type="button"
          className=" text-2xl hover:drop-shadow-xl ml-1"
        >
          <MdContentCopy />
        </button>
      </CopyToClipboard>

      {/* copied to clipboardnotification */}
      <Popover
        open={popOver}
        onClose={() => setPopOver(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 2 }}>{label} copied to clipboard!</Typography>
      </Popover>
    </div>
  );
};
