import React, { useEffect, useState } from 'react';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns/src';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_AQUIRING_ADD,
	URL_AQUIRING_LIST,
	URL_KATEGORI_LIST,
	URL_PAYMENTDATA_ADD,
} from '../../../config';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';

var CryptoJS = require('crypto-js');

const PaymentDataAdd = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose } =
		useStateContext();

	const navigate = useNavigate();

	const [paymentCode, setPaymentCode] = useState('');
	const [paymentName, setPaymentName] = useState('');
	const [aliasName, setAliasName] = useState('');
	const [acquiringData, setAcquiringData] = useState([]);
	const [categoryData, setCategoryData] = useState([]);
	const [idCategory, setIdCategory] = useState(null);
	const [idAcquirer, setIdAcquirer] = useState(null);
	const [id, setId] = useState(null);

	const [status, setStatus] = useState(false);

	useEffect(() => {
		getDataCategory();
		getDataAcquirer();
	}, []);

	const dataUpdate = (e) => {
		e.preventDefault();

		modalOpenClose(true);

		const inputData = {
			uraian: paymentName,
			idKategoriPembayaran: idCategory
				? categoryData[currentIndex].id
				: null,
			paymentCode: paymentCode,
			alias: aliasName
				? aliasName
				: idCategory
				? categoryData[currentIndex].uraian
				: paymentName,
			acquiringCode: idAcquirer
				? acquiringData[currentIndexAcquiring].acquiringCode
				: null,
		};

		fetch(BASE_URL + URL_PAYMENTDATA_ADD, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	const getDataCategory = () => {
		fetch(BASE_URL + URL_KATEGORI_LIST, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						let mData = _.cloneDeep(data.result);
						mData.map((o) => {
							o.value = o.id;
							o.label = o.uraian;
						});

						setCategoryData(mData);
					} else {
						setCategoryData([]);
					}
				}
			})
			.catch((err) => {
				notifyErr(err.message);
			});
	};

	const getDataAcquirer = () => {
		let mData = {
			order: 'ASC',
			orderBy: 'id',
			limit: 999,
			page: 1,
		};

		setAcquiringData([]);

		fetch(BASE_URL + URL_AQUIRING_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						let mData = _.cloneDeep(data.result);
						mData.map((o) => {
							o.value = o.id;
							o.label = o.acquiringCode + ' - ' + o.acquiringName;
						});

						setAcquiringData(mData);
					} else {
						setAcquiringData([]);
					}
				}
			})
			.catch((err) => {
				notifyErr(err.message);
			});
	};

	const currentIndex =
		categoryData && categoryData.length > 0
			? categoryData.findIndex((item) => {
					return item.value === idCategory;
			  })
			: 0;

	const handleChangeCategory = (event, values) => {
		if (values != null) {
			setIdCategory(values.value);
		} else {
			setIdCategory(null);
		}
	};

	const currentIndexAcquiring =
		acquiringData && acquiringData.length > 0
			? acquiringData.findIndex((item) => {
					return item.value === idAcquirer;
			  })
			: 0;

	const handleChangeAcquirer = (event, values) => {
		if (values != null) {
			setIdAcquirer(values.value);
		} else {
			setIdAcquirer(null);
		}
	};

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="New Payment" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Payment Code"
						variant="outlined"
						onChange={(text) => setPaymentCode(text.target.value)}
						placeholder="Payment Code"
						InputLabelProps={{ shrink: true }}
						value={paymentCode}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Payment Name"
						variant="outlined"
						onChange={(text) => setPaymentName(text.target.value)}
						placeholder="Payment Name"
						InputLabelProps={{ shrink: true }}
						value={paymentName}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Alias Name"
						variant="outlined"
						onChange={(text) => setAliasName(text.target.value)}
						placeholder="Alias Name"
						InputLabelProps={{ shrink: true }}
						value={aliasName}
					/>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndex}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectCategory"
								options={categoryData}
								onChange={handleChangeCategory}
								defaultValue={
									categoryData ? categoryData[currentIndex] : null
								}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Category"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="  ">
					<div className="flex gap-6 mt-5">
						<div className=" w-full">
							<Autocomplete
								key={currentIndexAcquiring}
								required
								fullWidth={true}
								style={{
									width: 'auto',
								}}
								disablePortal
								id="selectAcquirer"
								options={acquiringData}
								onChange={handleChangeAcquirer}
								defaultValue={
									acquiringData
										? acquiringData[currentIndexAcquiring]
										: null
								}
								renderInput={(params) => (
									<TextField
										required
										{...params}
										label="Choose Acquirer"
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default PaymentDataAdd;
