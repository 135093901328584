import React, { useEffect, useState } from 'react';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns/src';

import {
	MdKeyboardBackspace,
	MdArrowBack,
	MdSave,
} from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { Navigate, useNavigate } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL } from '../../../config';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

const DeviceAdd = () => {
	const navigate = useNavigate();

	const notify = (text) => {
		toast.success(text, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyError = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		activeMenu,
	} = useStateContext();
	const [corp, setCorp] = useState([]);

	const getCorp = () => {
		const dataInput = {
			order: 'ASC',
			// limit: 100,
			draw: 2,
			page: 1,
			hirarkiId: hirarkiUser,
		};
		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					setCorp(data.result);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [merchKey, setMerchKey] = useState([]);
	const getCoreSettle = () => {
		const dataInput = {
			order: 'ASC',
			limit: 0,
			draw: 2,
			page: 0,
		};
		fetch(BASE_URL + '/core/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setMerchKey(data.result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getCorp();
		getCoreSettle();
	}, []);

	const [idCorp, setIdCorp] = useState(0);
	const [deviceId, setDeviceId] = useState('');
	const [mid, setMid] = useState('');
	const [dkiTid, setDkiTid] = useState('');
	const [dkiMid, setDkiMid] = useState('');
	const [idCorpMerchant, setIdCorpMerchant] = useState(0);
	const [jenisDevice, setJenisDevice] = useState('');
	const [isPrepaidOffline, setisPrepaidOffline] = useState(false);
	const [pushPaymentQueue, setpushPaymentQueue] = useState('');
	const [opsOutletCode, setopsOutletCode] = useState('');

	const onChangeCorp = (event, values) => {
		setIdCorp(values.value);
	};
	const onChangeJenis = (event) => {
		setJenisDevice(event.target.value);
	};

	const onChangeCore = (event, values) => {
		setIdCorpMerchant(values.value);
	};

	const inputData = (e) => {
		e.preventDefault();
		const dataInput = {
			deviceId: deviceId,
			idCorporate: parseInt(idCorp),
			mid: mid,
			dkitid: dkiTid,
			dkimid: dkiMid,
			idCorporateMerchantkey: parseInt(idCorpMerchant),
			jenisDevice: jenisDevice,
			isPrepaidOffline: false,
			pushPaymentQueue: pushPaymentQueue,
			opsOutletCode: opsOutletCode,
		};
		fetch(BASE_URL + '/device/add', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				let message = json.message;
				if (json.success === true) {
					console.log(json);
					notify('New Data Device Berhasil!');
				} else if (json.success === false) {
					console.log(json);
					notifyError(message);
				}
			})
			.catch((err) => {
				alert(err);
			});
	};

	const dataCorp = [];
	{
		corp.map((item) => {
			let dataValue = {
				value: item.id,
				label: item.uraian,
			};
			dataCorp.push(dataValue);
		});
	}

	const dataMerch = [];
	{
		merchKey.map((item) => {
			let dataValue = {
				value: item.id,
				label: item.name,
			};
			dataMerch.push(dataValue);
		});
	}

	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={inputData}
		>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10 rounded-3xl bg-white">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="New Device" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				{/* corporate insert */}
				<div className=" w-full -mr-0 md:-mr-0 mb-5">
					<Autocomplete
						required
						fullWidth={true}
						style={{
							width: 'auto',
						}}
						disablePortal
						id="combo-box-demo"
						options={dataCorp}
						onChange={onChangeCorp}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								label="Corporate"
								// InputLabelProps={{ shrink: true }}
							/>
						)}
					/>
				</div>

				<div className=" flex gap-5 mb-5">
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="device-id"
							label="Device ID"
							variant="outlined"
							onChange={(text) => setDeviceId(text.target.value)}
							placeholder="9238OIJHI7, 4TF87IJ, ..."
							InputLabelProps={{ shrink: true }}
						/>
					</div>
					<div className="  w-full">
						<FormControl required fullWidth>
							<InputLabel id="select-jenisdev">
								Jenis Device
							</InputLabel>
							<Select
								required
								labelId="select-jenisdev"
								id="select-jenisdev"
								value={jenisDevice}
								label="Jenis Device"
								onChange={onChangeJenis}
							>
								<MenuItem value={'base'} disabled={true}>
									- Pilih Jenis Device -
								</MenuItem>
								<MenuItem value={'SUNMI'}>SUNMI</MenuItem>
								<MenuItem value={'QPROX'}>QPROX</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>

				<div className=" flex gap-5 mb-5">
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="mid"
							label="MKP MID"
							variant="outlined"
							onChange={(text) => setMid(text.target.value)}
							placeholder="M001, M002, ..."
							InputLabelProps={{ shrink: true }}
						/>
					</div>
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="outlet-code"
							label="Outlet Code"
							variant="outlined"
							onChange={(text) => setopsOutletCode(text.target.value)}
							value={opsOutletCode}
							placeholder="Ops outlet code"
							InputLabelProps={{ shrink: true }}
						/>
					</div>
				</div>

				<div className=" flex gap-5 mb-5">
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="dki-tid"
							label="DKI TID"
							variant="outlined"
							onChange={(text) => setDkiTid(text.target.value)}
							placeholder="T001, T002, ..."
							InputLabelProps={{ shrink: true }}
						/>
					</div>
					<div className=" w-full">
						<TextField
							className="w-full"
							required
							id="dki-mid"
							label="DKI MID"
							variant="outlined"
							onChange={(text) => setDkiMid(text.target.value)}
							placeholder="M001, M002, ..."
							InputLabelProps={{ shrink: true }}
						/>
					</div>
				</div>

				{/* Merchant Key insert */}
				<div className=" w-full -mr-0 md:-mr-0 mb-5">
					<Autocomplete
						required
						fullWidth={true}
						style={{
							width: 'auto',
						}}
						disablePortal
						id="combo-box-demo"
						options={dataMerch}
						onChange={onChangeCore}
						renderInput={(params) => (
							<TextField required {...params} label="Merchant Key" />
						)}
					/>
				</div>

				<div className=" flex gap-5 mb-5">
					<div className=" w-full">
						<FormControl required fullWidth>
							<InputLabel id="select-modelpushpayment">
								Model Push Payment
							</InputLabel>
							<Select
								required
								labelId="select-modelpushpayment"
								id="select-modelpushpayment"
								value={pushPaymentQueue}
								label="Model Push Payment"
								onChange={(event) => {
									setpushPaymentQueue(event.target.value);
								}}
							>
								<MenuItem value={'base'}>
									- Pilih Model Push Payment -
								</MenuItem>
								<MenuItem value={'QUEUE'}>QUEUE</MenuItem>
								<MenuItem value={'STACK'}>STACK</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="  w-full">
						<FormControl required fullWidth>
							<InputLabel id="select-isprepaidoffline">
								Is Prepaid Offline
							</InputLabel>
							<Select
								required
								labelId="select-isprepaidoffline"
								id="select-isprepaidoffline"
								value={isPrepaidOffline}
								label="Is Prepaid Offline"
								onChange={(event) => {
									setisPrepaidOffline(event.target.value);
								}}
							>
								<MenuItem value={'base'}>
									- Is Prepaid Offline -
								</MenuItem>
								<MenuItem value={true}>Ya</MenuItem>
								<MenuItem value={false}>Tidak</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>

				<div className=" mt-5 justify-between  flex gap-5">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						borderRadius={'10px'}
						color="white"
						text="Previous Page"
						icon={<MdArrowBack />}
					/>
					<Button
						type="submit"
						bgColor={currentColor}
						borderRadius={'10px'}
						color="white"
						text="Submit"
						icon={<MdSave />}
					/>
				</div>
			</div>
		</form>
	);
};

export default DeviceAdd;
