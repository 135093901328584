export const getTimeStamp = () => {
	let date = new Date();
	let mDay = date.getDate().toString().padStart(2, '0');
	let mMonth = (date.getMonth() + 1).toString().padStart(2, '0');
	let mYear = date.getFullYear();

	let mHour = date.getHours().toString().padStart(2, '0');
	let mMin = date.getMinutes().toString().padStart(2, '0');
	let mSec = date.getSeconds().toString().padStart(2, '0');

	return (
		mYear +
		'-' +
		mMonth +
		'-' +
		mDay +
		'T' +
		mHour +
		':' +
		mMin +
		':' +
		mSec +
		'Z'
	);
};
