import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_AQUIRING_EDIT,
	URL_KATEGORI_EDIT,
} from '../../../config';

import TextField from '@mui/material/TextField';

var CryptoJS = require('crypto-js');

const AcquiringPaymentUpdate = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose } =
		useStateContext();

	const navigate = useNavigate();

	const { Id } = useParams();

	const [aquiringCode, setAquiringCode] = useState('');
	const [aquiringName, setAquiringName] = useState('');
	const [bankCode, setBankCode] = useState('');
	const [logo, setLogo] = useState('');
	const [id, setId] = useState(null);

	const [status, setStatus] = useState(false);

	const dataUpdate = (e) => {
		e.preventDefault();

		const inputData = {
			acquiringCode: aquiringCode,
			acquiringName: aquiringName,
			bankCode: bankCode,
			logo: logo,
			id: id,
		};

		modalOpenClose(true);

		fetch(BASE_URL + URL_AQUIRING_EDIT, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	useEffect(() => {
		if (Id) {
			try {
				var byted = Id.replace(/p1L2u3S/g, '+')
					.replace(/s1L2a3S4h/g, '/')
					.replace(/e1Q2u3A4l/g, '=');

				var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
				var initialId = bytes.toString(CryptoJS.enc.Utf8);

				var data = JSON.parse(initialId);

				setAquiringCode(data.acquiringCode);
				setAquiringName(data.acquiringName);
				setBankCode(data.bankCode);
				setLogo(data.logo);
				setId(data.id);
			} catch (err) {
				navigate(`/404`);
			}
		}
	}, [Id]);

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="Update Acquirer" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Acquirer Code"
						variant="outlined"
						onChange={(text) => setAquiringCode(text.target.value)}
						placeholder="Acquirer Code"
						InputLabelProps={{ shrink: true }}
						value={aquiringCode}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Acquirer Name"
						variant="outlined"
						onChange={(text) => setAquiringName(text.target.value)}
						placeholder="Acquirer Name"
						InputLabelProps={{ shrink: true }}
						value={aquiringName}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Bank Code"
						variant="outlined"
						onChange={(text) => setBankCode(text.target.value)}
						placeholder="Bank Code"
						InputLabelProps={{ shrink: true }}
						value={bankCode}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Logo"
						variant="outlined"
						onChange={(text) => setLogo(text.target.value)}
						placeholder="Logo"
						InputLabelProps={{ shrink: true }}
						value={logo}
						multiline
						maxRows={4}
					/>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default AcquiringPaymentUpdate;
