import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import {
	BASE_URL,
	URL_AQUIRING_LIST,
	URL_AQUIRING_SYNC,
	URL_AQUIRING_WORKER_ADD,
	URL_AQUIRING_WORKER_UPDATE,
} from '../../../config';
import { CorpAction, CorpSyncLoc } from './DataTableComponent';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdArrowBack, MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	Modal,
	OutlinedInput,
	TextField,
} from '@mui/material';

import _ from 'lodash';
import { checkIsAvailableModule } from '../../../Routes';
import ToggleText from '../../../components/ToggleText';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const AcquiringPayment = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		privilege,
		screenWidth,
	} = useStateContext();

	const navigate = useNavigate();
	const [registrationModal, setRegistrationModal] = useState(null);
	const [count, setCount] = useState(0);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [pending, setPending] = useState(false);

	const [dataCorp, setDataCorp] = useState([]);

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => getDataCorp(),
		});
	};
	const notifyErr = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const getDataCorp = () => {
		modalOpenClose(true);

		let mData = {
			order: 'ASC',
			orderBy: 'id',
			limit: 999,
			page: 1,
		};

		setDataCorp([]);

		fetch(BASE_URL + URL_AQUIRING_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						console.log(data.result);
						setDataCorp(data.result);
						setCount(data.length);
						modalOpenClose(false);
					} else {
						setDataCorp([]);
						setCount(0);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	const dataUpdate = () => {
		modalOpenClose(true);

		const inputData = {
			kodeBankAcquiring: registrationModal.bankCode,
			namaBankAcquiring: registrationModal.acquiringName,
			bankAcquiringMainUrl: registrationModal.bankacquiringmainurl,
			authCredential: registrationModal.authcredential,
		};

		let url = URL_AQUIRING_WORKER_ADD;

		if (registrationModal.syncCode) {
			let mParse = JSON.parse(registrationModal.syncCode);
			if (mParse.idbankacquiring) {
				url = URL_AQUIRING_WORKER_UPDATE;
				inputData.idbankacquiring = mParse.idbankacquiring;
			}
		}

		fetch(BASE_URL + url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				setRegistrationModal(null);
				modalOpenClose(false);

				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				setRegistrationModal(null);
				notifyErr(error.message);
				modalOpenClose(false);
			});
	};

	const dataGetSync = () => {
		modalOpenClose(true);

		const inputData = {
			bankCode: registrationModal.bankCode,
		};

		let url = URL_AQUIRING_SYNC;

		fetch(BASE_URL + url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				setRegistrationModal(null);
				modalOpenClose(false);

				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				setRegistrationModal(null);
				notifyErr(error.message);
				modalOpenClose(false);
			});
	};

	// list corporate Column
	const [corpColumn, setCorpColum] = useState([
		{
			name: 'Acquirer Code',
			selector: 'acquiringCode',
			sortable: true,
			width: 250,
		},
		{
			name: 'Acquirer Name',
			selector: 'acquiringName',
			sortable: true,
			width: 400,
		},
		{
			name: 'Bank Code',
			selector: 'bankCode',
			sortable: true,
			width: 200,
			cell: (row) => <>{row.bankCode || '-'}</>,
		},
		{
			name: 'Logo',
			selector: 'logo',
			sortable: true,
			width: 200,
			cell: (row) => <ToggleText minToggle={40} text={row.logo} />,
		},
		{
			name: 'Sync',
			selector: '',
			sortable: true,
			width: 100,
			cell: (row) => (
				<button
					style={
						row.syncCode != 0
							? { background: '#E8F5E9' }
							: { background: '#FFF3E0' }
					}
					className={`py-2 px-3 text-sm capitalize rounded-2xl`}
				>
					<p
						className=" font-semibold"
						style={{
							color: row.syncCode != 0 ? '#5AB55E' : '#FFAA2C',
						}}
					>
						{row.syncCode != 0 ? 'Yes' : 'No'}
					</p>
				</button>
			),
		},
	]);

	const searchData = () => {
		if (search) {
			let mData = _.cloneDeep(dataCorp);
			return mData.filter(
				(o) =>
					o.acquiringCode
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					o.acquiringName.toLowerCase().includes(search.toLowerCase())
			);
		} else {
			return dataCorp;
		}
	};

	const inputModal = (fieldData, text) => {
		if (registrationModal) {
			let mReqData = _.cloneDeep(registrationModal);
			mReqData[fieldData] = text;

			setRegistrationModal(mReqData);
		}
	};

	useEffect(() => {
		let addColumn = _.cloneDeep(corpColumn);

		if (
			checkIsAvailableModule('EDIT_MASTER_AQUIRING', privilege) &&
			checkIsAvailableModule('DELETE_MASTER_AQUIRING', privilege)
		) {
			addColumn.unshift({
				name: 'Action', //useTemplate
				selector: '',
				sortable: true,
				width: 100,
				cell: (row) => CorpAction(row, () => getDataCorp()),
			});
		}

		if (checkIsAvailableModule('SYNC_MASTER_AQUIRING', privilege)) {
			addColumn.push({
				name: '', //useTemplate
				selector: '',
				sortable: true,
				width: 80,
				style: {
					flex: 1,
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
				},
				cell: (row) =>
					CorpSyncLoc(row, (isModalOpen) => {
						let mParse = row.syncCode ? JSON.parse(row.syncCode) : '';

						if (mParse) {
							row = {
								...row,
								...mParse,
							};
						}

						isModalOpen ? setRegistrationModal(row) : getDataCorp();
					}),
			});
		}

		setCorpColum(addColumn);
		getDataCorp();
	}, []);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className="flex  justify-between">
				<Header title={'Acquirer Data'} />
				{checkIsAvailableModule('ADD_MASTER_AQUIRING', privilege) && (
					<Button
						customFunc={() => navigate('/add-acquiring-payment')}
						text="Add New +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>

			<div className=" mt-5 md:-mt-5 flex md:justify-end">
				<div className=" w-full  md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									setSearch(keyword);
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearch(keyword)}
										edge="end"
									>
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={corpColumn}
					data={searchData()}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>

			<Modal
				onBackdropClick={() => setRegistrationModal(null)}
				open={registrationModal != null}
			>
				<div className=" top-1/4 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p className=" font-semibold text-base md:text-xl">
						Required data
					</p>
					<div className=" flex flex-col justify-center gap-5 mt-5 ">
						<div className=" mt-5 w-full">
							<TextField
								className="w-full"
								required
								InputProps={{
									readOnly: true,
								}}
								id="aquirer-name"
								label="Acquirer Name"
								variant="outlined"
								placeholder="Acquirer Name"
								InputLabelProps={{ shrink: true }}
								value={
									registrationModal &&
									registrationModal.acquiringName +
										' - ' +
										registrationModal.bankCode
								}
							/>
						</div>

						<div className=" w-full">
							<TextField
								className="w-full"
								required
								id="main-url"
								label="Main URL"
								variant="outlined"
								placeholder="Main URL"
								InputLabelProps={{ shrink: true }}
								onChange={(text) =>
									inputModal(
										'bankacquiringmainurl',
										text.target.value
									)
								}
								value={
									registrationModal &&
									registrationModal.bankacquiringmainurl
										? registrationModal.bankacquiringmainurl
										: ''
								}
							/>
						</div>

						<div className=" w-full">
							<TextField
								className="w-full"
								required
								id="auth-credential"
								label="Auth Credential"
								variant="outlined"
								placeholder="Auth Credential"
								InputLabelProps={{ shrink: true }}
								onChange={(text) =>
									inputModal('authcredential', text.target.value)
								}
								value={
									registrationModal &&
									registrationModal.authcredential
										? registrationModal.authcredential
										: ''
								}
							/>
						</div>
					</div>

					<div className=" flex mt-5 justify-between">
						{/* {registrationModal && registrationModal.syncCode && ( */}
						<div className="">
							<Button
								customFunc={() => dataGetSync()}
								bgColor={'orange'}
								width="60"
								height="fit"
								borderRadius={'10px'}
								text={'Sync data'}
								color={'white'}
							/>
						</div>
						{/* )} */}
						<div className="">
							<Button
								customFunc={() => dataUpdate()}
								bgColor={'red'}
								width="60"
								height="fit"
								borderRadius={'10px'}
								text={
									registrationModal && registrationModal.syncCode
										? 'Update'
										: 'Create'
								}
								color={'white'}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default AcquiringPayment;
