import LogoMKP from '../../data/A2Plogo.png';
import HeaderPDF from '../../data/HeaderPDF.png';

import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	body: {
		paddingTop: 20,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	title: {
		fontSize: 24,
		textAlign: 'center',
	},
	textHeader: {
		fontSize: 11,
		textAlign: 'justify',
		color: '#3B3B3B',
		marginBottom: 5,
	},
	textHeader2: {
		fontSize: 11,
		textAlign: 'justify',
		marginBottom: 5,
	},
	text1: {
		margin: 12,
		fontSize: 11,
		textAlign: 'justify',
	},
	text2: {
		top: 5,
		margin: 12,
		fontSize: 11,
		textAlign: 'justify',
		left: 96,
		marginLeft: 96,
		position: 'absolute',
		width: 400,
	},
	image: {
		marginVertical: 15,
		marginHorizontal: 100,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
	section: {
		backgroundColor: '#F8F8F8',
		width: '100%',
		padding: 10,
		paddingTop: 5,
		paddingBottom: 5,
		flexDirection: 'row',
	},
	section2: {
		backgroundColor: '#ffffff',
		width: '100%',
		padding: 10,
		paddingTop: 5,
		paddingBottom: 5,
		flexDirection: 'row',
	},
});

const document = (successData) => {
	return (
		<Document>
			<Page style={styles.body}>
				<Image
					src={LogoMKP}
					style={{ width: 130, height: 100, alignSelf: 'center' }}
				/>
				<Image
					src={HeaderPDF}
					style={{
						height: 30,
						width: '100%',
						alignSelf: 'center',
						marginBottom: 20,
					}}
				/>
				<View style={{ flexDirection: 'row', marginBottom: 10 }}>
					<View style={{ alignItems: 'flex-end' }}>
						<Text style={styles.textHeader}>Periode:</Text>
						<Text style={styles.textHeader}>Status Transaksi:</Text>
						<Text style={styles.textHeader}>Corporate:</Text>
						<Text style={styles.textHeader}>
							Kategori Pembayaran:
						</Text>
					</View>
					<View style={{ marginLeft: 10 }}>
						<Text style={styles.textHeader2}>
							{successData.startDate} - {successData.endDate}
						</Text>
						<Text style={styles.textHeader2}>
							{successData.status}
						</Text>
						<Text style={styles.textHeader2}>
							{successData.corporate}
						</Text>
						<Text style={styles.textHeader2}>
							{successData.pembayaran}
						</Text>
					</View>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Jumlah Transaksi:</Text>
					<Text style={styles.text2}>
						{successData.jumlahTrx.format02()} Transaksi
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Tunai:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalTunai.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Lainnya:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalLain.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total CC:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalCC.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Debit:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalDebit.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total QRIS:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalQRIS.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Brizzi:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalBrizzi.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total E-Money:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalEMoney.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Tapcash:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalTapCash.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Flazz:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalFlazz.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total JakCard:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalJakcard.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total VA:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalVA.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Bruto:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalBruto.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total MDR:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalMDR.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Potongan:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalPotongan.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Service Fee:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalServiceFee.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Payment Fee:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalPaymentFee.format()}
					</Text>
				</View>
				<View style={styles.section2}>
					<Text style={styles.text1}>Total Vendor Fee:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalVendorFee.format()}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text1}>Total Nett:</Text>
					<Text style={styles.text2}>
						Rp. {successData.totalNett.format()}
					</Text>
				</View>
				<Text
					style={styles.pageNumber}
					render={({ pageNumber, totalPages }) =>
						`© ${new Date().getFullYear()} MKP Mobile All rights reserved.`
					}
				/>
			</Page>
		</Document>
	);
};

export default document;
