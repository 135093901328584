import { data } from 'autoprefixer';
import React, { useEffect, useState } from 'react';
import { MdArrowBack, MdSave } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Header } from '../../../components';
import { BASE_URL, URL_AQUIRING_LIST } from '../../../config';
import { useStateContext } from '../../../contexts/ContextProvider';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Checkbox,
	TextField,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from 'react-switch';
import _ from 'lodash';

var CryptoJS = require('crypto-js');

const CorporatePaymentUpdate = () => {
	const { activeMenu, currentColor, currentAuth, setAuth } =
		useStateContext();

	const navigate = useNavigate();
	const { Id } = useParams();

	// Decrypt
	var byted = Id.replace(/p1L2u3S/g, '+')
		.replace(/s1L2a3S4h/g, '/')
		.replace(/e1Q2u3A4l/g, '=');

	var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
	var initialId = bytes.toString(CryptoJS.enc.Utf8);

	const notify = (message) => {
		toast.success(message, {
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const [acquiringData, setAcquiringData] = useState([]);
	const [payment, setPayment] = useState([]);
	const [active, setActive] = useState(false);
	const [allPayment, setAllPayment] = useState([]);

	const [cid, setCid] = useState('');
	const [uraian, setUraian] = useState('');
	const [kota, setKota] = useState('');
	const [provinsi, setProvinsi] = useState('');

	const [alias, setAlias] = useState('');
	const [opsPid, setOpsPid] = useState('');
	const [opsPidOffus, setOpsPidOffus] = useState('');

	const [expanded, setExpanded] = useState(null);

	// get data all payment method
	const getDataAllPayment = () => {
		fetch(BASE_URL + '/metodepembayaran/list', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					setAllPayment(
						_.sortBy(json.result, ['acquiringCode'], ['asc'])
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//get data of corporate payment by Id
	const getDataPayment = () => {
		const inputData = {
			idCorporate: parseInt(initialId),
		};

		fetch(BASE_URL + '/corppayment/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.result.metodePembayaran != null) {
					let mResult = _.cloneDeep(json.result.metodePembayaran);
					mResult.map((o) => {
						o.status = o.isActive ? o.isActive : false;
						return o;
					});

					setPayment(mResult);
				} else {
					setPayment([]);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//get data corporate
	const getDataCorporate = () => {
		const dataInput = {
			id: parseInt(initialId),
		};

		fetch(BASE_URL + '/corporate/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				const data = json.result;
				data.map((item) => {
					setCid(item.cid);
					setUraian(item.uraian);
					setKota(item.namaKota);
					setProvinsi(item.namaProvinsi);
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getDataAcquiring = () => {
		let mData = {
			order: 'ASC',
			orderBy: 'id',
			limit: 999,
			page: 1,
		};

		fetch(BASE_URL + URL_AQUIRING_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (json.result != null) {
						let mResult = _.cloneDeep(json.result);
						mResult = mResult.map((o) => {
							o.label = o.acquiringName;
							o.value = o.acquiringCode;

							return o;
						});

						setAcquiringData(mResult);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	const handleInput = (text, data, keyUpdate) => {
		let mPayment = _.cloneDeep(payment);

		let mIndex = mPayment.findIndex(
			(o) => o.idMetodePembayaran == data.id
		);

		if (mIndex != -1) {
			mPayment[mIndex][keyUpdate] = text;

			if (mPayment[mIndex].status == false) {
				mPayment[mIndex].mdrAmount = 0;
				mPayment[mIndex].mdrPercentageStatus = false;
				mPayment[mIndex].mdrIsExclude = false;
				mPayment[mIndex].samnum = 0;
				mPayment[mIndex].opsPid = '';
				mPayment[mIndex].gatewayPaymentCode = '';
			}
		} else {
			let obj = {
				idMetodePembayaran: data.id,
				uraian: data.uraian,
				samnum: 0,
				opsPid: '',
				mdrAmount: 0,
				mdrPercentageStatus: false,
				mdrIsExclude: false,
				status: true,
				gatewayPaymentCode: '',
			};

			mPayment.push(obj);
		}

		setPayment(mPayment);
	};

	// function untuk button simpan
	const onClicked = () => {
		// let dataFilter = payment.filter((o) => o.status);
		let mAllPayment = _.cloneDeep(allPayment);
		let a = mAllPayment.map((data) => {
			let mFilter = payment.filter(
				(o) => o.idMetodePembayaran == data.id
			);

			let mData = {};
			if (mFilter.length == 1) {
				mData = mFilter[0];
				mData.status = mData.status ? mData.status : false;
			} else {
				mData.idMetodePembayaran = data.id;
				mData.uraian = data.uraian;
				mData.samnum = 0;
				mData.opsPid = '';
				mData.mdrAmount = 0;
				mData.mdrPercentageStatus = false;
				mData.mdrIsExclude = false;
				mData.alias = '';
				mData.opsPidOffUs = '';
				mData.status = false;
				mData.gatewayPaymentCode = '';
			}

			return mData;
		});

		let dataMap = _.cloneDeep(a).map((o) => {
			o.mdrAmount =
				o.mdrAmount > 0 ? parseFloat(o.mdrAmount) : o.mdrAmount;
			if (o.status) {
				o.isActive = o.status;
			} else {
				o.isActive = false;
			}
			return o;
		});

		const inputData = {
			idCorporate: parseInt(initialId),
			metodePembayaran: dataMap,
		};

		fetch(BASE_URL + '/corppayment/edit', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success === true) {
					notify(json.message);
					console.log(json);
				} else {
					notifyErr(json.message);
					console.log(json);
				}
			})
			.catch((err) => {
				notifyErr(err.message);
			});
	};

	useEffect(() => {
		getDataPayment();
		getDataCorporate();
		if (allPayment.length == 0) getDataAllPayment();
		// if (acquiringData.length == 0) getDataAcquiring();
	}, []);

	return (
		<div className={`  ${activeMenu ? '-ml-0' : ''}`}>
			<div
				className={` h-fit m-2 mt-16 md:m-10 p-4 md:p-10  rounded-3xl bg-white
            ${activeMenu ? 'max-w-full ' : ' max-w-full'}`}
			>
				<Header title="Active Payment" />

				<div className=" md:ml-10">
					<div className=" flex">
						<div className="">
							<p className=" text-lg mb-5">CID</p>
							<p className="mt-5 mb-5 text-lg">Description</p>
							<p className="mt-5 mb-5 text-lg">City</p>
							<p className="mt-5 mb-5 text-lg">Payment</p>
						</div>

						<div className=" ml-10 md:ml-10">
							<p className="text-lg  mb-5">{cid}</p>
							<p className="text-lg mt-5 mb-5">{uraian}</p>
							<p className=" text-lg mt-5 mb-5">
								{kota}, {provinsi}
							</p>
						</div>
					</div>
					<div className="flex flex-col">
						{allPayment.map((Data, index) => {
							const selectedPayment = payment.some((item) => {
								return (
									item.idMetodePembayaran == Data.id && item.status
								);
							});

							const selectedPaymentIndex = payment.findIndex(
								(item) => {
									return item.idMetodePembayaran === Data.id;
								}
							);

							return (
								<Accordion
									expanded={expanded === Data.id && selectedPayment}
									onChange={() =>
										setExpanded(expanded == Data.id ? null : Data.id)
									}
									key={index}
									className={`md:flex mb-3 flex-col`}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1bh-content"
										id="panel1bh-header"
									>
										<div
											className={`md:flex row items-start md:flex-wrap w-full`}
										>
											<div>
												<Checkbox
													defaultChecked={selectedPayment}
													id={Data.id}
													type={'checkbox'}
													value={selectedPayment}
													checked={selectedPayment}
													onChange={(e) =>
														handleInput(
															payment[selectedPaymentIndex] ===
																undefined
																? false
																: !payment[selectedPaymentIndex]
																		.status,
															Data,
															'status'
														)
													}
												/>
											</div>
											<div
												className={`md:flex items-center row ${
													selectedPayment
														? ''
														: 'pointer-events-none opacity-30'
												}`}
											>
												<div className="mt-1 ml-3 md:w-40 flex flex-col">
													<span htmlFor={Data.id} className="text-lg">
														{Data.alias}
													</span>
													<span className="text-xs">
														{Data.acquiringName}
													</span>
												</div>
												<div className="mt-1 md:ml-5 md:w-28">
													<TextField
														size="small"
														id={Data.id}
														disabled={active}
														placeholder="SAM Slot"
														label="SAM Slot"
														variant="outlined"
														InputLabelProps={{ shrink: true }}
														type={'number'}
														value={
															payment[selectedPaymentIndex] ===
															undefined
																? ''
																: payment[selectedPaymentIndex]
																		.samnum === 0
																? ''
																: payment[selectedPaymentIndex].samnum
														}
														onChange={(e) =>
															handleInput(
																Number(e.target.value),
																Data,
																'samnum'
															)
														}
													/>
												</div>
												<div className="mt-1 md:ml-5 md:w-auto">
													<label className="md:flex md:flex-col">
														<span className="text-xs mb-2">
															MDR Excluded
														</span>
														<Switch
															height={20}
															handleDiameter={10}
															onChange={(e) => {
																handleInput(
																	payment[selectedPaymentIndex] ===
																		undefined
																		? false
																		: !payment[selectedPaymentIndex]
																				.mdrIsExclude,
																	Data,
																	'mdrIsExclude'
																);
															}}
															checked={
																payment[selectedPaymentIndex] ===
																undefined
																	? false
																	: payment[selectedPaymentIndex]
																			.mdrIsExclude
															}
														/>
													</label>
												</div>

												<div className="mt-1 md:ml-5 md:w-auto">
													<label className="md:flex md:flex-col">
														<span className="text-xs mb-2">
															is Percentage
														</span>
														<Switch
															height={20}
															handleDiameter={10}
															onChange={(e) => {
																handleInput(
																	payment[selectedPaymentIndex] ===
																		undefined
																		? false
																		: !payment[selectedPaymentIndex]
																				.mdrPercentageStatus,
																	Data,
																	'mdrPercentageStatus'
																);
															}}
															checked={
																payment[selectedPaymentIndex] ===
																undefined
																	? false
																	: payment[selectedPaymentIndex]
																			.mdrPercentageStatus
															}
														/>
													</label>
												</div>

												<div className=" mt-1 md:ml-5 md:w-24 ">
													<TextField
														size="small"
														id={Data.id}
														disabled={active}
														placeholder="MDR"
														label="MDR"
														variant="outlined"
														InputLabelProps={{ shrink: true }}
														type={'number'}
														value={
															payment[selectedPaymentIndex] ===
															undefined
																? ''
																: String(
																		payment[selectedPaymentIndex]
																			.mdrAmount
																  )
														}
														onChange={(e) =>
															handleInput(
																e.target.value,
																Data,
																'mdrAmount'
															)
														}
													/>
												</div>
											</div>
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div
											key={index}
											className={`md:flex flex-col w-full ${
												selectedPayment
													? ''
													: 'pointer-events-none opacity-30'
											}`}
										>
											<div className="mt-3 md:ml-5 flex flex-row items-center">
												<span className="text-lg md:w-56">
													Alias Name
												</span>
												<TextField
													size="small"
													id={Data.id}
													disabled={active}
													placeholder="Alias Name"
													value={
														payment[selectedPaymentIndex] ===
														undefined
															? ''
															: payment[selectedPaymentIndex].alias
													}
													onChange={(e) =>
														handleInput(e.target.value, Data, 'alias')
													}
												/>
											</div>
											<div className="mt-3 md:ml-5 flex flex-row items-center">
												<span className="text-lg md:w-56">
													Operation ID ON_US
												</span>
												<TextField
													size="small"
													id={Data.id}
													disabled={active}
													placeholder="PID ON_US"
													value={
														payment[selectedPaymentIndex] ===
														undefined
															? ''
															: payment[selectedPaymentIndex].opsPid
													}
													onChange={(e) =>
														handleInput(
															e.target.value,
															Data,
															'opsPid'
														)
													}
												/>
											</div>
											<div className="mt-3 md:ml-5 flex flex-row items-center">
												<span className="text-lg md:w-56">
													Operation ID OFF_US
												</span>
												<TextField
													size="small"
													id={Data.id}
													disabled={active}
													placeholder="PID OFF_US"
													value={
														payment[selectedPaymentIndex] ===
														undefined
															? ''
															: payment[selectedPaymentIndex]
																	.opsPidOffUs
													}
													onChange={(e) =>
														handleInput(
															e.target.value,
															Data,
															'opsPidOffUs'
														)
													}
												/>
											</div>
											<div className="mt-3 md:ml-5 flex flex-wrap flex-row items-center">
												<span className="text-lg md:w-56">
													Payment Code Routing
												</span>
												<TextField
													size="small"
													id={Data.id}
													disabled={active}
													placeholder="1002xx"
													value={
														payment[selectedPaymentIndex] ===
														undefined
															? ''
															: payment[selectedPaymentIndex]
																	.gatewayPaymentCode
													}
													onChange={(e) =>
														handleInput(
															e.target.value,
															Data,
															'gatewayPaymentCode'
														)
													}
												/>
											</div>
										</div>
									</AccordionDetails>
									{/* <hr className="mt-2" /> */}
								</Accordion>
							);
						})}
					</div>
				</div>
				<div className=" justify-between mt-3 flex ">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						borderRadius={'10px'}
						color="white"
						text="Previous Page"
						icon={<MdArrowBack />}
					/>
					<div className=" mr-14 md:mr-10">
						<Button
							customFunc={() => onClicked()}
							bgColor={currentColor}
							borderRadius={'10px'}
							color="white"
							text="Submit"
							icon={<MdSave />}
						/>
					</div>
				</div>
				<ToastContainer limit={1} autoClose={2500} />
			</div>
		</div>
	);
};

export default CorporatePaymentUpdate;
