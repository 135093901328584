import React, { useState } from "react";
import { MdClose, MdEdit, MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import { checkIsAvailableModule } from "../../../Routes";
import { Drawer, Popover, Tooltip, Typography } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";

const CryptoJS = require("crypto-js");

export const SoundboxUserAction = ({ row }) => {
  const { privilege } = useStateContext();

  // Encrypt
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(row),
    "nasirames"
  ).toString();

  var enc = ciphertext
    .replace(/\+/g, "p1L2u3S")
    .replace(/\//g, "s1L2a3S4h")
    .replace(/=/g, "e1Q2u3A4l");

  const navigate = useNavigate();

  return (
    <div className="flex gap-2">
      {checkIsAvailableModule("EDIT_CONFIG_CORPORATE", privilege) && (
        <button
          type="button"
          onClick={() => navigate(`/update-soundbox-user/${enc}`)}
          className=" text-2xl text-green-500 hover:drop-shadow-xl"
        >
          <MdEdit />
        </button>
      )}
    </div>
  );
};

export const Copy = ({ label, value }) => {
  const [popOver, setPopOver] = useState(false);
  return (
    <div className="flex w-full">
      <CopyToClipboard text={value}>
        <button
          onClick={() => {
            setPopOver(true);
          }}
          type="button"
          className=" text-2xl hover:drop-shadow-xl ml-1"
        >
          <MdContentCopy />
        </button>
      </CopyToClipboard>

      {/* copied to clipboardnotification */}
      <Popover
        open={popOver}
        onClose={() => setPopOver(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 2 }}>{label} copied to clipboard!</Typography>
      </Popover>
    </div>
  );
};

export const SoundBoxListTrxAction = ({ row }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const DrawerContent = ({ row }) => (
    <div className="w-screen md:w-400">
      <div className="w-full flex shadow-md p-5 mb-3 justify-between items-center">
        <h3>Detail Data</h3>
        <MdClose
          onClick={() => setOpenDrawer(false)}
          className="cursor-pointer"
        />
      </div>
      <div className="p-5 pt-0">
        {Object.entries(row)
          .filter((item) => item[0] !== "id")
          .map((item) => (
            <div className="my-3">
              <div className="capitalize font-semibold">
                {item[0].replace(/([a-z])([A-Z])/g, "$1 $2")} :
              </div>
              <div>{!item[1] ? "-" : item[1]}</div>
            </div>
          ))}
      </div>
    </div>
  );
  return (
    <div className=" ">
      <Tooltip title="SEE DETAILS">
        <button
          className=" text-2xl text-blue-500"
          onClick={() => setOpenDrawer(true)}
        >
          <MdRemoveRedEye />
        </button>
      </Tooltip>

      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {<DrawerContent row={row} />}
      </Drawer>
    </div>
  );
};
