import React, { useState, useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { RiLoader4Line } from 'react-icons/ri';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

const BarChart = ({
	dateStart,
	dateEnd,
	loading,
	timePeriod,
	data,
	title,
}) => {
	// context states
	const { currentColor } = useStateContext();

	// data state
	const [countRequest, setCountRequest] = useState([]);
	const [successCount, setSuccessCount] = useState([]);
	const [failedCount, setFailedCount] = useState([]);

	const xaxisCategories =
		timePeriod === 'monthly'
			? Array.from({ length: 12 }, (_, i) =>
					moment().month(i).format('MMM')
			  )
			: timePeriod === 'weekly'
			? Array.from({ length: 5 }, (_, i) => `Week ${i + 1}`)
			: timePeriod === 'daily'
			? Array.from(
					{ length: moment(dateStart).daysInMonth() },
					(_, i) =>
						moment(dateStart)
							.date(i + 1)
							.format('DD MMM')
			  )
			: [];

	// chart configuration
	const state = {
		series: [
			{
				name: 'All Request',
				data:
					timePeriod === 'daily'
						? countRequest
						: data.map((item, i) => {
								return {
									x: i,
									y: item.countRequest,
									goals:
										item.countRequest > 0
											? [
													{
														name: 'Sent',
														value: item.sentCount,
														strokeDashArray: 3,
														strokeWidth: 15,
														strokeHeight: 5,
														strokeColor: '#DEF3FC',
													},
													{
														name: 'Success',
														value: item.successCount,
														strokeWidth: 15,
														strokeHeight: 5,
														strokeColor: '#6DCA61',
													},
													{
														name: 'Failed',
														value: item.failedCount,
														strokeWidth: 15,
														strokeHeight: 5,
														strokeColor: '#FC476A',
													},
											  ]
											: [],
								};
						  }),
			},
		],
		// series: [
		// 	{
		// 	  name: "Count Request",
		// 	  data:
		// 	    timePeriod === "daily"
		// 	      ? countRequest
		// 	      : data.map((item) => item.countRequest),
		// 	},
		// 	{
		// 		name: 'Failed Count',
		// 		data:
		// 			timePeriod === 'daily'
		// 				? failedCount
		// 				: data.map((item) => item.failedCount),
		// 	},
		// 	{
		// 		name: 'Success Count',
		// 		data:
		// 			timePeriod === 'daily'
		// 				? successCount
		// 				: data.map((item) => item.successCount),
		// 	},
		// ],
		options: {
			chart: {
				id: title,
				type: 'bar',
				height: 350,
				toolbar: {
					export: {
						csv: {
							filename: `${title} - ${dateStart}-${dateEnd}`,
						},
						svg: {
							filename: `${title} - ${dateStart}-${dateEnd}`,
						},
						png: {
							filename: `${title} - ${dateStart}-${dateEnd}`,
						},
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '70%',
					endingShape: 'rounded',
				},
			},
			// dataLabels: { enabled: false },
			dataLabels: {
				formatter: function (val, opt) {
					const goals =
						opt.w.config.series[opt.seriesIndex].data[
							opt.dataPointIndex
						].goals;

					if (goals && goals.length) {
						// return `${val} / ${goals[0].value}`;
						return `${val}`;
					}
					return val;
				},
			},
			// stroke: {
			// 	show: true,
			// 	width: 1,
			// 	colors: ['transparent'],
			// },
			// colors: ['#FF0000', '#319932'],
			colors: ['#538AEA'],
			// colors: [currentColor, '#FF0000', '#329932'],
			xaxis: { categories: xaxisCategories },
			yaxis: {
				title: { text: title },
				labels: {
					formatter: function (value) {
						return value >= 1000000000
							? (value / 1000000000).toFixed(0) + ' Miliar'
							: value.toLocaleString();
					},
				},
			},
			fill: { opacity: 1 },
			legend: {
				show: true,
				showForSingleSeries: true,
				customLegendItems: [
					'All Request',
					'Sent',
					'Success',
					'Failed',
				],
				markers: {
					fillColors: ['#538AEA', '#DEF3FC', '#6DCA61', '#FC476A'],
				},
			},
		},
	};

	// run every filter button clicked, initial mount, or time period changes
	useEffect(() => {
		let updatedData = [];
		let updatedData2 = [];
		let updatedData3 = [];

		// fill the array with zeroes data
		const daysInMonth = moment(dateStart, 'D MMM YYYY').daysInMonth(); // Get the number of days in the month
		updatedData = new Array(daysInMonth).fill(0);
		updatedData2 = new Array(daysInMonth).fill(0);
		updatedData3 = new Array(daysInMonth).fill(0);

		// for each object fill the array with the data corresponding to the datePart index
		data.forEach((item) => {
			const index = item.datePart - 1; // Convert 1-based index to 0-based
			updatedData[index] = item.countRequest;
			updatedData2[index] = item.successCount;
			updatedData3[index] = item.failedCount;
		});

		setCountRequest(updatedData);
		setSuccessCount(updatedData2);
		setFailedCount(updatedData3);
	}, [timePeriod, dateStart, data]);

	return (
		<div className="w-full">
			<div hidden={!loading}>
				<p style={{ color: currentColor }}>
					<RiLoader4Line className="animate-spin ml-32 md:ml-80 z-50 mt-48 text-5xl md:text-7xl absolute" />
				</p>
			</div>

			<ReactApexChart
				options={state.options}
				series={state.series}
				type="bar"
				height={500}
			/>
		</div>
	);
};

export default BarChart;
