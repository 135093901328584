import React, { useEffect, useState } from 'react';

import { useStateContext } from '../../contexts/ContextProvider';
import { BASE_URL } from '../../config';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useNavigate } from 'react-router-dom';
import { RiLoader4Line } from 'react-icons/ri';
import moment from 'moment';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';

const Stacked = ({ Corp }) => {
	const { currentColor, currentAuth, setAuth, dataLogin } =
		useStateContext();

	const navigate = useNavigate();

	const [timePeriod, setTimePeriod] = useState('monthlyrevenue');

	const [count, setCount] = useState([]);
	const [loading, setLoading] = useState(true);

	const [timePeriodDesc, setTimePeriodDesc] = useState('');

	//get data revenue
	const [revenueData, setRevenueData] = useState([]);

	const getRevenueData = () => {
		setLoading(false);

		const inputData = {
			corporate: Corp,
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(inputData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			`/dashboard/${timePeriod}/v2` +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + `/dashboard/${timePeriod}/v2`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					setRevenueData([]);
					setLoading(true);
				} else {
					if (data.result != null) {
						setRevenueData(data.result);

						const countData = data.result.map((item) => {
							return item.transaction_count;
						});

						if (timePeriod == 'dailyrevenue') {
							if (data.result && data.result.length > 0)
								setTimePeriodDesc(
									moment(data.result[0].day).format('DD MMM YYYY') +
										' s.d ' +
										moment(
											data.result[data.result.length - 1].day
										).format('DD MMM YYYY')
								);
						} else if (timePeriod == 'weeklyrevenue') {
							setTimePeriodDesc(
								'Weekly in ' + moment().format('MMMM')
							);
						} else if (timePeriod == 'monthlyrevenue') {
							setTimePeriodDesc(
								'Monthly in ' + moment().format('YYYY')
							);
						} else if (timePeriod == 'yearlyrevenue') {
							setTimePeriodDesc('Yearly');
						}

						setCount(countData);
						setLoading(true);
					} else {
						setRevenueData([]);
						setLoading(true);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (Corp && timePeriod) {
			setTimePeriodDesc('');
			getRevenueData();
		}
	}, [timePeriod, Corp]);

	useEffect(() => {
		const tooltipOps = {
			tooltip: {
				y: {
					formatter: function (
						val,
						{ series, seriesIndex, dataPointIndex, w }
					) {
						return (
							'Rp. ' +
							val.format() +
							' / ' +
							count[dataPointIndex].format() +
							' Transaction'
						);
					},
				},
			},
		};
		ApexCharts.exec(
			state.options.chart.id,
			'updateOptions',
			tooltipOps
		);
		// ApexCharts.exec(state.options.chart.id);
	}, [count]);

	const state = {
		series: [
			{
				name: 'Revenue',
				data: revenueData.map((item) => {
					return item.sum_transactions;
				}),
			},
		],
		options: {
			chart: {
				id: 'chartStacked',
				type: 'bar',
				height: 350,
				width: '100%',
				toolbar: {
					export: {
						csv: {
							filename: 'Revenue ' + timePeriodDesc,
						},
						svg: {
							filename: 'Revenue ' + timePeriodDesc,
						},
						png: {
							filename: 'Revenue ' + timePeriodDesc,
						},
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '55%',
					endingShape: 'rounded',
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			colors: [currentColor],
			xaxis:
				timePeriod === 'monthlyrevenue'
					? {
							categories: [
								'Jan',
								'Feb',
								'Mar',
								'Apr',
								'May',
								'Jun',
								'Jul',
								'Aug',
								'Sep',
								'Oct',
								'Nov',
								'Dec',
							],
					  }
					: timePeriod === 'weeklyrevenue'
					? {
							categories: [
								'Week 1',
								'Week 2',
								'Week 3',
								'Week 4',
								'Week 5',
								'Week 6',
								'Week 7',
								'Week 8',
								'Week 9',
								'Week 10',
								'Week 11',
								'Week 12',
							],
					  }
					: timePeriod === 'dailyrevenue'
					? {
							categories: revenueData.map((item) => {
								return moment(item.day).format('DD MMM');
							}),
					  }
					: timePeriod === 'yearlyrevenue'
					? {
							categories: revenueData.map((item) => {
								return item.year;
							}),
					  }
					: '',
			yaxis: {
				title: {
					text: 'Revenue (Rp)',
				},
				labels: {
					formatter: function (value) {
						return value >= 1000000000
							? (value / 1000000000).toFixed(0) + ' Miliar'
							: value.format();
					},
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (
						val,
						{ series, seriesIndex, dataPointIndex, w }
					) {
						return (
							'Rp. ' +
							val.format() +
							' / ' +
							count[dataPointIndex].format() +
							' Transaction'
						);
					},
				},
			},
		},
	};

	const width = window.innerWidth;

	return (
		<div className=" w-full">
			<p className=" text-xl font-bold">REVENUE</p>
			<p>
				Revenue Chart{' '}
				{timePeriodDesc ? '(' + timePeriodDesc + ')' : ''}
			</p>
			<div className=" mt-5 md: flex gap-5 justify-center">
				<div className=" md:flex gap-5">
					<div className="  justify-center flex">
						<input
							type="radio"
							id="dailyrevenue"
							name="paket"
							value="dailyrevenue"
							className=" cursor-pointer "
							onChange={(e) => setTimePeriod(e.target.value)}
							defaultChecked={timePeriod === 'dailyrevenue'}
						/>
						<label
							htmlFor="dailyrevenue"
							className="ml-2  md:text-base cursor-pointer"
						>
							Daily
						</label>
					</div>
					<div className="justify-center flex">
						<input
							type="radio"
							id="weeklyrevenue"
							name="paket"
							value="weeklyrevenue"
							className=" cursor-pointer "
							onChange={(e) => setTimePeriod(e.target.value)}
							defaultChecked={timePeriod === 'weeklyrevenue'}
						/>
						<label
							htmlFor="weeklyrevenue"
							className="ml-2  md:text-base cursor-pointer"
						>
							Weekly
						</label>
					</div>
				</div>
				<div className=" md:flex gap-5">
					<div className="justify-center flex">
						<input
							type="radio"
							id="monthlyrevenue"
							name="paket"
							value="monthlyrevenue"
							className=" cursor-pointer "
							onChange={(e) => setTimePeriod(e.target.value)}
							defaultChecked={timePeriod === 'monthlyrevenue'}
						/>
						<label
							htmlFor="monthlyrevenue"
							className="ml-2 md:text-base cursor-pointer"
						>
							Monthly
						</label>
					</div>
					<div className="justify-center flex">
						<input
							type="radio"
							id="yearlyrevenue"
							name="paket"
							value="yearlyrevenue"
							className=" cursor-pointer "
							onChange={(e) => setTimePeriod(e.target.value)}
							defaultChecked={timePeriod === 'yearlyrevenue'}
						/>
						<label
							htmlFor="yearlyrevenue"
							className="ml-2  md:text-base cursor-pointer"
						>
							Yearly
						</label>
					</div>
				</div>
			</div>

			<div hidden={loading}>
				<p style={{ color: currentColor }}>
					<RiLoader4Line className=" animate-spin ml-32 md:ml-80 z-50  mt-48 text-5xl md:text-7xl absolute " />
				</p>
			</div>

			<ReactApexChart
				options={state.options}
				series={state.series}
				type="bar"
				height={500}
				// width={width < 1200 ? 300 : activeMenu ? 640 : 860}
			/>
		</div>
	);
};

export default Stacked;
