import React from "react";

import { useStateContext } from "../contexts/ContextProvider";

const Button = ({
  customFunc,
  icon,
  bgColor,
  color,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width = "auto",
  width2 = 28,
  height,
  type = "button",
  className = null,
  disabled = false,
}) => {
  const { setIsClicked, initialState } = useStateContext();

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={() => customFunc && customFunc()}
      style={{ backgroundColor: disabled ? "#64748b" : bgColor, color, borderRadius }}
      className={` flex items-center gap-3 text-${size} p-3 w-${width2} md:w-${width} justify-center ${disabled ? "" : "hover:drop-shadow-xl"} hover:bg-${bgHoverColor} h-${height} ${
        className ? className : ""
      }`}
    >
      {icon} {text}
    </button>
  );
};

export default Button;
