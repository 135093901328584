import React, { useEffect, useState } from 'react';
import { GrCircleAlert } from 'react-icons/gr';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../config';
import { Header } from '../../components';
import Button from '../../components';
import { listTrxProductDetail } from '../../data/columnData';
import DataTable from 'react-data-table-component';
import { useStateContext } from '../../contexts/ContextProvider';
import './TransactionDetail.css';
import notfound from '../../data/notfound.png';

var CryptoJS = require('crypto-js');

const customStyles = {
	rows: {
		style: {
			minHeight: '72px', // override the row height
		},
	},
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			// textTransform: "uppercase",
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '8px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
	// action: {
	//   style: {
	//     padding: "10px",
	//   },
	// },
};

const TransactionDetail = () => {
	const { Id } = useParams();

	// Decrypt
	var byted = Id.replace(/p1L2u3S/g, '+')
		.replace(/s1L2a3S4h/g, '/')
		.replace(/e1Q2u3A4l/g, '=');

	var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
	var initialId = bytes.toString(CryptoJS.enc.Utf8);

	const { activeMenu, currentAuth, modalOpenClose } =
		useStateContext();

	const [trxData, setTrxData] = useState({
		nomorHeader: '',
		merchantNoRef: '',
		createdAt: '',
		terimaTunai: 0,
		statusAwalTrx: '',
		statusTrx: '',
		metodePembayaran: '',
		resNoRef: '',
		cid: '',
		corporate: '',
		deviceId: '',
		cardPan: '',
		cardType: '',
		mid: '',
		tid: '',
		hargaJual: 0,
		diskon: 0,
		qty: 0,
		satuan: '',
		serviceFee: 0,
		batchNumber: '',
		terminalRefNumber: '',
		trxStart: '',
		trxEnd: '',
		tanggalBerlaku: '',
		tanggalExpired: '',
		responseTime: '',
		username: '',
	});
	const getOneData = () => {
		modalOpenClose(true);
		const dataBody = {
			idTrx: parseInt(initialId),
		};
		fetch(BASE_URL + '/listtrx/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// 'Access-Control-Allow-Origin': "*",
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success == true) {
					console.log(json.result, 'log data');
					//   setTrxData({
					//     nomorHeader: json.result.nomorHeader,
					//     merchantNoRef: json.result.merchantNoRef,
					//   });
					setTrxData({
						nomorHeader: json.result.nomorHeader, //
						merchantNoRef: json.result.merchantNoRef, //
						createdAt: json.result.createdAt, //
						terimaTunai: json.result.terimaTunai, ///
						statusAwalTrx: json.result.statusAwalTrx, //
						statusTrx: json.result.statusTrx, //
						metodePembayaran: json.result.metodePembayaran, ///
						resNoRef: json.result.resNoRef, //
						cid: json.result.cid, ///
						corporate: json.result.corporate, //
						deviceId: json.result.deviceId, //
						cardPan: json.result.cardPan, //
						cardType: json.result.cardType, //
						mid: json.result.mid, //
						tid: json.result.tid, //
						hargaJual: json.result.hargaJual, //
						diskon: json.result.paymentDisc, //
						qty: 0,
						satuan: 0,
						serviceFee: json.result.serviceFee,
					});
					modalOpenClose(false);
				}
			})
			.catch((err) => {
				console.log('Error! ', err);
				modalOpenClose(false);
			});
		return <p className=" font-bold text-2xl">{trxData}</p>;
	};

	// get detail produk transaction
	const [productDetail, setProductDetail] = useState([]);
	const getProductTrx = () => {
		const inputData = {
			idTrx: parseInt(initialId),
		};
		fetch(BASE_URL + '/listtrx/getproduk', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log(json);
				if (json.success === true) {
					let data = [];
					console.log(json.result);
					data.push(json.result);
					setProductDetail(data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [hargaJual, setHargaJual] = useState(0);
	const [diskon, setDiskon] = useState(0);
	const [mdr, setMdr] = useState(0);
	const [potongan, setPotongan] = useState(0);
	const [serviceFee, setServiceFee] = useState(0);

	const setDataMain = () => {
		productDetail.map((item) => {
			setHargaJual(item.hargaJual);
			setDiskon(item.diskon);
			setServiceFee(item.serviceFee);
		});
	};

	useEffect(() => {
		getOneData();
		// getProductTrx();
		setDataMain();
	}, []);

	const width = window.innerWidth;
	return (
		<div
			className={` h-fit md:m-10 p-2 md:p-10 md:rounded-3xl bg-white -mt-36 px-5 `}
		>
			<div className=" bg-[#E8EAF6] h-fit  mt-24 md:mt-0 p-4 md:p-10 md:px-40 rounded-2xl md:pb-24 ">
				<div className=" mt-3 md:mt-0">
					<div className=" justify-between  md:flex ml-5 md:-ml-24">
						<div className="">
							<p className="  text-[#3F51B5] text-sm md:text-base font-body">
								Harga Jual
							</p>
							<p className="  absolute text-[#3F51B5] font-semibold text-xl md:text-3xl">
								Rp.{' '}
								{trxData.hargaJual ? trxData.hargaJual.format() : 0}
							</p>
						</div>
						<div className="  mt-12 md:mt-0">
							<p className="  text-[#3F51B5] text-sm md:text-base font-body">
								Diskon
							</p>
							<p className="absolute  text-[#3F51B5] font-semibold text-xl md:text-3xl">
								Rp.{' '}
								{trxData.paymentDisc
									? trxData.paymentDisc.format()
									: 0}
							</p>
						</div>
						<div className="mt-12 md:mt-0">
							<p className="  text-[#3F51B5] text-sm md:text-base font-body">
								MDR
							</p>
							<p className="   absolute text-[#3F51B5] font-semibold text-xl md:text-3xl">
								Rp.{' '}
								{trxData.paymentMDR ? trxData.paymentMDR.format() : 0}
							</p>
						</div>
					</div>

					<div className=" mt-52  md:mt-16 justify-between md:flex ml-5 md:-ml-24 md:-mb-14">
						<div className=" -mt-40 md:mt-0">
							<p className="  text-[#3F51B5] text-sm md:text-base font-body">
								Potongan
							</p>
							<p className=" md:absolute text-[#3F51B5] font-semibold text-xl md:text-3xl">
								Rp. {trxData.potongan ? trxData.potongan.format() : 0}
							</p>
						</div>
						<div className="  mt-4 md:mt-0 mb-5 ">
							<p className="  text-[#3F51B5] text-sm md:text-base font-body">
								Service Fee
							</p>
							<p className=" md:absolute   text-[#3F51B5] font-semibold text-xl md:text-3xl">
								Rp.{' '}
								{trxData.serviceFee ? trxData.serviceFee.format() : 0}
							</p>
						</div>
						<div>
							<p className="  text-[#3F51B5] text-sm md:text-base font-body"></p>
							<p className="  absolute  text-[#3F51B5] font-semibold text-xl md:text-3xl"></p>
						</div>
					</div>
				</div>
			</div>

			{/* List Transaction Detail */}

			<div className=" ">
				<div className=" p-4 md:p-0 flex items-center gap-4 mt-10 mb-4 ">
					<p className=" text-2xl">
						<GrCircleAlert />
					</p>
					<p className=" text-base font-semibold">Detail Transaksi</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex ">
					<p className=" text-gray-600 md:absolute  text-sm md:text-base font-normal ">
						Nomor Header:
					</p>
					<p className=" text-gray-600  text-sm md:text-base  font-normal  md:ml-64  ">
						{trxData.nomorHeader ? trxData.nomorHeader : '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Merchant Noref:
					</p>
					<p className=" text-gray-600    md:-mt-0  text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.merchantNoRef ? trxData.merchantNoRef : '-'}
					</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Tanggal Dibuat:
					</p>
					<p className=" text-gray-600     text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.createdAt ? trxData.createdAt : '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Tanggal Berlaku:
					</p>
					<p className=" text-gray-600    text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.tanggalBerlaku ? trxData.tanggalBerlaku : '-'}
					</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Tanggal Expired:
					</p>
					<p className=" text-gray-600  text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.tanggalExpired ? trxData.tanggalExpired : '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Terima Tunai:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						Rp.{' '}
						{trxData.terimaTunai ? trxData.terimaTunai.format() : '-'}
					</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute mb-2  text-sm md:text-base  font-normal ">
						Status Awal Transaksi:
					</p>
					{trxData.statusAwalTrx === 'Pending' ? (
						<button
							style={{ background: '#FFF3E0' }}
							className={`py-2 px-3  md:-mt-1 text-sm   md:ml-64  rounded-2xl`}
						>
							<p
								className=" text-gray-600  font-normal"
								style={{ color: '#FFAA2C' }}
							>
								{trxData.statusAwalTrx}
							</p>
						</button>
					) : (
						<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
							-
						</p>
					)}
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute mb-2 text-sm md:text-base  font-normal ">
						Transaction Status:
					</p>
					<button
						style={
							trxData.statusTrx === 1
								? { background: '#E8F5E9' }
								: trxData.statusTrx === 2
								? { background: '#FFF3E0' }
								: trxData.statusTrx === 3
								? { background: '#FFF3E0' }
								: { background: '#E3F2FD' }
						}
						className={`py-2 px-3 md:-mt-1 text-sm  md:ml-64  rounded-2xl`}
					>
						{trxData.statusTrx === 1 ? (
							<p
								className=" font-normal"
								style={{ color: '#5AB55E' }}
							>
								Sukses
							</p>
						) : trxData.statusTrx === 2 ? (
							<p
								className=" font-normal"
								style={{ color: '#FFAA2C' }}
							>
								Pending
							</p>
						) : trxData.statusTrx === 3 ? (
							<p
								className=" font-normal"
								style={{ color: '#F5574C' }}
							>
								Gagal
							</p>
						) : (
							<p
								className=" font-normal"
								style={{ color: '#2B9BF4' }}
							>
								{trxData.statusTrx}
							</p>
						)}
					</button>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Metode Pembayaran:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.metodePembayaran
							? trxData.metodePembayaran
							: '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Response Nomor Ref:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.resNoRef ? trxData.resNoRef : '-'}
					</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						CID:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.cid ? trxData.cid : '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full h-auto ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Corporate:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.corporate ? trxData.corporate : '-'}
					</p>
				</div>

				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full h-auto">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Device ID:
					</p>
					<p className=" text-gray-600   text-xs md:text-base  font-normal   md:ml-64 ">
						{trxData.deviceId ? trxData.deviceId : '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Card PAN:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal   md:ml-64 ">
						{trxData.cardPan ? trxData.cardPan : '-'}
					</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute    text-sm md:text-base  font-normal ">
						Card Type:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.cardType ? trxData.cardType : '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						MID:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.mid ? trxData.mid : '-'}
					</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute    text-sm md:text-base  font-normal ">
						TID:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.tid ? trxData.tid : '-'}
					</p>
				</div>
				<div className=" bg-[#ffffff]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Batch Number:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.batchNumber ? trxData.batchNumber : '-'}
					</p>
				</div>
				<div className=" bg-[#F8F8F8]  p-4 md:flex w-full ">
					<p className=" text-gray-600 md:absolute   text-sm md:text-base  font-normal ">
						Terminal Reference Number:
					</p>
					<p className=" text-gray-600   text-sm md:text-base  font-normal  md:ml-64 ">
						{trxData.terminalRefNumber
							? trxData.terminalRefNumber
							: '-'}
					</p>
				</div>
			</div>

			<div className=" px-3 mt-5 mb-10">
				<DataTable
					columns={listTrxProductDetail}
					data={productDetail}
					theme="solarized"
					customStyles={customStyles}
					noDataComponent={<div></div>}
				/>
			</div>
		</div>
	);
};

export default TransactionDetail;
