import React from "react";
import pagenotfound from "../data/pagenotfound.png";

const PageNotFound = () => {
  return (
    <div className=" flex justify-center mt-36">
      <img src={pagenotfound} style={{ width: "auto", height: 500 }} />
    </div>
  );
};

export default PageNotFound;
