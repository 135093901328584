export const MatrixUtils = {
	isMobile: function isMobile() {
		return window && window.matchMedia('(max-width: 767px)').matches;
	},
	width: function width() {
		return window.innerWidth > 0
			? window.innerWidth
			: window.screen.width;
	},
	height: function height() {
		return window.innerHeight > 0
			? window.innerHeight
			: window.screen.height;
	},
};
