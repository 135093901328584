var CryptoJS = require('crypto-js');

const secretKey = 'MKPmobile123456@';

export const encrypt = (plainText) => {
	try {
		const ciphertext = CryptoJS.AES.encrypt(
			plainText,
			'MKPMobile123@'
		).toString();

		return ciphertext;
	} catch (error) {
		return null;
	}
};

export const decrypt = (chiperText) => {
	try {
		var bytes = CryptoJS.AES.decrypt(chiperText, 'MKPMobile123@');
		var initialId = bytes.toString(CryptoJS.enc.Utf8);
		return initialId;
	} catch (error) {
		return null;
	}
};

export const decryptSecre = (chiperText) => {
	try {
		var bytes = CryptoJS.AES.decrypt(
			chiperText,
			'MKPPAYMENTLINKAPPS2PAY1122334455',
			{
				iv: 'PAYMENTLINKA2P00', // parse the IV
				padding: CryptoJS.pad.Pkcs7,
				mode: CryptoJS.mode.CBC,
			}
		);
		var initialId = bytes.toString(CryptoJS.enc.Utf8);

		console.log('chiperText', chiperText);
		console.log('initialId', initialId);
		return initialId;
	} catch (error) {
		return null;
	}
};

export const urlEncrypt = (row) => {
	let chiper = CryptoJS.AES.encrypt(
		JSON.stringify(row),
		'nasirames'
	).toString();

	var enc = chiper
		.replace(/\+/g, 'p1L2u3S')
		.replace(/\//g, 's1L2a3S4h')
		.replace(/=/g, 'e1Q2u3A4l');

	return enc;
};

export const urlDecrypt = (url) => {
	try {
		var byted = url
			.replace(/p1L2u3S/g, '+')
			.replace(/s1L2a3S4h/g, '/')
			.replace(/e1Q2u3A4l/g, '=');

		var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
		var initialId = bytes.toString(CryptoJS.enc.Utf8);

		var data = JSON.parse(initialId);

		return data;
	} catch (err) {
		return null;
	}
};

export const encryptHmac512Base64 = (
	plainText,
	secretKeyCustom = null
) => {
	var secretKeyBytes = secretKeyCustom ? secretKeyCustom : secretKey;
	var payloadBytes = CryptoJS.enc.Utf8.parse(plainText);
	var signatureBytes = CryptoJS.HmacSHA512(
		payloadBytes,
		secretKeyBytes
	);

	var signatureBase64String =
		CryptoJS.enc.Base64.stringify(signatureBytes);

	return signatureBase64String;
};

export const encryptSHA512 = (plainText) => {
	return CryptoJS.SHA512(plainText).toString(CryptoJS.enc.Hex);
};

export const encryptHmac512 = (plainText, secretKeyCustom = null) => {
	var secretKeyBytes = secretKeyCustom ? secretKeyCustom : secretKey;
	var payloadBytes = CryptoJS.enc.Utf8.parse(plainText);
	var signatureBytes = CryptoJS.HmacSHA512(
		payloadBytes,
		secretKeyBytes
	);

	var signatureBase64String = signatureBytes.toString(
		CryptoJS.enc.Hex
	);

	return signatureBase64String;
};
