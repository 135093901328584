import { MdRadioButtonUnchecked } from 'react-icons/md';

export const SidebarData = [
	{
		id: 1,
		groupName: 'Dashboard',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'dashboard', // Link to route name
		nameLink: 'Dashboard',
		link: '/dashboard',
	},
	{
		id: 2,
		groupName: 'Master',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'acquiring-payment', // Link to route name
		nameLink: 'Acquirer Payment',
		link: '/acquiring-payment',
	},
	{
		id: 2,
		groupName: 'Master',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'issuing-payment', // Link to route name
		nameLink: 'Issuer Payment',
		link: '/issuing-payment',
	},
	{
		id: 2,
		groupName: 'Master',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'card-type', // Link to route name
		nameLink: 'Card Type',
		link: '/card-type',
	},
	{
		id: 2,
		groupName: 'Master',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'issuing-bin-payment', // Link to route name
		nameLink: 'Issuer BIN Card',
		link: '/issuing-bin-payment',
	},
	{
		id: 3,
		groupName: 'Master',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'payment-category', // Link to route name
		nameLink: 'Payment Category',
		link: '/payment-category',
	},
	{
		id: 3,
		groupName: 'Master',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'payment-data', // Link to route name
		nameLink: 'Payment Data',
		link: '/payment-data',
	},
	{
		id: 4,
		groupName: 'Master',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'role-task', // Link to route name
		nameLink: 'Role Task',
		link: '/role-task',
	},
	{
		id: 5,
		groupName: 'System',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'status-data', // Link to route name
		nameLink: 'Status Data',
		link: '/status-data',
	},
	{
		id: 5,
		groupName: 'System',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'gateway-transaction', // Link to route name
		nameLink: 'Gateway Transaction',
		link: '/gateway-transaction',
	},
	{
		id: 6,
		groupName: 'System',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'service-log', // Link to route name
		nameLink: 'Service Log',
		link: '/service-log',
	},
	{
		id: 6,
		groupName: 'Debit Gateway',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'gateway-debit-merchant', // Link to route name
		nameLink: 'Merchant Gateway',
		link: '/gateway-debit-merchant',
	},
	{
		id: 6,
		groupName: 'Debit Gateway',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'gateway-debit-segmentasi', // Link to route name
		nameLink: 'Segmentasi Gateway',
		link: '/gateway-debit-segmentasi',
	},
	{
		id: 7,
		groupName: 'Configuration',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'role', // Link to route name
		nameLink: 'Role',
		link: '/role',
	},
	{
		id: 8,
		groupName: 'Configuration',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'corporate', // Link to route name
		nameLink: 'Corporate',
		link: '/corporate',
	},
	{
		id: 9,
		groupName: 'Configuration',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'corporate-payment', // Link to route name
		nameLink: 'Corporate Payment',
		link: '/corporate-payment',
	},
	{
		id: 10,
		groupName: 'Configuration',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'core-settlement-keys', // Link to route name
		nameLink: 'Core Settlement Keys',
		link: '/core-settlement-keys',
	},
	{
		id: 11,
		groupName: 'Configuration',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'user', // Link to route name
		nameLink: 'User',
		link: '/user',
	},
	{
		id: 12,
		groupName: 'Configuration',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'device', // Link to route name
		nameLink: 'Device',
		link: '/device',
	},
	// {
	//   id: 12,
	//   groupName: "Configuration",
	//   icon: <MdRadioButtonUnchecked />,
	//   nameRoute: "device", // Link to route name
	//   nameLink: "Device Log",
	//   link: "/log-device",
	// },
	{
		id: 13,
		groupName: 'Payment Link',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'payment-link-add', // Link to route name
		nameLink: 'Create Invoice',
		link: '/payment-link-add',
	},
	{
		id: 13,
		groupName: 'Payment Link',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'payment-link', // Link to route name
		nameLink: 'List Invoice',
		link: '/payment-link',
	},
	{
		id: 14,
		groupName: 'Configuration',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'static-qr', // Link to route name
		nameLink: 'Static QR',
		link: '/static-qr',
	},
	{
		id: 15,
		groupName: 'Transaction Report',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'success-transaction', // Link to route name
		nameLink: 'Success Transaction',
		link: '/success-transaction',
	},
	{
		id: 16,
		groupName: 'Transaction Report',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'settled-transaction', // Link to route name
		nameLink: 'Settled Transaction',
		link: '/settled-transaction',
	},
	{
		id: 17,
		groupName: 'Transaction Report',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'list-transaction', // Link to route name
		nameLink: 'List Transaction',
		link: '/list-transaction',
	},
	{
		id: 18,
		groupName: 'Settlement Data',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'settlement-maluku', // Link to route name
		nameLink: 'BPD Maluku',
		link: '/settlement-maluku',
	},
	{
		id: 19,
		groupName: 'Sound Box',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'soundbox-summary', // Link to route name
		nameLink: 'Sound Box Summary',
		link: '/soundbox-summary',
	},
	{
		id: 20,
		groupName: 'Sound Box',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'soundbox-list', // Link to route name
		nameLink: 'Sound Box List',
		link: '/soundbox-list',
	},
	{
		id: 21,
		groupName: 'Sound Box',
		icon: <MdRadioButtonUnchecked />,
		nameRoute: 'soundbox-user', // Link to route name
		nameLink: 'Sound Box User',
		link: '/soundbox-user',
	},
];
