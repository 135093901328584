import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useStateContext } from "../../../contexts/ContextProvider";
import { BASE_URL } from "../../../config";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Modal,
  Box,
  Tab,
  Typography,
  Popover,
} from "@mui/material";

import notfound from "../../../data/notfound.png";
import { MdArrowBack, MdSearch, MdContentCopy, MdInfo } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "../../../components";
// import { BiDollar } from "react-icons/bi";

var CryptoJS = require("crypto-js");

const customStyles = {
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "700",
      paddingLeft: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const CorporateDetail = () => {
  // fetch corporate ID from url and decrypt
  const { Id } = useParams();
  // Decrypt
  const byted = Id.replace(/p1L2u3S/g, "+")
    .replace(/s1L2a3S4h/g, "/")
    .replace(/e1Q2u3A4l/g, "=");

  const bytes = CryptoJS.AES.decrypt(byted, "nasirames");
  const initialId = bytes.toString(CryptoJS.enc.Utf8);

  const navigate = useNavigate();

  // context states
  const { currentAuth, setAuth, modalOpenClose, screenWidth } =
    useStateContext();

  // request config
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${currentAuth}`,
    },
  };

  // tab state
  const [tabIndex, setTabIndex] = useState("1");
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  // table data state
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState("asc");
  const [header, setHeader] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // modal states & function
  const [modalOpen, setModalOpen] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [pendingSecretKey, setPendingSecretKey] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  // states initialization
  const [corporateData, setCorporateData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [keyword, setKeyword] = useState("");

  // headers
  const userHeaders = [
    {
      name: "Action",
      cell: (row) => {
        return (
          <button
            type="button"
            className="text-2xl hover:drop-shadow-xl w-full pr-3"
            onClick={() => {
              handleOpen();
              fetchSecretKey(row.encCredentials);
            }}
          >
            <MdInfo />
          </button>
        );
      },
    },
    {
      name: "User Type",
      selector: (row) => row["jenisUser"],
      sortable: true,
      width: 140,
      cell: (row) => (
        <div style={{ alignSelf: "center" }}>
          <button
            type="button"
            style={
              row.jenisUser === "Dashboard"
                ? { background: "#E1F5FE" }
                : row.jenisUser === "MPOS"
                ? { background: "#FFF3E0" }
                : row.jenisUser === "INTENT"
                ? { background: "#E8F5E9" }
                : row.jenisUser === "QPROX"
                ? { background: "#E1F5FE" }
                : row.jenisUser === "EDC"
                ? { background: "#E8F5E9" }
                : { background: "Red" }
            }
            className={`py-2 px-3 text-sm capitalize rounded-2xl`}
          >
            <p
              style={
                row.jenisUser === "Dashboard"
                  ? { color: "#339EF4" }
                  : row.jenisUser === "MPOS"
                  ? { color: "#FFAD33" }
                  : row.jenisUser === "INTENT"
                  ? { color: "#4CAF50" }
                  : row.jenisUser === "QPROX"
                  ? { color: "#339EF4" }
                  : row.jenisUser === "EDC"
                  ? { color: "#4CAF50" }
                  : { color: "Red" }
              }
            >
              {row.jenisUser}
            </p>
          </button>
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => row["nama"],
      sortable: true,
      width: 200,
      cell: (row) => <div className=" ">{row.nama}</div>,
    },
    {
      name: "Username",
      selector: (row) => row["username"],
      sortable: true,
      width: 140,
      cell: (row) => <div className=" ">{row.username}</div>,
    },
    {
      name: "Corporate",
      selector: (row) => row["namaCorporate"],
      sortable: true,
      width: 200,
      cell: (row) => <div className=" ">{row.namaCorporate}</div>,
    },
    {
      name: "Email",
      selector: (row) => row["email"],
      sortable: true,
      width: 250,
      cell: (row) => <div className=" ">{row.email}</div>,
    },
    {
      name: "Enc Credential",
      selector: (row) => row["encCredentials"],
      sortable: true,
      width: 200,
    },
    {
      name: "Callback Data",
      selector: (row) => row["callbackData"],
      sortable: true,
      width: 200,
      cell: (row) => <div className=" ">{row.callbackData ? "YES" : "NO"}</div>,
    },
  ];
  const deviceHeaders = [
    {
      name: "Device ID",
      width: 160,
      selector: (row) => row["deviceId"],
      // cell: (row) => DeviceView(row),
    },
    {
      name: "Corporate",
      selector: (row) => row["namaCorporate"],
      sortable: true,
      width: 230,
    },
    {
      name: "Merchant Key",
      selector: (row) => row["merchantKey"],
      sortable: true,
      width: 200,
    },
    {
      name: "Settlement Key",
      selector: (row) => row["CSKName"],
      sortable: true,
      width: 250,
    },
    {
      name: "DKI TID",
      selector: (row) => row["dkiTid"],
      sortable: true,
      width: 160,
    },
    {
      name: "DKI MID",
      selector: (row) => row["dkiMid"],
      sortable: true,
      width: 160,
    },
    {
      name: "MKP MID",
      selector: (row) => row["mid"],
      sortable: true,
      width: 160,
    },
    {
      name: "Ops Outlet ID",
      selector: (row) => row["opsOutletCode"],
      sortable: true,
      width: 160,
    },
  ];
  const paymentHeaders = [
    {
      name: "Alias",
      selector: (row) => row["alias"],
      width: 200,
    },
    {
      name: "Payment Method",
      selector: (row) => row["uraian"],

      width: 200,
    },
    {
      name: "Samnum",
      selector: (row) => row["samnum"],
      width: 150,
    },
    {
      name: "Ops PID",
      selector: (row) => row["opsPid"],
      width: 150,
    },
    {
      name: "Ops PID Off Us",
      selector: (row) => row["opsPidOffUs"],
      width: 150,
    },
    {
      name: "MDR Amount",
      selector: (row) => row["mdrAmount"],
      width: 150,
    },
    {
      name: "MDR Percentage Status",
      selector: (row) => row["mdrPercentageStatus"],
      width: 200,
    },
    {
      name: "MDR Is Exclude",
      selector: (row) => row["mdrIsExclude"],
      width: 150,
    },
    {
      name: "Gateway Payment Code",
      selector: (row) => row["gatewayPaymentCode"],
      width: 200,
    },
  ];

  // summary
  const summary = [
    {
      key: "CID:",
      value: corporateData.cid || "-",
      background: "bg-[#F8F8F8]",
    },
    {
      key: "Corporate:",
      value: corporateData.uraian || "-",
      background: "bg-[#ffffff]",
    },
    {
      key: "Category:",
      value: corporateData.namaCorporateCategory || "-",
      background: "bg-[#F8F8F8]",
    },
    {
      key: "Telephone:",
      value: corporateData.telepon || "-",
      background: "bg-[#ffffff]",
    },
    {
      key: "Level:",
      value: corporateData.level || "-",
      background: "bg-[#F8F8F8]",
    },
    {
      key: "Level Codes:",
      value: corporateData.hirarkiId || "-",
      background: "bg-[#ffffff]",
    },
    {
      key: "City:",
      value: corporateData.namaKota || "-",
      background: "bg-[#F8F8F8]",
    },
    {
      key: "Province:",
      value: corporateData.namaProvinsi || "-",
      background: "bg-[#ffffff]",
    },
    {
      key: "Address:",
      value: corporateData.alamat || "-",
      background: "bg-[#F8F8F8]",
    },
    {
      key: "Lat, Long:",
      value:
        corporateData.latitude || corporateData.longitude
          ? `${corporateData.latitude} , ${corporateData.longitude}`
          : "-",
      background: "bg-[#ffffff]",
    },
  ];

  // corporate fetch function
  const getSingleCorporate = async () => {
    modalOpenClose(true);
    const body = {
      id: parseInt(initialId),
    };

    try {
      const res = await axios.post(BASE_URL + "/corporate/get", body, config);
      setCorporateData(...res.data.result);
    } catch (e) {
      console.error("Error =>", e);
    } finally {
      modalOpenClose(false);
    }
  };

  // table data fetch function
  const getTableData = async (page, totalData = limit) => {
    modalOpenClose(true);
    const body = {
      page: parseInt(page),
      limit: totalData,
      order,
      orderBy: header,
      keyword,
      hirarkiId: corporateData.hirarkiId,
    };

    const paymentBody = {
      idCorporate: parseInt(initialId),
    };

    try {
      const res = await axios.post(
        BASE_URL +
          `/${
            tabIndex === "1"
              ? "user"
              : tabIndex === "2"
              ? "device"
              : "corppayment"
          }/${tabIndex === "3" ? "get" : "list"}`,
        tabIndex === "3" ? paymentBody : body,
        config
      );

      if (res.data.result !== null) {
        if (tabIndex === "1") {
          setUserData(res.data.result);
        } else if (tabIndex === "2") {
          setDeviceData(res.data.result);
        } else {
          const filteredData = res.data.result.metodePembayaran.filter(
            (data) => data.isActive === true
          );
          const mapped = filteredData.map((data) => ({
            ...data,
            mdrPercentageStatus: data.mdrPercentageStatus.toString(),
            mdrIsExclude: data.mdrIsExclude.toString(),
            gatewayPaymentCode: data.gatewayPaymentCode ?? "-",
          }));
          setPaymentData(mapped);
        }
        setCount(res.data.countData);
      } else {
        setUserData([]);
        setDeviceData([]);
        setPaymentData([]);
      }
    } catch (e) {
      if (e.response.data.statusCode === "401") {
        setAuth(null);
        navigate("/");
        localStorage.removeItem("auth");
      }
      console.error("Error:", e);
    } finally {
      modalOpenClose(false);
    }
  };

  // fetch secret key
  const fetchSecretKey = async (key) => {
    setPendingSecretKey(true);
    const body = {
      merchantKey: key,
    };

    try {
      const res = await axios.post(BASE_URL + "/user/secretkey", body, config);
      setSecretKey(
        res.data.result === null ? res.data.message : res.data.result
      );
    } catch (e) {
      console.error("Error : ", e);
    } finally {
      setPendingSecretKey(false);
    }
  };

  // table handler
  const handlePerRowsChange = (totalData, page) => {
    getTableData(page, totalData);
    setLimit(totalData);
  };

  const handlePageChange = (page) => {
    getTableData(page);
    setCurrentPage(page);
  };

  const customSort = (rows, selector, direction) => {
    setHeader(rows.selector);
    setOrder(selector);
  };

  // fetch corporate on initial mount
  useEffect(() => {
    getSingleCorporate();
  }, []);

  useEffect(() => {
    if (corporateData.hirarkiId) {
      getTableData(1);
    }
  }, [tabIndex, corporateData.hirarkiId, order, header]);

  return (
    <div>
      {/* modal */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Secret Key
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="w-full flex">
              {pendingSecretKey ? (
                "fetching data..."
              ) : (
                <span className="truncate">{secretKey}</span>
              )}
              <CopyToClipboard text={secretKey}>
                <button
                  onClick={() => setOpenPop(true)}
                  type="button"
                  className=" text-2xl hover:drop-shadow-xl ml-1"
                >
                  <MdContentCopy />
                </button>
              </CopyToClipboard>
              <Popover
                open={openPop}
                onClose={() => setOpenPop(false)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  Secret Key copied to clipboard!
                </Typography>
              </Popover>
            </div>
          </Typography>
        </Box>
      </Modal>

      {/* summary card */}
      <div className="h-fit m-2 mt-16 md:m-10 p-6 md:p-10 mb-0 md:rounded-3xl bg-white max-w-screen md:max-w-full">
        <div>
          <div className=" flex items-center gap-4 mb-4">
            <p className=" text-2xl">{/* <BiDollar /> */}</p>
            <p className=" font-semibold  ">Corporate Details</p>
          </div>

          {summary.map((item, index) => (
            <div className={`${item.background} p-4 flex w-full `} key={index}>
              <p className=" text-gray-600 text-sm md:text-base font-normal absolute ">
                {item.key}
              </p>
              <p className=" text-gray-600 text-sm md:text-base font-normal ml-36 md:ml-72">
                {item.value}
              </p>
            </div>
          ))}
        </div>
      </div>
      {/* tab and table cards */}
      <div className="h-fit m-2 mt-16 md:m-10 p-6 md:p-10 mb-0 md:rounded-3xl bg-white max-w-screen md:max-w-full">
        {tabIndex !== "3" && (
          <div className="mt-5 md:-mt-5 flex md:justify-end">
            <div className=" w-full md:w-3/12 mb-2 mt-2  flex end">
              <FormControl className=" w-full" variant="outlined">
                <OutlinedInput
                  size="small"
                  notched
                  id="outlined-adornment-password"
                  type={"text"}
                  placeholder="Search"
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getTableData(1);
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => getTableData(1)} edge="end">
                        <MdSearch />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>
        )}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label="Users" value={"1"} />
                <Tab label="Devices" value={"2"} />
                <Tab label="Payments" value={"3"} />
              </TabList>
            </Box>
            {[
              { data: userData, header: userHeaders },
              { data: deviceData, header: deviceHeaders },
              { data: paymentData, header: paymentHeaders },
            ].map((item, index) => {
              return (
                <TabPanel value={`${index + 1}`} key={index}>
                  <DataTable
                    noDataComponent={
                      <div className=" justify-center">
                        <img
                          src={notfound}
                          style={{
                            width: "auto",
                            height: screenWidth >= 500 ? 400 : 200,
                          }}
                          alt="not found"
                        />
                      </div>
                    }
                    columns={item.header}
                    data={item.data}
                    pagination
                    paginationServer
                    paginationTotalRows={count}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    // progressPending={pending}
                    // progressComponent={
                    //   <RiLoader4Line className=" animate-spin text-6xl" />
                    // }
                    onSort={customSort}
                    sortServer
                    theme="solarized"
                    customStyles={customStyles}
                    style={{ width: "20%" }}
                  />
                </TabPanel>
              );
            })}
          </TabContext>
        </Box>
      </div>

      <div className="p-6 md:p-10">
        <Button
          customFunc={() => navigate(-1)}
          bgColor={"gray"}
          width="60"
          height="fit"
          borderRadius={"10px"}
          text="Previous Page"
          color={"white"}
          type="button"
          icon={<MdArrowBack />}
        />
      </div>
    </div>
  );
};

export default CorporateDetail;
