import React from "react";

import config from "../config";

const IconUtils = () => {
  var address = window.location.host;
  const favicon = document.getElementById("favicon");

  if (address === "localhost:3000") {
    favicon.href = "logoA2P.png";
  } else {
    favicon.href = "logoA2P.png";
  }
};

export default IconUtils;
