import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import { BASE_URL } from '../../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';
import { CoreSetAction } from './DataTableComponent';
import { checkIsAvailableModule } from '../../../Routes';
import _ from 'lodash';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

const SettlementKey = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		screenWidth,
		modalOpenClose,
		privilege,
	} = useStateContext();

	const navigate = useNavigate();
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [count, setCount] = useState(0);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);

	const [order, setOrder] = useState('asc');
	const [header, setHeader] = useState('');

	const [pending, setPending] = useState(false);

	// get Data SettlementKey
	const [dataCoreSet, setDataCoreSet] = useState([]);
	const getDataCoreSet = (page = pagination, totalData = limit) => {
		modalOpenClose(true);

		const bodyData = {
			order: order,
			limit: totalData,
			page: page,
			keyword: search,
			orderBy: header,
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/core/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						console.log('THIS CORE SETT');
						console.log(data.result);
						setDataCoreSet(data.result);
						setCount(data.countData);
						modalOpenClose(false);
					} else {
						setDataCoreSet([]);
						setCount(0);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	useEffect(() => {
		getDataCoreSet();
	}, [search, order, header]);

	const handlePerRowsChange = (totalData, page) => {
		getDataCoreSet(page, totalData);
		setLimit(totalData);
	};

	const handlePageChange = (page) => {
		getDataCoreSet(page);
		setCurrentPage(page);
	};

	const searchData = () => {
		setSearch(keyword);
	};

	const customSort = (rows, selector, direction) => {
		console.log(selector);
		setHeader(rows.selector);
		setOrder(selector);
	};

	const [coreSetColumn, setCorpSetColum] = useState([
		{
			name: 'Value',
			selector: 'value',
			sortable: true,
			width: 'auto',
		},
		{
			name: 'Name',
			selector: 'name',
			sortable: true,
			width: 250,
		},
		{
			name: 'Username',
			selector: 'workerUsername',
			sortable: true,
			width: 180,
		},
	]);

	useEffect(() => {
		if (
			checkIsAvailableModule(
				'EDIT_CONFIG_SETTLEMENT_KEY',
				privilege
			) &&
			checkIsAvailableModule(
				'DELETE_CONFIG_SETTLEMENT_KEY',
				privilege
			)
		) {
			let addColumn = _.cloneDeep(coreSetColumn);
			addColumn.unshift({
				name: 'Action', //useTemplate
				selector: '',
				sortable: true,
				width: 100,
				cell: (row) =>
					CoreSetAction(row, () => {
						handlePageChange(1);
					}),
			});

			setCorpSetColum(addColumn);
		}
	}, []);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white `}
		>
			<div className="flex justify-between ">
				<Header title={'Settlement Keys'} />
				{checkIsAvailableModule(
					'ADD_CONFIG_SETTLEMENT_KEY',
					privilege
				) && (
					<Button
						customFunc={() => navigate('/insert-core-settlement')}
						text="New Data +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>
			<div className=" md:-mt-5 flex md:justify-end">
				<div className=" w-full md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									searchData();
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={() => searchData()} edge="end">
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<DataTable
				columns={coreSetColumn}
				data={dataCoreSet}
				noDataComponent={
					<div className=" justify-center">
						<img
							src={notfound}
							style={{
								width: 'auto',
								height: screenWidth >= 500 ? 400 : 200,
							}}
						/>
					</div>
				}
				progressPending={pending}
				progressComponent={
					<RiLoader4Line className=" animate-spin text-6xl" />
				}
				pagination
				paginationServer
				paginationTotalRows={count}
				paginationDefaultPage={currentPage}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				onSort={customSort}
				sortServer
				theme="solarized"
				customStyles={customStyles}
			/>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default SettlementKey;
