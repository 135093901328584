import { data } from 'autoprefixer';
import React, { useEffect, useState } from 'react';
import { MdArrowBack, MdSave } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Header } from '../../../components';
import {
	BASE_URL,
	URL_ROLE_ADD,
	URL_ROLE_TASK_LIST,
} from '../../../config';
import { useStateContext } from '../../../contexts/ContextProvider';
import TextField from '@mui/material/TextField';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Checkbox,
	Switch,
} from '@mui/material';
import { BsChevronDown } from 'react-icons/bs';
import { styled } from '@mui/styles';

import _ from 'lodash';

const CustomizedAccordion = styled(Accordion)(() => ({
	'& .MuiPaper-root': {
		backgroundColor: '#000000',
		borderRadius: '10rem',
		boxShadow: 'none',
	},
	boxShadow: 'none',
	borderRadius: 10,
}));

const RoleAdd = () => {
	const {
		activeMenu,
		currentColor,
		currentAuth,
		setAuth,
		hirarkiUser,
		currentName,
	} = useStateContext();

	const navigate = useNavigate();
	const { Id } = useParams();

	const notify = (message) => {
		toast.success(message, {
			autoClose: 500,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const [dataPrivilege, setDataPrivilege] = useState([]); //array of data for checkbox

	const [checkedAll, setCheckedAll] = useState(true);
	const [hirarki, setHirarki] = useState('');
	const [corp, setCorp] = useState([]);
	const [namaRole, setNamaRole] = useState('');
	const [roleTask, setRoleTask] = useState([]);

	const getCorp = () => {
		const dataInput = {
			order: 'ASC',
			// limit: 100,
			draw: 2,
			page: 1,
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						if (data.result.length > 0) {
							let mdata = _.cloneDeep(data.result);

							mdata.map((o) => {
								o.label = o.uraian;
								o.value = o.hirarkiId;

								return o;
							});

							setCorp(mdata);
						}
					} else {
						setCorp([]);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const inputRole = (e) => {
		e.preventDefault();
		const dataInput = {
			roleName: namaRole,
			roleAssignment: roleTask
				.filter((o) => o.status)
				.map((o) => o.id),
			createdBy: currentName,
		};

		fetch(BASE_URL + URL_ROLE_ADD, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.success === true) {
						notify(data.message);
					} else {
						notifyErr(data.message);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getRoleTask = () => {
		fetch(BASE_URL + URL_ROLE_TASK_LIST, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						let mdata = _.cloneDeep(data.result);
						mdata.map((o) => {
							o.status = checkedAll;
							o.expanded = true;
							return o;
						});

						setRoleTask(mdata);
					} else {
						setRoleTask([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
			});
	};

	useEffect(() => {
		// getCorp();
		getRoleTask();
	}, []);

	const onChangeCorp = (event, values) => {
		setHirarki(values.value);
	};

	const groupRole = (roleTask) => {
		if (roleTask && roleTask.length > 0) {
			let g = _.groupBy(roleTask, 'group');
			return Object.keys(g);
		}

		return [];
	};

	const getRole = (groupTask) => {
		if (roleTask && roleTask.length > 0) {
			let rTask = _.cloneDeep(roleTask);
			return rTask.filter((o) => o.group == groupTask);
		}

		return [];
	};

	const handleInput = (text, data, keyUpdate) => {
		let mTask = _.cloneDeep(roleTask);

		let mIndex = mTask.findIndex((o) => o.id == data.id);

		if (mIndex != -1) {
			mTask[mIndex][keyUpdate] = text;
		}

		setRoleTask(mTask);
	};

	const handleInputGroup = (text, groupName, keyUpdate) => {
		let mTask = _.cloneDeep(roleTask);

		mTask.map((o) => {
			if (o.group == groupName) {
				o[keyUpdate] = text;
			}
		});

		setRoleTask(mTask);
	};

	useEffect(() => {
		let mdata = _.cloneDeep(roleTask);
		mdata.map((o) => {
			o.status = checkedAll;
			return o;
		});

		setRoleTask(mdata);
	}, [checkedAll]);

	return (
		<form onSubmit={inputRole}>
			<div
				className={` h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ${
					activeMenu ? 'max-w-6xl' : 'max-w-full'
				} `}
			>
				<Header title="New Role User" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="role-name"
						label="Nama Role"
						variant="outlined"
						onChange={(text) => setNamaRole(text.target.value)}
						placeholder="MASTER ADMIN, ADMIN UPDATE, ADMIN,..."
						InputLabelProps={{ shrink: true }}
					/>
				</div>

				<div className=" ml-2 mt-5 w-full ">
					<p className="  text-gray-500 text-sm">Choose Role User</p>
					<div className=" flex">
						<p className="  mt-2 text-gray-500 text-sm">
							Unselect All
						</p>
						<Switch
							defaultChecked={checkedAll}
							value={checkedAll}
							onChange={() => {
								setCheckedAll(!checkedAll);
							}}
						/>
						<p className=" mt-2 text-gray-500 text-sm">Select All</p>
					</div>
				</div>

				<div className=" flex flex-wrap flex-row gap-5 justify-between w-full">
					{groupRole(roleTask) &&
						groupRole(roleTask).map((role) => {
							let allGroup = getRole(role);
							let groupChecked = allGroup.filter(
								(o) => o.status
							).length;

							return (
								<div className="md:w-full">
									<CustomizedAccordion
										expanded={
											allGroup.length > 0
												? allGroup[0].expanded
												: true
										}
										onChange={() => {
											handleInputGroup(
												allGroup.length > 0
													? !allGroup[0].expanded
													: true,
												role,
												'expanded'
											);
										}}
									>
										<AccordionSummary
											expandIcon={<BsChevronDown />}
											aria-controls="panel1a-content"
											id="panel1a-header"
										>
											<div className="flex items-center">
												<Checkbox
													defaultChecked={
														allGroup.length == groupChecked
													}
													id={groupChecked}
													type={'checkbox'}
													value={allGroup.length == groupChecked}
													checked={allGroup.length == groupChecked}
													onChange={(e) =>
														handleInputGroup(
															!(groupChecked && groupChecked > 0),
															role,
															'status'
														)
													}
												/>
												<p className=" text-lg font-bold text-left">
													{role}
												</p>
											</div>
										</AccordionSummary>
										<AccordionDetails>
											{/* Dashboard Checklist */}

											<div className="md:flex flex-row flex-wrap justify-start">
												{allGroup &&
													allGroup.map((task) => {
														return (
															<div className="mb-1 ml-2 mr-2 md:w-auto">
																<Checkbox
																	defaultChecked={task.status}
																	id={task.id}
																	type={'checkbox'}
																	value={task.status}
																	checked={task.status}
																	onChange={(e) =>
																		handleInput(
																			!task.status,
																			task,
																			'status'
																		)
																	}
																/>
																<label
																	htmlFor={'Dashboard'}
																	className=" ml-3"
																>
																	{task.roleTask}
																</label>
															</div>
														);
													})}
											</div>
										</AccordionDetails>
									</CustomizedAccordion>
								</div>
							);
						})}
				</div>

				<div className=" mt-5 justify-between flex gap-5">
					<Button
						customFunc={() => navigate(-1)}
						// customFunc={() => notifyErr("IdPembayaran")}
						bgColor={'gray'}
						borderRadius={'10px'}
						color="white"
						text="Previous Page"
						icon={<MdArrowBack />}
					/>
					<Button
						type="submit"
						bgColor={currentColor}
						borderRadius={'10px'}
						color="white"
						text="Submit"
						icon={<MdSave />}
					/>
				</div>
				<ToastContainer limit={1} autoClose={2500} />
			</div>
		</form>
	);
};

export default RoleAdd;
