import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import { BASE_URL_ONLINE, URL_ERROR_LOG_LIST } from '../../../config';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	Autocomplete,
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
} from '@mui/material';
import ToggleText from '../../../components/ToggleText';
import moment from 'moment';
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FiRefreshCw } from 'react-icons/fi';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '8px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const ServiceLog = () => {
	const {
		setAuth,
		modalOpenClose,
		privilege,
		screenWidth,
		currentColor,
	} = useStateContext();

	const editCorp = privilege.some((item) => {
		return item.menu === 'Corporate' && item.task === 'Update';
	});

	const deleteCorp = privilege.some((item) => {
		return item.menu === 'Corporate' && item.task === 'Delete';
	});

	const insertCorp = privilege.some((item) => {
		return item.menu === 'Corporate' && item.task === 'Insert';
	});

	const dateTo = moment().endOf('day').format('YYYY-MM-DD HH:mm');
	const dateFrom = moment().startOf('day').format('YYYY-MM-DD HH:mm');
	const maxDate = moment().subtract(1, 'months').format('YYYY-MM-01');

	// data for filter
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);

	const navigate = useNavigate();
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [statusCode, setStatusCode] = useState('');
	const [statusCategory, setStatusCategory] = useState('');
	const [merchantKey, setMerchantKey] = useState('');
	const [merchantNoRef, setMerchantNoRef] = useState('');

	const [pending, setPending] = useState(false);
	const [dataCorp, setDataCorp] = useState([]);

	const [dataFilter, setDataFilter] = useState([
		{ label: 'Set Periodically', value: 1 },
		{ label: 'More specific time', value: 2 },
	]);

	const [dataPeriodic, setDataPeriodic] = useState([
		{ label: 'Last hour', value: 1 },
		{ label: 'Last 3 hours', value: 2 },
		{ label: 'Last 12 hours', value: 3 },
		{ label: 'In today', value: 4 },
	]);

	const [idFilter, setIdFilter] = useState(1);
	const [idPeriodic, setIdPeriodic] = useState(1);

	const getDataCorp = () => {
		modalOpenClose(true);
		setDataCorp([]);

		let mDateFrom = '';
		let mDateTo = '';
		let momentObj = moment();

		if (idFilter == 1) {
			if (idPeriodic == 1) {
				mDateFrom = momentObj
					.subtract(1, 'hours')
					.format('YYYY-MM-DD HH:mm:ss');
				mDateTo = momentObj
					.endOf('day')
					.format('YYYY-MM-DD HH:mm:ss');
			} else if (idPeriodic == 2) {
				mDateFrom = momentObj
					.subtract(3, 'hours')
					.format('YYYY-MM-DD HH:mm:ss');
				mDateTo = momentObj
					.endOf('day')
					.format('YYYY-MM-DD HH:mm:ss');
			} else if (idPeriodic == 3) {
				mDateFrom = momentObj
					.subtract(12, 'hours')
					.format('YYYY-MM-DD HH:mm:ss');
				mDateTo = momentObj
					.endOf('day')
					.format('YYYY-MM-DD HH:mm:ss');
			} else if (idPeriodic == 4) {
				mDateFrom = momentObj
					.startOf('day')
					.format('YYYY-MM-DD HH:mm:ss');
				mDateTo = momentObj
					.endOf('day')
					.format('YYYY-MM-DD HH:mm:ss');
			}
		} else {
			mDateFrom = startDate + ':00';
			mDateTo = endDate + ':00';
		}

		let mData = {
			dateFrom: mDateFrom,
			dateTo: mDateTo,
			errorsCategory: statusCategory,
			errorsCode: statusCode,
			merchantKey: merchantKey,
			merchantNoRef: merchantNoRef,
		};

		console.log(mData);

		fetch(BASE_URL_ONLINE + URL_ERROR_LOG_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Basic bWtwbW9iaWxlOm1rcG1vYmlsZTEyMw==`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						console.log(data.result);
						setDataCorp(data.result);
						modalOpenClose(false);
					} else {
						setDataCorp([]);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	const searchData = () => {
		setSearch(keyword);
	};

	const handleClick = () => {
		getDataCorp();
	};

	// list corporate Column
	const [corpColumn] = useState([
		{
			name: 'ID',
			selector: 'errorsID',
			sortable: true,
			width: 100,
		},
		{
			name: 'Status',
			selector: 'errorsCode',
			sortable: true,
			width: 120,
		},
		{
			name: 'Category',
			selector: 'errorsCategory',
			sortable: true,
			width: 120,
		},
		{
			name: 'Status Name',
			selector: 'errorsReadable',
			sortable: true,
			width: 150,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.errorsReadable} />
			),
		},
		{
			name: 'Description',
			selector: 'errorsDescription',
			sortable: true,
			width: 150,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.errorsDescription} />
			),
		},
		{
			name: 'Message',
			selector: 'errorsMessage',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.errorsMessage} />
			),
		},
		{
			name: 'on Function',
			selector: 'errorsFunction',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.errorsFunction} />
			),
		},
		{
			name: 'on File',
			selector: 'errorsFile',
			sortable: true,
			width: 200,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.errorsFile} />
			),
		},
		{
			name: 'Merchant Key',
			selector: 'merchantKey',
			sortable: true,
			width: 300,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.merchantKey} />
			),
		},
		{
			name: 'Merchant Ref',
			selector: 'merchantNoRef',
			sortable: true,
			width: 300,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.merchantNoRef} />
			),
		},
		{
			name: 'Request Log',
			selector: 'errorsRequest',
			sortable: true,
			width: 300,
			cell: (row) => (
				<ToggleText minToggle={60} text={row.errorsRequest} />
			),
		},
		{
			name: 'Created At',
			selector: 'createdAt',
			sortable: true,
			width: 150,
		},
	]);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className="flex flex-row gap-6 mt-5 mb-5">
				<div className={idFilter == 1 ? 'w-1/2' : 'w-full'}>
					<Autocomplete
						required
						fullWidth={true}
						style={{
							width: 'auto',
						}}
						disablePortal
						id="selectFilter"
						options={dataFilter}
						onChange={(event, values) => {
							if (values != null) {
								setIdFilter(values.value);
							} else {
								setIdFilter(1);
							}
						}}
						value={dataFilter.filter((o) => o.value == idFilter)[0]}
						renderInput={(params) => (
							<TextField required {...params} label="Filter By" />
						)}
					/>
				</div>

				{idFilter == 1 && (
					<div className="w-1/2">
						<Autocomplete
							required
							fullWidth={true}
							style={{
								width: 'auto',
							}}
							disablePortal
							id="selectPeriodic"
							options={dataPeriodic}
							onChange={(event, values) => {
								if (values != null) {
									setIdPeriodic(values.value);
								} else {
									setIdPeriodic(1);
								}
							}}
							value={
								dataPeriodic.filter((o) => o.value == idPeriodic)[0]
							}
							renderInput={(params) => (
								<TextField
									required
									{...params}
									label="Choose period"
								/>
							)}
						/>
					</div>
				)}
			</div>
			{idFilter == 2 && (
				<div className=" md:flex gap-5 mb-5">
					{/* start date input */}
					<div className=" w-full mb-5 md:mb-0">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DateTimePicker
								required
								className=" w-full"
								label="From Time"
								value={startDate}
								onChange={(newValue) => {
									setStartDate(newValue.format('YYYY-MM-DD HH:mm'));
								}}
								inputFormat="DD-MM-YYYY HH:mm"
								minDate={maxDate}
								maxDate={dateTo}
								renderInput={(params) => (
									<TextField required {...params} />
								)}
							/>
						</LocalizationProvider>
					</div>
					{/* end date input */}
					<div className=" w-full">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DateTimePicker
								required
								className=" w-full"
								label="Until Time"
								value={endDate}
								onChange={(newValue) => {
									setEndDate(newValue.format('YYYY-MM-DD HH:mm'));
								}}
								inputFormat="DD-MM-YYYY HH:mm"
								minDate={maxDate}
								maxDate={dateTo}
								renderInput={(params) => (
									<TextField required {...params} />
								)}
							/>
						</LocalizationProvider>
					</div>
				</div>
			)}
			<div className=" md:flex md:flex-row gap-5 mb-5 mt-5">
				<div className=" w-full mb-5 md:mb-0">
					<TextField
						className="w-full"
						id="corp-name"
						label="Status Code"
						variant="outlined"
						onChange={(text) => setStatusCode(text.target.value)}
						placeholder="Status Code"
						InputLabelProps={{ shrink: true }}
						value={statusCode}
					/>
				</div>
				<div className=" w-full mb-5 md:mb-0">
					<TextField
						className="w-full"
						id="corp-name"
						label="Status Category"
						variant="outlined"
						onChange={(text) => setStatusCategory(text.target.value)}
						placeholder="Status Category"
						InputLabelProps={{ shrink: true }}
						value={statusCategory}
					/>
				</div>
			</div>
			<div className=" md:flex md:flex-row gap-5 mb-5 mt-5">
				<div className="w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Merchant Key"
						variant="outlined"
						onChange={(text) => setMerchantKey(text.target.value)}
						placeholder="Merchant Key"
						InputLabelProps={{ shrink: true }}
						value={merchantKey}
					/>
				</div>
				<div className="w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Merchant No Ref"
						variant="outlined"
						onChange={(text) => setMerchantNoRef(text.target.value)}
						placeholder="Merchant No Ref"
						InputLabelProps={{ shrink: true }}
						value={merchantNoRef}
					/>
				</div>
			</div>

			<Button
				customFunc={() => handleClick()}
				// customFunc={() => alert(paging)}
				bgColor={currentColor}
				color="white"
				text="Filter"
				borderRadius={'10px'}
				icon={<FiRefreshCw />}
			/>

			<div className=" mt-5 md:mt-10 md:flex md:justify-between">
				<Header className="mb-5" title={'Service Log'} />
				<div className="w-full md:w-3/12 mb-2 flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									searchData();
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={() => searchData()} edge="end">
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={corpColumn}
					data={dataCorp}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default ServiceLog;
