import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../config';
import { useStateContext } from '../../contexts/ContextProvider';
import ApexCharts from 'apexcharts';
import moment from 'moment';
import { getTimeStamp } from '../../utils/DateUtils';
import {
	encryptHmac512Base64,
	encryptSHA512,
} from '../../utils/CryptoUtils';

const SplineChart = ({ Corp }) => {
	const theDate = new Date();

	const date = theDate.toISOString();

	const { currentAuth, activeMenu, screenWidth, dataLogin } =
		useStateContext();

	//get Data Week
	const [dataWeek, setDataWeek] = useState([]);
	const getDataLastWeek = () => {
		const inputData = {
			corporate: Corp,
		};

		let mTimeStamp = getTimeStamp();
		let bodyEncripted = encryptSHA512(
			JSON.stringify(inputData).toLowerCase().replace(/\s/g, '')
		);

		let dataPLain =
			'POST' +
			'||' +
			`/dashboard/paymethod/v2` +
			'||' +
			(dataLogin.username ?? '') +
			'||' +
			bodyEncripted +
			'||' +
			mTimeStamp;

		let signature = encryptHmac512Base64(dataPLain);

		fetch(BASE_URL + '/dashboard/paymethod/v2', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
				'X-MKP-Timestamp': mTimeStamp,
				'X-MKP-Signature': signature,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.result != null) {
					setDataWeek(json.result);
				} else {
					setDataWeek([]);
				}
				// console.log("Payment: ", json.result.metodePembayaran);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		if (Corp) {
			getDataLastWeek();
		}
	}, [Corp]);

	// date list by -27 and -20 days
	const day1 = new Date();
	day1.setDate(day1.getDate() - 7);

	const day2 = new Date();
	day2.setDate(day2.getDate() - 6);

	const day3 = new Date();
	day3.setDate(day3.getDate() - 5);

	const day4 = new Date();
	day4.setDate(day4.getDate() - 4);

	const day5 = new Date();
	day5.setDate(day5.getDate() - 3);

	const day6 = new Date();
	day6.setDate(day6.getDate() - 2);

	const day7 = new Date();
	day7.setDate(day7.getDate() - 1);

	//merging data
	const [mergedData, setMergedData] = useState([]);
	const mergeData = () => {
		const whatDay = (day) => {
			return day === 0
				? 'Sunday'
				: day === 1
				? 'Monday'
				: day === 2
				? 'Tuesday'
				: day === 3
				? 'Wednesday'
				: day === 4
				? 'Thursday'
				: day === 5
				? 'Friday'
				: day === 6
				? 'Saturday'
				: '';
		};

		const data = [
			{
				date: `${day1.getFullYear()}-${
					day1.getMonth() + 1
				}-${day1.getDate()}`,
				day: whatDay(day1.getDay()),
				tunai: 0,
				lain: 0,
				cc: 0,
				debit: 0,
				qris: 0,
				brizzi: 0,
				eMoney: 0,
				tapCash: 0,
				flazz: 0,
				jakcard: 0,
				va: 0,
				biller: 0,
			},
			{
				date: `${day2.getFullYear()}-${
					day2.getMonth() + 1
				}-${day2.getDate()}`,
				day: whatDay(day2.getDay()),
				tunai: 0,
				lain: 0,
				cc: 0,
				debit: 0,
				qris: 0,
				brizzi: 0,
				eMoney: 0,
				tapCash: 0,
				flazz: 0,
				jakcard: 0,
				va: 0,
				biller: 0,
			},
			{
				date: `${day3.getFullYear()}-${
					day3.getMonth() + 1
				}-${day3.getDate()}`,
				day: whatDay(day3.getDay()),
				tunai: 0,
				lain: 0,
				cc: 0,
				debit: 0,
				qris: 0,
				brizzi: 0,
				eMoney: 0,
				tapCash: 0,
				flazz: 0,
				jakcard: 0,
				va: 0,
				biller: 0,
			},
			{
				date: `${day4.getFullYear()}-${
					day4.getMonth() + 1
				}-${day4.getDate()}`,
				day: whatDay(day4.getDay()),
				tunai: 0,
				lain: 0,
				cc: 0,
				debit: 0,
				qris: 0,
				brizzi: 0,
				eMoney: 0,
				tapCash: 0,
				flazz: 0,
				jakcard: 0,
				va: 0,
				biller: 0,
			},
			{
				date: `${day5.getFullYear()}-${
					day5.getMonth() + 1
				}-${day5.getDate()}`,
				day: whatDay(day5.getDay()),
				tunai: 0,
				lain: 0,
				cc: 0,
				debit: 0,
				qris: 0,
				brizzi: 0,
				eMoney: 0,
				tapCash: 0,
				flazz: 0,
				jakcard: 0,
				va: 0,
				biller: 0,
			},
			{
				date: `${day6.getFullYear()}-${
					day6.getMonth() + 1
				}-${day6.getDate()}`,
				day: whatDay(day6.getDay()),
				tunai: 0,
				lain: 0,
				cc: 0,
				debit: 0,
				qris: 0,
				brizzi: 0,
				eMoney: 0,
				tapCash: 0,
				flazz: 0,
				jakcard: 0,
				va: 0,
				biller: 0,
			},
			{
				date: `${day7.getFullYear()}-${
					day7.getMonth() + 1
				}-${day7.getDate()}`,
				day: whatDay(day7.getDay()),
				tunai: 0,
				lain: 0,
				cc: 0,
				debit: 0,
				qris: 0,
				brizzi: 0,
				eMoney: 0,
				tapCash: 0,
				flazz: 0,
				jakcard: 0,
				va: 0,
				biller: 0,
			},
		];
		var date = new Set(dataWeek.map((item) => item.day));

		var merged = [
			...dataWeek,
			...data.filter((item) => !date.has(item.day)),
		];

		setMergedData(merged);
	};

	useEffect(() => {
		mergeData();
	}, [dataWeek]);

	const sortData = () => {
		mergedData.sort(
			(a, b) =>
				Date.parse(new Date(a.date)) - Date.parse(new Date(b.date))
		);
		const opsi = {
			xaxis: {
				// type: "datetime",
				categories: mergedData.map((item) => {
					return item.day + ', ' + item.date;
				}),
			},
		};
		ApexCharts.exec(state.options.chart.id, 'updateOptions', opsi);

		const serie = [
			{
				name: 'Tunai',
				data: mergedData.map((item) => {
					return item.tunai;
				}),
			},
			{
				name: 'Debit Card',
				data: mergedData.map((item) => {
					return item.debit;
				}),
			},
			{
				name: 'Credit Card',
				data: mergedData.map((item) => {
					return item.cc;
				}),
			},
			{
				name: 'QRIS',
				data: mergedData.map((item) => {
					return item.qris;
				}),
			},
			{
				name: 'Brizzi',
				data: mergedData.map((item) => {
					return item.brizzi;
				}),
			},
			{
				name: 'E-Money',
				data: mergedData.map((item) => {
					return item.eMoney;
				}),
			},
			{
				name: 'Tap Cash',
				data: mergedData.map((item) => {
					return item.tapCash;
				}),
			},
			{
				name: 'Flazz',
				data: mergedData.map((item) => {
					return item.flazz;
				}),
			},
			{
				name: 'JakCard',
				data: mergedData.map((item) => {
					return item.jakcard;
				}),
			},
		];
		ApexCharts.exec(state.options.chart.id, 'updateSeries', serie);
	};
	useEffect(() => {
		sortData();
	}, [mergedData]);

	const state = {
		series: [
			{
				name: 'Tunai',
				data: mergedData.map((item) => {
					return item.tunai;
				}),
			},
			{
				name: 'Debit Card',
				data: mergedData.map((item) => {
					return item.debit;
				}),
			},
			{
				name: 'Credit Card',
				data: mergedData.map((item) => {
					return item.cc;
				}),
			},
			{
				name: 'QRIS',
				data: mergedData.map((item) => {
					return item.qris;
				}),
			},
			{
				name: 'Brizzi',
				data: mergedData.map((item) => {
					return item.brizzi;
				}),
			},
			{
				name: 'E-Money',
				data: mergedData.map((item) => {
					return item.eMoney;
				}),
			},
			{
				name: 'Tap Cash',
				data: mergedData.map((item) => {
					return item.tapCash;
				}),
			},
			{
				name: 'Flazz',
				data: mergedData.map((item) => {
					return item.flazz;
				}),
			},
			{
				name: 'JakCard',
				data: mergedData.map((item) => {
					return item.jakcard;
				}),
			},
		],
		options: {
			legend: {
				onItemClick: {
					toggleDataSeries: true,
				},
			},
			chart: {
				id: 'chartBar',
				height: 350,
				width: '100%',
				type: 'bar',
				toolbar: {
					export: {
						csv: {
							filename:
								'Traffic Gross Transaction By Payment ' +
								(dataWeek.length > 0
									? moment(dataWeek[0].date).format('DD MMM YYYY') +
									  ' s.d ' +
									  moment(dataWeek[dataWeek.length - 1].date).format(
											'DD MMM YYYY'
									  )
									: ''),
						},
						svg: {
							filename:
								'Traffic Gross Transaction By Payment ' +
								(dataWeek.length > 0
									? moment(dataWeek[0].date).format('DD MMM YYYY') +
									  ' s.d ' +
									  moment(dataWeek[dataWeek.length - 1].date).format(
											'DD MMM YYYY'
									  )
									: ''),
						},
						png: {
							filename:
								'Traffic Gross Transaction By Payment ' +
								(dataWeek.length > 0
									? moment(dataWeek[0].date).format('DD MMM YYYY') +
									  ' s.d ' +
									  moment(dataWeek[dataWeek.length - 1].date).format(
											'DD MMM YYYY'
									  )
									: ''),
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return value >= 1000000000
							? value / 1000000000 + ' Miliar'
							: value >= 1000000
							? value / 1000000 + ' Juta'
							: value.format();
					},
					// labels: {
					//   formatter: function (value) {
					//     return "Rp. " + value.format();
					//   },
				},
			},
			xaxis: {
				// type: "datetime",
				categories: mergedData.map((item) => {
					return item.day + ' ,' + item.date;
				}),
			},
			colors: [
				'#008FFB',
				'#00E396',
				'#005596',
				'#FEB019',
				'#FF4560',
				'#775DD0',
				'#00FBFB',
				'#FDEC00',
				'#FB00F1',
			],
			tooltip: {
				// y: {
				//   formatter: mergedData.map((item) => {
				//     return item.day + " ," + item.date;
				//   }),
				// },
				y: {
					formatter: function (val) {
						return 'Rp. ' + val.format();
					},
				},
			},
		},
	};

	const width = window.innerWidth;

	return (
		<div className=" w-full">
			<p className=" font-bold text-base md:text-xl mb-2">
				Last Week's Gross Transaction
			</p>
			<p className=" -mt-2 font-base text-xs md:text-base mb-2">
				( by Payment Categories )
			</p>
			<ReactApexChart
				options={state.options}
				series={state.series}
				type="bar"
				height={350}
				width={activeMenu ? screenWidth - 470 : '100%'}
			/>
		</div>
	);
};

export default SplineChart;
