import React, { useEffect, useState } from 'react';

import { useStateContext } from '../../contexts/ContextProvider';
import { BASE_URL } from '../../config';
import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { RiLoader4Line } from 'react-icons/ri';

const LineChart = ({ Corp, Start, End, Data }) => {
	const {
		currentMode,
		currentAuth,
		currentColor,
		hirarkiUser,
		setAuth,
	} = useStateContext();

	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const [revenueData, setRevenueData] = useState([]);

	const refresher = () => {
		ApexCharts.exec(state.options.chart.id, 'updateSeries', [
			{
				name: 'Transaction',
				data: revenueData.map((item) => {
					return item.omset;
				}),
				// data: [1, 2, 3, 4, 5, 6],
			},
		]);
	};

	// useEffect(() => {
	// 	if (Corp && Start && End) {
	// 		refresher();
	// 		getRevenueData();
	// 	}
	// }, [Corp, Start, End]);

	useEffect(() => {
		if (Data) {
			refresher();
			setRevenueData(Data);
		}
	}, [Data]);

	const width = window.innerWidth;

	const dateStart = new Date(Start);
	const formattedStart = dateStart.toLocaleDateString('en-GB', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});

	const dateEnd = new Date(End);
	const formattedEnd = dateEnd.toLocaleDateString('en-GB', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});

	const state = {
		series: [
			{
				name: 'Transaction',
				data: revenueData.map((item) => {
					return item.omset;
				}),
				// data: [1, 2, 3, 4, 5, 6],
			},
		],
		options: {
			chart: {
				id: 'bar-chart',
				type: 'area',
				stacked: false,
				height: 350,
				width: '100%',
				zoom: {
					type: 'x',
					enabled: true,
					autoScaleYaxis: true,
				},
				toolbar: {
					autoSelected: 'zoom',
					export: {
						csv: {
							filename:
								'Revenue on ' + formattedStart + ' - ' + formattedEnd,
						},
						svg: {
							filename:
								'Revenue on ' + formattedStart + ' - ' + formattedEnd,
						},
						png: {
							filename:
								'Revenue on ' + formattedStart + ' - ' + formattedEnd,
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			markers: {
				size: 0,
			},
			colors: [currentColor],
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					opacityFrom: 0.5,
					opacityTo: 0,
					stops: [0, 90, 100],
				},
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return val >= 1000000000
							? (val / 1000000000).toFixed() + ' Miliar'
							: val >= 1000000
							? (val / 1000000).toFixed() + ' Juta'
							: (val / 1000).toFixed() + ' Ribu';
					},
				},
				title: {
					text: 'Revenue (Rp)',
				},
			},
			xaxis: {
				categories: Data.map((item) => {
					return item.date;
				}),
				tickAmount: 10,
				labels: {
					hideOverlappingLabels: false,
				},
			},
			tooltip: {
				shared: false,
				y: {
					formatter: function (val) {
						// return (val / 1000000).toFixed() + " Juta";
						return val >= 1000000000
							? (val / 1000000000).toFixed() + ' Miliar'
							: val >= 1000000
							? (val / 1000000).toFixed() + ' Juta'
							: (val / 1000).toFixed() + ' Ribu';
					},
				},
			},
		},
	};

	return (
		<div className=" w-full">
			<p className=" text-xl font-bold">REVENUE</p>
			<p className=" text-sm mb-3">
				Revenue on {formattedStart} - {formattedEnd}
			</p>
			<div hidden={loading}>
				<p style={{ color: currentColor }}>
					<RiLoader4Line className=" animate-spin ml-36 md:ml-36 z-50  mt-24 text-5xl md:text-5xl absolute " />
				</p>
			</div>
			<ReactApexChart
				options={state.options}
				series={state.series}
				type="area"
				height={280}
				width={'100%'}
				// width={(width * 1) / 5}
			/>
		</div>
	);
};

export default LineChart;
