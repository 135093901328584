import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import { BASE_URL } from '../../../config';

import { TextField } from '@mui/material';

var CryptoJS = require('crypto-js');

const SettlementKeyUpdate = () => {
	const navigate = useNavigate();

	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, setAuth, activeMenu } =
		useStateContext();

	const [value, setValue] = useState('');
	const [idCorp, setIdCorp] = useState(221);
	const [nama, setNama] = useState('');
	const [Data, setData] = useState([]);
	const [dataUser, setDataUser] = useState([]);

	const getCorp = () => {
		const dataBody = {
			order: 'ASC',
			// limit: 999,
			draw: 2,
			page: 1,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (json.result != null) {
						console.log(json.result);
						setData(json.result);
					} else {
						setData([]);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const [workerUser, setWorkerUser] = useState();

	const getUser = (idcorp) => {
		const dataInput = {
			order: 'ASC',
			limit: 999,
			draw: 2,
			idCorporate: parseInt(idcorp),
		};

		fetch(BASE_URL + '/user/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataInput),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log('data user', json);
				console.log(json.message);
				setDataUser(json.result);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const [status, setStatus] = useState(false);
	// update data coreSettle
	const updateData = (e) => {
		e.preventDefault();
		const inputData = {
			nama: nama,
			value: value,
			workerUsername: workerUser,
			id: parseInt(initialId),
		};

		fetch(BASE_URL + '/core/edit', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log(json.result);
				setStatus(json.success);
				let message = json.message;
				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((err) => {
				notifyErr(err.message);
			});
	};

	const { Id } = useParams();

	// Decrypt
	var byted = Id.replace(/p1L2u3S/g, '+')
		.replace(/s1L2a3S4h/g, '/')
		.replace(/e1Q2u3A4l/g, '=');

	var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
	var initialId = bytes.toString(CryptoJS.enc.Utf8);

	const [oneData, setOneData] = useState([]);

	const getOneCoreSettle = () => {
		const dataBody = {
			id: parseInt(initialId),
		};
		fetch(BASE_URL + '/core/get', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(dataBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				console.log('CoreSet Res: ' + json.result);
				setOneData(json.result);
				setValue(json.result.value);
				setIdCorp(json.result.idCorporate);
				setNama(json.result.name);
				setWorkerUser(json.result.workerUsername);
				getUser(json.result.idCorporate);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getCorp();
		getOneCoreSettle();
	}, []);

	return (
		<form
			className={` ${activeMenu ? '-ml-0' : ''}`}
			onSubmit={updateData}
		>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title={'Update Settlement Key'} />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" w-full gap-8 flex">
					<div className=" mb-5 w-full">
						<TextField
							className="w-full"
							required
							id="value"
							label="Value"
							variant="outlined"
							placeholder="Value"
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setValue(text.target.value)}
							value={value}
						/>
					</div>
					<div className=" mb-5 w-full">
						<TextField
							className="w-full"
							required
							id="nama"
							label="Nama"
							variant="outlined"
							placeholder="Nama"
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setNama(text.target.value)}
							value={nama}
						/>
					</div>
				</div>

				<div className=" flex mb-5 gap-8">
					<div className=" mb-5 w-full">
						<TextField
							className="w-full"
							required
							id="username-worker"
							label="Username Worker"
							variant="outlined"
							placeholder="Username Worker"
							InputLabelProps={{ shrink: true }}
							onChange={(text) => setWorkerUser(text.target.value)}
							value={workerUser}
						/>
					</div>
				</div>

				<div className=" flex gap-5  justify-between">
					<Button
						customFunc={() => navigate(-1)}
						bgColor={'gray'}
						width="60"
						height="fit"
						borderRadius={'10px'}
						text="Previous Page"
						color={'white'}
						icon={<MdArrowBack />}
					/>
					<Button
						type="submit"
						bgColor={currentColor}
						borderRadius={'10px'}
						color="white"
						height="fit"
						width="60"
						text="Submit"
						icon={<MdSave />}
					/>
				</div>
			</div>
		</form>
	);
};

export default SettlementKeyUpdate;
