import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import { BASE_URL } from '../../../config';

import { CorpAction } from './DataTableComponent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';
import ToggleText from '../../../components/ToggleText';
import { checkIsAvailableModule } from '../../../Routes';
import _ from 'lodash';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { BsFileEarmarkExcelFill } from 'react-icons/bs';
import { CorpSyncLoc } from './DataTableComponent';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '600',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

createTheme('solarized', {
	text: {
		primary: '#000000',
		secondary: '#000000',
	},
	background: {
		default: '#ffffff',
	},
});

const Corporate = () => {
	const {
		currentColor,
		currentAuth,
		setAuth,
		modalOpenClose,
		hirarkiUser,
		screenWidth,
		privilege,
	} = useStateContext();

	const navigate = useNavigate();
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [count, setCount] = useState(0);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);

	const [order, setOrder] = useState('asc');
	const [header, setHeader] = useState('');

	const [pending, setPending] = useState(false);

	// get Data Corporate

	const [dataCorp, setDataCorp] = useState([]);
	const getDataCorp = (page = pagination, totalData = limit) => {
		modalOpenClose(true);

		const bodyData = {
			order: order,
			limit: totalData,
			page: page,
			keyword: search,
			orderBy: header,
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null && data.success) {
						setDataCorp(data.result);
						setCount(data.countData);
						modalOpenClose(false);
					} else {
						setDataCorp([]);
						setCount(0);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	useEffect(() => {
		getDataCorp();
	}, [search, order, header]);

	const handlePerRowsChange = (totalData, page) => {
		getDataCorp(page, totalData);
		setLimit(totalData);
	};

	const handlePageChange = (page) => {
		getDataCorp(page);
		setCurrentPage(page);
	};

	const searchData = () => {
		setSearch(keyword);
	};

	const customSort = (rows, selector, direction) => {
		console.log(selector);
		setHeader(rows.selector);
		setOrder(selector);
	};

	const [corpColumn, setCorpColum] = useState([
		{
			name: 'CID',
			selector: 'cid',
			sortable: true,
			width: 130,
		},
		{
			name: 'Coporate',
			selector: 'uraian',
			sortable: true,
			width: 250,
		},
		{
			name: 'Account Number',
			selector: 'corporateBankAccount',
			sortable: true,
			width: 250,
		},
		{
			name: 'Category',
			selector: 'namaCorporateCategory',
			sortable: true,
			width: 200,
		},
		{
			name: 'Telephone',
			selector: 'telepon',
			sortable: true,
			width: 130,
		},
		{
			name: 'Level',
			selector: 'level',
			sortable: true,
			width: 130,
		},
		{
			name: 'Level Codes',
			selector: 'hirarkiId',
			sortable: true,
			width: 400,
		},
		// {
		// 	name: 'City',
		// 	selector: 'namaKota',
		// 	sortable: true,
		// 	width: 150,
		// },
		// {
		// 	name: 'Province',
		// 	selector: 'namaProvinsi',
		// 	sortable: true,
		// 	width: 150,
		// },
		{
			name: 'Address',
			selector: 'alamat',
			sortable: true,
			width: 350,
			cell: (row) => <ToggleText minToggle={150} text={row.alamat} />,
		},
		{
			name: 'Lat, Long',
			selector: '',
			sortable: true,
			width: 200,
			cell: (row) =>
				row.latitude && row.longitude ? (
					<ToggleText
						minToggle={60}
						text={row.latitude + ' , ' + row.longitude}
					/>
				) : (
					<></>
				),
		},
	]);

	useEffect(() => {
		let addColumn = _.cloneDeep(corpColumn);
		if (
			checkIsAvailableModule('EDIT_CONFIG_CORPORATE', privilege) ||
			checkIsAvailableModule('DELETE_CONFIG_CORPORATE', privilege) ||
			checkIsAvailableModule('DETAIL_CONFIG_CORPORATE', privilege)
		) {
			addColumn.unshift({
				name: 'Action', //useTemplate
				selector: '',
				sortable: true,
				width: 150,
				cell: (row) => CorpAction(row, () => handlePageChange(1)),
			});
		}

		if (
			checkIsAvailableModule('SYNC_LOC_CONFIG_CORPORATE', privilege)
		) {
			addColumn.push({
				name: 'Location Sync', //useTemplate
				selector: '',
				sortable: true,
				width: 250,
				cell: (row) => CorpSyncLoc(row, () => handlePageChange(1)),
			});
		}

		setCorpColum(addColumn);
	}, []);

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const exportToExcel = async () => {
		modalOpenClose(true);

		const bodyData = {
			order: '',
			// limit: 1000,
			page: 1,
			keyword: '',
			orderBy: '',
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + '/corporate/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					modalOpenClose(false);
					if (data.result != null && data.success) {
						const ws = XLSX.utils.json_to_sheet(data.result);
						const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
						const excelBuffer = XLSX.write(wb, {
							bookType: 'xlsx',
							type: 'array',
						});
						const dataExcel = new Blob([excelBuffer], {
							type: fileType,
						});
						FileSaver.saveAs(
							dataExcel,
							`Data Corporate` + fileExtension
						);
					} else {
						setDataCorp([]);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white w-auto`}
		>
			<div className="flex justify-between">
				<Header title={'Corporate Data'} />
				{checkIsAvailableModule(
					'ADD_CONFIG_CORPORATE',
					privilege
				) && (
					<Button
						customFunc={() => navigate('/insert-corporate')}
						text="New Data +"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
					/>
				)}
			</div>

			<div
				className={`mt-5 md:-mt-5 flex ${
					checkIsAvailableModule(
						'EXPORT_EXCEL_CONFIG_CORPORATE',
						privilege
					)
						? 'md:justify-between'
						: 'md:justify-end'
				}`}
			>
				{checkIsAvailableModule(
					'EXPORT_EXCEL_CONFIG_CORPORATE',
					privilege
				) && (
					<Button
						customFunc={() => exportToExcel()}
						text="Excel Export"
						bgColor={currentColor}
						color="white"
						height="fit"
						borderRadius="10px"
						icon={<BsFileEarmarkExcelFill />}
					/>
				)}
				<div className=" w-full  md:w-3/12 mb-2 mt-2  flex end">
					<FormControl className=" w-full" variant="outlined">
						<OutlinedInput
							size="small"
							notched
							id="outlined-adornment-password"
							type={'text'}
							// value={values.password}
							placeholder="Search"
							onChange={(text) => setKeyword(text.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									searchData();
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={() => searchData()} edge="end">
										<MdSearch />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</div>
			</div>

			<div className=" flex-1  ">
				<DataTable
					columns={corpColumn}
					data={dataCorp}
					progressPending={pending}
					progressComponent={
						<RiLoader4Line className=" animate-spin text-6xl" />
					}
					noDataComponent={
						<div className=" justify-center">
							<img
								src={notfound}
								style={{
									width: 'auto',
									height: screenWidth >= 500 ? 400 : 200,
								}}
							/>
						</div>
					}
					pagination
					paginationServer
					paginationTotalRows={count}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					onSort={customSort}
					sortServer
					theme="solarized"
					customStyles={customStyles}
					width={'70%'}
				/>
			</div>

			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default Corporate;
