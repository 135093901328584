import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_GATEWAT_DEBIT_MERCHANT_EDIT,
	URL_GATEWAT_DEBIT_SEGMEN_LIST,
	URL_KATEGORI_EDIT,
} from '../../../config';

import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

import _ from 'lodash';

var CryptoJS = require('crypto-js');

const GatewayMerchantUpdate = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose, setAuth } =
		useStateContext();

	const navigate = useNavigate();

	const { Id } = useParams();

	const [id, setId] = useState(null);
	const [merchantKey, setMerchantKey] = useState('');
	const [mid, setMid] = useState('');
	const [email, setEmail] = useState('');
	const [notelp, setNotelp] = useState('');
	const [address, setAddress] = useState('');
	const [idSegment, setIdSegment] = useState(0);
	const [dataSegment, setDataSegment] = useState([]);

	const [status, setStatus] = useState(false);

	const dataUpdate = (e) => {
		e.preventDefault();
		modalOpenClose(true);

		const inputData = {
			noTelp: notelp,
			address: address,
			merchantEmail: email,
			merchantKey: merchantKey,
			mkpMid: mid,
			idRouteSegmen: idSegment,
		};

		fetch(BASE_URL + URL_GATEWAT_DEBIT_MERCHANT_EDIT, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);

				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	useEffect(() => {
		if (Id && dataSegment.length > 0) {
			try {
				var byted = Id.replace(/p1L2u3S/g, '+')
					.replace(/s1L2a3S4h/g, '/')
					.replace(/e1Q2u3A4l/g, '=');

				var bytes = CryptoJS.AES.decrypt(byted, 'nasirames');
				var initialId = bytes.toString(CryptoJS.enc.Utf8);

				var data = JSON.parse(initialId);

				setIdSegment(data.idRouteSegmen);
				setMerchantKey(data.merchantKey);
				setEmail(data.merchantEmail);
				setMid(data.mkpMid);
				setNotelp(data.noTelp);
				setAddress(data.address);
				setId(data.id);
			} catch (err) {
				navigate(`/404`);
			}
		}
	}, [Id, dataSegment]);

	const getDataSegment = () => {
		fetch(BASE_URL + URL_GATEWAT_DEBIT_SEGMEN_LIST, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
				} else {
					if (data.result != null) {
						let objData = _.cloneDeep(data.result).map((o) => {
							o.label = o.namaRouteSegmen;
							o.value = o.idRouteSegmen;
							return o;
						});

						setDataSegment(objData);
					} else {
						setDataSegment([]);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				notifyErr(err.message);
			});
	};

	useEffect(() => {
		getDataSegment();
	}, []);

	const currentIndexSegment =
		dataSegment && dataSegment.length > 0
			? dataSegment.findIndex((item) => {
					return item.idRouteSegmen == idSegment;
			  })
			: 0;

	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="Update Payment Category" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Merchant Key"
						variant="outlined"
						placeholder="merchant key"
						InputLabelProps={{ shrink: true }}
						InputProps={{
							readOnly: true,
						}}
						value={merchantKey}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="MID"
						variant="outlined"
						onChange={(text) => setMid(text.target.value)}
						placeholder="MID"
						InputLabelProps={{ shrink: true }}
						value={mid}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Email"
						variant="outlined"
						type={'email'}
						onChange={(text) => setEmail(text.target.value)}
						placeholder="email"
						InputLabelProps={{ shrink: true }}
						value={email}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="No telp"
						variant="outlined"
						onChange={(text) => setNotelp(text.target.value)}
						placeholder="08xxxx"
						maxLength="13"
						InputLabelProps={{ shrink: true }}
						value={notelp}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="alamat"
						required
						label="Address"
						variant="outlined"
						placeholder="Jalan Pemuda No. 8"
						InputLabelProps={{ shrink: true }}
						onChange={(text) => setAddress(text.target.value)}
						value={address}
					/>
				</div>

				<div className=" mt-5 w-full">
					<Autocomplete
						key={currentIndexSegment}
						required
						fullWidth={true}
						disablePortal
						disableClearable
						id="selectSegment"
						options={dataSegment}
						onChange={(event, values) => {
							setIdSegment(values.value);
						}}
						defaultValue={
							dataSegment[currentIndexSegment]
								? dataSegment[currentIndexSegment]
								: null
						}
						getOptionLabel={(option) => option.label}
						renderInput={(params) => (
							<TextField required {...params} label="Segment" />
						)}
					/>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default GatewayMerchantUpdate;
