import React, { useState, useEffect, useMemo } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '../../../components';
import { BASE_URL, URL_DEVICE_LOG } from '../../../config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdEdit, MdRemoveRedEye, MdSearch } from 'react-icons/md';
import { RiLoader4Line } from 'react-icons/ri';
import notfound from '../../../data/notfound.png';
import {
	FormControl,
	IconButton,
	InputAdornment,
	OutlinedInput,
} from '@mui/material';
import { DeviceAction, MerchKey } from './DataTableComponent';
import { checkIsAvailableModule } from '../../../Routes';
import _ from 'lodash';
import { urlDecrypt } from '../../../utils/CryptoUtils';

const customStyles = {
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '700',
			paddingLeft: '0 8px',
		},
	},
	cells: {
		style: {
			paddingLeft: '0px', // override the cell padding for data cells
			paddingRight: '8px',
		},
	},
};

const DeviceLogDetail = () => {
	const { Id } = useParams();

	const {
		currentAuth,
		setAuth,
		privilege,
		hirarkiUser,
		screenWidth,
		modalOpenClose,
	} = useStateContext();

	const navigate = useNavigate();
	const [limit, setLimit] = useState(10);
	const [pagination, setPagination] = useState(1);
	const [count, setCount] = useState(0);
	const [keyword, setKeyword] = useState('');
	const [search, setSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState('desc');
	const [header, setHeader] = useState('logCreated');

	const [deviceId, setDeviceId] = useState(null);

	const [pending, setPending] = useState(false);

	// get Data DeviceLogDetail
	const [dataDeviceLogDetail, setDataDeviceLogDetail] = useState([]);

	const getDataDeviceLogDetail = (
		page = pagination,
		totalData = limit
	) => {
		modalOpenClose(true);

		setDataDeviceLogDetail([]);

		const bodyData = {
			order: order,
			limit: totalData,
			page: page,
			keyword: search,
			orderBy: header,
			deviceID: deviceId,
			hirarkiId: hirarkiUser,
		};

		fetch(BASE_URL + URL_DEVICE_LOG, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.statusCode === '401') {
					setAuth(null);
					navigate('/');
					localStorage.removeItem('auth');
					modalOpenClose(false);
				} else {
					if (data.result != null) {
						console.log(data.result);
						setDataDeviceLogDetail(data.result);
						setCount(data.countData);
						modalOpenClose(false);
					} else {
						setDataDeviceLogDetail([]);
						modalOpenClose(false);
					}
				}
			})
			.catch((err) => {
				console.log('err =>', err);
				modalOpenClose(false);
			});
	};

	useEffect(() => {
		console.log('deviceId', deviceId);
		if (deviceId) getDataDeviceLogDetail();
	}, [deviceId]);

	const handlePerRowsChange = (totalData, page) => {
		getDataDeviceLogDetail(page, totalData);
		setLimit(totalData);
	};

	const handlePageChange = (page) => {
		getDataDeviceLogDetail(page);
		setCurrentPage(page);
	};

	const customSort = (rows, selector, direction) => {
		console.log(selector);
		setHeader(rows.selector);
		setOrder(selector);
	};

	const [deviceLogDetailColumn, setDeviceLogDetailColumn] = useState([
		{
			name: 'Log Created',
			selector: 'logCreated',
			sortable: true,
			width: 200,
		},
		{
			name: 'Status',
			selector: 'logStatus',
			sortable: true,
			width: 160,
		},
		{
			name: 'Device ID',
			selector: 'deviceID',
			sortable: true,
			width: 160,
		},
		{
			name: 'Corporate',
			selector: 'corporateName',
			sortable: true,
			width: 230,
		},
		{
			name: 'Merchant Key',
			selector: 'merchantKey',
			sortable: true,
			width: 200,
		},
		{
			name: '',
			cell: (row) => MerchKey(row),
		},
		{
			name: 'Settlement Key',
			selector: 'merchantKey',
			sortable: true,
			width: 250,
		},
		{
			name: 'DKI TID',
			selector: 'dkiTid',
			sortable: true,
			width: 160,
		},
		{
			name: 'DKI MID',
			selector: 'dkiMid',
			sortable: true,
			width: 160,
		},
		{
			name: 'MKP MID',
			selector: 'mid',
			sortable: true,
			width: 160,
		},
		{
			name: 'Ops Outlet ID',
			selector: 'opsOutletCode',
			sortable: true,
			width: 160,
		},
		{
			name: 'Data Created',
			selector: 'createdAt',
			sortable: true,
			width: 200,
		},
	]);

	useEffect(() => {
		if (
			checkIsAvailableModule(
				'EDIT_CONFIG_DEVICELogDetail',
				privilege
			) &&
			checkIsAvailableModule(
				'DELETE_CONFIG_DEVICELogDetail',
				privilege
			)
		) {
			let addColumn = _.cloneDeep(deviceLogDetailColumn);
			addColumn.unshift({
				name: 'Action', //useTemplate
				selector: '',
				sortable: true,
				width: 100,
				cell: (row) => DeviceAction(row, () => handlePageChange(1)),
			});

			setDeviceLogDetailColumn(addColumn);
		}
	}, []);

	useEffect(() => {
		if (Id) {
			try {
				var data = urlDecrypt(Id);

				setDeviceId(JSON.parse(data).deviceId);
			} catch (err) {
				navigate(`/404`);
			}
		}
	}, [Id]);

	return (
		<div
			className={` h-fit m-2 mt-16 md:m-10 ml-4 p-4 md:p-10  rounded-3xl bg-white `}
		>
			<div className="flex justify-between">
				<Header title={'Device Logs'} />
			</div>

			<DataTable
				columns={deviceLogDetailColumn}
				data={dataDeviceLogDetail}
				noDataComponent={
					<div className=" justify-center">
						<img
							src={notfound}
							style={{
								width: 'auto',
								height: screenWidth >= 500 ? 400 : 200,
							}}
						/>
					</div>
				}
				// progressPending={loading}
				pagination
				paginationServer
				paginationTotalRows={count}
				paginationDefaultPage={currentPage}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				progressPending={pending}
				progressComponent={
					<RiLoader4Line className=" animate-spin text-6xl" />
				}
				// selectableRows
				// onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
				onSort={customSort}
				sortServer
				theme="solarized"
				customStyles={customStyles}
			/>
			<ToastContainer
				limit={1}
				style={{ marginTop: 0 }}
				autoClose={2500}
			/>
		</div>
	);
};

export default DeviceLogDetail;
