import React, { useEffect, useState } from 'react';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Header } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

import { useStateContext } from '../../../contexts/ContextProvider';
import {
	BASE_URL,
	URL_AQUIRING_LIST,
	URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_ADD,
	URL_GATEWAT_DEBIT_MERCHANT_ADD,
	URL_GATEWAT_DEBIT_SEGMEN_LIST,
	URL_KATEGORI_ADD,
} from '../../../config';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { urlDecrypt } from '../../../utils/CryptoUtils';
import _ from 'lodash';

var CryptoJS = require('crypto-js');

const GatewayMerchantAcquiringAdd = () => {
	const notify = (message) => {
		toast.success(message, {
			autoClose: 1000,
			position: toast.POSITION.TOP_CENTER,
			onClose: () => navigate(-1),
		});
	};

	const notifyErr = (text) => {
		toast.error(text, {
			position: toast.POSITION.TOP_CENTER,
		});
	};

	const { currentColor, currentAuth, modalOpenClose, setAuth } =
		useStateContext();

	const navigate = useNavigate();
	const { Id } = useParams();

	const [bankMID, setBankMID] = useState('');
	const [vendorId, setVendorId] = useState('');
	const [bankCredentialOne, setBankCredentialOne] = useState('');
	const [bankCredentialTwo, setBankCredentialTwo] = useState('');
	const [bankCredentialThree, setBankCredentialThree] = useState('');
	const [idTipeTransaksi, setIdTipeTransaksi] = useState(null);
	const [dataTransType, setDataTransType] = useState([
		{ value: 1, label: 'DEBIT' },
		{ value: 2, label: 'CREDIT' },
	]);

	const [dataMerchant, setDataMerchant] = useState(null);

	const [dataAcquiring, setDataAcquiring] = useState([]);
	const [kodeBankAcquiring, setKodeBankAcquiring] = useState('');

	const [status, setStatus] = useState(false);

	const dataUpdate = (e) => {
		e.preventDefault();
		modalOpenClose(true);

		const inputData = {
			merchantKey: dataMerchant ? dataMerchant.merchantKey : null,
			kodeBankAcquiring: kodeBankAcquiring,
			bankMid: bankMID,
			bankVendorId: vendorId,
			bankCredentialOne: bankCredentialOne,
			bankCredentialTwo: bankCredentialTwo,
			bankCredentialThree: bankCredentialThree,
			idTipeTransaksi: idTipeTransaksi,
		};

		fetch(BASE_URL + URL_GATEWAT_DEBIT_MERCHANT_ACQUIRING_ADD, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(inputData),
		})
			.then((response) => response.json())
			.then((json) => {
				modalOpenClose(false);
				setStatus(json.success);
				let message = json.message;

				if (json.success === true) {
					notify(message);
				} else if (json.success === false) {
					notifyErr(message);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	const getAquiring = () => {
		let mData = {
			order: 'ASC',
			orderBy: 'id',
			limit: 999,
			page: 1,
		};

		fetch(BASE_URL + URL_AQUIRING_LIST, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${currentAuth}`,
			},
			body: JSON.stringify(mData),
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.success === true) {
					if (json.result != null) {
						let shortName = _.sortBy(json.result, 'acquiringName');

						let objData = shortName
							.filter((m) => m.bankCode)
							.map((o) => {
								o.label = o.acquiringName;
								o.value = o.bankCode;

								return o;
							});

						setDataAcquiring(objData);
					} else {
						setDataAcquiring([]);
					}
				} else {
					setDataAcquiring([]);
				}
			})
			.catch((error) => {
				modalOpenClose(false);
				notifyErr(error.message);
			});
	};

	useEffect(() => {
		getAquiring();
	}, []);

	useEffect(() => {
		if (Id) {
			var data = urlDecrypt(Id);

			if (data != null) {
				setDataMerchant(data);
			} else {
				notifyErr('Invalid URL');
			}
		}
	}, [Id]);

	const currentIndexAcquiring =
		dataAcquiring && dataAcquiring.length > 0
			? dataAcquiring.findIndex((item) => {
					return item.value === kodeBankAcquiring;
			  })
			: 0;

	const currentIndexType =
		dataTransType && dataTransType.length > 0
			? dataTransType.findIndex((item) => {
					return item.value === idTipeTransaksi;
			  })
			: 0;
	return (
		<form onSubmit={dataUpdate}>
			<div className=" max-w-full h-fit m-2 mt-16 md:m-10 p-2 md:p-10  rounded-3xl bg-white ">
				<ToastContainer
					limit={1}
					style={{ marginTop: 0 }}
					autoClose={2500}
				/>
				<Header title="New Acquiring Merchant" />
				<p className=" -mt-10 mb-10 text-sm text-gray-500">
					* required
				</p>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Merchant Key"
						variant="outlined"
						placeholder="Merchant Key"
						InputLabelProps={{ shrink: true }}
						InputProps={{
							readOnly: true,
						}}
						value={dataMerchant ? dataMerchant.merchantKey : null}
					/>
				</div>

				<div className=" mt-5 w-full">
					<Autocomplete
						key={currentIndexAcquiring}
						required
						fullWidth={true}
						disableClearable
						disablePortal
						id="selectAcquiring"
						options={dataAcquiring}
						onChange={(event, values) =>
							setKodeBankAcquiring(values.value)
						}
						defaultValue={
							dataAcquiring
								? dataAcquiring[currentIndexAcquiring]
								: null
						}
						renderInput={(params) => (
							<TextField required {...params} label="Acquiring" />
						)}
					/>
				</div>

				<div className=" mt-5 w-full">
					<Autocomplete
						key={currentIndexType}
						required
						fullWidth={true}
						disableClearable
						disablePortal
						id="selectAcquiring"
						options={dataTransType}
						onChange={(event, values) =>
							setIdTipeTransaksi(values.value)
						}
						defaultValue={
							dataTransType ? dataTransType[currentIndexType] : null
						}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								label="Transaction Type"
							/>
						)}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Bank MID"
						variant="outlined"
						onChange={(text) => setBankMID(text.target.value)}
						placeholder="MID"
						InputLabelProps={{ shrink: true }}
						value={bankMID}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						required
						id="corp-name"
						label="Vendor ID"
						variant="outlined"
						onChange={(text) => setVendorId(text.target.value)}
						placeholder="Vendor ID"
						InputLabelProps={{ shrink: true }}
						value={vendorId}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Credential 1"
						variant="outlined"
						onChange={(text) =>
							setBankCredentialOne(text.target.value)
						}
						placeholder="Credential 1"
						InputLabelProps={{ shrink: true }}
						value={bankCredentialOne}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Credential 2"
						variant="outlined"
						onChange={(text) =>
							setBankCredentialTwo(text.target.value)
						}
						placeholder="Credential 2"
						InputLabelProps={{ shrink: true }}
						value={bankCredentialTwo}
					/>
				</div>

				<div className=" mt-5 w-full">
					<TextField
						className="w-full"
						id="corp-name"
						label="Credential 3"
						variant="outlined"
						onChange={(text) =>
							setBankCredentialThree(text.target.value)
						}
						placeholder="Credential 3"
						InputLabelProps={{ shrink: true }}
						value={bankCredentialThree}
					/>
				</div>

				<div className=" flex mt-5 justify-between">
					<div className=" mr-5">
						<Button
							customFunc={() => navigate(-1)}
							bgColor={'gray'}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Previous Page"
							color={'white'}
							type="button"
							icon={<MdArrowBack />}
						/>
					</div>
					<div>
						<Button
							customFunc={() => dataUpdate()}
							type="submit"
							bgColor={currentColor}
							width="60"
							height="fit"
							borderRadius={'10px'}
							text="Submit"
							color={'white'}
							icon={<MdSave />}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default GatewayMerchantAcquiringAdd;
