import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
var CryptoJS = require('crypto-js');

//List Transaction Column
export const listTransactionColumn = (dataCorporate) => {
	let m = [
		{
			name: 'CID',
			selector: 'cid',
			sortable: false,
			width: 100,
		},
		{
			name: 'Merchant Name',
			selector: 'corporateName',
			sortable: false,
			width: 200,
		},
		{
			name: 'Account Number',
			selector: '',
			sortable: true,
			width: 300,
			cell: (row) => (
				<div>
					<p>
						{dataCorporate &&
						dataCorporate.filter((o) => {
							return o.cid == row.cid;
						}).length > 0
							? dataCorporate.filter((o) => {
									return o.cid == row.cid;
							  })[0].corporateBankAccount
							: ''}
					</p>
				</div>
			),
		},
		{
			name: 'Count Transaction',
			selector: 'countTrx',
			sortable: false,
			width: 200,
		},
		{
			name: 'Transaction Total',
			selector: 'totalTrx',
			sortable: false,
			width: 200,
			cell: (row) => (
				<div>
					<p>Rp. {row.totalTrx.format()}</p>
				</div>
			),
		},
		{
			name: 'MDR',
			selector: 'totalMdr',
			sortable: false,
			width: 140,
			cell: (row) => (
				<div>
					<p>Rp. {row.totalMdr.format()}</p>
				</div>
			),
		},
		{
			name: 'Nett Total',
			selector: 'totalNett',
			sortable: false,
			width: 140,
			cell: (row) => (
				<div>
					<p>Rp. {row.totalNett.format()}</p>
				</div>
			),
		},
	];

	return m;
};
