import React, { useEffect } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	redirect,
	Navigate,
} from 'react-router-dom';
import { Navbar, Sidebar2 } from './components';

import Modal from '@mui/material/Modal';

import { useStateContext } from './contexts/ContextProvider';

import './App.css';
import { RiErrorWarningLine, RiLoader4Line } from 'react-icons/ri';

import IconUtils from './components/IconUtils';
import { CustomRoutes, getRouteAvailable } from './Routes';
import _ from 'lodash';
import { decrypt } from './utils/CryptoUtils';

var CryptoJS = require('crypto-js');

const App = () => {
	const {
		setCurrentMode,
		setCurrentColor,
		setActiveMenu,
		activeMenu,
		currentMode,
		currentColor,
		currentAuth,
		openModal,
		setAuth,
		privilege,
		setPrivilegeRole,
		setHirarkiLogin,
		handleClickProfile,
		setEncDataLogin,
	} = useStateContext();

	var address = window.location.host;
	const favicon = document.getElementById('favicon');

	const pathName = window.location.pathname;

	useEffect(() => {
		const currentThemeColor = localStorage.getItem('colorMode');
		const currentThemeMode = localStorage.getItem('themeMode');

		if (currentThemeColor && currentThemeMode) {
			setCurrentColor(currentThemeColor);
			setCurrentMode(currentThemeMode);
		}

		const currentToken = localStorage.getItem('auth');
		const currentName = localStorage.getItem('nama');
		const currentRole = localStorage.getItem('_r');

		if (currentToken && currentName && currentRole) {
			let decryptAuth = decrypt(currentToken);
			let decryptName = decrypt(currentName);
			let decryptRole = decrypt(currentRole);

			if (decryptAuth && decryptName && decryptRole) {
				setAuth(decryptAuth, decryptName, decryptRole);
			}
		}

		const currentPrivi = localStorage.getItem('privilege');

		if (currentPrivi) {
			let decryptPriv = decrypt(currentPrivi);

			try {
				let parsePriv = JSON.parse(decryptPriv);
				if (parsePriv) setPrivilegeRole(parsePriv);
			} catch (error) {}
		}

		const currentHirarki = localStorage.getItem('hirarki');
		const currentLogin = localStorage.getItem('_dl');

		if (currentHirarki) {
			setHirarkiLogin(currentHirarki);
		}

		if (currentLogin) {
			let md = decrypt(currentLogin);

			try {
				let mP = JSON.parse(md);
				if (mP) setEncDataLogin(mP);
			} catch (error) {}
		}

		IconUtils();
	}, []);

	var width = window.innerWidth;
	const sidebarWidth = width >= 500 ? 300 : 0;

	Number.prototype.format = function () {
		var options = {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		};

		return ` ${this.toLocaleString('id-ID', options)}`;
	};

	Number.prototype.format02 = function () {
		return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
	};

	return (
		<div className={currentMode === 'Dark' ? 'dark ' : ''}>
			{currentAuth === null ? (
				<BrowserRouter>
					<Routes>
						<Route
							path="/"
							element={
								CustomRoutes.filter((o) => o.name == 'login')[0]
									.element
							}
						/>

						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</BrowserRouter>
			) : (
				<BrowserRouter>
					<div className="flex relative dark:bg-main-dark-bg bg-main-bg">
						{pathName != '/404' && (
							<div
								className={`z-50 bg-white -ml-3  flex-auto  ${
									activeMenu ? 'mr-0' : 'mr-0'
								} `}
							>
								<Sidebar2 />
							</div>
						)}

						<div
							className={`dark:bg-main-dark-bg bg-transparent min-h-screen flex-auto flex-wrap
                 z-0 ${activeMenu ? '' : 'w-full'}`}
							style={
								activeMenu ? { width: width - sidebarWidth } : {}
							}
						>
							{pathName != '/404' && (
								<div
									className="  bg-main-bg dark:bg-main-dark-bg
                navbar w-full -mb-16"
								>
									<Navbar />
								</div>
							)}

							<div
								onClick={
									width >= 500
										? () => handleClickProfile(false)
										: () => {
												setActiveMenu(false);
												handleClickProfile(false);
										  }
								}
								className=" mt-20"
							>
								<Routes>
									{getRouteAvailable(CustomRoutes, privilege).map(
										(o) => {
											return o.status ? (
												<>
													<Route
														key={o.name}
														path={o.path}
														element={o.element}
													/>
												</>
											) : (
												<Route
													key={o.name}
													path={o.path}
													element={
														CustomRoutes.filter(
															(o) => o.name == '404'
														)[0].element
													}
												/>
											);
										}
									)}
								</Routes>
							</div>
						</div>
					</div>
				</BrowserRouter>
			)}
			<Modal open={openModal}>
				<div className=" top-1/3 p-5 ml-12 md:left-1/3 absolute shadow-md bg-white dark:bg-[#42464D] h-auto md:h-auto text-center rounded-lg md:w-400 w-3/4 ">
					<p style={{ color: currentColor }}>
						<RiLoader4Line className=" animate-spin mt-7 text-5xl md:text-7xl absolute left-1/3 ml-5 md:ml-7" />
					</p>
					<p className=" text-2xl font-semibold mt-20 md:mt-24">
						Loading...
					</p>
				</div>
			</Modal>
		</div>
	);
};

const PrivateRouter = (props) => {
	const { currentAuth } = useStateContext();

	useEffect(() => {
		if (currentAuth === null) {
			redirect('/login');
		}
	}, [currentAuth]);

	return (
		<Route path={props.data.path} element={props.data.element} />
	);
};

export default App;
